import { routesPathConstants } from "./routesPath.constant";
export const SIDEBAR_BUTTONS = {
  DASHBOARD: "DASHBOARD",
  SELL_AND_TRADE: "SELL_AND_TRADE",
  INVENTORY: "INVENTORY",
  ANALYTICS: "ANALYTICS",
  EMPLOYEES: "EMPLOYEES",
  CUSTOMERS: "CUSTOMERS",
  SETTINGS: "SETTINGS",
  ADMIN: "ADMIN",
};

export const NESTED_SIDEBAR_OPTIONS = {
  DASHBOARD: [],
  SELL_AND_TRADE: [
    {
      name: "New Transaction",
      route: routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION,
    },
    {
      name: "New Return",
      route: routesPathConstants.SELL_AND_TRADE_NEW_RETURN,
    },
    {
      name: "Open/Close",
      route: routesPathConstants.SELL_AND_TRADE_OPEN_CLOSE_REGISTER,
    },
    {
      name: "Cash Management",
      route: routesPathConstants.SELL_AND_TRADE_CASH_MANAGEMENT,
    },
    {
      name: "Register Sessions",
      route: routesPathConstants.SELL_AND_TRADE_REGISTER_SESSIONS,
    },
    { name: "History", route: routesPathConstants.SELL_AND_TRADE_HISTORY },
    { name: "Drafts", route: routesPathConstants.SELL_AND_TRADE_DRAFTS },
  ],
  INVENTORY: [
    { name: "Items", route: routesPathConstants.INVENTORY_ITEMS },
    { name: "History", route: routesPathConstants.INVENTORY_ITEMS_HISTORY },
    // { name: "Summary", route: routesPathConstants.INVENTORY_ITEMS_SUMMARY },
    {
      name: "Price Changes",
      route: routesPathConstants.INVENTORY_ITEMS_PRICE_CHANGES,
    },
    {
      name: "Invoices",
      route: routesPathConstants.INVENTORY_INVOICES,
    },
    // {
    //   name: "Stock Audit",
    //   route: routesPathConstants.INVENTORY_ITEMS_STOCK_AUDIT,
    // },
  ],
  ANALYTICS: [
    {
      name: "Sales Summary",
      route: routesPathConstants.ANALYTICS_SALES_SUMMARY,
    },
  ],
  EMPLOYEES: [],
  CUSTOMERS: [],
  SETTINGS: [
    // { name: "Users", route: routesPathConstants.SETTINGS_USERS },
    { name: "Devices", route: routesPathConstants.SETTINGS_DEVICES },
    { name: "Discounts", route: routesPathConstants.SETTINGS_DISCOUNT },
    // {
    //   name: "Price Changes",
    //   route: routesPathConstants.SETTINGS_PRICE_CHANGES,
    // },
    // { name: "Employees", route: routesPathConstants.SETTINGS_EMPLOYEES },
    { name: "Trade-Ins", route: routesPathConstants.SETTINGS_TRADE_INS },
    {
      name: "Item Organization",
      route: routesPathConstants.SETTINGS_ITEM_ORGANIZATION,
    },
    {
      name: "Price Markups",
      route: routesPathConstants.SETTINGS_PRESET_PRICES,
    },
    {
      name: "Printer Settings",
      route: routesPathConstants.SETTINGS_PRINTER_SETTINGS,
    },
    {
      name: "Store Settings",
      route: routesPathConstants.SETTINGS_RECEIPT_AND_LABELS,
    },
    {
      name: "Outlets",
      route: routesPathConstants.SETTINGS_OUTLETS,
    },

    // {
    //   name: "Shopify Connection",
    //   route: routesPathConstants.SETTINGS_SHOPIFY_CONNECTION,
    // },
  ],
  ADMIN: [
    { name: "Stores", route: routesPathConstants.ADMIN_STORES },
    { name: "Companies", route: routesPathConstants.ADMIN_COMPANIES },
    { name: "Devices", route: routesPathConstants.ADMIN_DEVICES },
    { name: "Superadmins", route: routesPathConstants.ADMIN_SYSTEMUSERS },
  ],
};
