import {
  EMPTY_STRING,
  outletConstants,
  printerConstants,
} from "../../../constants";
import { STORE_CARD_PAYMENT_TYPE_ENUMS } from "../../../system/globalEnums";
import {
  getFormattedDateTime,
  parseToThousandSeperatorDecimalNumber,
} from "../../utility";
import {
  getItemsList,
  prepareItemRow,
  getTotalItemsQuantity,
  alignValueLeftAndRight,
  getPrintReceiptHeaderSection,
} from "./prepareReceiptCommon";
import { printReceiptData } from "./printReceipt";

//------- wrap a sentense in way that any word donot break
export const wordWrap = (text, maxLineLength) => {
  const words = text.split(" ");
  let lines = [];
  let currentLine = "";

  words.forEach((word) => {
    if ((currentLine + word).length <= maxLineLength) {
      currentLine += `${word} `;
    } else {
      lines.push(currentLine.trim());
      currentLine = `${word} `;
    }
  });

  if (currentLine.trim().length > 0) {
    lines.push(currentLine.trim());
  }

  // Join lines with line break escape character
  return lines.join("\x0A");
};

//------- Open Cash drawer
export const handleOpenCashDrawer = (printer) => {
  printReceiptData(
    ["\x10" + "\x14" + "\x01" + "\x00" + "\x05"],
    printer,
    false
  );
};

//------- function to prepare a receipt
export const prepareReceiptForPrinter = (
  transactionData,
  user,
  id,
  type,
  storeDetails,
  selectedCustomer,
  receiptItems,
  discountAmount,
  subTotal,
  taxAmount,
  totalPrice,
  cashTenderedAmount,
  cashOwedAmount,
  giftCardPaymentHistory,
  openCashDrawer,
  giftReceipt,
  isPercentDiscount,
  discountValue,
  printTime,
  isReprint = false
) => {
  const dateTime = printTime;
  const currency = "$";

  //------- Show Payment history for a transaction or trade
  const getPaymentHistory = (history) => {
    let historyString = "Payment History:\n";

    history.forEach((payment) => {
      let amount = parseToThousandSeperatorDecimalNumber(
        payment.currentAmountToPay
      );
      if (payment.isUndoTransaction) {
        switch (payment.paymentType) {
          //------- if payment processed with credit card
          case "creditCard":
            historyString +=
              alignValueLeftAndRight(
                "Paid Credit Card:",
                `${currency}${amount}`
              ) + "\n";
            if (
              payment.activeCardPaymentType ===
              STORE_CARD_PAYMENT_TYPE_ENUMS.VERIFONE
            ) {
              historyString +=
                alignValueLeftAndRight(
                  "Card Type:",
                  `${payment.creditCard.paymentBrand}`
                ) +
                "\n" +
                alignValueLeftAndRight(
                  "Last 4:",
                  `${payment.creditCard.maskedPan}`
                ) +
                "\n" +
                alignValueLeftAndRight(
                  "Auth #:",
                  `${payment.creditCard.paymentTokenValue}`
                ) +
                "\n";
            }
            break;
          //------- if payment processed with gift card
          case "giftCard":
            historyString +=
              alignValueLeftAndRight(
                `Paid ${payment.giftCardName}:`,
                `${currency}${amount}`
              ) +
              "\n" +
              alignValueLeftAndRight(
                "Remaining Balance:",
                `${currency}${parseToThousandSeperatorDecimalNumber(
                  payment.remainingBalance
                )}`
              ) +
              "\n";
            break;
          //------- if payment processed with store credit
          case "storeCredit":
            historyString +=
              alignValueLeftAndRight(
                "Paid Store Credit:",
                `${currency}${amount}`
              ) + "\n";
            break;
          //------- if payment processed with cash
          case "cash":
            historyString +=
              alignValueLeftAndRight("Paid Cash:", `${currency}${amount}`) +
              "\n" +
              alignValueLeftAndRight(
                "Tendered Amount:",
                `${currency}${parseToThousandSeperatorDecimalNumber(
                  payment.cashTenderedAmount
                )}`
              ) +
              "\n" +
              alignValueLeftAndRight(
                "Change Owed:",
                `${currency}${parseToThousandSeperatorDecimalNumber(
                  payment.cashChangeOwed
                )}`
              ) +
              "\n";
            break;
          default:
            break;
        }
      }
    });

    return historyString;
  };

  //------- prepare receipt data to print
  const receiptData = [
    ...getPrintReceiptHeaderSection({
      id,
      type,
      user,
      dateTime,
      isReprint,
      storeDetails,
      openCashDrawer,
      selectedCustomer,
    }),
    //Items List
    // `${prepareItemRow("Item", "Qty", "Price")}`,
    `${prepareItemRow("Item", "Qty", !giftReceipt ? "Price" : "")}`,
    "\x1B" + "\x45" + "\x0A", // bold off
    "\x0A", // line break
    // `${getItemsList(receiptItems).join("")}`, // line break
    `${getItemsList(receiptItems, giftReceipt, true).join("")}`,
    "__________________________________________" + "\x0A",
    "\x0A", // line break
    //Total Items
    giftReceipt
      ? ""
      : `${alignValueLeftAndRight(
          "Total Items :",
          `${getTotalItemsQuantity(receiptItems)}`
        )}` +
        "\x0A" +
        // Subtotal
        `${alignValueLeftAndRight(
          "Subtotal :",
          `${currency}${parseToThousandSeperatorDecimalNumber(subTotal)}`
        )}` +
        "\x0A" +
        // Discount
        `${alignValueLeftAndRight(
          `Discount(${
            isPercentDiscount ? `%${discountValue}` : `$${discountValue}`
          }) : `,
          `${currency}${parseToThousandSeperatorDecimalNumber(discountAmount)}`
        )}` +
        "\x0A" +
        // Tax
        `${alignValueLeftAndRight(
          `Tax(${transactionData.taxPercentage}%) :`,
          `${currency}${parseToThousandSeperatorDecimalNumber(taxAmount)}`
        )}` +
        "\x0A" +
        // Total Amount
        `${alignValueLeftAndRight(
          "Total Amount :",
          `${currency}${parseToThousandSeperatorDecimalNumber(totalPrice)}`
        )}` +
        "\x0A" +
        "__________________________________________" +
        "\x0A",

    //Paymnet History
    giftReceipt
      ? ""
      : `${getPaymentHistory(type.history)}` +
        "__________________________________________" +
        "\x0A",
    "\x0A", // line break

    "\x1B" + "\x61" + "\x31", // center align
    "\x1D\x68\x64", // Set barcode height (104 dots)
    "\x1D\x77\x02", // Set barcode width (module 2)
    `\x1D\x6B\x45\x0B${id}\x00`,
    "\x0A", // line break
    `${id}` + "\x0A" + "\x0A",
    //Return Policy
    `${
      storeDetails.returnPolicy
        ? wordWrap(
            storeDetails.returnPolicy,
            printerConstants.RECEIPT_ROW_LENGTH
          )
        : ""
    }`,
    "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A",
    "\x1B" + "\x69", // cut paper (old syntax)
    //"\x1D" + "\x56" + "\x01", // partial cut (new syntax)
  ];

  return receiptData;
};

export const prepareCashManagementReceiptForPrinter = (
  data = {
    header: {
      receiptTitle: EMPTY_STRING,
      storeName: EMPTY_STRING,
      receiptBalance: EMPTY_STRING,
    },
    body: [{ leftValue: EMPTY_STRING, rightValue: EMPTY_STRING }],
  },
  isDrawerOpen = false
) => {
  // Define a fixed width for the left column
  const leftColumnWidth = 15; // Adjust this value as needed

  const commands = [
    "\x1B" + "\x40", // Initialize printer
    "\x1B" + "\x61" + "\x30", // Left alignment
    "\x1B" + "\x21" + "\x01", // Set small font
    `${data.header.storeName}` + "\x0A" + "\x0A", // Store name
    "\x1B" + "\x21" + "\x00", // Reset font to normal
    "\x1B" + "\x61" + "\x31", // Center alignment
    `${data.header.receiptTitle}` + "\x0A" + "\x0A", // Receipt title
    "\x1B" + "\x21" + "\x30", // Emphasized mode on
    `${data.header.receiptBalance}`, // Receipt balance
    "\x1B" + "\x21" + "\x0A" + "\x1B" + "\x45" + "\x0A", // Emphasized mode off
    "\x0A", // Line Break
    "__________________________________________" + "\x0A", // Line
    "\x1B" + "\x61" + "\x30", // Left alignment
  ];

  // Add table data
  data.body.forEach((item) => {
    const paddedLeftValue = String(item.leftValue + ":").padEnd(
      leftColumnWidth,
      " "
    ); // Pad left value
    commands.push(
      "\x1B" + "\x21" + "\x08", // Set emphasized (bold) font for left value
      paddedLeftValue, // Left value with fixed width
      "\x1B" + "\x21" + "\x00", // Reset font to normal
      item.rightValue + "\x0A" // Right value with a new line
    );
  });

  // Add final commands
  commands.push(
    "__________________________________________" + "\x0A", // Line
    "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A", // Line breaks
    "\x1B" + "\x69", // Cut paper
    `${isDrawerOpen ? "\x10" + "\x14" + "\x01" + "\x00" + "\x05" : ""}`
  );

  return commands;
};
