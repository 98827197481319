import React from "react";
import { Form, Formik } from "formik";
import { Col, Modal, Row } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import { getModalConfiguration } from "../../../../shared/utility";
import { DollarIcon } from "../../../../assets/icons/iconsProvider";
import RadioButton from "../../../../shared/components/radioButton/RadioButton";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import {
  addNewOutletValidationSchema,
  addRemoveCashValidationSchema,
} from "../../../../shared/validationSchema/validationSchema";
import {
  EMPTY_STRING,
  outletConstants,
  buttonNameConstants,
  buttonTypeConstants,
  toastMessages,
  defaultGridState,
} from "../../../../constants";
import { connect } from "react-redux";

const AddRemoveCashModal = (props) => {
  //-------Props
  const {
    user,
    addCash,
    currentStore,
    currentOutlet,
    currentSessionId,
    isAddRemoveCashModal,
    toggleIsAddRemoveCashModal,
  } = props;

  //------Get Modal Configuration
  const { modalTitle, buttonType, radioButtonOptions, defaultOption } =
    getModalConfiguration(isAddRemoveCashModal);

  //-------initial values
  const initialValues = {
    registerName: currentOutlet?.name || EMPTY_STRING,
    amount: 0,
    note: EMPTY_STRING,
    cashType: defaultOption,
  };

  //-------handle hide modal
  const handleHideModal = () => {
    toggleIsAddRemoveCashModal(false);
  };

  //-------handle add remove cash modal
  const handleAddRemoveCashSubmit = (values) => {
    const dataToSend = {
      note: values.note,
      amount: values.amount,
      type: values.cashType,
      user: {
        userId: user?.id,
        name: `${user.firstName} ${user.lastName}`,
      },
      sessionId: currentSessionId,
      store: {
        id: currentStore?.id,
        name: currentStore?.storeName,
      },
      outlet: { id: currentOutlet?.id, name: currentOutlet?.name },
    };

    const message =
      isAddRemoveCashModal === outletConstants.ADD_CASH_MODAL
        ? toastMessages.CASH_ADDED_SUCCESSFULLY
        : toastMessages.CASH_REMOVED_SUCCESSFULLY;

    addCash(dataToSend, message, defaultGridState, handleHideModal);
  };

  //-------Modal Visibility
  const isModalVisible =
    isAddRemoveCashModal === outletConstants.ADD_CASH_MODAL ||
    isAddRemoveCashModal === outletConstants.REMOVE_CASH_MODAL;

  return (
    <Modal
      show={isModalVisible}
      onHide={handleHideModal}
      size="md"
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal"
    >
      <Formik
        initialValues={initialValues}
        validate={addRemoveCashValidationSchema}
        onSubmit={handleAddRemoveCashSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
              <Row>
                <Col md={12} className="d-flex align-items-center mb-2">
                  <RadioButton
                    radioButtons={radioButtonOptions}
                    name="cashType"
                    className=" flex-row-reverse"
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <InputTextField
                    label="Register Name"
                    name="registerName"
                    placeHolder="Enter Register Name"
                    maxLength={500}
                    disabled={true}
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <InputNumberField
                    label="Amount"
                    name="amount"
                    IconImage={DollarIcon}
                    isHandleKeyDown={true}
                    placeHolder="0.00"
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <InputTextField
                    label="Note (Optional)"
                    name="note"
                    placeHolder="Enter a note"
                    maxLength={500}
                  />
                </Col>
              </Row>
            </Modal.Body>{" "}
            <Modal.Footer>
              <Col md={3} className=" d-flex gap-2">
                <Button
                  type="submit"
                  className="w-100"
                  label={modalTitle}
                  buttonType={buttonType}
                ></Button>
              </Col>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddRemoveCashModal;
