import moment from "moment";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Topbar from "../../../../shared/components/topbar/Topbar";
import Button from "../../../../shared/components/Buttons/Button";
import {
  PRODUCT_TYPES_ENUMS,
  SET_BY_MARGIN_ENUMS,
} from "../../../../system/globalEnums";
import SelectField from "../../../../shared/components/selectField/SelectField";
import CheckboxField from "../../../../shared/components/checkBox/CheckboxField";
import SwitchButton from "../../../../shared/components/SwitchButton/SwitchButton";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import {
  toastType,
  toastMessages,
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  datepickerConstants,
  SET_BY_MARGIN_OPTIONS,
  tradeInDiscountConstant,
  tradeInDiscount,
} from "../../../../constants";
import {
  ClockIcon,
  DateIcon,
  DeleteIcon,
  PercentageIcon,
} from "../../../../assets/icons/iconsProvider";
import { tradeInDiscountActions } from "../../../../redux/actions";
import {
  discountValidationSchema,
  discountWithoutRangeValidationSchema,
} from "../../../../shared/validationSchema/validationSchema";
import {
  customToast,
  filterApplyToSearchOptions,
  handleMapSelectedOptionsInEdit,
  isSpinnerEnabled,
  handleCategorySelect,
  createCompanyDynamicUrl,
} from "../../../../shared/utility";
import TradeFilters from "../../../../shared/components/FilterDropdowns/TradeFilters";
import NewDatePicker from "../../../../shared/components/datePickerDropDown/NewDatePicker";

const CreateDiscount = (props) => {
  const {
    currentStore,
    spinnerArray,
    itemOrganization,
    addCategoryTypeDiscount,
    updateCategoryTypeDiscount,
  } = props;

  //-----reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;

  const { state } = useLocation();
  const navigate = useNavigate();
  const [selectedCategories, setSelectedCategories] = useState(
    state?.isEdit
      ? handleMapSelectedOptionsInEdit(
          state?.data?.discountGroup,
          state?.data?.discountType
        )
      : []
  );
  const [searchCategories, setSearchCategories] = useState([]);
  const [setByFilter, setSetByFilter] = useState("");
  const [filterProductType, setFilterProductType] = useState("");
  const [filterProductCategory, setFilterProductCategory] = useState("");
  const [rangeCheckBoxStatus, setRangeCheckBoxStatus] = useState(
    state?.data?.minPriceRange >= 0 && state?.data?.maxPriceRange >= 0
      ? true
      : false
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(startDate);

  const initialValues = {
    description: state?.data?.description || globalConstants.EMPTY_STRING,
    setBy: state?.data?.discountType || SET_BY_MARGIN_ENUMS.TYPE.value,
    discountPercentage:
      state?.data?.discountPercentage || globalConstants.EMPTY_STRING,
    minPrice:
      Number(state?.data?.minPriceRange) >= 0
        ? state?.data?.minPriceRange
        : "0.00",
    maxPrice:
      Number(state?.data?.maxPriceRange) >= 0
        ? state?.data?.maxPriceRange
        : "0.00",
    setEndDate:
      state?.data?.endDate &&
      state?.data?.endDate !== globalConstants?.DEFAULT_ENDDATE
        ? true
        : false,
  };

  const handleCancelButton = () => {
    navigate(createCompanyDynamicUrl(routesPathConstants.SETTINGS_DISCOUNT));
  };
  const handleSubmit = (data, { resetForm }) => {
    if (data.setEndDate) {
      if (startDate <= endDate) {
        handleFormSave(data, resetForm);
      } else {
        customToast(
          toastMessages.END_DATE_MUST_BE_GREATER_THAN_START_DATE,
          toastType.ERROR
        );
      }
    } else {
      handleFormSave(data, resetForm);
    }
  };
  const handleFormSave = (data, resetForm) => {
    if (selectedCategories?.length <= 0) {
      customToast(toastMessages.PLEASE_SELECT_CATEGORIES, toastType.ERROR);
      return;
    }
    const updatedSelectedCategories = selectedCategories.map((items) => {
      return data.setBy === SET_BY_MARGIN_ENUMS.TYPE.value
        ? {
            productType: items.name,
          }
        : data.setBy === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
        ? {
            categoryId: items.id,
            categoryName: items.name,
            parentCategoryId: items.parentCategoryId,
            parentCategoryName: items.parentCategoryName,
          }
        : {
            categoryId: items.id,
            categoryName: items.name,
          };
    });
    const dateToSend = {
      id: state?.data?.id ?? undefined,
      discountType: data.setBy,
      description: data.description,
      discountPercentage: data.discountPercentage,
      minPriceRange: rangeCheckBoxStatus
        ? data.minPrice
        : tradeInDiscountConstant.DEFAULT_MIN_RANGE,
      maxPriceRange: rangeCheckBoxStatus
        ? data.maxPrice
        : tradeInDiscountConstant.DEFAULT_MAX_RANGE,
      startDate: moment(startDate).toISOString(),
      endDate: data.setEndDate
        ? moment(endDate).endOf("day").toISOString()
        : globalConstants?.DEFAULT_ENDDATE,
      discountGroup: updatedSelectedCategories,
      store: {
        id: currentStore.id,
        name: currentStore.storeName,
      },
    };
    if (state?.isEdit) {
      updateCategoryTypeDiscount(currentStore?.id, dateToSend);
      // resetForm();
    } else {
      addCategoryTypeDiscount(currentStore?.id, dateToSend);
      // resetForm();
    }
  };
  const handleSwitchChange = (id, checkboxStatus) => {
    setRangeCheckBoxStatus(checkboxStatus);
  };

  const removeSelectedCategory = (categoryToRemove) => {
    setSelectedCategories(
      selectedCategories.filter(
        (category) => category.name !== categoryToRemove
      )
    );

    const fullCategoryObject = selectedCategories.find(
      (category) => category.name === categoryToRemove
    );

    if (fullCategoryObject) {
      setSearchCategories(
        [...searchCategories, fullCategoryObject].sort((a, b) =>
          a.name.localeCompare(b.name)
        )
      );
    }
  };
  const handleStartDate = (date, values) => {
    setStartDate(date);
  };
  const handleEndDate = (date) => {
    setEndDate(date);
  };
  const handleSetByChange = (option, isResetSelectedCategories = true) => {
    setSetByFilter(option);
    isResetSelectedCategories && setSelectedCategories([]);
    setSearchCategories(
      filterApplyToSearchOptions(
        option,
        customTypes.filter(
          (item) => item.productType !== PRODUCT_TYPES_ENUMS.ALL
        ),
        customCategories,
        customSubCategories,
        filterProductType,
        filterProductCategory,
        selectedCategories
      )
    );
  };

  const categoriesColumn = [
    {
      id: "categoryName",
      name: "Category Name",
      selector: (row) => row.name,
      cell: (row) => <span className="users-cell-max-size">{row.name}</span>,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            {
              <button
                type="button"
                onClick={() => {
                  removeSelectedCategory(row?.name);
                }}
                id={`tool-${row.id}`}
                className="action-Button-Wrapper"
              >
                <DeleteIcon className="cursor-pointer" />
              </button>
            }
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];

  useEffect(() => {
    if (state?.isEdit) {
      // setSelectedCategories([
      //   {
      //     ...state.data,
      //     name: state?.data?.categoryName,
      //     id: state?.data?.categoryId,
      //   },
      // ]);
      // setSelectedCategories(
      //   state?.data?.discountGroup?.map((row) => ({
      //     ...row,
      //     name: row?.categoryName,
      //     id: row?.categoryId,
      //   }))
      // );
      const startData = state?.data?.startDate;
      const dateObjectStart = new Date(startData);
      setStartDate(dateObjectStart);

      const endData = state?.data?.endDate;
      const dateObjectEnd =
        endData === globalConstants?.DEFAULT_ENDDATE
          ? new Date()
          : new Date(endData);
      setEndDate(dateObjectEnd);
    } else {
      setSelectedCategories([]);
    }
  }, [state]);

  useEffect(() => {
    // handle reload on add/edit discount page
    if (state.currentStore.id !== currentStore.id) {
      navigate(createCompanyDynamicUrl(routesPathConstants.SETTINGS_DISCOUNT));
    }
    // eslint-disable-next-line
  }, [currentStore]);

  useEffect(() => {
    handleSetByChange(
      state?.isEdit
        ? state?.data?.discountType
        : SET_BY_MARGIN_ENUMS.TYPE.value,
      false
    ); // eslint-disable-next-line
  }, [customTypes, customCategories, customSubCategories]);

  useEffect(() => {
    setSearchCategories(
      filterApplyToSearchOptions(
        setByFilter,
        customTypes.filter(
          (item) => item.productType !== PRODUCT_TYPES_ENUMS.ALL
        ),
        customCategories,
        customSubCategories,
        filterProductType,
        filterProductCategory,
        selectedCategories
      )
    ); // eslint-disable-next-line
  }, [setByFilter, filterProductType, filterProductCategory]);

  return (
    <>
      <Topbar
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.SETTINGS_DISCOUNT,
        }}
        title={!state?.isEdit ? "Create Discount" : "Edit Discount"}
      ></Topbar>
      <Row className="m-0 w-100  container-scrollbar user-info-wrapper">
        <Col className="p-0">
          <Formik
            initialValues={initialValues}
            validate={
              rangeCheckBoxStatus
                ? discountValidationSchema
                : discountWithoutRangeValidationSchema
            }
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values }) => (
              <Form className="">
                <Row className="m-0 ">
                  <Col md={8} className="">
                    <Row className="discount-value-div me-0 ms-0">
                      <Col md={12} className="mb-3 px-0">
                        <p className="heading-discount-value-text mb-1">
                          Description
                        </p>
                        <InputTextField
                          name="description"
                          placeHolder="Description"
                        />
                      </Col>
                      <Col md={12} className="mb-3 px-0">
                        <p className="heading-discount-value-text mb-1">
                          Set by:
                        </p>
                        <SelectField
                          name="setBy"
                          options={SET_BY_MARGIN_OPTIONS}
                          isSearchable={false}
                          placeHolder={"Set By..."}
                          onChangeValue={handleSetByChange}
                        />
                      </Col>
                      <Col md={12} className="p-0">
                        <TradeFilters
                          setBy={values.setBy}
                          customTypes={customTypes}
                          customCategories={customCategories}
                          filterProductType={filterProductType}
                          setFilterProductType={setFilterProductType}
                          setFilterProductCategory={setFilterProductCategory}
                          filterProductCategory={filterProductCategory}
                        />
                        <p className="heading-discount-value-text mb-1">
                          Apply to{" "}
                          {
                            SET_BY_MARGIN_OPTIONS.find(
                              (option) => option.value === values.setBy
                            ).label
                          }{" "}
                          :
                        </p>
                      </Col>
                      <Col md={12} className="p-0 position-relative">
                        <SelectField
                          name="searchOptions"
                          options={searchCategories.map((category) => ({
                            value: category,
                            label: category?.name,
                          }))}
                          isSearchable={true}
                          isSelectedValueAsPlaceholder={false}
                          placeHolder={
                            values.setBy === SET_BY_MARGIN_ENUMS.TYPE.value
                              ? "Search Types"
                              : "Search Categories"
                          }
                          onChangeValue={(option) => {
                            handleCategorySelect(
                              option,
                              setByFilter,
                              searchCategories,
                              setSearchCategories,
                              selectedCategories,
                              setSelectedCategories,
                              filterProductCategory
                            );
                          }}
                        />
                      </Col>

                      <Col md={12} className="p-0 mt-2">
                        <DataTable
                          className="categories-data-table"
                          columns={categoriesColumn}
                          data={selectedCategories}
                          fixedHeader={true}
                        />
                      </Col>
                      <Col md={5} className="mt-3 mb-3 p-0">
                        <SwitchButton
                          onSwitchChange={handleSwitchChange}
                          label={"Set Price Range"}
                          id="setpricerange"
                          className="switch-alignment"
                          isSwitchOn={rangeCheckBoxStatus}
                        />
                      </Col>
                      <Col
                        md={7}
                        className="d-flex gap-2 mt-2 mb-2 p-0 justify-content-between"
                      >
                        {rangeCheckBoxStatus && (
                          <>
                            <Col md={6} className=" ps-0 ">
                              <InputNumberField
                                name="minPrice"
                                placeHolder="Min Price"
                                type="number"
                              />
                            </Col>
                            <Col md={6} className=" pe-0 ">
                              <InputNumberField
                                name="maxPrice"
                                placeHolder="Max Price"
                                type="number"
                              />
                            </Col>
                          </>
                        )}
                      </Col>
                      <Col
                        md={5}
                        className="d-flex mt-3 justify-conetent-center  ps-0 gap-2"
                      >
                        <Col md={6} className="">
                          <SelectField
                            name="discountPercentageTitle"
                            placeHolder={"Percentage"}
                            options={tradeInDiscountConstant?.DISCOUNT_OPTION}
                            isSearchable={false}
                            isDisabled={true}
                            // defaultValue={tradeInDiscountConstant[0]}
                          />
                        </Col>

                        <Col md={6}>
                          <InputTextField
                            name="discountPercentage"
                            placeHolder="23"
                            type="number"
                            className=""
                            LeftIconImage={PercentageIcon}
                          />
                        </Col>
                      </Col>
                    </Row>

                    <Row className="activity-date-div me-0 ms-0">
                      <Col md={6} className="ps-0">
                        <span className="heading-discount-value-text">
                          Active Dates
                        </span>
                      </Col>
                      <Col
                        md={6}
                        className="d-flex flex-column justify-content-end align-items-start"
                      >
                        <CheckboxField
                          name="setEndDate"
                          label={datepickerConstants.SET_END_DATE}
                        />
                      </Col>
                      <Col
                        md={12}
                        className="p-0 d-flex gap-2 mt-4  align-items-start"
                      >
                        <Col className="pe-3" md={6}>
                          <NewDatePicker
                            IconImage={ClockIcon}
                            onDateChange={(date) =>
                              handleStartDate(date, values)
                            }
                            noOptionsMessage="No Date Found"
                            placeholder="Date"
                            label={datepickerConstants.START_DATE}
                            value={startDate}
                            minDate={new Date()}
                          />
                        </Col>
                        {values.setEndDate && (
                          <Col className="pe-3" md={6}>
                            <NewDatePicker
                              IconImage={ClockIcon}
                              onDateChange={handleEndDate}
                              noOptionsMessage="No Date Found"
                              placeholder="Date"
                              label={datepickerConstants.END_DATE}
                              value={endDate}
                              minDate={new Date()}
                            />
                          </Col>
                        )}
                      </Col>
                    </Row>
                    <Col md={5}></Col>
                    <Col md={7} className="mt-3 pe-0 mb-5 d-flex gap-2 ms-auto">
                      <Button
                        type="button"
                        label={buttonNameConstants.CANCEL}
                        buttonType={buttonTypeConstants.GHOST_BUTTON}
                        className="w-100"
                        handleClick={() => {
                          handleCancelButton();
                        }}
                        isDisabled={
                          isSpinnerEnabled(
                            spinnerArray,
                            tradeInDiscount.ADD_DISCOUNT
                          ) ||
                          isSpinnerEnabled(
                            spinnerArray,
                            tradeInDiscount.UPDATE_CATEGORYTYPE_DISCOUNT
                          )
                        }
                      ></Button>
                      <Button
                        type="submit"
                        className="w-100"
                        label={
                          state?.isEdit
                            ? buttonNameConstants.UPDATE
                            : buttonNameConstants.SAVE
                        }
                        isDisabled={
                          isSpinnerEnabled(
                            spinnerArray,
                            tradeInDiscount.ADD_DISCOUNT
                          ) ||
                          isSpinnerEnabled(
                            spinnerArray,
                            tradeInDiscount.UPDATE_CATEGORYTYPE_DISCOUNT
                          )
                        }
                      ></Button>
                    </Col>
                  </Col>
                  <Col md={4} className=""></Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};
//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  user: state.user,
  inventory: state.inventory,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  itemOrganization: state.itemOrganization,
  customTypes: state.itemOrganization.customTypes,
  customSubCategories: state.itemOrganization.customSubCategories,
  company: state.authentication.company,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addCategoryTypeDiscount: (storeId, data) => {
    dispatch(tradeInDiscountActions.addCategoryTypeDiscount(storeId, data));
  },
  updateCategoryTypeDiscount: (storeId, data) => {
    dispatch(tradeInDiscountActions.updateCategoryTypeDiscount(storeId, data));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateDiscount);
