import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

import {
  buttonNameConstants,
  buttonTypeConstants,
  EMPTY_STRING,
  inventoryConstants,
  invoiceConstants,
} from "../../../../constants";
import Button from "../../../../shared/components/Buttons/Button";
import { DollarIcon } from "../../../../assets/icons/iconsProvider";
import ProductTagList from "../../../../shared/components/tags/ProductTagList";
import CheckboxField from "../../../../shared/components/checkBox/CheckboxField";
import InputTextArea from "../../../../shared/components/inputTextArea/InputTextArea";
import CustomItemCategorySelectField from "../../Shared/CustomItemCategorySelectField";
import CustomItemConditionSelectField from "../../Shared/CustomItemConditionSelectField";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import CustomItemSubcategorySelectField from "../../Shared/CustomItemSubcategorySelectField";
import CustomItemProductTypeSelectField from "../../Shared/CustomItemProductTypeSelectField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import { addCustomItemValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import {
  getCustomProductConditionList,
  getTypeOrCategoryIdByName,
  getTypeOrCategoryObject,
  handleCalculateEachProductPercentage,
  isSpinnerEnabled,
  isStoreCustomProduct,
  isTagsEqual,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";
import { connect } from "react-redux";
import { invoiceActions } from "../../../../redux/actions";
import {
  PRODUCT_CONDITIONS_ENUMS,
  PRODUCT_TYPES_ENUMS,
} from "../../../../system/globalEnums";

const AddCustomItem = ({
  inventory,
  addToCart,
  itemOrganization,
  editCustomProduct,
  isCustomItemModal,
  toggleCustomItemModal,
  invoiceBatchInventory,
  setInvoiceBatchInventory,
}) => {
  //-------Reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;
  const { productTags } = inventory;

  //--------useState
  const [customProductSelectedTags, setCustomProductSelectedTags] = useState(
    editCustomProduct
      ? editCustomProduct?.tags?.filter(
          (tag) => tag !== inventoryConstants.CUSTOM_ITEM_TAG
        )
      : []
  );

  const [isProductExist, setIsProductExist] = useState(false);

  //-------inital values for customItem
  const customItemInitialValues = {
    quantity: editCustomProduct?.trackQuantity
      ? editCustomProduct.inStockQuantity
      : editCustomProduct?.inStockQuantity === 0
      ? EMPTY_STRING
      : 1,
    stockPrice: editCustomProduct?.inStockPrice || 0,
    costOfGoods: editCustomProduct?.costOfGoods || 0,
    upc: editCustomProduct?.upc || EMPTY_STRING,
    type: editCustomProduct
      ? getTypeOrCategoryIdByName(customTypes, editCustomProduct?.productType)
      : EMPTY_STRING,
    trackQuantity: editCustomProduct ? editCustomProduct?.trackQuantity : true,
    title: editCustomProduct?.productName || EMPTY_STRING,
    category: editCustomProduct
      ? getTypeOrCategoryIdByName(
          customCategories,
          editCustomProduct.consoleName,
          editCustomProduct?.productType
        )
      : EMPTY_STRING,
    condition: editCustomProduct?.productCondition || EMPTY_STRING,
    subCategory: editCustomProduct
      ? getTypeOrCategoryIdByName(
          customSubCategories,
          editCustomProduct.genre,
          editCustomProduct?.productType,
          editCustomProduct.consoleName
        )
      : EMPTY_STRING,
    description: editCustomProduct?.description || EMPTY_STRING,
  };

  //-------Handle Hide Modal
  const handleHideModal = () => {
    toggleCustomItemModal();
  };

  //-------handle Custom Item Submit
  const handleCustomItemSubmit = (values) => {
    const itemToAdd = {
      productId: inventoryConstants.STORE_CUSTOM_PRODUCT,
      subcategory:
        getTypeOrCategoryObject(customSubCategories, values.subCategory)
          ?.name || "",
      genre:
        getTypeOrCategoryObject(customSubCategories, values.subCategory)
          ?.name || "",
      upc: values.upc ?? EMPTY_STRING,
      tags: [
        ...(customProductSelectedTags || []),
        inventoryConstants.CUSTOM_ITEM_TAG,
      ],
      costOfGoods: values.costOfGoods,
      marketPrice: values.stockPrice,
      description: values.description,
      inStorePrice: 0,
      inStockPrice: values.stockPrice,
      inStockQuantity: values.trackQuantity ? values.quantity : 0,
      isItemLocked: false,
      isDataFetching: false,
      productsAvailableInInventory: editCustomProduct
        ? editCustomProduct?.productsAvailableInInventory
        : [],
      consoleName: getTypeOrCategoryObject(customCategories, values.category)
        ?.name,
      productName: values.title,
      productType: getTypeOrCategoryObject(customTypes, values.type)
        ?.productType,
      productCondition: values.condition,
      apiSource: inventoryConstants.STORE_CUSTOM_PRODUCT,
      trackQuantity: values.trackQuantity,
      productConditionList:
        getCustomProductConditionList(values.type, customTypes) || [],
      tcgPlayerUrl: EMPTY_STRING,
      serialNumber: EMPTY_STRING,
    };

    if (editCustomProduct?.id || editCustomProduct?.id === 0) {
      addToCart({ ...editCustomProduct, ...itemToAdd });
    } else {
      setInvoiceBatchInventory(
        handleCalculateEachProductPercentage([
          ...invoiceBatchInventory,
          {
            ...itemToAdd,
            id: invoiceBatchInventory?.length,
            isDataFetching: true,
          },
        ])
      );
    }
    handleHideModal();
  };

  //-------handle Track Quantity Change
  const handleTrackQuantityChange = (name, value, setFieldValue) => {
    setFieldValue("quantity", value ? 1 : "");
  };

  //-------onselectedtagchange
  const onCustomProductSelectTagsChange = (selectedTags) => {
    const selectedTagList = selectedTags.map((tag) => tag.label);
    setCustomProductSelectedTags(selectedTagList);
  };

  const getCutomProductIfExist = (values) => {
    const findProduct = editCustomProduct?.productsAvailableInInventory?.find(
      (product) => {
        if (
          product.product_name === values.title &&
          product.price.type === values.condition &&
          product.productType ===
            getTypeOrCategoryObject(customTypes, values.type)?.productType &&
          product.category_name ===
            getTypeOrCategoryObject(customCategories, values.category)?.name &&
          (!values.subCategory ||
            product.subcategory ===
              getTypeOrCategoryObject(customSubCategories, values.subCategory)
                ?.name) &&
          isTagsEqual(product.tags, [
            ...customProductSelectedTags,
            inventoryConstants.CUSTOM_ITEM_TAG,
          ])
        ) {
          return product;
        }
      }
    );
    return findProduct;
  };

  return (
    <Modal
      show={isCustomItemModal}
      size="lg"
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            {editCustomProduct ? "Edit Custom Item" : "Add Custom Item"}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body p-1">
        <Row className="m-0">
          <Formik
            initialValues={customItemInitialValues}
            validate={addCustomItemValidationSchema}
            onSubmit={handleCustomItemSubmit}
          >
            {({ values, setFieldValue }) => {
              setIsProductExist(getCutomProductIfExist(values));
              console.log("if exist", getCutomProductIfExist(values));
              return (
                <Form className="px-0">
                  <Col
                    md={12}
                    className="add-inventory-hero-wrapper my-2 py-4 px"
                  >
                    <Row className="m-0">
                      <Col
                        md={12}
                        className="mb-2 tab-heading text-muted d-flex justify-content-between align-items-center"
                      >
                        <span className="tab-heading">Product Information</span>
                        {isProductExist ? (
                          `${
                            isProductExist?.price?.quantity
                          } in stock at $${parseToThousandSeperatorDecimalNumber(
                            isProductExist?.price?.unit_sell_price
                          )}`
                        ) : (
                          <CheckboxField
                            name="trackQuantity"
                            label="Track Quantity"
                            isLabelLeft={true}
                            handleChange={(name, value) => {
                              handleTrackQuantityChange(
                                name,
                                value,
                                setFieldValue
                              );
                            }}
                          />
                        )}
                      </Col>
                      <Col md={9} className="mb-3">
                        <InputTextField
                          disabled={editCustomProduct}
                          name="title"
                          maxLength={100}
                          label="Title"
                          placeHolder="Enter Title"
                          className="border"
                        />
                      </Col>
                      <Col md={3} className="mb-3">
                        <InputNumberField
                          label="Quantity"
                          name="quantity"
                          placeHolder={values.trackQuantity ? "0" : ""}
                          className="add-inventory-quantity-field"
                          isConvertToDecimal={false}
                          step={"1"}
                          isHandleKeyDown={true}
                          isBorder={true}
                          disabled={!values.trackQuantity}
                        />
                      </Col>
                      <Col md={12} className="mb-3">
                        <InputTextArea
                          maxLength={1500}
                          name="description"
                          label="Description (Optional)"
                          placeHolder="Enter Description"
                          className={"border"}
                          disabled={editCustomProduct}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="add-inventory-hero-wrapper my-3">
                    <Row className="m-0">
                      <Col md={12} className="mb-2 tab-heading text-muted">
                        Product Categorization
                      </Col>

                      <Col md={3} className="mb-2">
                        <CustomItemProductTypeSelectField
                          setFieldValue={setFieldValue}
                          customTypes={customTypes}
                          isDisabled={editCustomProduct}
                        />
                      </Col>
                      <Col md={3} className="mb-2">
                        <CustomItemCategorySelectField
                          setFieldValue={setFieldValue}
                          customCategories={customCategories}
                          values={values}
                          isDisabled={editCustomProduct}
                        />
                      </Col>
                      <Col md={3} className="mb-2">
                        <CustomItemSubcategorySelectField
                          setFieldValue={setFieldValue}
                          values={values}
                          customTypes={customTypes}
                          customCategories={customCategories}
                          customSubCategories={customSubCategories}
                          isDisabled={editCustomProduct}
                        />
                      </Col>
                      <Col md={3} className="mb-2">
                        <CustomItemConditionSelectField
                          customTypes={customTypes}
                          values={values}
                        />
                      </Col>
                      <Col md={12} className="mb-3">
                        <InputTextField
                          name="upc"
                          label="UPC (Optional)"
                          placeHolder="111000294854"
                          className="border"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="add-inventory-hero-wrapper my-3">
                    <Row className="m-0">
                      <Col md={12} className="mb-2 tab-heading text-muted">
                        Product Tags
                      </Col>
                      <Col md={12}>
                        <ProductTagList
                          productDetail={{
                            tags: [
                              ...(customProductSelectedTags?.map(
                                (tag, index) => ({
                                  id: index,
                                  label: tag,
                                  selected: true,
                                })
                              ) || []),
                            ],
                          }}
                          className={"align-items-center"}
                          tagsList={[]}
                          suggestedArrayList={productTags}
                          onSelectTagsChange={onCustomProductSelectTagsChange}
                          customProductTags={true}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="add-inventory-hero-wrapper my-3">
                    <Row className="m-0">
                      <Col md={12} className="mb-2 tab-heading text-muted">
                        Pricing
                      </Col>
                      <Col md={6} className="mb-2">
                        <InputNumberField
                          label="Stock Price"
                          name="stockPrice"
                          placeHolder="0.00"
                          className="add-inventory-quantity-field"
                          isBorder={true}
                          IconImage={DollarIcon}
                        />
                      </Col>
                      <Col md={6} className="mb-2">
                        <InputNumberField
                          label="Cost of Goods"
                          name="costOfGoods"
                          placeHolder="0.00"
                          className="add-inventory-quantity-field"
                          isBorder={true}
                          IconImage={DollarIcon}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6} className="d-flex align-items-end gap-2 ms-auto">
                    <Button
                      type="button"
                      className="w-100"
                      label={buttonNameConstants.CANCEL}
                      handleClick={() => handleHideModal()}
                      buttonType={buttonTypeConstants.GHOST_BUTTON}
                    />
                    <Button
                      type="submit"
                      className="w-100"
                      label={
                        editCustomProduct
                          ? buttonNameConstants.SAVE
                          : buttonNameConstants.ADD_ITEM
                      }
                    />
                  </Col>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  itemOrganization: state.itemOrganization,
  invoiceBatchInventory: state.invoice.invoiceBatchInventory,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setInvoiceBatchInventory: (invoiceBatch) =>
    dispatch(invoiceActions.setInvoiceBatchInventory(invoiceBatch)),
});

//-------Export AddInvoice Component
export default connect(mapStateToProps, mapDispatchToProps)(AddCustomItem);
