import { Parse, IsExpired, GetVersion } from "aamva-parser";
import { City, State } from "country-state-city";

import { countryCodesConstants, EMPTY_STRING } from "../../constants";
import {
  capitalizeEveryWord,
  parseLiscenceScannedData,
  handleCustomerLicenseScanError,
} from "../utility";

export const handleScan = ({
  user,
  formikRef,
  logErrors,
  scannedData,
  currentStore,
  toggleScanningModal,
}) => {
  // Sample inputs for testing
  //   const sampleInputForIsDataParsed = `ANSI 636029090302DL00410284ZO03250015DLDAQC417380DACFRANKIEDADWANRAKDCSZEDEKIAHDCUDAG1316 PLAZA ST NWDAHDAISALEMDAJORDAK97304-40530DCACDCBNONEDCDNONEDBB04262002DAU068 inDAW240DAYBRODBC1DBD11082022DBA04262030DCFAY1010239DCGUSADCKAY1010239DDENDDFNDDGNDDHDDJ04262023DDKDDLDDAFDDB12082018DDDZOZOA11082022`;
  //   const sampleInputForIsDataSorted = `'', '@', 'ANSI 636014090102DL00410292ZC03330024DL', 'DAQY5597134', 'DCSBAINS', 'DDEN', 'DACSIMRAN', 'DDFN', 'DADSINGH', 'DDGNDCACDCBNONEDCDNONE', 'DBD12082020', 'DBB01012000', 'DBA01012029', 'DBC1', 'DAU076 IN', 'DAYBRO', 'DAG1234 MAIN ST', 'DAISAN JOSE', 'DAJCA', 'DAK951480000  ', 'DCF12/08/202066815/AAFD/25', 'DCGUSADAW205', 'DAZBLK', 'DCK20343Y55971340401DDAFDDB08292017DDJ11012028DDK1ZCZCABRNZCBBLKZCCZCD'`;
  //   const preparedData = parseLiscenceScannedData(
  //     sampleInputForIsDataSorted,
  //     IsExpired
  //   );
  const preparedData = parseLiscenceScannedData(scannedData, IsExpired);
  let usLicenseData = EMPTY_STRING;

  if (preparedData.isDataSorted) {
    usLicenseData = preparedData.data;
    const state = State.getStatesOfCountry(countryCodesConstants.USA).find(
      (state) => state.isoCode === usLicenseData.state
    );
    formikRef.current.setValues((prevVal) => ({
      ...prevVal,
      city: usLicenseData?.city
        ? capitalizeEveryWord(usLicenseData?.city)
        : EMPTY_STRING,
      lastName: usLicenseData?.lastName || EMPTY_STRING,
      state: state?.name || EMPTY_STRING,
      firstName: usLicenseData?.firstName || EMPTY_STRING,
      line1: usLicenseData?.address || EMPTY_STRING,
      zip: usLicenseData?.postalCode || EMPTY_STRING,
      driverLicense: usLicenseData?.licenseNumber || EMPTY_STRING,
      expirationDate: usLicenseData?.expirationDate
        ? usLicenseData?.expirationDate
        : null,
    }));
    toggleScanningModal();
  } else if (preparedData.isDataParsed) {
    usLicenseData = Parse(preparedData.data);
    const state = State.getStatesOfCountry(countryCodesConstants.USA).find(
      (state) => state.isoCode === usLicenseData.state
    );
    formikRef.current.setValues((prevVal) => ({
      ...prevVal,
      city: usLicenseData?.city
        ? capitalizeEveryWord(usLicenseData.city)
        : EMPTY_STRING,
      lastName: usLicenseData?.lastName || EMPTY_STRING,
      state: state?.name || EMPTY_STRING,
      firstName: usLicenseData?.firstName || EMPTY_STRING,
      line1: usLicenseData?.streetAddress || EMPTY_STRING,
      zip: usLicenseData?.postalCode?.slice(0, 5) || EMPTY_STRING,
      driverLicense: usLicenseData?.driversLicenseId || EMPTY_STRING,
      expirationDate: usLicenseData?.expirationDate
        ? usLicenseData?.expirationDate
        : null,
    }));
    toggleScanningModal();
  } else {
    const errorMessgae = `scannedPayload: ${scannedData} , error Type: ${
      preparedData.data
    },missing Substrings: ${
      preparedData?.missingSubstrings?.join("-") || EMPTY_STRING
    }`;
    handleCustomerLicenseScanError(user, errorMessgae, logErrors, currentStore);
  }
};
