import React from "react";
import { useNavigate } from "react-router-dom";

import {
  createCompanyDynamicUrl,
  isSessionOpenedByCurrentUser,
} from "../../../shared/utility";

const SidebarListItem = ({
  tab,
  user,
  index,
  outlet,
  isTillOpen,
  currentOutlet,
}) => {
  const navigate = useNavigate();

  const isOpenCloseTab = tab.name === "Open/Close";
  const isCashManagementTab = tab.name === "Cash Management";
  const isSessionOpenedByUser = isSessionOpenedByCurrentUser(outlet, user);

  //------Example Scenarios:
  //------No Outlet Selected (!currentOutlet?.id):
  //------The "Open/Close" tab will be disabled because no outlet is selected.
  //------Session Open but Not by Current User:
  //------The "Open/Close" tab will be disabled if the session was opened by someone else.
  //------No Session Open:
  //------The "Cash Management" tab will be disabled if there is no open session.
  //------Session Open but Not by Current User (Cash Management):
  //------The "Cash Management" tab will be disabled if the session was opened by someone else.
  const isDisabled =
    (!currentOutlet?.id && isOpenCloseTab) ||
    (isTillOpen && isOpenCloseTab && !isSessionOpenedByUser) ||
    (!isTillOpen && isCashManagementTab) ||
    (isCashManagementTab && !isSessionOpenedByUser);

  // Determine if the item is active
  const isActive = window.location.pathname.includes(tab.route);

  // Handle click event
  const handleClick = () => {
    if (isDisabled) return; // Ignore click if disabled
    navigate(createCompanyDynamicUrl(tab.route)); // Navigate to the route
  };

  return (
    <li
      key={index}
      className={`d-flex justify-content-center align-items-start flex-column my-1 ${
        isDisabled ? "sidebar-disbaled-link" : ""
      } ${isActive ? "active-nested-sidebar-btn" : ""}`}
      onClick={handleClick}
      aria-disabled={isDisabled}
      role="menuitem"
    >
      {tab.name}
    </li>
  );
};

export default SidebarListItem;
