import serverResponseHandler from "../system/serverResponseHandler";
import { getPaginationFilterParamsFromGridState } from "../shared/utility";
import {
  getRequest,
  putRequest,
  postRequest,
  deleteRequest,
} from "../system/serverCall";
import { EMPTY_STRING, OUTLET_UPDATE_API_TYPE } from "../constants";

//-------Get All Outlets of Current Store
const getOutlets = (storeId, isActive) => {
  return getRequest(
    `outlet/${storeId}${isActive ? "?isActive=false" : ""}`,
    true
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Outlet by MacAddress
const getOutletByMacAddress = (storeId, macAddress) => {
  return getRequest(`outlet/${storeId}/${macAddress}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Add Outlet
const addOutlet = (storeId, data) => {
  return postRequest(`outlet/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Edit Outlet
const editOutlet = (data) => {
  return putRequest(`outlet/${data.store.id}/${data.id}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const editOutletLoggedInUsers = (data) => {
  return putRequest(
    `outlet/temp/${data.macAddress}?type=${OUTLET_UPDATE_API_TYPE.LOGGED_IN_USERS_ON_LOGOUT}`,
    data
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Delete Outlet
const deleteOutlet = (storeId, outletId) => {
  return deleteRequest(`outlet/${storeId}/${outletId}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Open Register
const openRegister = (data) => {
  return postRequest(
    `outlet-session/${data?.store?.id}/${data?.outlet?.id}`,
    data
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Open Till
const openTill = (data) => {
  return putRequest(
    `outlet-session/${data?.store?.id}/${data?.outlet?.id}/${data?.id}?editOutletCase=OPEN_TILL`,
    data
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Close Register
const closeRegister = (data) => {
  return putRequest(
    `outlet-session/${data?.store?.id}/${data?.outlet?.id}/${data?.id}?editOutletCase=CLOSE_OUTLET_SESSION`,
    data
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Outlet by MacAddress
const getCashManagementDetails = (storeId, outetId, sessionId, gridState) => {
  return getRequest(
    `cash/${storeId}/${outetId}/${sessionId}?${getPaginationFilterParamsFromGridState(
      gridState
    )}`,
    true
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Add Cash
const addCash = (data) => {
  return postRequest(
    `cash/${data?.store?.id}/${data?.outlet?.id}/${data?.sessionId}`,
    data
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Session Details By Session Id
const getSessionDetails = (storeId, outletId, sessionId) => {
  return getRequest(`outlet-session/${storeId}/${outletId}/${sessionId}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Session Details By Session Id
const getAllSessionsHistory = (storeId, gridState) => {
  return getRequest(
    `outlet-session/${storeId}?${getPaginationFilterParamsFromGridState(
      gridState
    )}`
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Single Session Detail History
const getSessionHistory = (storeId, sessionId) => {
  return getRequest(`outlet-session-history/${storeId}/${sessionId}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

export const outletService = {
  addCash,
  openTill,
  addOutlet,
  editOutlet,
  getOutlets,
  deleteOutlet,
  openRegister,
  closeRegister,
  getSessionDetails,
  getSessionHistory,
  getAllSessionsHistory,
  getOutletByMacAddress,
  editOutletLoggedInUsers,
  getCashManagementDetails,
};
