export const outletConstants = {
  ACTIVE: "Active",
  ENABLED: "Enabled",
  CASH_IN: "Cash In",
  ADD_CASH: "ADD_CASH",
  INACTIVE: "Inactive",
  DISABLED: "Disbaled",
  CASH_OUT: "Cash Out",
  ADD_OUTLET: "ADD_OUTLET",
  CASH_DRAWER_OPEN: "Cash Drawer Opened",
  EDIT_OUTLET: "EDIT_OUTLET",
  MAC_ADDRESS: "MAC_ADDRESS",
  GET_OUTLETS: "GET_OUTLETS",
  SALE_ENTERY: "Sale - Payment Received",
  OUTLET_GRID_ROW_HEIGHT: 48,
  TRADE_ENTERY: "Trade Purchase - Cash Given",
  DELETE_OUTLET: "DELETE_OUTLET",
  RETURN_ENTERY: " Return - Cash Given",
  OPEN_REGISTER: "OPEN_REGISTER",
  PETTY_CASH_IN: "Petty Cash In",
  NOT_CONFIGURED: "NOT_CONFIGURED",
  CLOSE_REGISTER: "CLOSE_REGISTER",
  ADD_CASH_MODAL: "ADD_CASH_MODAL",
  PETTY_CASH_OUT: "Petty Cash Out",
  REMOVE_CASH_MODAL: "REMOVE_CASH_MODAL",
  GET_SESSION_HISTORY: "GET_SESSION_HISTORY",
  GET_ALL_SESSION_HISTORY: "GET_ALL_SESSION_HISTORY",
  GET_LAST_SESSION_DETAILS: "GET_LAST_SESSION_DETAILS",
  GET_OUTLET_BY_MAC_ADDRESS: "GET_OUTLET_BY_MAC_ADDRESS",
  GET_CASH_MANAGEMENT_DETAILS: "GET_CASH_MANAGEMENT_DETAILS",
  GET_CURRENT_SESSION_DETAILS: "GET_CURRENT_SESSION_DETAILS",
  STORE_OUTLETS: "STORE_OUTLETS",
};

export const OUTLET_UPDATE_API_TYPE = {
  LOGGED_IN_USERS_ON_LOGOUT: "LOGGED_IN_USERS_ON_LOGOUT",
};

export const NO_MAC_ADDRESS_FOUND = "NO_MAC_ADDRESS_FOUND";
