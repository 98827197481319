import React from "react";
import { connect } from "react-redux";
import { Col, Modal, Row } from "react-bootstrap";

import { systemActions } from "../../../../redux/actions";
import Button from "../../../../shared/components/Buttons/Button";
import { PRODUCT_TYPES_ENUMS } from "../../../../system/globalEnums";
import SelectDropDown from "../../../../shared/components/selectDropdown/SelectDropdown";
import {
  buttonNameConstants,
  buttonTypeConstants,
  inventoryConstants,
  toastType,
} from "../../../../constants";
import {
  customToast,
  handleBulkConditionChange,
  handleCalculateEachProductPercentage,
} from "../../../../shared/utility";

const BatchBulkUpdateModal = ({
  activateSpinner,
  tradeDiscount,
  deactivateSpinner,
  isBulkModal,
  setIsBulkModal,
  bulkUpdateCondition,
  rowsToUpdateCondition,
  setBulkUpdateCondition,
  addBatchInventory,
  setAddBatchInventory,
  setRowsToUpdateCondition,
  setToggleClearRows,
  toggledClearRows,
}) => {
  const { markupPresets, tradeinMargin } = tradeDiscount;

  const handleHideBulkModal = () => {
    setIsBulkModal(!isBulkModal);
  };

  const handleBulkSubmit = () => {
    if (bulkUpdateCondition) {
      const bulkUpdateItemIds = rowsToUpdateCondition.map((item) => item.id);
      activateSpinner(inventoryConstants.BULK_UPDATE);

      handleBulkConditionChange(
        markupPresets,
        bulkUpdateCondition?.value,
        bulkUpdateItemIds,
        false,
        addBatchInventory,
        setAddBatchInventory,
        tradeinMargin,
        handleCalculateEachProductPercentage
      );

      setIsBulkModal(false);
      setRowsToUpdateCondition([]);
      setBulkUpdateCondition("");
      setToggleClearRows(!toggledClearRows);
      deactivateSpinner(inventoryConstants.BULK_UPDATE);
    } else {
      customToast("Condition not selected", toastType.ERROR);
    }
  };

  return (
    <Modal
      show={isBulkModal}
      size="sm"
      onHide={handleHideBulkModal}
      animation={true}
      centered
      backdrop="static"
      className={"add-inventory-modal bulk-modal"}
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">Bulk Update</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body d-flex ">
        <Row className="m-0">
          <Col md={4} className="ps-md-0 d-flex align-items-center mb-3">
            Condition
          </Col>
          <Col md={8} className="pe-md-0  mb-3">
            <SelectDropDown
              menuPortalTarget=""
              menuPlacement="bottom"
              handleChange={(e) => {
                setBulkUpdateCondition(e);
              }}
              options={
                rowsToUpdateCondition[0]?.productType ===
                PRODUCT_TYPES_ENUMS.VIDEO_GAME
                  ? inventoryConstants.VIDEO_GAME_CONDITION
                  : rowsToUpdateCondition[0]?.productType ===
                    PRODUCT_TYPES_ENUMS.TRADING_CARD
                  ? inventoryConstants.TRADING_CARD_CONDITION
                  : inventoryConstants.OTHER_CONDITION
              }
              value={bulkUpdateCondition}
            />
          </Col>
          <Col md={6} className="ps-md-0">
            <Button
              label={buttonNameConstants.CANCEL}
              className="w-50"
              type="button"
              handleClick={() => {
                setIsBulkModal(false);
              }}
              buttonType={buttonTypeConstants.GHOST_BUTTON}
            />
          </Col>
          <Col md={6}>
            <Button
              label={buttonNameConstants.UPDATE}
              className="w-50"
              type="button"
              handleClick={handleBulkSubmit}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  tradeDiscount: state.tradeDiscount,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
});

//-------Export AddNewUser Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchBulkUpdateModal);
