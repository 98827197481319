import React from "react";

import NewDatePicker from "../datePickerDropDown/NewDatePicker";

const ExpirationDateInput = (props) => {
  // parent props
  const { name, value, setFieldValue } = props;
  return (
    <NewDatePicker
      minDate={new Date()}
      IconImage={true}
      label={"Expiration Date"}
      placeholderText="Select Expiration Date"
      noOptionsMessage="No Date Found"
      isFormik={true}
      name={name}
      value={value}
      setFieldValue={setFieldValue}
    />
  );
};

export default ExpirationDateInput;
