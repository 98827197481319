import React, { useState } from "react";

import { DeleteIcon } from "../../../assets/icons/iconsProvider";
import VerticalNumberField from "../inputNumberField/VerticalNumberField";
import {
  getProductDetailCardTags,
  getShortestSku,
  isAllowedDeleteItem,
  parseToDecimalNumber,
  parseToThousandSeperatorDecimalNumber,
} from "../../utility";
import { inventoryConstants, transactionConstants } from "../../../constants";
import SimpleNumberField from "../inputNumberField/SimpleNumberField";
import ToggleButtonWithText from "../toggleButtons/ToggleButtonWithText";
import { TRADING_CARD_CATEGORIES_ENUMS } from "../../../system/globalEnums";

const ProductCartCard = ({
  productName,
  skuNumber,
  price,
  handleDeleteCartItem,
  quantity,
  max,
  handleQuantityChange,
  discountPercentage,
  actualPrice,
  isDiscountApplied,
  handlePriceChange,
  category,
  productObject,
  isNewTransaction = false,
  key,
  handleSingleItemDiscountField,
  handleDiscountType,
  handleDiscountValueChange,
}) => {
  const handleLocalQuantityChange = (sku, newQuantity) => {
    handleQuantityChange(sku, newQuantity);
  };

  const handleInputClick = (event) => {
    event.stopPropagation();
  };

  const toggleDiscountTab = () => {
    handleDiscountType(skuNumber);
  };

  return (
    <div
      className={`cart-card ${
        productObject?.isSingleItemDiscountField ? "opened-cart-item-card" : ""
      } d-flex flex-column mb-2`}
    >
      <div
        className="cart-card cart-inner-card d-flex align-items-center gap-2 justify-content-between py-1 cursor-pointer"
        key={key}
        onClick={() => {
          handleSingleItemDiscountField(skuNumber);
        }}
      >
        <VerticalNumberField
          sku={skuNumber}
          quantity={quantity}
          maxValue={max}
          disabled={productObject?.serialNumber}
          onChange={handleLocalQuantityChange}
        />
        <div
          className={`cart-card-name-wrapper ${
            !discountPercentage > 0 && "cart-card-name-without-discount"
          } d-flex flex-column gap-1`}
        >
          <span className="user-select-none">{productName}</span>
          <span className="d-flex align-items-center gap-2 user-select-none">
            {category === transactionConstants.MISCELLANEOUS_SALE_ITEM
              ? transactionConstants.MISCELLANEOUS
              : category}
            {isNewTransaction && ` (${productObject.type}) `}
            {isNewTransaction &&
              getProductDetailCardTags(
                productObject.tags?.filter(
                  (tag) => tag !== inventoryConstants.CUSTOM_ITEM_TAG
                ),
                skuNumber
              )}
          </span>
          {
            <span className="d-flex align-items-center gap-2">
              <span className="trade-cart-cart-sku pe-1">{`#${getShortestSku(
                skuNumber
              )}`}</span>
              {productObject?.subcategory ===
                TRADING_CARD_CATEGORIES_ENUMS.SYSTEM &&
                productObject?.serialNumber && (
                  <span className="pe-1">
                    S/N : {productObject?.serialNumber}
                  </span>
                )}
            </span>
          }
        </div>
        {discountPercentage > 0 ? (
          <div className="cart-card-discount-wrapper d-flex justify-content-center align-items-center gap-2">
            {Number(discountPercentage) > 0 ? (
              <span className="text-decoration-line-through">
                ${parseToThousandSeperatorDecimalNumber(actualPrice)}
              </span>
            ) : (
              ""
            )}
            <span
              className={`cursor-pointer ${
                !Number(discountPercentage) > 0
                  ? "opacity-50 text-decoration-line-through"
                  : "discounted-amount"
              }`}
            >
              {!productObject.isToggleDiscount
                ? `-${discountPercentage}%`
                : `-$${discountPercentage}`}
            </span>
          </div>
        ) : (
          ""
        )}

        <div className=" d-flex justify-content-center align-items-center gap-2">
          <div className="amount-to-paid-field-wrapper product-cart-card-price">
            <span className="dollar-icon-alignment">$</span>
            <input
              type="number"
              className="amount-to-paid-field"
              value={price}
              step={0.01}
              onChange={(e) => {
                handlePriceChange(skuNumber, e.target.value);
              }}
              onBlur={(e) => {
                const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
                handlePriceChange(skuNumber, inputValue);
              }}
              max={999999}
              min={1}
              onClick={handleInputClick}
            />
          </div>
          <button
            type="button"
            className="cart-card-delete-button"
            onClick={(event) => handleDeleteCartItem(event, skuNumber)}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
      {productObject?.isSingleItemDiscountField && (
        <div className="single-item-cart-item-discount py-2 px-2 border-top d-flex justify-content-end align-items-center gap-3">
          <span className="opacity-50">Discount</span>
          <SimpleNumberField
            className="discount-number-field"
            value={discountPercentage}
            onChange={(value) => {
              handleDiscountValueChange(skuNumber, value);
            }}
            maxValue={
              !productObject?.isToggleDiscount ? 100 : Number(actualPrice)
            }
          />
          <ToggleButtonWithText
            isToggle={productObject?.isToggleDiscount}
            handleLeftButtonClick={toggleDiscountTab}
            handleRightButtonClick={toggleDiscountTab}
            leftButtonLabel="%"
            rightButtonLabel="$"
            className="discount-toggle-button"
          />
        </div>
      )}
    </div>
  );
};

export default ProductCartCard;
