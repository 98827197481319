import { invoiceConstants } from "../constants";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";
import { getPaginationFilterParamsFromGridState } from "../shared/utility";

//-------Get Invoices
const getAllInvoices = (storeId, gridState) => {
  return getRequest(
    `invoice/${storeId}?${getPaginationFilterParamsFromGridState(gridState)}`,
    true
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Invoices
const addInvoice = (storeId, data) => {
  return postRequest(`invoice/${storeId}`, data, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const editInvoice = (data) => {
  return putRequest(`invoice/${data.store?.id}/${data.id}`, data, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Vendors
const getVendors = (storeId) => {
  return getRequest(
    `store-misc-data/${storeId}/${invoiceConstants.INVOICE_VENDOR}`,
    true
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Add Vendor
const addVendor = (storeId, data) => {
  return postRequest(
    `store-misc-data/${storeId}/${invoiceConstants.INVOICE_VENDOR}`,
    data,
    true
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Delete Vendor
const deleteInvoice = (storeId, id) => {
  return deleteRequest(`invoice/${storeId}/${id}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

export const invoiceService = {
  addVendor,
  addInvoice,
  getVendors,
  editInvoice,
  deleteInvoice,
  getAllInvoices,
};
