import {
  PRINT_LABEL_DESIGN_ENUMS,
  PRODUCT_TYPES_ENUMS,
} from "../system/globalEnums";
import { routesPathConstants } from "./routesPath.constant";

//-------Export Global Constants
export const globalConstants = {
  DAILY: "Daily",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
  CUSTOM: "Custom",
  ALL: "All",
  LARGE_SCREEN_SIZE: 1920,

  EMPTY_STRING: "",
  EMPTY_OBJECT: {},
  REQUEST: "_REQUEST",
  SUCCESS: "_SUCCESS",
  FAILURE: "_FAILURE",
  SORT: {
    ASC: "asc",
    DESC: "desc",
  },
  DRAFT: "Draft",
  CASH: "cash",
  CREDIT: "storeCredit",
  GIFT_CARD: "giftCard",
  CREDIT_CARD: "creditCard",
  TYPE_ID_TRD: "TRD",
  TYPE_ID_TRA: "TRA",
  TYPE_RETURN: "RET",
  DEFAULT_ENDDATE: "2099-12-03T03:16:17.652Z",
  MAX_LENGTH_OF_PRODUCT_TITLE: 35,
  MAX_LENGTH_OF_PRODUCT_CATEGORY: 12,
  COMPANY_DOMAIN_URL: "COMPANY_DOMAIN_URL",

  NOT_CONFIGURED: "Not Configured",
};

export const gridSortingConstants = {
  DEFAULT: "",
  ACTIVE: "active",
  ASCENDING: "asc",
  DESCENDING: "desc",
  INACTIVE: "inactive",
};

export const countryCodesConstants = {
  USA: "US",
  CANADA: "CA",
};

export const defaultGridState = {
  pageIndex: 1,
  pageNumber: 1,
  totalRecords: 0,
  searchQuery: "",
  pageEndRecordNumber: 0,
  pageStartRecordNumber: 0,
  pageSizeOptions: [10, 20, 50, 100, 500],
  sortFilter: globalConstants.EMPTY_STRING,
  pageSize: process.env.REACT_APP_GRID_PAGE_SIZE,
  filters: "",
};

export const defaultSaleProductsState = {
  pageIndex: 1,
  totalRecords: 0,
  searchQuery: "",
  pageEndRecordNumber: 0,
  pageStartRecordNumber: 0,
  sortFilter: globalConstants.EMPTY_STRING,
  category: globalConstants.EMPTY_STRING,
  productType: globalConstants.EMPTY_STRING,
  subcategory: globalConstants.EMPTY_STRING,
  pageSize: process.env.REACT_APP_GRID_PAGE_SIZE,
};

export const IMAGE_FILE_TYPES = {
  PNG: "image/png",
  JPG: "image/jpg",
  JPEG: "image/jpeg",
};

export const toggleSwitchButtonConstants = {
  SHOW_ON_LABEL: "showOnLabels",
  SHOW_ON_RECEIPT: "showOnReceipt",
  IS_OUTLET_TRACKING: "isOutletTracking",
  IS_SALE_OUT_OF_STOCK: "isSaleOutOfStock",
  IS_ID_REQUIRED_TRADE_IN: "isIdForTradein",
  IS_CASH_TRACKING_ENABLED: "isCashTracking",
  IS_AUTO_PRINT_RECEIPT: "isAutoPrintReceipt",
  IS_SERIAL_NUMBER_REQUIRED_INVENTORY: "isSerialNumber",
  ALWAYS_USE_STOCK_PRICE_FOR_COGS: "alwaysUseStockPriceForCogs",
  IS_ID_REQUIRED_TRADE_IN_STORE_CREDIT: "isIdForTradeinStoreCredit",
  APPLY_STORE_CREDIT_PAYMENTS_AS_PRE_TAX: "applyStoreCreditPaymentsAsPreTax",
  IS_ITEMIZED_BREAKDOWN_ON_TRADE_RECEIPT: "isItemizedBreakDownForTradeReceipt",
};

//------- Print Label Design Options for Print label modal
export const PRINT_LABEL_DESIGN_OPTIONS = [
  {
    value: PRINT_LABEL_DESIGN_ENUMS.STANDARD.value,
    label: PRINT_LABEL_DESIGN_ENUMS.STANDARD.label,
  },
  {
    value: PRINT_LABEL_DESIGN_ENUMS.MINIMAL.value,
    label: PRINT_LABEL_DESIGN_ENUMS.MINIMAL.label,
  },
  {
    value: PRINT_LABEL_DESIGN_ENUMS.ALTERNATE.value,
    label: PRINT_LABEL_DESIGN_ENUMS.ALTERNATE.label,
  },
];

export const CONSOLE_LOGS_TYPES = {
  LOG: "log",
  ERROR: "error",
  WARNING: "warn",
};

export const STATS_SCREEN_CONSTANTS = {
  DASHBOARD_STATS: "DASHBOARD_STATS",
  FINANCE_STATS: "FINANCE_STATS",
  SALES_SUMMARY_STATS: "SALES_SUMMARY_STATS",
};

export const NO_NESTED_SIDEBAR_ROUTES = [
  routesPathConstants.CUSTOMERS,
  routesPathConstants.DASHBOARD,
  routesPathConstants.PRINTER_SETTINGS,
  routesPathConstants.BILLING,
  routesPathConstants.PAYOUTS,
  routesPathConstants.USER_INFORMATION,
  routesPathConstants.CUSTOMERS_EDIT,
  routesPathConstants.CUSTOMERS_ADD,
  routesPathConstants.SETTINGS_USERS,
  routesPathConstants.SETTINGS_USERS_EDIT_USER,
  routesPathConstants.ADD_NEW_USER,
];
