export const invoiceConstants = {
  DRAFT: "DRAFT",
  FULFILLED: "FULFILLED",
  ADD_INVOICE: "ADD_INVOICE",
  GET_VENDORS: "GET_VENDORS",
  DELETE_INVOICE: "DELETE_INVOICE",
  ADD_NEW_VENDOR: "ADD_NEW_VENDOR",
  INVOICE_VENDOR: "INVOICE_VENDOR",
  FULFILL_INVOICE: "FULFILL_INVOICE",
  GET_ALL_INVOICES: "GET_ALL_INVOICES",
  INVOICE_CUSTOM_PRODUCT: "INVOICE_CUSTOM_PRODUCT",
  SET_INVOICE_BATCH_INVENTORY: "SET_INVOICE_BATCH_INVENTORY",
  STATUS_OPTIONS: [
    {
      value: "All",
      label: "All",
    },
    {
      value: "DRAFT",
      label: "Draft",
    },
    {
      value: "FULFILLED",
      label: "Fulfilled",
    },
  ],
};
