import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

import { authenticationActions } from "../../redux/actions";
import ConfirmationModal from "../../views/Inventory/Items/ConfirmationModal/ConfirmationModal";

const IdleUserModal = () => {
  const [isIdleModal, setIsIdleModal] = useState(false);
  const { getLastActiveTime, reset } = useIdleTimer({
    timeout: process.env.REACT_APP_IDLE_USER_TIMEOUT,
    onIdle: () => {
      if (!isIdleModal) {
        setIsIdleModal(true);
      }
    },
    debounce: 500,
  });

  return (
    <ConfirmationModal
      isConfirmationModal={isIdleModal}
      handleNoButtonClick={() => {}}
      handleYesButtonClick={() => {
        authenticationActions.employeeLogout();
      }}
      toggleConfirmationModal={setIsIdleModal}
      message={"Your Session has expired!"}
      isNoButton={false}
      yesButtonText={"Sign in again"}
      size={"sm"}
    />
  );
};

export default IdleUserModal;
