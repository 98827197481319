import { globalConstants, invoiceConstants } from "../../constants";
import { updateObject } from "../../shared/utility";

//------initialState
const initialState = {
  vendors: [],
  invoices: [],
  invoiceBatchInventory: [],
};

//-------Export Invoice Reducer
export const invoice = (state = initialState, action) => {
  switch (action.type) {
    //-------Get Invoices success case
    case invoiceConstants.GET_ALL_INVOICES + globalConstants.SUCCESS:
      return updateObject(state, {
        invoices: action.data,
      });
    //-------Get Invoices failure case
    case invoiceConstants.GET_ALL_INVOICES + globalConstants.FAILURE:
      return updateObject(state, {
        invoices: [],
      });
    //-------Get Vendors success case
    case invoiceConstants.GET_VENDORS + globalConstants.SUCCESS:
      return updateObject(state, {
        vendors: action.data,
      });
    //-------Get Vendors failure case
    case invoiceConstants.GET_VENDORS + globalConstants.FAILURE:
      return updateObject(state, {
        vendors: [],
      });
    //-------Set Invoice Batch Inventory
    case invoiceConstants.SET_INVOICE_BATCH_INVENTORY:
      return updateObject(state, {
        invoiceBatchInventory: action.data,
      });
    //-------Default State
    default:
      return state;
  }
};
