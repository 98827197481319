import {
  outletConstants,
  systemConstants,
  toastMessages,
  toastType,
  printerConstants,
  transactionConstants,
  transactionDefaultData,
  tradeTransactionDefaultData,
} from "../../constants";
import { outletService } from "../../services";
import {
  customToast,
  getStoreSaleCartDetailsFromStorage,
  isTransactionSplit,
  updateOutletInLocalStorage,
} from "../../shared/utility";
import { custom, failure, success } from "./actionsUtilities";
import { transactionActions } from "./transaction.action";

//-------Add Outlet
const addOutlet = (storeId, data, toggleAddNewOutletModal) => {
  return (dispatch) => {
    dispatch(
      custom(outletConstants.ADD_OUTLET, systemConstants.SPINNER_ACTIVATE)
    );
    outletService
      .addOutlet(storeId, data)
      .then(
        (response) => {
          dispatch(getOutlets(storeId));
          dispatch(getOutletByMacAddress(storeId, data.macAddress));
          customToast(
            toastMessages.OUTLET_ADDED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          toggleAddNewOutletModal();
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(outletConstants.ADD_OUTLET, systemConstants.SPINNER_DEACTIVATE)
        );
      });
  };
};

//-------Edit Outlet
const editOutlet = (updatedOutletData, toggleAddNewOutletModal, outletData) => {
  return (dispatch) => {
    dispatch(
      custom(outletConstants.EDIT_OUTLET, systemConstants.SPINNER_ACTIVATE)
    );
    outletService
      .editOutlet(updatedOutletData)
      .then(
        (response) => {
          dispatch(getOutlets(updatedOutletData.store.id));
          dispatch(
            getOutletByMacAddress(
              updatedOutletData.store.id,
              updatedOutletData.id
            )
          );
          dispatch(
            handleResetCartOnOutletCashTrackingUpdate(
              updatedOutletData,
              outletData
            )
          );
          toggleAddNewOutletModal();
          customToast(toastMessages.UPDATED_SUCCESSFULLY, toastType.SUCCESS);
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            outletConstants.EDIT_OUTLET,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Edit Outlet
const editOutletLoggedInUsers = (data) => {
  return (dispatch) => {
    dispatch(
      custom(outletConstants.EDIT_OUTLET, systemConstants.SPINNER_ACTIVATE)
    );
    outletService
      .editOutletLoggedInUsers(data)
      .then(
        (response) => {},
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            outletConstants.EDIT_OUTLET,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get Outlets
const getOutlets = (storeId, isActive = false) => {
  return (dispatch) => {
    dispatch(
      custom(outletConstants.GET_OUTLETS, systemConstants.SPINNER_ACTIVATE)
    );
    outletService
      .getOutlets(storeId, isActive)
      .then(
        (response) => {
          dispatch(success(response, outletConstants.GET_OUTLETS));
        },
        (error) => {
          dispatch(failure([], outletConstants.GET_OUTLETS));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            outletConstants.GET_OUTLETS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get Outlet By MacAddress
const getOutletByMacAddress = (storeId, macAddress) => {
  return (dispatch) => {
    dispatch(
      custom(
        outletConstants.GET_OUTLET_BY_MAC_ADDRESS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    outletService
      .getOutletByMacAddress(storeId, macAddress)
      .then(
        (response) => {
          updateOutletInLocalStorage(storeId, response);
          dispatch(
            success(response, outletConstants.GET_OUTLET_BY_MAC_ADDRESS)
          );
        },
        (error) => {
          updateOutletInLocalStorage(storeId, {});
          dispatch(failure({}, outletConstants.GET_OUTLET_BY_MAC_ADDRESS));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            outletConstants.GET_OUTLET_BY_MAC_ADDRESS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//------- Open Register
const openRegister = (data, macAddress, setIsStartSellingFrom) => {
  return (dispatch) => {
    dispatch(
      custom(outletConstants.OPEN_REGISTER, systemConstants.SPINNER_ACTIVATE)
    );
    outletService
      .openRegister(data)
      .then(
        (response) => {
          setIsStartSellingFrom(true);
          customToast(
            toastMessages.SESSION_STARTED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getOutletByMacAddress(data?.store?.id, macAddress));
        },
        (error) => {
          dispatch(getOutletByMacAddress(data?.store?.id, macAddress));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            outletConstants.OPEN_REGISTER,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//------- Opening till
const openTill = (data, macAddress) => {
  return (dispatch) => {
    dispatch(
      custom(outletConstants.OPEN_REGISTER, systemConstants.SPINNER_ACTIVATE)
    );
    outletService
      .openTill(data)
      .then(
        (response) => {
          customToast(
            toastMessages.REGISTER_OPENED_SUCCESSFULLY,
            toastType.SUCCESS
          );

          dispatch(getOutletByMacAddress(data?.store?.id, macAddress));
          // Print cash management receipt
          dispatch(
            custom(
              { ...response, type: outletConstants.OPEN_REGISTER },
              printerConstants.PRINT_CASH_MANAGEMENT_RECEIPT
            )
          );
        },
        (error) => {
          dispatch(getOutletByMacAddress(data?.store?.id, macAddress));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            outletConstants.OPEN_REGISTER,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//------- Close Register
const closeRegister = (data, macAddress, setIsStartSellingFrom) => {
  return (dispatch) => {
    dispatch(
      custom(outletConstants.CLOSE_REGISTER, systemConstants.SPINNER_ACTIVATE)
    );
    outletService
      .closeRegister(data)
      .then(
        (response) => {
          if (setIsStartSellingFrom) {
            setIsStartSellingFrom(false);
            customToast(
              toastMessages.SESSION_CLOSED_SUCCESSFULLY,
              toastType.SUCCESS
            );
          } else {
            customToast(
              toastMessages.REGISTER_CLOSED_SUCCESSFULLY,
              toastType.SUCCESS
            );
          }
          dispatch(getOutletByMacAddress(data?.store?.id, macAddress));
          if (!setIsStartSellingFrom) {
            // Print cash management receipt
            dispatch(
              custom(
                { ...response, type: outletConstants.CLOSE_REGISTER },
                printerConstants.PRINT_CASH_MANAGEMENT_RECEIPT
              )
            );
          }
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            outletConstants.CLOSE_REGISTER,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get Cash Management Details
const getCashManagementDetails = (storeId, outetId, sessionId, gridState) => {
  return (dispatch) => {
    dispatch(
      custom(
        outletConstants.GET_CASH_MANAGEMENT_DETAILS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    outletService
      .getCashManagementDetails(storeId, outetId, sessionId, gridState)
      .then(
        (response) => {
          dispatch(
            success(response, outletConstants.GET_CASH_MANAGEMENT_DETAILS)
          );
        },
        (error) => {
          dispatch(failure([], outletConstants.GET_CASH_MANAGEMENT_DETAILS));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            outletConstants.GET_CASH_MANAGEMENT_DETAILS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//------- Add Cash
const addCash = (data, message, gridState, handleHideModal) => {
  return (dispatch) => {
    dispatch(
      custom(outletConstants.ADD_CASH, systemConstants.SPINNER_ACTIVATE)
    );
    outletService
      .addCash(data)
      .then(
        (response) => {
          if (message) {
            customToast(message, toastType.SUCCESS);
          }
          handleHideModal();
          dispatch(
            getCashManagementDetails(
              data?.store?.id,
              data?.outlet?.id,
              data?.sessionId,
              gridState
            )
          );
          dispatch(
            getCurrentSessionDetails(
              data?.store?.id,
              data?.outlet?.id,
              data?.sessionId
            )
          );
          // Print cash management receipt
          dispatch(
            custom(data, printerConstants.PRINT_CASH_MANAGEMENT_RECEIPT)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(outletConstants.ADD_CASH, systemConstants.SPINNER_DEACTIVATE)
        );
      });
  };
};

//-------get Current Session Details
const getCurrentSessionDetails = (storeId, outletId, sessionId) => {
  return (dispatch) => {
    dispatch(
      custom(
        outletConstants.GET_CURRENT_SESSION_DETAILS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    outletService
      .getSessionDetails(storeId, outletId, sessionId)
      .then(
        (response) => {
          dispatch(
            success(response, outletConstants.GET_CURRENT_SESSION_DETAILS)
          );
        },
        (error) => {
          dispatch(failure([], outletConstants.GET_CURRENT_SESSION_DETAILS));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            outletConstants.GET_CURRENT_SESSION_DETAILS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get last session details
const getLastSessionDetails = (storeId, outletId, sessionId) => {
  return (dispatch) => {
    dispatch(
      custom(
        outletConstants.GET_LAST_SESSION_DETAILS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    outletService
      .getSessionDetails(storeId, outletId, sessionId)
      .then(
        (response) => {
          dispatch(success(response, outletConstants.GET_LAST_SESSION_DETAILS));
        },
        (error) => {
          dispatch(failure([], outletConstants.GET_LAST_SESSION_DETAILS));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            outletConstants.GET_LAST_SESSION_DETAILS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get All Session History
const getAllSessionsHistory = (storeId, gridState) => {
  return (dispatch) => {
    dispatch(
      custom(
        outletConstants.GET_ALL_SESSION_HISTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    outletService
      .getAllSessionsHistory(storeId, gridState)
      .then(
        (response) => {
          dispatch(success(response, outletConstants.GET_ALL_SESSION_HISTORY));
        },
        (error) => {
          dispatch(failure([], outletConstants.GET_ALL_SESSION_HISTORY));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            outletConstants.GET_ALL_SESSION_HISTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get Single Session History
const getSessionHistory = (storeId, sessionId) => {
  return (dispatch) => {
    dispatch(
      custom(
        outletConstants.GET_SESSION_HISTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    outletService
      .getSessionHistory(storeId, sessionId)
      .then(
        (response) => {
          dispatch(success(response, outletConstants.GET_SESSION_HISTORY));
        },
        (error) => {
          dispatch(failure([], outletConstants.GET_SESSION_HISTORY));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            outletConstants.GET_SESSION_HISTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get last session details
const emptyCurrentAndLastSession = () => {
  return (dispatch) => {
    dispatch(failure({}, outletConstants.GET_LAST_SESSION_DETAILS));
    dispatch(failure({}, outletConstants.GET_CURRENT_SESSION_DETAILS));
  };
};

const emptyOutlet = () => {
  return (dispatch) => {
    dispatch(failure([], outletConstants.GET_OUTLETS));
    dispatch(failure({}, outletConstants.GET_OUTLET_BY_MAC_ADDRESS));
  };
};
//-------Delete Single Outlet
const deleteOutlet = (storeId, outletId) => {
  return (dispatch) => {
    dispatch(
      custom(outletConstants.DELETE_OUTLET, systemConstants.SPINNER_ACTIVATE)
    );
    outletService
      .deleteOutlet(storeId, outletId)
      .then(
        (response) => {
          dispatch(getOutlets(storeId));
          dispatch(getOutletByMacAddress(storeId, outletId));
          customToast(toastMessages.DELETED_SUCCESSFULLY, toastType.SUCCESS);
        },
        (error) => {
          dispatch(failure([], outletConstants.DELETE_OUTLET));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            outletConstants.DELETE_OUTLET,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

// In case of successful cashTrackingEnable, empty transactions/trade cart (iff split payment)
export const handleResetCartOnOutletCashTrackingUpdate = (
  updatedOutletData,
  outletData
) => {
  return (dispatch) => {
    if (updatedOutletData.isCashTracking && !outletData.isCashTracking) {
      const storeId = outletData.store.id;
      const saleTransaction = getStoreSaleCartDetailsFromStorage(
        transactionConstants.NEW_SALE_PAGE_DATA,
        storeId,
        transactionDefaultData
      );
      const tradeTransaction = getStoreSaleCartDetailsFromStorage(
        transactionConstants.NEW_TRADE_PAGE_DATA,
        storeId,
        tradeTransactionDefaultData
      );

      if (isTransactionSplit(saleTransaction)) {
        dispatch(
          transactionActions.setTransactionData(transactionDefaultData, storeId)
        );
      }
      if (isTransactionSplit(tradeTransaction)) {
        dispatch(
          transactionActions.setNewTradeData(
            tradeTransactionDefaultData,
            storeId
          )
        );
      }
    }
  };
};

//-------Export Outlet Actions
export const outletActions = {
  addCash,
  openTill,
  addOutlet,
  editOutlet,
  getOutlets,
  emptyOutlet,
  deleteOutlet,
  openRegister,
  closeRegister,
  getSessionHistory,
  getAllSessionsHistory,
  getOutletByMacAddress,
  getLastSessionDetails,
  editOutletLoggedInUsers,
  getCurrentSessionDetails,
  getCashManagementDetails,
  emptyCurrentAndLastSession,
};
