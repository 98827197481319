import React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router";
import { Row, Col } from "react-bootstrap";

import {
  EMPTY_STRING,
  SIDEBAR_BUTTONS,
  NO_MAC_ADDRESS_FOUND,
} from "../../constants";
import {
  createCompanyDynamicUrl,
  isSpinnerEnabled,
  parseToNumber,
} from "../../shared/utility";
import LogoImage from "../../assets/images/LoginLogo.png";
import { authenticationActions } from "../../redux/actions";
import Button from "../../shared/components/Buttons/Button";
import { TopbarLogoutIcon } from "../../assets/icons/iconsProvider";
import InputTextField from "../../shared/components/inputTextField/InputTextField";
import { employeeLoginValidationSchema } from "../../shared/validationSchema/validationSchema";
import InputPasswordField from "../../shared/components/inputPasswordField/InputPasswordField";
import {
  systemConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  authenticationConstants,
} from "../../constants";
import CompanyLogo from "../../assets/images/CompanyLogo.png";
import { getMacAddress } from "../../shared/Printers/ReceiptPrinters/printReceipt";

//-------EmployeeLogin component start here
const EmployeeLogin = (props) => {
  const navigate = useNavigate();
  const initialValues = {
    companyUserId: "",
    password: "",
  };
  //-------Handle EmployeeLogin click
  const handleEmployeeLogin = async (values) => {
    props.handleEmployeeLoginClick(
      {
        companyUserId: parseToNumber(values.companyUserId),
        password: values.password,
        macAddress: (await getMacAddress()) || NO_MAC_ADDRESS_FOUND,
      },
      props?.company?.id
    );
    props.setActiveSidebarBtn(SIDEBAR_BUTTONS.DASHBOARD);
  };

  const handleForgotPinClick = () => {
    navigate(createCompanyDynamicUrl(routesPathConstants.FORGOT_PIN));
  };

  const handleCompanyLogout = () => {
    props.companyLogout();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
    }
  };

  //-------Return EmployeeLogin Component
  return (
    <div>
      <div className=" login-card-wrapper row d-flex justify-content-center align-items-center m-0 p-4 min-vh-100 min-vw-100">
        <img
          className="login-collect-pos-icon"
          src={LogoImage}
          alt="collect pos icon"
        />
        <div className="login-card col-sm-8 col-md-6 col-lg-4 py-4 px-5 pb-3 d-flex flex-column justify-content-center align-items-center rounded-4">
          <Row className="w-100 m-0">
            <Col md={12} className="d-flex p-0">
              <button
                className="logout-button d-flex justify-content-center align-items-center gap-1 p-0"
                type="button"
                onClick={handleCompanyLogout}
              >
                <TopbarLogoutIcon />
                Log Out
              </button>
            </Col>
            <Col
              md={12}
              className="p-0 d-flex justify-content-center align-items-center py-3"
            >
              <span className="company-name">
                {props?.company?.companyName || EMPTY_STRING}
              </span>
              {/* <img src={CompanyLogo} className="company-logo" /> */}
            </Col>
          </Row>
          <h1 className="py-2">Employee Login</h1>
          <Formik
            initialValues={initialValues}
            validate={employeeLoginValidationSchema}
            onSubmit={handleEmployeeLogin}
          >
            <Form className="login-form-wrapper" autoComplete="off">
              <Row className="w-100 m-0">
                <Col xs={12} className="p-0 mb-3">
                  <InputTextField
                    type="text"
                    name="companyUserId"
                    maxLength={4}
                    label="Employee ID"
                    placeHolder="Enter Employee ID"
                    autoComplete="new-password"
                    isFocus={true}
                    handleKeyDown={handleKeyDown}
                  />
                </Col>
                <Col xs={12} className="p-0 mb-3">
                  <InputPasswordField
                    type="password"
                    name="password"
                    label="PIN"
                    maxLength={100}
                    placeHolder="Enter PIN"
                    autoComplete="new-password"
                    handleKeyDown={(event) => {}}
                  />
                </Col>
                <Col xs={12} className="p-0 mt-3 ">
                  <Button
                    type="submit"
                    className="w-100"
                    label={buttonNameConstants.LOGIN}
                    buttonType={buttonTypeConstants.BLACK_BUTTON}
                    isDisabled={isSpinnerEnabled(
                      props.spinnerArray,
                      authenticationConstants.EMPLOYEE_LOGIN
                    )}
                  />
                </Col>
                <Col
                  xs={12}
                  className="p-0 mt-2 mb-0 d-flex align-items-center"
                >
                  <span
                    className="forgot-password-link"
                    onClick={handleForgotPinClick}
                  >
                    Forgot Your ID or PIN?
                  </span>
                </Col>
                <Col
                  xs={12}
                  className="p-0 mt-4 mb-0 d-flex justify-content-center align-items-center text-muted"
                >
                  Powered by CollectPOS
                </Col>
              </Row>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}; //-------EmployeeLogin component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerArray: state.system.spinnerArray,
  company: state.authentication.company,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  handleEmployeeLoginClick: (data, companyId) =>
    dispatch(authenticationActions.employeeLogin(data, companyId)),
  companyLogout: () => dispatch(authenticationActions.companyLogout()),
  setActiveSidebarBtn: (data) =>
    dispatch({ type: systemConstants.SET_ACTIVE_SIDEBAR_BTN, data: data }),
});

//-------Export EmployeeLogin Component
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeLogin);
