import moment from "moment";

// compare string case incessitive
export const isStringEqual = (a, b) => {
  return typeof a === "string" && typeof b === "string"
    ? a.localeCompare(b, undefined, { sensitivity: "accent" }) === 0
    : a === b;
};

// remove duplicate values of array of string
export const getArrayUniqueValues = (arr) => {
  return [...new Set(arr)];
};

export const isDateOlderThanToday = (date) => {
  return new moment(date) < new moment();
};
