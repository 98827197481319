import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import SidebarListItem from "./SidebarListItem";
import {
  globalConstants,
  toggleSwitchButtonConstants,
} from "../../../constants";

const NestedSidebarBody = ({ user, outlet, ...props }) => {
  //------reducer
  const { currentOutlet, isTillOpen } = outlet;

  return (
    <>
      {props?.currentStore?.[toggleSwitchButtonConstants.IS_OUTLET_TRACKING] ? (
        <div className="d-flex flex-column align-items-start border-bottom sidebar-outlet-name-wrapper">
          <span>{currentOutlet?.name || globalConstants.NOT_CONFIGURED}</span>
          <span>Outlet</span>
        </div>
      ) : (
        ""
      )}

      <ul className="p-0 d-flex flex-column justify-content-center align-items-center">
        {props.tabs.map((tab, index) => {
          return (
            <SidebarListItem
              tab={tab}
              user={user}
              key={index}
              index={index}
              outlet={outlet}
              currentOutlet={currentOutlet}
              isTillOpen={isTillOpen}
            />
          );
        })}
      </ul>
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  outlet: state.outlet,
  user: state.authentication.user,
  currentStore: state?.store?.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NestedSidebarBody);
