import { connect } from "react-redux";
import { Col, Modal, Row } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";

import AddCustomItem from "./AddCustomItem";
import VendorDropdown from "./VendorDropdown";
import EditOrResetCogs from "./EditOrResetCogs";
import Button from "../../../../shared/components/Buttons/Button";
import SearchInventory from "../../Items/SearchInventory/SearchInventory";
import BatchProductEdit from "../../Items/AddBatchInventory/BatchProductEdit";
import { TRADING_CARD_CATEGORIES_ENUMS } from "../../../../system/globalEnums";
import ConfirmationModal from "../../Items/ConfirmationModal/ConfirmationModal";
import BatchBulkUpdateModal from "../../Items/AddBatchInventory/BatchBulkUpdateModal";
import TableCustomHeader from "../../../../shared/components/table/TableCustomHeader";
import SelectDropDown from "../../../../shared/components/selectDropdown/SelectDropdown";
import NewDatePicker from "../../../../shared/components/datePickerDropDown/NewDatePicker";
import SimpleNumberField from "../../../../shared/components/inputNumberField/SimpleNumberField";
import SimpleInputTextField from "../../../../shared/components/inputTextField/SimpleInputTextField";
import EditableReactDataTableComponent from "../../../../shared/components/table/EditableReactDataTableComponent";
import InventoryOverviewThreeCards from "../../../../shared/components/inventoryOverviewThreeCards/InventoryOverviewThreeCards";
import {
  systemActions,
  invoiceActions,
  inventoryActions,
} from "../../../../redux/actions";
import {
  AddIcon,
  EditIcon,
  LockIcon,
  ClockIcon,
  UnLockIcon,
  DeleteIcon,
} from "../../../../assets/icons/iconsProvider";
import {
  customToast,
  isSystemProduct,
  isSpinnerEnabled,
  isMarketPriceHigh,
  getProductIfExist,
  handleResetPrices,
  getInStockProducts,
  handleEmptyOnFocus,
  handleRaritiesChange,
  parseToDecimalNumber,
  addToCartInBatchTrade,
  handleBatchBulkDelete,
  batchStockPriceChange,
  getProductSubCategory,
  batchCostOfGoodsChange,
  onChangeProductQuantity,
  onChangeProductCondition,
  handleBatchProductDelete,
  handleBulkUpdateCondition,
  handleTotalCogsValueChange,
  handleShowNintendoCondition,
  handleLockOrUnlockTradeItem,
  getTotalCogsOfBatchProducts,
  setDefaultProductDataObject,
  getTotalQuantityOfInvoiceBatch,
  getTotalStockPriceOfInvoiceBatch,
  parseToThousandSeperatorWholeNumber,
  handleCalculateEachProductPercentage,
  parseToThousandSeperatorDecimalNumber,
  isStoreCustomProduct,
  getInvoiceTotalCostOfGoods,
} from "../../../../shared/utility";
import {
  toastType,
  EMPTY_STRING,
  toastMessages,
  invoiceConstants,
  inventoryConstants,
  buttonNameConstants,
  buttonTypeConstants,
  transactionConstants,
  toggleSwitchButtonConstants,
  BATCH_INVENTORY_DEFAULT_PRODUCT_OBJECT,
} from "../../../../constants";

const AddInvoice = ({
  addInvoice,
  productTags,
  editInvoice,
  spinnerArray,
  currentStore,
  updateInvoice,
  tradeDiscount,
  activateSpinner,
  editInvoiceData,
  itemOrganization,
  invoiceGridState,
  deactivateSpinner,
  isAddInvoiceModal,
  setEditInvoiceData,
  handleFulfillInvoice,
  invoiceBatchInventory,
  toggleAddInvoiceModal,
  setInvoiceBatchInventory,
  priceChartingProductInfo,
  emptyPriceChartingProductObject,
}) => {
  //-------Reducer
  const { markupPresets, tradeinMargin } = tradeDiscount;
  const { customTypes, customCategories } = itemOrganization;

  //-------useref
  const asyncSelectRef = useRef();
  //------useState
  const [totalCost, setTotalCost] = useState(0);
  const [isBulkModal, setIsBulkModal] = useState(false);
  const [rarities, setRarities] = useState(EMPTY_STRING);
  const [editTradeObject, setEditTradeObject] = useState({});
  const [editCustomProduct, setEditCustomProduct] = useState({});
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [isCustomItemModal, setIsCustomItemModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(EMPTY_STRING);
  const [bulkUpdateCondition, setBulkUpdateCondition] = useState("");
  const [selectedRarity, setSelectedRarity] = useState(EMPTY_STRING);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [rowsToUpdateCondition, setRowsToUpdateCondition] = useState([]);
  const [note, setNote] = useState(editInvoiceData?.note || EMPTY_STRING);
  const [addInventoryItemModal, setAddInventoryItemModal] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(
    editInvoiceData?.invoiceDate || new Date()
  );
  const [invoiceId, setInvoiceId] = useState(
    editInvoiceData?.invoiceId || EMPTY_STRING
  );
  const [productDataObject, setProductDataObject] = useState(
    BATCH_INVENTORY_DEFAULT_PRODUCT_OBJECT
  );
  const [totalValues, setTotalValues] = useState({
    totalCost: 0,
    totalItems: 0,
    totalPrice: 0,
    totalMarketPrice: 0,
  });
  const [gridState, setGridState] = useState({
    pageSize: 500,
    pageIndex: 0,
    sortBy: [],
  });

  //-------Toggle confirmation modal
  const toggleConfirmationModal = () => {
    setIsConfirmationModal(!isConfirmationModal);
  };

  //------handle hide modal
  const handleHideModal = () => {
    toggleAddInvoiceModal();
    setInvoiceBatchInventory([]);
    setEditInvoiceData(EMPTY_STRING);
  };

  //-------Toggle Add Inventoty Item Modal
  const toggleAddInventoryItemModal = () =>
    setAddInventoryItemModal(!addInventoryItemModal);

  //-------Toggle Custom Item Modal
  const toggleCustomItemModal = () => {
    setIsCustomItemModal(!isCustomItemModal);
  };

  //-------handle edit button click
  const handleEditCardClick = (productDetail) => {
    if (productDetail?.apiSource === inventoryConstants.STORE_CUSTOM_PRODUCT) {
      setEditCustomProduct(productDetail);
      toggleCustomItemModal();
    } else {
      setEditTradeObject(productDetail);
      toggleAddInventoryItemModal();
    }
  };

  //-------column headers
  const columnHeaders = useMemo(
    () => [
      {
        id: "title",
        name: "Title",
        selector: (row) => row.productName,
        cell: (row) => {
          return (
            <div className="d-flex flex-column">
              <TableCustomHeader
                id={`tool${row.id}`}
                label={
                  <span className="inventory-cell-max-size product-title-max-width">
                    {row.productName}
                  </span>
                }
                tooltopText={row.productName}
              />
              {getProductSubCategory(row.genre) ===
                TRADING_CARD_CATEGORIES_ENUMS.SYSTEM &&
                (currentStore[
                  toggleSwitchButtonConstants
                    .IS_SERIAL_NUMBER_REQUIRED_INVENTORY
                ] ||
                  row?.serialNumber) && (
                  <span
                    className={`${
                      row.serialNumber ? EMPTY_STRING : "text-danger"
                    } batch-seial-number-label`}
                  >
                    S/N : {row?.serialNumber || "Required"}
                  </span>
                )}
            </div>
          );
        },
        sortable: false,
        sortDirection: null,
      },
      {
        id: "condition",
        name: "Condition",
        selector: (row) => row.productCondition,
        cell: (row) => {
          return (
            <SelectDropDown
              menuPlacement="bottom"
              options={row.productConditionList}
              noOptionsMessage="No Type Found"
              placeHolder="Type"
              defaultPageSize={{
                value: row?.productCondition,
                label: handleShowNintendoCondition(
                  row.consoleName,
                  row?.productCondition
                ),
              }}
              handleChange={(e) =>
                onChangeProductCondition(
                  markupPresets,
                  row.id,
                  e.value,
                  false,
                  invoiceBatchInventory,
                  setInvoiceBatchInventory,
                  tradeinMargin
                )
              }
              isDisabled={row?.isItemLocked}
              menuPortalTarget=""
              value={{
                value: row?.productCondition,
                label: handleShowNintendoCondition(
                  row.consoleName,
                  row?.productCondition
                ),
              }}
            />
          );
        },
        sortable: false,
        sortDirection: null,
      },
      {
        id: "rarity",
        name: "Printing",
        selector: (row) => row.rarity,
        cell: (row) => {
          return (
            <SelectDropDown
              menuPlacement="bottom"
              options={row.raritiesList || []}
              noOptionsMessage="No Printing Found"
              placeHolder="Printings"
              defaultPageSize={row.rarity}
              handleChange={(selectedOption) =>
                handleRaritiesChange(
                  row.id,
                  selectedOption,
                  invoiceBatchInventory,
                  setInvoiceBatchInventory,
                  tradeinMargin,
                  false,
                  markupPresets
                )
              }
              isDisabled={row?.isItemLocked}
              value={row.rarity}
              menuPortalTarget=""
            />
          );
        },
        sortable: false,
        sortDirection: null,
      },
      {
        id: "type",
        name: "Type",
        selector: (row) => row.productType,
        cell: (row) => {
          return (
            <span className="inventory-cell-max-size">{row.productType}</span>
          );
        },
        sortable: false,
        sortDirection: null,
        width: "8%",
      },
      {
        id: "category",
        name: "Category",
        selector: (row) => row.consoleName,
        cell: (row) => {
          return (
            <span className="inventory-cell-max-size">{row.consoleName}</span>
          );
        },
        sortable: false,
        sortDirection: null,
      },
      {
        id: "marketPrice",
        name: "Market Price",
        selector: (row) => row.marketPrice,
        cell: (row) => {
          return (
            <span
              className={`inventory-cell-max-size ${
                isMarketPriceHigh(
                  row.marketPrice,
                  row.inStockPrice,
                  productTags,
                  isStoreCustomProduct(row, "apiSource")
                    ? row.tags
                    : row.tags.map((tag) => tag.label),
                  row.isDataFetching,
                  isStoreCustomProduct(row, "apiSource")
                )
                  ? "text-danger"
                  : ""
              }`}
            >
              {parseToThousandSeperatorDecimalNumber(row.marketPrice)}
            </span>
          );
        },
        sortable: false,
        sortDirection: null,
        width: "9%",
      },
      {
        id: "quantity",
        name: "Quantity",
        selector: (row) => row?.inStockQuantity ?? 0,
        cell: (row) => {
          return (
            <SimpleNumberField
              step={1}
              minimumValue={1}
              isConvertToDecimal={false}
              value={row?.inStockQuantity ?? 0}
              className={`minimum-number-field max-input-width w-100 trade-batch-input-fields`}
              maxValue={inventoryConstants.THRESHOLD_MAX_CASH_VALUE}
              disabled={
                (isSystemProduct(getProductSubCategory(row?.genre)) &&
                  currentStore[
                    toggleSwitchButtonConstants
                      .IS_SERIAL_NUMBER_REQUIRED_INVENTORY
                  ]) ||
                row?.serialNumber ||
                row?.isItemLocked ||
                (!row.trackQuantity && isStoreCustomProduct(row, "apiSource"))
              }
              onChange={(value) => {
                if (value !== 0) {
                  onChangeProductQuantity(
                    row.id,
                    value,
                    invoiceBatchInventory,
                    setInvoiceBatchInventory,
                    handleCalculateEachProductPercentage
                  );
                }
              }}
              isHandleKeyDown={true}
            />
          );
        },
        sortable: false,
        sortDirection: null,
        width: "8%",
      },
      {
        id: "cogs",
        name: "COGS",
        selector: (row) => row.costOfGoods,
        cell: (row) => {
          return (
            <input
              key={row.costOfGoods}
              min={0}
              type="number"
              defaultValue={row.costOfGoods}
              // value={row.costOfGoods}
              onFocus={handleEmptyOnFocus}
              className="input-textfield editable-inventory-cell-max-size trade-batch-input-fields"
              onBlur={(e) => {
                const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
                batchCostOfGoodsChange(
                  row.id,
                  inputValue,
                  invoiceBatchInventory,
                  setInvoiceBatchInventory
                );
              }}
              disabled={row?.isItemLocked}
            ></input>
          );
        },
        sortable: false,
        sortDirection: null,
        width: "8%",
      },
      {
        id: "stockPrice",
        name: "Stock Price",
        selector: (row) => row.inStockPrice,
        cell: (row) => {
          return (
            <input
              key={row.inStockPrice}
              min={0}
              type="number"
              // value={row.inStockPrice}
              defaultValue={row.inStockPrice}
              onFocus={handleEmptyOnFocus}
              className={`input-textfield editable-inventory-cell-max-size trade-batch-input-fields ${
                isMarketPriceHigh(
                  row.marketPrice,
                  row.inStockPrice,
                  productTags,
                  isStoreCustomProduct(row, "apiSource")
                    ? row.tags
                    : row.tags.map((tag) => tag.label),
                  row.isDataFetching,
                  isStoreCustomProduct(row, "apiSource")
                )
                  ? "border-danger"
                  : ""
              }`}
              onBlur={(e) => {
                const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
                batchStockPriceChange(
                  row.id,
                  inputValue,
                  currentStore,
                  invoiceBatchInventory,
                  setInvoiceBatchInventory
                );
              }}
              disabled={row?.isItemLocked}
            ></input>
          );
        },
        sortable: false,
        sortDirection: null,
        width: "8%",
      },
      {
        id: "inStockPriceAnd",
        name: "",
        cell: (row) => {
          return getProductIfExist(row, (value, id) => {
            batchStockPriceChange(
              id,
              value,
              currentStore,
              invoiceBatchInventory,
              setInvoiceBatchInventory
            );
          });
        },
        sortable: false,
        width: "7%",
      },
      {
        id: "action",
        name: "",
        cell: (row) => {
          return row?.isDataFetching ? (
            <div className="d-flex justify-content-center align-items-center">
              <RotatingLines
                visible={true}
                height="20"
                width="20"
                color="grey"
                strokeColor="#6a18ed"
                strokeWidth="4"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <button
                id={`modalTool${row.id}`}
                className="action-Button-Wrapper"
                onClick={() =>
                  handleBatchProductDelete(
                    row.id,
                    invoiceBatchInventory,
                    setInvoiceBatchInventory
                  )
                }
              >
                <DeleteIcon className="cursor-pointer text-primary" />
              </button>
              <button
                id={`modalTool${row.id}`}
                className="action-Button-Wrapper  ps-0 pe-0 mx-auto"
                onClick={() => {
                  handleLockOrUnlockTradeItem(
                    currentStore,
                    invoiceBatchInventory,
                    setInvoiceBatchInventory,
                    row.id
                  );
                }}
              >
                {row?.isItemLocked ? (
                  <LockIcon className="cursor-pointer text-primary" />
                ) : (
                  <UnLockIcon className="cursor-pointer " />
                )}
              </button>
              <button
                id={`modalTool${row.id}`}
                className="action-Button-Wrapper ps-0 pe-0"
                onClick={() => {
                  handleEditCardClick(row);
                }}
              >
                <EditIcon className="cursor-pointer text-primary" />
              </button>
            </div>
          );
        },
        sortable: false,
        width: "8%",
      },
    ],
    [invoiceBatchInventory]
  );

  const handleInvoiceSubmit = (type) => {
    if (!invoiceId) {
      customToast(toastMessages.INVOICE_ID_REQUIRED, toastType.ERROR);
      return;
    }

    if (!invoiceDate) {
      customToast(toastMessages.INVOICE_DATE_REQUIRED, toastType.ERROR);
      return;
    }

    if (!selectedVendor?.value) {
      customToast(toastMessages.VENDOR_IS_REQUIRED, toastType.ERROR);
      return;
    }

    if (invoiceBatchInventory?.length <= 0) {
      customToast(toastMessages.PLEASE_SELECT_SOME_PRODUCT, toastType.ERROR);
      return;
    }

    let isInvalid = invoiceBatchInventory.some((inv) => {
      return Number(inv.inStockPrice) === 0;
    });

    if (isInvalid) {
      customToast("Quantity & Stock Price Cannot be 0", toastType.ERROR);
      return;
    }

    //-------prepare Invoice Inventory
    const invoiceInventory = invoiceBatchInventory;
    const dataToSend = {
      invoiceId: invoiceId,
      invoiceDate: invoiceDate,
      note: note,
      invoiceType: type,
      inventory: invoiceInventory,
      vendor: {
        id: selectedVendor?.value,
        name: selectedVendor?.label,
      },
      store: {
        id: currentStore?.id,
        name: currentStore?.storeName,
      },
    };
    if (editInvoiceData?.id) {
      dataToSend.id = editInvoiceData?.id;
    }
    switch (type) {
      case invoiceConstants.DRAFT:
        editInvoiceData?.id
          ? editInvoice(dataToSend, invoiceGridState, handleHideModal)
          : addInvoice(
              currentStore?.id,
              dataToSend,
              invoiceGridState,
              handleHideModal
            );
        break;
      case invoiceConstants.FULFILLED:
        handleFulfillInvoice(
          { data: dataToSend },
          invoiceGridState,
          handleHideModal
        );
        break;
    }
  };

  //-------handle invoice date change
  const handleInvoiceDateChange = (value) => {
    setInvoiceDate(value);
  };

  const handleAddCustomItem = () => {
    setEditCustomProduct(EMPTY_STRING);
    toggleCustomItemModal();
  };

  //-------Handle table sort
  const onTableSort = (column, sortDirection) => {
    columnHeaders(
      columnHeaders.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  //-------handle rows selection change
  const handleRowSelectionChange = useCallback((state) => {
    setRowsToUpdateCondition(state.selectedRows);
  }, []);

  //------ handle clear batch
  const handleClearBatch = () => {
    setInvoiceBatchInventory([]);
  };
  const scrollToTop = () => {
    var tableElement = document.getElementsByClassName("rdt_TableBody")[0];
    if (tableElement) {
      tableElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  //-------handle conformation actions
  const handleConfirmYesButtonClick = () => {
    handleClearBatch();
    toggleConfirmationModal();
  };

  const handleConfirmNoButtonClick = () => {
    toggleConfirmationModal();
  };

  //-------useEffect
  const prevLength = useRef(invoiceBatchInventory?.length);
  useEffect(() => {
    if (invoiceBatchInventory?.length > prevLength.current) {
      getInStockProducts(
        currentStore,
        inventoryActions,
        invoiceBatchInventory,
        setInvoiceBatchInventory,
        markupPresets,
        handleCalculateEachProductPercentage,
        tradeinMargin
      );
    }
    prevLength.current = invoiceBatchInventory?.length;
  }, [invoiceBatchInventory?.length]);

  useEffect(() => {
    setTotalValues({
      totalCost: getTotalCogsOfBatchProducts(invoiceBatchInventory, false),
      totalItems: getTotalQuantityOfInvoiceBatch(invoiceBatchInventory),
      totalPrice: getTotalStockPriceOfInvoiceBatch(invoiceBatchInventory),
      totalMarketPrice: invoiceBatchInventory.reduce((acc, currentInv) => {
        return isStoreCustomProduct(currentInv, "apiSource") &&
          !currentInv.trackQuantity
          ? Number(currentInv.marketPrice * 1)
          : Number(currentInv.marketPrice * currentInv.inStockQuantity) + acc;
      }, 0),
    });
  }, [invoiceBatchInventory]);

  //-------update product condition and type
  useEffect(() => {
    if (priceChartingProductInfo && isAddInvoiceModal) {
      activateSpinner(inventoryConstants.ADD_ITEM_IN_BATCH);
      setDefaultProductDataObject(
        markupPresets,
        setRarities,
        currentStore,
        inventoryActions,
        productDataObject,
        invoiceBatchInventory,
        setSelectedRarity,
        tradeinMargin,
        true,
        setProductDataObject,
        setInvoiceBatchInventory,
        priceChartingProductInfo,
        handleCalculateEachProductPercentage,
        scrollToTop,
        emptyPriceChartingProductObject,
        customTypes
      );
      deactivateSpinner(inventoryConstants.ADD_ITEM_IN_BATCH);
    }
  }, [priceChartingProductInfo]);

  return (
    <>
      <Modal
        size="lg"
        centered
        backdrop="static"
        animation={true}
        onHide={handleHideModal}
        dialogClassName="modal-90w"
        show={isAddInvoiceModal}
        className={`add-inventory-modal`}
      >
        <Modal.Header closeButton className="add-inventory-modal-header">
          <Modal.Title>
            <span className="add-inventory-modal-header-name">Add Invoice</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-inventory-modal-body py-0">
          <Row className="m-0">
            <Col md={2} className="ps-0 mb-3">
              <NewDatePicker
                IconImage={ClockIcon}
                onDateChange={(date) => handleInvoiceDateChange(date)}
                noOptionsMessage="No Date Found"
                placeholder="Date"
                label={"Date"}
                value={invoiceDate}
              />
            </Col>
            <Col md={2} className=" mb-3">
              <VendorDropdown
                isVendor={
                  editInvoiceData?.vendor
                    ? {
                        value: editInvoiceData?.vendor?.id,
                        label: editInvoiceData.vendor.name,
                      }
                    : EMPTY_STRING
                }
                getSelectedVendor={setSelectedVendor}
              />
            </Col>
            <Col md={2} className="mb-3">
              <SimpleInputTextField
                label="Invoice ID"
                value={invoiceId}
                onChange={(e) => setInvoiceId(e.target.value)}
                wrapperClassName=""
                placeHolder="Enter Invoice ID"
              />
            </Col>
            <Col md={3} className=" mb-3"></Col>
            {rowsToUpdateCondition?.length ? (
              <Col md={3} className="d-flex justify-content-end pe-md-0 gap-2">
                <Button
                  label={buttonNameConstants.DELETE}
                  type="button"
                  buttonType={buttonTypeConstants.RED_BUTTON}
                  handleClick={() => {
                    handleBatchBulkDelete(
                      toggledClearRows,
                      invoiceBatchInventory,
                      setToggleClearRows,
                      setInvoiceBatchInventory,
                      rowsToUpdateCondition,
                      setRowsToUpdateCondition
                    );
                  }}
                  isDisabled={isSpinnerEnabled(spinnerArray, [
                    inventoryConstants.ADD_INVENTORY,
                    transactionConstants.ADD_TRADE_BATCH_ITEM,
                  ])}
                />
                <Button
                  label={buttonNameConstants.BULK_UPDATE}
                  type="button"
                  handleClick={() => {
                    handleBulkUpdateCondition(
                      isBulkModal,
                      setIsBulkModal,
                      rowsToUpdateCondition
                    );
                  }}
                  isDisabled={isSpinnerEnabled(spinnerArray, [
                    inventoryConstants.ADD_INVENTORY,
                    transactionConstants.ADD_TRADE_BATCH_ITEM,
                  ])}
                />
              </Col>
            ) : invoiceBatchInventory?.length ? (
              <Col md={3} className="d-flex justify-content-end pe-md-0 gap-2">
                {!rowsToUpdateCondition?.length && (
                  <Button
                    label={buttonNameConstants.CLEAR}
                    type="button"
                    buttonType={buttonTypeConstants.LIGHT_PURPLE}
                    handleClick={() => {
                      toggleConfirmationModal();
                    }}
                    isDisabled={isSpinnerEnabled(spinnerArray, [
                      inventoryConstants.ADD_INVENTORY,
                      transactionConstants.ADD_TRADE_BATCH_ITEM,
                    ])}
                  />
                )}
              </Col>
            ) : (
              ""
            )}
            <Col md={10} className="mb-0 ps-md-0">
              <SearchInventory
                customTypes={customTypes}
                isSearchCustomProduct={true}
                asyncSelectRef={asyncSelectRef}
                customCategories={customCategories}
              />
            </Col>
            <Col md={2}>
              <Button
                IconImage={AddIcon}
                isDisabled={false}
                handleClick={handleAddCustomItem}
                label={buttonNameConstants.ADD_CUSTOM_ITEM}
              />
            </Col>
            <Col md={12} className="px-0">
              <EditableReactDataTableComponent
                selectableRows
                columns={columnHeaders}
                onSort={onTableSort}
                gridState={gridState}
                data={[...invoiceBatchInventory].reverse()}
                setGridState={setGridState}
                clearSelectedRows={toggledClearRows}
                total={invoiceBatchInventory?.length}
                defaultSortFieldId={defaultSortFieldId}
                handleRowSelectionChange={handleRowSelectionChange}
              />
            </Col>
            <Col md={6} className="ps-md-0 ">
              <EditOrResetCogs
                data={totalValues}
                cogsPrice={totalValues.totalCost}
                isDisabled={!invoiceBatchInventory?.length}
                handleRefresh={() =>
                  handleResetPrices(
                    markupPresets,
                    false,
                    invoiceBatchInventory,
                    setInvoiceBatchInventory,
                    tradeinMargin,
                    handleCalculateEachProductPercentage
                  )
                }
                handleCogsChange={(data) => {
                  handleTotalCogsValueChange(
                    data,
                    setTotalCost,
                    invoiceBatchInventory,
                    setInvoiceBatchInventory
                  );
                }}
              />
              <div className="input-field-wrapper input-textarea-wrapper invoice-textarea mt-2">
                <textarea
                  value={note}
                  placeholder="Notes..."
                  onChange={(e) => setNote(e.target.value)}
                  className={`input-textfield ps-3 invoice-textarea`}
                />
              </div>
            </Col>
            <Col md={6} className="pe-md-0">
              <InventoryOverviewThreeCards
                data={[
                  {
                    label: "Total Items",
                    value: parseToThousandSeperatorWholeNumber(
                      totalValues?.totalItems || 0
                    ),
                  },
                  {
                    label: "Total Market Value",
                    value: parseToThousandSeperatorDecimalNumber(
                      totalValues?.totalMarketPrice || 0
                    ),
                  },
                  {
                    label: "Total Stock Price",
                    value: parseToThousandSeperatorDecimalNumber(
                      totalValues?.totalPrice || 0
                    ),
                  },
                ]}
              />
              <Row className="m-0 mt-3">
                <Col md={4} className="ps-md-0">
                  <Button
                    label={buttonNameConstants.CANCEL}
                    className="w-100 "
                    type="button"
                    handleClick={handleHideModal}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    isDisabled={false}
                  />
                </Col>
                <Col md={4}>
                  <Button
                    className="w-100"
                    isDisabled={false}
                    handleClick={() =>
                      handleInvoiceSubmit(invoiceConstants.DRAFT)
                    }
                    label={buttonNameConstants.SAVE_AS_DRAFT}
                    buttonType={buttonTypeConstants.LIGHT_PURPLE}
                  />
                </Col>
                <Col md={4} className="pe-md-0">
                  <Button
                    className="w-100"
                    isDisabled={false}
                    handleClick={() =>
                      handleInvoiceSubmit(invoiceConstants.FULFILLED)
                    }
                    label={buttonNameConstants.MARK_AS_FULFILLED}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {addInventoryItemModal && (
        <BatchProductEdit
          addToCart={(editProduct) => {
            addToCartInBatchTrade(
              editProduct,
              invoiceBatchInventory,
              setEditTradeObject,
              setInvoiceBatchInventory
            );
          }}
          editProductObject={editTradeObject}
          isBatchProductEditModal={addInventoryItemModal}
          toggleIsBatchProductEditModal={toggleAddInventoryItemModal}
        />
      )}
      <BatchBulkUpdateModal
        isBulkModal={isBulkModal}
        setIsBulkModal={setIsBulkModal}
        toggledClearRows={toggledClearRows}
        setToggleClearRows={setToggleClearRows}
        addBatchInventory={invoiceBatchInventory}
        bulkUpdateCondition={bulkUpdateCondition}
        rowsToUpdateCondition={rowsToUpdateCondition}
        setAddBatchInventory={setInvoiceBatchInventory}
        setBulkUpdateCondition={setBulkUpdateCondition}
        setRowsToUpdateCondition={setRowsToUpdateCondition}
      />
      {isCustomItemModal && (
        <AddCustomItem
          addToCart={(editProduct) => {
            addToCartInBatchTrade(
              editProduct,
              invoiceBatchInventory,
              setEditTradeObject,
              setInvoiceBatchInventory
            );
          }}
          editCustomProduct={editCustomProduct}
          isCustomItemModal={isCustomItemModal}
          toggleCustomItemModal={toggleCustomItemModal}
        />
      )}
      <ConfirmationModal
        message={toastMessages.CLEAR_BATCH_WARNING}
        isConfirmationModal={isConfirmationModal}
        handleNoButtonClick={handleConfirmNoButtonClick}
        handleYesButtonClick={handleConfirmYesButtonClick}
        toggleConfirmationModal={toggleConfirmationModal}
      />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  tradeDiscount: state.tradeDiscount,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  productTags: state.inventory.productTags,
  itemOrganization: state.itemOrganization,
  invoiceBatchInventory: state.invoice.invoiceBatchInventory,
  priceChartingProductInfo: state.inventory.priceChartingProductInfo,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  setInvoiceBatchInventory: (invoiceBatch) =>
    dispatch(invoiceActions.setInvoiceBatchInventory(invoiceBatch)),
  emptyPriceChartingProductObject: () =>
    dispatch(inventoryActions.emptyPriceChartingProductObject()),
  addInvoice: (storeId, data, gridState, handleHideModal) =>
    dispatch(
      invoiceActions.addInvoice(storeId, data, gridState, handleHideModal)
    ),
  editInvoice: (data, gridState, handleHideModal) =>
    dispatch(invoiceActions.editInvoice(data, gridState, handleHideModal)),
});

//-------Export AddInvoice Component
export default connect(mapStateToProps, mapDispatchToProps)(AddInvoice);
