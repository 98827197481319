import React from "react";
import { connect } from "react-redux";

import { custom } from "../../../redux/actions/actionsUtilities";
import ConfirmationModal from "../../../views/Inventory/Items/ConfirmationModal/ConfirmationModal";
import {
  toastMessages,
  systemConstants,
  SYSTEM_MODAL_TYPES,
  DEFAULT_SYSTEM_MODAL,
} from "../../../constants";

const DeleteUserModal = (props) => {
  const { systemModal, setSystemModal, handleYesButtonClick } = props;

  const handleSetIsModal = (e) => {
    e
      ? setSystemModal({
          type: SYSTEM_MODAL_TYPES.DELETE_USER_MODAL,
          metaData: {},
        })
      : setSystemModal(DEFAULT_SYSTEM_MODAL);
  };

  return (
    systemModal.type === SYSTEM_MODAL_TYPES.DELETE_USER_MODAL && (
      <ConfirmationModal
        isConfirmationModal={true}
        toggleConfirmationModal={handleSetIsModal}
        message={toastMessages.DELETE_USER_CONFIRMATION}
        handleNoButtonClick={() => handleSetIsModal(false)}
        handleYesButtonClick={() => {
          handleYesButtonClick(systemModal.metaData);
        }}
      />
    )
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  systemModal: state.system.systemModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setSystemModal: (data) =>
    dispatch(custom(data, systemConstants.SET_SYSTEM_MODAL)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserModal);
