import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import _ from "lodash"; // Import lodash here
import React, { useCallback, useEffect, useRef, useState } from "react";

import { getUpdatedCartItems } from "../NewTrade";
import { RotatingLines } from "react-loader-spinner";
import BatchTradeEdit from "../AddTrade/BatchTradeEdit";
import AddTradeBatchFooter from "./AddTradeBatchFooter";
import Button from "../../../../shared/components/Buttons/Button";
import TableCustomHeader from "../../../../shared/components/table/TableCustomHeader";
import SearchInventory from "../../../Inventory/Items/SearchInventory/SearchInventory";
import SelectDropDown from "../../../../shared/components/selectDropdown/SelectDropdown";
import ConfirmationModal from "../../../Inventory/Items/ConfirmationModal/ConfirmationModal";
import SimpleNumberField from "../../../../shared/components/inputNumberField/SimpleNumberField";
import EditableReactDataTableComponent from "../../../../shared/components/table/EditableReactDataTableComponent";
import {
  LockIcon,
  EditIcon,
  UnLockIcon,
  DeleteIcon,
} from "../../../../assets/icons/iconsProvider";
import {
  toastType,
  EMPTY_STRING,
  toastMessages,
  globalConstants,
  inventoryConstants,
  buttonNameConstants,
  buttonTypeConstants,
  transactionConstants,
  TRADE_BATCH_DEFAULT_DATA,
  toggleSwitchButtonConstants,
} from "../../../../constants";
import {
  systemActions,
  inventoryActions,
  transactionActions,
} from "../../../../redux/actions";
import {
  formatDate,
  customToast,
  calculateRatio,
  isSystemProduct,
  isSpinnerEnabled,
  getProductIfExist,
  handleResetPrices,
  isMarketPriceHigh,
  handleEmptyOnFocus,
  getInStockProducts,
  calculateOfferPrices,
  parseToDecimalNumber,
  handleRaritiesChange,
  getProductSubCategory,
  convertToFixedPrecision,
  onChangeProductCondition,
  handleBulkConditionChange,
  setDefaultProductDataObject,
  handleLockOrUnlockTradeItem,
  getTotalTradeOfferOfBatchProducts,
  parseToThousandSeperatorDecimalNumber,
  handleItemCashTradePercentage,
  parseToNumber,
  isTransactionAllowed,
} from "../../../../shared/utility";
import {
  PRODUCT_TYPES_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../../system/globalEnums";

const AddTradeBatch = (props) => {
  //-------Destructure props
  const {
    user,
    outlet,
    addToCart,
    transaction,
    productTags,
    customTypes,
    spinnerArray,
    currentStore,
    newTradeData,
    markupPresets,
    tradeDiscount,
    generateTempSku,
    activateSpinner,
    customCategories,
    deactivateSpinner,
    globalTradeinMargin,
    addTradeBatchInventory,
    priceChartingProductInfo,
    setAddTradeBatchInventory,
    isTradeBatchInventoryModal,
    setIsTradeBatchInventoryModal,
    emptyPriceChartingProductObject,
  } = props;

  //------reducers
  const { tempSku } = transaction;
  const { tradeinMargin } = tradeDiscount;

  //-------column headers for the batch trade table
  const columnHeaders = [
    {
      id: "title",
      name: "Title",
      selector: (row) => row.productName,
      cell: (row) => {
        return (
          <div className="d-flex flex-column">
            <TableCustomHeader
              id={`tool${row.id}`}
              label={
                <span className="inventory-cell-max-size product-title-max-width">
                  {row.productName}
                </span>
              }
              tooltopText={row.productName}
            />
            {getProductSubCategory(row.genre) ===
              TRADING_CARD_CATEGORIES_ENUMS.SYSTEM &&
              (currentStore[
                toggleSwitchButtonConstants.IS_SERIAL_NUMBER_REQUIRED_INVENTORY
              ] ||
                row?.serialNumber) && (
                <span
                  className={`${
                    row.serialNumber ? EMPTY_STRING : "text-danger"
                  } batch-seial-number-label`}
                >
                  S/N : {row?.serialNumber || "Required"}
                </span>
              )}
          </div>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "condition",
      name: "Condition",
      selector: (row) => row.productCondition,
      cell: (row) => {
        return (
          <SelectDropDown
            menuPlacement="bottom"
            options={row.productConditionList}
            noOptionsMessage="No Type Found"
            placeHolder="Type"
            defaultPageSize={{
              value: row?.productCondition,
              label: row?.productCondition,
            }}
            isDisabled={row?.isItemLocked}
            handleChange={(e) =>
              onChangeProductCondition(
                markupPresets,
                row.id,
                e.value,
                true,
                addTradeBatchInventory,
                handleAddTradeBatchInventory,
                tradeinMargin,
                handleItemCashTradePercentage
              )
            }
            menuPortalTarget=""
            value={{
              value: row?.productCondition,
              label: row?.productCondition,
            }}
          />
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "rarity",
      name: "Printing",
      selector: (row) => row.rarity,
      cell: (row) => {
        return (
          <SelectDropDown
            menuPlacement="bottom"
            options={row.raritiesList || []}
            noOptionsMessage="No Printing Found"
            placeHolder="Printings"
            defaultPageSize={row.rarity}
            isDisabled={row?.isItemLocked}
            handleChange={(selectedOption) =>
              handleRaritiesChange(
                row.id,
                selectedOption,
                addTradeBatchInventory,
                handleAddTradeBatchInventory,
                tradeinMargin,
                true,
                markupPresets
              )
            }
            menuPortalTarget=""
            value={row.rarity}
          />
        );
      },
      sortable: false,
      sortDirection: null,
    },

    {
      id: "type",
      name: "Type",
      selector: (row) => row.productType,
      cell: (row) => {
        return (
          <span className="inventory-cell-max-size">{row.productType}</span>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "category",
      name: "Category",
      selector: (row) => row.consoleName,
      cell: (row) => {
        return (
          <span className="inventory-cell-max-size">{row.consoleName}</span>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "marketPrice",
      name: "Market Price",
      selector: (row) => row.marketPrice,
      cell: (row) => {
        return (
          <span
            className={`inventory-cell-max-size ${
              isMarketPriceHigh(
                row.marketPrice,
                row.inStockPrice,
                productTags,
                row.tags.map((tag) => tag.label),
                row.isDataFetching
              )
                ? "text-danger"
                : ""
            }`}
          >
            {parseToThousandSeperatorDecimalNumber(row.marketPrice)}
          </span>
        );
      },
      sortable: false,
      sortDirection: null,
      width: "8%",
    },
    {
      id: "quantity",
      name: "Quantity",
      selector: (row) => row?.inStockQuantity ?? 0,
      cell: (row) => {
        return (
          <SimpleNumberField
            minimumValue={1}
            isConvertToDecimal={false}
            value={row?.inStockQuantity ?? 0}
            maxValue={inventoryConstants.THRESHOLD_MAX_CASH_VALUE}
            className=" max-input-width trade-batch-input-fields"
            onChange={(value) => {
              if (value !== 0) {
                onChangeQuantity(value, row.id);
              }
            }}
            isHandleKeyDown={true}
            disabled={
              (isSystemProduct(
                getProductSubCategory(productDataObject?.genre)
              ) &&
                currentStore[
                  toggleSwitchButtonConstants
                    .IS_SERIAL_NUMBER_REQUIRED_INVENTORY
                ]) ||
              row?.isItemLocked
            }
          />
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "Cash",
      name: "Cash",
      selector: (row) => row.cashOffer,
      cell: (row) => {
        return (
          <input
            key={row.cashOffer}
            min={0}
            type="number"
            defaultValue={row.cashOffer}
            // value={row.cashOffer}
            onFocus={handleEmptyOnFocus}
            // onChange={(e) => onChangeCashOffer(e.target.value, row.id)}
            className="input-textfield  editable-inventory-cell-max-size trade-batch-input-fields"
            onBlur={(e) => {
              const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
              const prevValue = parseToDecimalNumber(row.cashOffer);
              inputValue !== prevValue && onChangeCashOffer(inputValue, row.id);
            }}
            disabled={row?.isItemLocked}
          ></input>
        );
      },
      sortable: false,
      sortDirection: null,
    },

    {
      id: "Trade",
      name: "Trade",
      selector: (row) => row.tradeOffer,
      cell: (row) => {
        return (
          <input
            key={row.tradeOffer}
            defaultValue={row.tradeOffer}
            min={0}
            type="number"
            // value={row.tradeOffer}
            onFocus={handleEmptyOnFocus}
            className="input-textfield  editable-inventory-cell-max-size me-2 trade-batch-input-fields"
            // onChange={(e) => onChangeTradeOffer(e.target.value, row.id)}
            onBlur={(e) => {
              const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
              const prevValue = parseToDecimalNumber(row.tradeOffer);
              inputValue !== prevValue &&
                onChangeTradeOffer(inputValue, row.id);
            }}
            disabled={row?.isItemLocked}
          ></input>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "stockPrice",
      name: "Stock Price",
      selector: (row) => row.inStockPrice,
      cell: (row) => {
        return (
          <input
            key={row.inStockPrice}
            min={0}
            type="number"
            // value={row.inStockPrice}
            defaultValue={row.inStockPrice}
            onFocus={handleEmptyOnFocus}
            // onChange={(e) => onChangeStockPrice(e.target.value, row.id)}
            className={`input-textfield editable-inventory-cell-max-size trade-batch-input-fields ${
              isMarketPriceHigh(
                row.marketPrice,
                row.inStockPrice,
                productTags,
                row.tags.map((tag) => tag.label),
                row.isDataFetching
              )
                ? "border-danger"
                : ""
            }`}
            onBlur={(e) => {
              const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
              const prevValue = parseToDecimalNumber(row.inStockPrice);
              inputValue !== prevValue &&
                onChangeStockPrice(inputValue, row.id);
            }}
            disabled={row?.isItemLocked}
          ></input>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "inStockPriceAnd",
      name: "",
      cell: (row) => {
        return getProductIfExist(row, onChangeStockPrice);
      },
      sortable: false,
      width: "8%",
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return row?.isDataFetching ? (
          <div className="d-flex justify-content-center align-items-center">
            <RotatingLines
              visible={true}
              height="20"
              width="20"
              color="grey"
              strokeColor="#6a18ed"
              strokeWidth="4"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <button
              id={`modalTool${row.id}`}
              className="action-Button-Wrapper  ps-0 pe-0"
              onClick={() => onDeleteItem(row.id)}
            >
              <DeleteIcon className="cursor-pointer text-primary" />
            </button>{" "}
            <button
              id={`modalTool${row.id}`}
              className="action-Button-Wrapper  ps-0 pe-0 mx-auto"
              onClick={() => {
                handleLockOrUnlockTradeItem(
                  currentStore,
                  addTradeBatchInventory,
                  setAddTradeBatchInventory,
                  row.id
                );
              }}
            >
              {row?.isItemLocked ? (
                <LockIcon className="cursor-pointer text-primary" />
              ) : (
                <UnLockIcon className="cursor-pointer " />
              )}
            </button>
            <button
              id={`modalTool${row.id}`}
              className="action-Button-Wrapper ps-0 pe-0"
              onClick={() => {
                handleEditCardClick(row);
              }}
            >
              <EditIcon className="cursor-pointer text-primary" />
            </button>
            {/* delete icon button */}
          </div>
        );
      },
      sortable: false,
      width: "7%",
    },
  ];

  //-------UseStates
  const [isLink, setIsLink] = useState(true);
  const [columns, setColumns] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isBulkModal, setIsBulkModal] = useState(false);
  const [addTradeModal, setAddTradeModal] = useState(false);
  const [editTradeObject, setEditTradeObject] = useState({});
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [bulkUpdateCondition, setBulkUpdateCondition] = useState("");
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [rowsToUpdateCondition, setRowsToUpdateCondition] = useState([]);
  const [rarities, setRarities] = useState(globalConstants.EMPTY_STRING);
  const [pricechartingProductImage, setPricechartingProductImage] =
    useState("");
  const [initialRatio, setInitialRatio] = useState(
    globalTradeinMargin?.cashMarginPercentage /
      globalTradeinMargin?.tradeinMarginPercentage
  );
  const [selectedRarity, setSelectedRarity] = useState(
    globalConstants.EMPTY_STRING
  );
  const [cashTradeOfferValue, setCashTradeOfferValue] = useState({
    cashOfferValue: 0,
    tradeOfferValue: 0,
  });
  const [gridState, setGridState] = useState({
    pageSize: 500,
    pageIndex: 0,
    sortBy: [],
  });
  //-------initial Product DataObject
  const [productDataObject, setProductDataObject] = useState(
    TRADE_BATCH_DEFAULT_DATA
  );

  //-------useref
  const asyncSelectRef = useRef();

  //-------update trade transaction data in local storage and redux
  const handleAddTradeBatchInventory = (data) => {
    setAddTradeBatchInventory(data, currentStore?.id);
  };

  //-------Toggle confirmation modal
  const toggleConfirmationModal = () => {
    setIsConfirmationModal(!isConfirmationModal);
  };

  const handleConfirmNoButtonClick = () => {
    toggleConfirmationModal();
  };

  //-------handle conformation actions
  const handleConfirmYesButtonClick = () => {
    handleClearBatch();
    toggleConfirmationModal();
  };

  //------ handle clear batch
  const handleClearBatch = () => {
    setAddTradeBatchInventory([], currentStore?.id);
  };

  //------ handle delete selected items from batch
  const handleBulkDelete = () => {
    const itemToDeleteId = rowsToUpdateCondition.map((item) => item.id);

    const updatedInventory = addTradeBatchInventory.filter(
      (item) => !itemToDeleteId.includes(item.id)
    );
    handleAddTradeBatchInventory(
      updatedInventory?.map((item, index) => ({ ...item, id: index })) || []
    );
    setToggleClearRows(!toggledClearRows);
    setRowsToUpdateCondition([]);
    // Additional actions, such as showing a success message or triggering further logic
  };

  //-------handle onchnage any product quantity
  const onChangeQuantity = (newQuantity, Id) => {
    newQuantity = Number(newQuantity);
    const updatedInventory = addTradeBatchInventory.map((item) => {
      if (item.id === Id) {
        return { ...item, inStockQuantity: newQuantity };
      }
      return item;
    });
    handleAddTradeBatchInventory(updatedInventory);
  };

  //-------handle onchnage product stock price
  const onChangeStockPrice = (newPrice, Id) => {
    const updatedInventory = addTradeBatchInventory.map((item) => {
      if (item.id === Id) {
        const updatedItem = { ...item, inStockPrice: newPrice };
        // Calculate new offers based on newPrice or marketPrice
        if (newPrice !== 0) {
          const priceToUse = currentStore?.[
            toggleSwitchButtonConstants.ALWAYS_USE_STOCK_PRICE_FOR_COGS
          ]
            ? newPrice
            : Math.min(newPrice, item.marketPrice);
          const { cashOffer, tradeOffer } = calculateOfferPrices(
            priceToUse,
            item?.tradeInMarginTypeObject?.marginObject,
            item?.tradeInMarginTypeObject?.marginObject.cashMarginPercentage,
            item?.tradeInMarginTypeObject?.marginObject.tradeinMarginPercentage
          );
          updatedItem.cashOffer = parseToDecimalNumber(cashOffer);
          updatedItem.tradeOffer = parseToDecimalNumber(tradeOffer);
        }
        return updatedItem;
      }
      return item;
    });
    handleAddTradeBatchInventory(updatedInventory);
  };

  //-------handle delete item from batch
  const onDeleteItem = (Id) => {
    const updatedInventory = addTradeBatchInventory.filter(
      (item) => item.id !== Id
    );

    handleAddTradeBatchInventory(
      updatedInventory?.map((item, index) => ({ ...item, id: index })) || []
    );
  };

  const handleHideBulkModal = () => {
    setIsBulkModal(!isBulkModal);
  };

  const handleBulkUpdateCondition = () => {
    const sameProductType = rowsToUpdateCondition.every(
      (item, index, array) => {
        return index === 0 || item["productType"] === array[0]["productType"];
      }
    );

    if (sameProductType) {
      setIsBulkModal(!isBulkModal);
    } else {
      customToast("Selected rows has not same product Type", toastType.ERROR);
    }
  };

  const handleBulkSubmit = () => {
    if (bulkUpdateCondition) {
      const bulkUpdateItemIds = rowsToUpdateCondition.map((item) => item.id);
      activateSpinner(inventoryConstants.BULK_UPDATE);

      handleBulkConditionChange(
        markupPresets,
        bulkUpdateCondition?.value,
        bulkUpdateItemIds,
        true,
        addTradeBatchInventory,
        handleAddTradeBatchInventory,
        tradeinMargin,
        handleItemCashTradePercentage
      );

      setIsBulkModal(false);
      setRowsToUpdateCondition([]);
      setBulkUpdateCondition("");
      setToggleClearRows(!toggledClearRows);
      deactivateSpinner(inventoryConstants.BULK_UPDATE);
    } else {
      customToast("Condition not selected", toastType.ERROR);
    }
  };

  const handleResetBatch = () => {
    handleResetPrices(
      markupPresets,
      true,
      addTradeBatchInventory,
      (updatedInventory) => {
        setAddTradeBatchInventory(updatedInventory, currentStore?.id);
      },
      tradeinMargin,
      handleItemCashTradePercentage
    );
  };

  //-------handle onchnage of product cash offer
  const onChangeCashOffer = (newOffer, Id) => {
    // newOffer = Number(newOffer);
    const updatedInventory = addTradeBatchInventory.map((item) => {
      if (item.id === Id) {
        const ratio = calculateRatio(item);
        const tradeOfferPercentage = newOffer * ratio;
        return {
          ...item,
          tradeOffer: parseToDecimalNumber(tradeOfferPercentage),
          cashOffer: newOffer,
        };
      }
      return item;
    });
    handleAddTradeBatchInventory(
      handleItemCashTradePercentage(updatedInventory)
    );
  };

  //-------handle onchange product trade offer
  const onChangeTradeOffer = (newOffer, Id) => {
    // newOffer = Number(newOffer);
    const updatedInventory = addTradeBatchInventory.map((item) => {
      if (item.id === Id) {
        const ratio = calculateRatio(item);
        const cashOfferCalculated = newOffer / ratio;

        return {
          ...item,
          tradeOffer: newOffer,
          cashOffer: parseToDecimalNumber(cashOfferCalculated),
        };
      }
      return item;
    });
    handleAddTradeBatchInventory(
      handleItemCashTradePercentage(updatedInventory)
    );
  };

  //-------handle total offer value change
  const handleOfferValueChange = (data, isCashOfferValue = false) => {
    const value = Number(data.value);

    // Step 1: Check if all items are locked
    const hasUnlockedItems = addTradeBatchInventory.some(
      (item) => !item.isItemLocked
    );
    if (!hasUnlockedItems) {
      customToast(toastMessages.ALL_ITEMS_LOCKED_ERROR, toastType.ERROR);
      return; // Exit early if no unlocked items
    }

    // Step 2: Validate the inventory data for 0 or empty values
    let isInvalid = addTradeBatchInventory.some((inv) => {
      return (
        Number(inv.inStockQuantity) === 0 || // Check if inStockQuantity is 0
        Number(inv.cashOffer) === 0 || // Check if cashOffer is 0
        inv.cashOffer === "" || // Check if cashOffer is empty
        Number(inv.tradeOffer) === 0 || // Check if tradeOffer is 0
        inv.tradeOffer === "" // Check if tradeOffer is empty
      );
    });
    if (isInvalid) {
      customToast(
        "Quantity & Stock Price & cash or trade Offer Cannot be 0",
        toastType.ERROR
      );
      return; // Exit early if invalid fields are found
    }

    // Step 3: Handle the case where offerBy.value is 1 (flat rate for all products)
    if (data.offerBy.value === 1) {
      // Update all unlocked items with the flat value
      const finalUpdatedBatchInventory = addTradeBatchInventory.map((item) => {
        if (item.isItemLocked) {
          return item; // Skip locked items
        }
        let flatOfferValue = data?.isPercentageOffer
          ? parseToDecimalNumber(value)
          : parseToDecimalNumber(
              (Number(value) / 100) * Number(item?.marketPrice || 0)
            );
        flatOfferValue =
          flatOfferValue <= 0
            ? inventoryConstants.DEFAULT_OFFER_VALUE
            : flatOfferValue;
        // Set the flat offer value based on isCashOfferValue
        if (isCashOfferValue) {
          // If isCashOfferValue is true, set the flat rate for cashOffer
          const oppositeOfferValue = isLink
            ? parseToDecimalNumber(flatOfferValue * calculateRatio(item)) // If isLink, calculate the opposite offer
            : item.tradeOffer;

          return {
            ...item,
            cashOffer: flatOfferValue,
            tradeOffer: oppositeOfferValue,
          };
        } else {
          // If isCashOfferValue is false, set the flat rate for tradeOffer
          const oppositeOfferValue = isLink
            ? parseToDecimalNumber(flatOfferValue / calculateRatio(item)) // If isLink, calculate the opposite offer
            : item.cashOffer;

          return {
            ...item,
            tradeOffer: flatOfferValue,
            cashOffer: oppositeOfferValue,
          };
        }
      });

      // Step 4: Update the state with the final inventory after applying the flat rate
      handleAddTradeBatchInventory(finalUpdatedBatchInventory);
      return; // Exit after applying the flat rate to avoid further processing
    }

    // Step 5: Continue with the original process for offerBy.value other than 1
    // Recalculate the cash and trade offer percentages before proceeding
    const updatedBatchInventory = handleItemCashTradePercentage(
      addTradeBatchInventory
    );

    // Calculate total offer for locked items based on the type of offer (cash or trade)
    const lockedTotalOffer = parseToDecimalNumber(
      updatedBatchInventory.reduce((total, item) => {
        if (item.isItemLocked) {
          return (
            total +
            (isCashOfferValue
              ? Number(item.cashOffer)
              : Number(item.tradeOffer))
          );
        }
        return total;
      }, 0) ?? 0
    );

    // Step 6: Check if the incoming value is less than or equal to the total offer of locked items
    if (value <= Number(lockedTotalOffer)) {
      customToast(toastMessages.VALUE_LESS_THAN_LOCKED_ITEMS, toastType.ERROR);
      return; // Exit early if the value is invalid
    }

    // Step 7: Parse the input value
    const inputValue = parseToDecimalNumber(value - Number(lockedTotalOffer));
    // Update the state for the offer value (either cashOfferValue or tradeOfferValue)
    if (isCashOfferValue) {
      setCashTradeOfferValue({
        ...cashTradeOfferValue,
        cashOfferValue: inputValue,
      });
    } else {
      setCashTradeOfferValue({
        ...cashTradeOfferValue,
        tradeOfferValue: inputValue,
      });
    }

    // Step 8: Update the batch inventory based on whether it's a cash offer or trade offer
    let finalUpdatedBatchInventory = updatedBatchInventory.map((item) => {
      // Skip locked items (no need to update their offer values)
      if (item.isItemLocked) {
        return item; // Return unchanged item for locked items
      }

      let offerValue = 0;
      // Default opposite offer value
      let oppositeOfferValue = !isCashOfferValue
        ? item.cashOffer
        : item.tradeOffer;

      if (isCashOfferValue) {
        // Calculate the cash offer for the item
        offerValue =
          (inputValue * item.cashPercentagePerItem) /
          100 /
          item.inStockQuantity;

        // Apply the minimum threshold for cashOffer (if it falls below the threshold, set it to the default value)
        offerValue = parseToDecimalNumber(
          offerValue <= inventoryConstants.DEFAULT_OFFER_VALUE
            ? inventoryConstants.DEFAULT_OFFER_VALUE
            : offerValue
        );

        if (isLink) {
          // Calculate the corresponding trade offer based on the item ratio if isLink is true
          const itemRatio = calculateRatio(item);
          oppositeOfferValue = parseToDecimalNumber(
            itemRatio * parseToDecimalNumber(offerValue)
          );
        }
      } else {
        // Calculate the trade offer for the item
        offerValue =
          (inputValue * item.tradePercentagePerItem) /
          100 /
          item.inStockQuantity;

        // Apply the minimum threshold for tradeOffer
        offerValue = parseToDecimalNumber(
          offerValue <= inventoryConstants.DEFAULT_OFFER_VALUE
            ? inventoryConstants.DEFAULT_OFFER_VALUE
            : offerValue
        );

        if (isLink) {
          // Calculate the corresponding cash offer based on the item ratio if isLink is true
          const itemRatio = calculateRatio(item);
          oppositeOfferValue = parseToDecimalNumber(offerValue / itemRatio);
        }
      }

      return {
        ...item,
        cashOffer: isCashOfferValue
          ? parseToDecimalNumber(offerValue)
          : oppositeOfferValue,
        tradeOffer: isCashOfferValue
          ? parseToDecimalNumber(oppositeOfferValue)
          : offerValue,
      };
    });

    // Step 7: Handle the difference in the updated inventory
    const totalOffer = getTotalTradeOfferOfBatchProducts(
      finalUpdatedBatchInventory
    );
    const difference = parseToNumber(
      Number(inputValue) -
        Number(
          isCashOfferValue
            ? totalOffer.cashOfferValue
            : totalOffer.tradeOfferValue
        )
    );
    if (difference < 0 || difference > 0) {
      let productIndex = 0;
      // Step 8: Filter out locked items completely when calculating the product index
      const nonLockedBatchInventory = finalUpdatedBatchInventory.filter(
        (item) => !item.isItemLocked
      );
      // Step 9: Handle positive or negative difference in the respective offer (cashOffer or tradeOffer)
      if (difference < 0) {
        productIndex = nonLockedBatchInventory.reduce(
          (maxIndex, obj, idx, array) => {
            return obj[isCashOfferValue ? "cashOffer" : "tradeOffer"] >
              array[maxIndex][isCashOfferValue ? "cashOffer" : "tradeOffer"]
              ? idx
              : maxIndex;
          },
          0
        );
      } else {
        productIndex = nonLockedBatchInventory.reduce(
          (minIndex, obj, idx, array) => {
            return obj[isCashOfferValue ? "cashOffer" : "tradeOffer"] <
              array[minIndex][isCashOfferValue ? "cashOffer" : "tradeOffer"]
              ? idx
              : minIndex;
          },
          0
        );
      }
      // Step 10: Update the offer for the selected product based on the calculated difference
      let newOfferValue = parseToDecimalNumber(
        Number(
          nonLockedBatchInventory[productIndex][
            isCashOfferValue ? "cashOffer" : "tradeOffer"
          ]
        ) + difference
      );
      // Ensure offer doesn't go below the minimum threshold (0.01)
      if (newOfferValue < inventoryConstants.DEFAULT_OFFER_VALUE) {
        let remainingDifference =
          difference -
          (inventoryConstants.DEFAULT_OFFER_VALUE -
            nonLockedBatchInventory[productIndex][
              isCashOfferValue ? "cashOffer" : "tradeOffer"
            ]);
        nonLockedBatchInventory[productIndex][
          isCashOfferValue ? "cashOffer" : "tradeOffer"
        ] = inventoryConstants.DEFAULT_OFFER_VALUE;
        // If there's still remaining difference, redistribute it across other unlocked products
        for (let i = 0; i < nonLockedBatchInventory.length; i++) {
          if (i === productIndex) continue;

          let otherProduct = nonLockedBatchInventory[i];
          let potentialNewOfferValue =
            otherProduct[isCashOfferValue ? "cashOffer" : "tradeOffer"] -
            remainingDifference;

          if (
            potentialNewOfferValue >= inventoryConstants.DEFAULT_OFFER_VALUE
          ) {
            otherProduct[isCashOfferValue ? "cashOffer" : "tradeOffer"] =
              parseToDecimalNumber(potentialNewOfferValue);
            remainingDifference = 0;
            break;
          } else {
            remainingDifference -=
              otherProduct[isCashOfferValue ? "cashOffer" : "tradeOffer"] -
              inventoryConstants.DEFAULT_OFFER_VALUE;
            otherProduct[isCashOfferValue ? "cashOffer" : "tradeOffer"] =
              inventoryConstants.DEFAULT_OFFER_VALUE;
          }
        }
      } else {
        nonLockedBatchInventory[productIndex][
          isCashOfferValue ? "cashOffer" : "tradeOffer"
        ] = newOfferValue;
      }
      // Step 11: Rebuild the final updated batch inventory without modifying locked items
      finalUpdatedBatchInventory = finalUpdatedBatchInventory.map((item) => {
        if (item.isItemLocked) {
          return item; // Return the locked items unchanged
        }

        const updatedItem = nonLockedBatchInventory.find(
          (updatedItem) => updatedItem.id === item.id // Assuming each item has a unique 'id'
        );

        return updatedItem ? updatedItem : item; // Replace the unlocked item with the updated one
      });
    }
    // Step 12: Update the inventory list state with the final values
    handleAddTradeBatchInventory(finalUpdatedBatchInventory);
  };

  //--------handle submit add batch inventory
  const handleSubmitAddBatchInventory = async () => {
    //------- check session is open or not also in use or not or outlet is configured or not
    if (isTransactionAllowed(outlet, user, currentStore)) {
      return;
    }

    // validate required fields
    let isInvalid = addTradeBatchInventory.some((inv) => {
      return (
        Number(inv.inStockQuantity) === 0 ||
        Number(inv.inStockPrice) === 0 ||
        Number(inv.cashOffer) === 0 ||
        inv.cashOffer === "" ||
        Number(inv.tradeOffer) === 0 ||
        inv.tradeOffer === ""
      );
    });
    if (isInvalid) {
      customToast(
        "Quantity & Stock Price & cash or trade Offer Cannot be 0",
        toastType.ERROR
      );
      return;
    }

    let tempInitialSku = tempSku;
    activateSpinner(transactionConstants.ADD_TRADE_BATCH_ITEM);
    let i = 0;
    let updatedNewTradeData = newTradeData;
    // const margedBacthInventory = mergeBatchProducts(addTradeBatchInventory);
    const margedBacthInventory = addTradeBatchInventory;
    for (const product of margedBacthInventory) {
      try {
        let productData = {
          batch: "batch",
          cardNumber: "",
          description: "",
          upc: product.upc,
          epid: product.epid,
          ratio: initialRatio,
          genre: product.genre,
          trackQuantity: true,
          additionalCheckList: [],
          imgUrl: product?.imgUrl,
          cashOffer: product.cashOffer,
          apiSource: product.apiSource,
          product_id: product.productId,
          tradeOffer: product.tradeOffer,
          productType: product.productType,
          product_name: product.productName,
          category_name: product.consoleName,
          tcgPlayerUrl: product.tcgPlayerUrl,
          date_added: formatDate(new Date()),
          cardRarity: product?.rarity?.label || "",
          serialNumber: product?.serialNumber || EMPTY_STRING,
          tags: product?.tags ? product.tags.map((tag) => tag.label) : [],
          sku: [`${transactionConstants.BATCH_TRADE} ${tempInitialSku++}`],
          store: {
            id: currentStore.id,
            name: currentStore.storeName,
          },
          price: {
            unit_purchase_price: product.costOfGoods,
            unit_sell_price: product.inStockPrice || 0,
            quantity: product.inStockQuantity,
            type: product.productCondition,
            marketPrice: convertToFixedPrecision(product?.marketPrice) || 0,
          },
          subcategory:
            product.productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
              ? getProductSubCategory(product.genre)
              : "",
          tradeProductMetaData: {
            isItemLocked: product.isItemLocked,
            cashPercentagePerItem: product.cashPercentagePerItem,
            tradePercentagePerItem: product.tradePercentagePerItem,
            tradeInMarginTypeObject: product.tradeInMarginTypeObject,
            tradeProductType: transactionConstants.PRICECHARTING_PRODUCT,
          },
        };

        //------Keep an initial State of the Item
        productData = {
          ...productData,
          productInitialState: productData,
        };

        updatedNewTradeData = getUpdatedCartItems(
          updatedNewTradeData,
          productData,
          null
        );
      } catch (error) {
        customToast(error, toastType.ERROR);
      }
    }

    // setting the state only once after all the batch items are added in cart (increment the existing ones and create new if does not exist already)
    addToCart(updatedNewTradeData, true);
    generateTempSku(tempInitialSku);
    deactivateSpinner(transactionConstants.ADD_TRADE_BATCH_ITEM);
    handleCloseModal(true);
  };

  //-------handle close modal
  const handleCloseModal = (isModalSubmit = false) => {
    setIsTradeBatchInventoryModal(false);
    if (isModalSubmit) {
      handleAddTradeBatchInventory([]);
    }

    emptyPriceChartingProductObject();
    setSelectedRarity("");
    setRarities([]);
    setPricechartingProductImage("");
  };
  //-------handle add trade modal open
  const handleAddTradeModalOpen = () => {
    toggleAddTradeModal();
  };
  const scrollToTop = () => {
    var tableElement = document.getElementsByClassName("rdt_TableBody")[0];
    if (tableElement) {
      tableElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  //-------Handle table sort
  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  //-------toggle add trade modal
  const toggleAddTradeModal = useCallback(() => {
    setAddTradeModal((prevValue) => !prevValue);
  }, []);

  //-------handle batch trade product edit button click
  const handleEditCardClick = (productDetail) => {
    setEditTradeObject({
      ...productDetail,
    });

    handleAddTradeModalOpen();
  };

  //-------handle add to card batch trade
  const addToCartInBatchTrade = useCallback(
    (editProduct) => {
      const index = addTradeBatchInventory.findIndex(
        (item) => item.id === editProduct.id
      );

      if (index !== -1) {
        const updatedInventory = [
          ...addTradeBatchInventory.slice(0, index),
          ...addTradeBatchInventory.slice(index + 1),
        ];
        updatedInventory.splice(index, 0, editProduct);

        handleAddTradeBatchInventory(updatedInventory);
        setEditTradeObject("");
      }
    },
    [addTradeBatchInventory]
  );

  const handleRowSelectionChange = useCallback((state) => {
    setRowsToUpdateCondition(state.selectedRows);
  }, []);

  //-------useeffects

  //-------maintain ratio between trade and cash offer on
  //-------market price change or stock price change
  useEffect(() => {
    const marketPrice = convertToFixedPrecision(productDataObject.marketPrice);
    if (marketPrice == 0) {
      const ratio =
        globalTradeinMargin?.cashMarginPercentage /
        globalTradeinMargin?.tradeinMarginPercentage;
      setInitialRatio(ratio);
    } else {
      const initialCashValue =
        marketPrice * (globalTradeinMargin?.cashMarginPercentage / 100);
      const initialTradeValue =
        marketPrice * (globalTradeinMargin?.tradeinMarginPercentage / 100);
      const initialRatio = initialTradeValue / initialCashValue;
      setInitialRatio(initialRatio);
    }
  }, [globalTradeinMargin, productDataObject]);

  //-------set product image affter getting from backend hit
  useEffect(() => {
    setProductDataObject({
      ...productDataObject,
      imgUrl: pricechartingProductImage,
    });
  }, [pricechartingProductImage]);

  const prevLength = useRef(addTradeBatchInventory?.length);
  useEffect(() => {
    if (addTradeBatchInventory?.length > prevLength.current) {
      getInStockProducts(
        currentStore,
        inventoryActions,
        addTradeBatchInventory,
        handleAddTradeBatchInventory,
        markupPresets,
        handleItemCashTradePercentage,
        tradeinMargin,
        true
      );
    }
    prevLength.current = addTradeBatchInventory?.length;
  }, [addTradeBatchInventory?.length]);

  //-------update product condition and type
  useEffect(() => {
    if (priceChartingProductInfo && isTradeBatchInventoryModal && !isEdit) {
      activateSpinner(inventoryConstants.ADD_ITEM_IN_BATCH);
      setDefaultProductDataObject(
        markupPresets,
        setRarities,
        currentStore,
        inventoryActions,
        productDataObject,
        addTradeBatchInventory,
        setSelectedRarity,
        tradeinMargin,
        false,
        setProductDataObject,
        handleAddTradeBatchInventory,
        priceChartingProductInfo,
        handleItemCashTradePercentage,
        scrollToTop,
        emptyPriceChartingProductObject
      );

      deactivateSpinner(inventoryConstants.ADD_ITEM_IN_BATCH);
    }
  }, [priceChartingProductInfo]);

  useEffect(() => {
    setColumns([...columnHeaders]);

    setCashTradeOfferValue(
      getTotalTradeOfferOfBatchProducts(addTradeBatchInventory, false, false)
    );
  }, [addTradeBatchInventory]);

  return (
    <>
      <Modal
        size="lg"
        centered
        backdrop="static"
        animation={true}
        onHide={handleCloseModal}
        dialogClassName="modal-90w"
        show={isTradeBatchInventoryModal}
        className={`add-inventory-modal ${isModal && "d-none"}`}
      >
        <Modal.Header closeButton className="add-inventory-modal-header">
          <Modal.Title>
            <span className="add-inventory-modal-header-name">
              Batch Trade-In
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-inventory-modal-body py-0">
          {/* search inventory */}
          <Row className="m-0">
            <Col md={9} className="mb-0 ps-md-0">
              <SearchInventory
                customTypes={customTypes}
                asyncSelectRef={asyncSelectRef}
                customCategories={customCategories}
              />
            </Col>
            {rowsToUpdateCondition?.length ? (
              <Col md={3} className="d-flex justify-content-end pe-md-0 gap-2">
                <Button
                  label={buttonNameConstants.DELETE}
                  type="button"
                  buttonType={buttonTypeConstants.RED_BUTTON}
                  handleClick={handleBulkDelete}
                  isDisabled={isSpinnerEnabled(
                    spinnerArray,
                    transactionConstants.ADD_TRADE_BATCH_ITEM
                  )}
                />
                <Button
                  label={buttonNameConstants.BULK_UPDATE}
                  type="button"
                  handleClick={handleBulkUpdateCondition}
                  isDisabled={isSpinnerEnabled(
                    spinnerArray,
                    transactionConstants.ADD_TRADE_BATCH_ITEM
                  )}
                />
              </Col>
            ) : !rowsToUpdateCondition?.length &&
              addTradeBatchInventory?.length ? (
              <Col md={3} className="d-flex justify-content-end pe-md-0 gap-2">
                <Button
                  label={buttonNameConstants.CLEAR}
                  type="button"
                  buttonType={buttonTypeConstants.LIGHT_PURPLE}
                  handleClick={() => {
                    toggleConfirmationModal();
                  }}
                  isDisabled={isSpinnerEnabled(
                    spinnerArray,
                    transactionConstants.ADD_TRADE_BATCH_ITEM
                  )}
                />
              </Col>
            ) : (
              ""
            )}
          </Row>
          {/* main body */}
          <Row>
            <EditableReactDataTableComponent
              selectableRows
              columns={columns}
              onSort={onTableSort}
              gridState={gridState}
              data={[...addTradeBatchInventory].reverse()}
              setGridState={setGridState}
              total={addTradeBatchInventory?.length}
              clearSelectedRows={toggledClearRows}
              defaultSortFieldId={defaultSortFieldId}
              handleRowSelectionChange={handleRowSelectionChange}
            />
          </Row>
          {/*Modal footer */}
          <AddTradeBatchFooter
            isLink={isLink}
            setIsLink={setIsLink}
            handleResetBatch={handleResetBatch}
            spinnerArray={spinnerArray}
            closeModal={handleCloseModal}
            cashTradeOfferValue={cashTradeOfferValue}
            handleOfferValueChange={handleOfferValueChange}
            handleSubmitAddBatchInventory={handleSubmitAddBatchInventory}
            currentStore={currentStore}
            addTradeBatchInventory={addTradeBatchInventory}
            data={{
              totalItems: addTradeBatchInventory.reduce((acc, currentInv) => {
                return Number(currentInv.inStockQuantity) + acc;
              }, 0),
              totalMarketPrice: addTradeBatchInventory.reduce(
                (acc, currentInv) => {
                  return (
                    Number(
                      currentInv.marketPrice * currentInv.inStockQuantity
                    ) + acc
                  );
                },
                0
              ),
              totalPrice: addTradeBatchInventory.reduce((acc, currentInv) => {
                return (
                  Number(currentInv.inStockPrice * currentInv.inStockQuantity) +
                  acc
                );
              }, 0),
              totalCost: addTradeBatchInventory.reduce((acc, currentInv) => {
                return Number(currentInv.costOfGoods) + acc;
              }, 0),
              totalCashOffer: addTradeBatchInventory.reduce(
                (acc, currentInv) => {
                  return (
                    Number(currentInv.cashOffer * currentInv.inStockQuantity) +
                    acc
                  );
                },
                0
              ),
              totalTradeOffer: addTradeBatchInventory.reduce(
                (acc, currentInv) => {
                  return (
                    Number(currentInv.tradeOffer * currentInv.inStockQuantity) +
                    acc
                  );
                },
                0
              ),
            }}
          />
        </Modal.Body>
      </Modal>
      {addTradeModal && (
        <BatchTradeEdit
          newTradeData={newTradeData}
          addToCart={addToCartInBatchTrade}
          editProductObject={editTradeObject}
          isBatchProductEditModal={addTradeModal}
          toggleIsBatchProductEditModal={toggleAddTradeModal}
        />
      )}
      <Modal
        show={isBulkModal}
        size="sm"
        onHide={handleHideBulkModal}
        animation={true}
        centered
        backdrop="static"
        className={"add-inventory-modal bulk-modal"}
      >
        <Modal.Header closeButton className="add-inventory-modal-header">
          <Modal.Title>
            <span className="add-inventory-modal-header-name">Bulk Update</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-inventory-modal-body d-flex ">
          <Row className="m-0">
            <Col md={4} className="ps-md-0 d-flex align-items-center mb-3">
              Condition
            </Col>
            <Col md={8} className="pe-md-0  mb-3">
              <SelectDropDown
                menuPortalTarget=""
                menuPlacement="bottom"
                handleChange={(e) => {
                  setBulkUpdateCondition(e);
                }}
                options={
                  rowsToUpdateCondition[0]?.productType ===
                  PRODUCT_TYPES_ENUMS.VIDEO_GAME
                    ? inventoryConstants.VIDEO_GAME_CONDITION
                    : rowsToUpdateCondition[0]?.productType ===
                      PRODUCT_TYPES_ENUMS.TRADING_CARD
                    ? inventoryConstants.TRADING_CARD_CONDITION
                    : inventoryConstants.OTHER_CONDITION
                }
                value={bulkUpdateCondition}
              />
            </Col>
            <Col md={6} className="ps-md-0">
              <Button
                label={buttonNameConstants.CANCEL}
                className="w-50"
                type="button"
                handleClick={() => {
                  setIsBulkModal(false);
                }}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
              />
            </Col>
            <Col md={6}>
              <Button
                label={buttonNameConstants.UPDATE}
                className="w-50"
                type="button"
                handleClick={handleBulkSubmit}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>{" "}
      <ConfirmationModal
        message={toastMessages.CLEAR_BATCH_WARNING}
        isConfirmationModal={isConfirmationModal}
        handleNoButtonClick={handleConfirmNoButtonClick}
        handleYesButtonClick={handleConfirmYesButtonClick}
        toggleConfirmationModal={toggleConfirmationModal}
      />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  outlet: state.outlet,
  user: state.authentication.user,
  inventory: state.inventory,
  transaction: state.transaction,
  tradeDiscount: state.tradeDiscount,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  productTags: state.inventory.productTags,
  newTradeData: state.transaction.newTradeData,
  markupPresets: state.tradeDiscount.markupPresets,
  tradeinMargin: state.tradeDiscount.tradeinMargin,
  addTradeBatchInventory: state.transaction.addTradeBatchInventory,
  priceChartingProductInfo: state.inventory.priceChartingProductInfo,
  globalTradeinMargin: state.tradeDiscount.tradeinMargin?.globalMargin,
  isTradeBatchInventoryModal: state.transaction.isTradeBatchInventoryModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  generateTempSku: (data) => dispatch(transactionActions.generateTempSku(data)),
  emptyPriceChartingProductObject: () =>
    dispatch(inventoryActions.emptyPriceChartingProductObject()),
  getPricechartingProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getPricechartingProductById(productMetaData, productId)
    ),
  setPokemonProductInReducer: (productMetaData, value) =>
    dispatch(
      inventoryActions.setPokemonProductInReducer(productMetaData, value)
    ),
  getScryFallProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getScryFallProductById(productMetaData, productId)
    ),
  handleDraftModal: (isToggle) =>
    dispatch({
      type: transactionConstants.SET_DRAFT_CONFIRMATION,
      data: isToggle,
    }),
  setIsTradeBatchInventoryModal: (isModalOpen) =>
    dispatch({
      type:
        transactionConstants.SET_IS_TRADE_BATCH_INVENTORY +
        globalConstants.SUCCESS,
      data: isModalOpen,
    }),
  setAddTradeBatchInventory: (data, storeId) =>
    dispatch(transactionActions.setAddTradeBatchInventory(data, storeId)),
});

//-------Export Add Trade Batch Component
export default connect(mapStateToProps, mapDispatchToProps)(AddTradeBatch);
