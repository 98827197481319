import { globalConstants, printerConstants } from "../../constants";
import { updateObject } from "../../shared/utility";
import { printCashManagementReceipt } from "../../shared/Printers/ReceiptPrinters/printReceipt";

//-------InitialState of the inventory reducer
const initialState = {
  availablePrinters: [],
  defaultReceiptPrinter: globalConstants.EMPTY_STRING,
  defaultZebraPrinter: globalConstants.EMPTY_STRING,
  isPrintLabelModal: false,
};

//-------Export Inventory Reducer
export const printer = (state = initialState, action) => {
  switch (action.type) {
    //-------get all inventory success
    case printerConstants.GET_AVAILABLE_PRINTERS + globalConstants.SUCCESS:
      return updateObject(state, {
        availablePrinters: action.data.avialablePrinters,
        defaultReceiptPrinter: action.data.defaultReceiptPrinter,
        defaultZebraPrinter: action.data.defaultZeraPrinter,
      });
    //-------get all inventory failure
    case printerConstants.GET_AVAILABLE_PRINTERS + globalConstants.FAILURE:
      return updateObject(state, {
        availablePrinters: [],
      });
    //-------get all inventory failure
    case printerConstants.SET_PRINT_LABEL_MODAL:
      return updateObject(state, {
        isPrintLabelModal: action.data,
      });
    case printerConstants.PRINT_CASH_MANAGEMENT_RECEIPT:
      printCashManagementReceipt(action.data, state.defaultReceiptPrinter);
      return updateObject(state, {});
    //-------Default State
    default:
      return state;
  }
};
