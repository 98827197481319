import moment from "moment";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { outletActions } from "../../../redux/actions";
import Topbar from "../../../shared/components/topbar/Topbar";
import CategoriesDropdown from "../../../shared/components/selectDropdown/CategoriesDropdown";
import DashboardDatePicker from "../../../shared/components/datetimePickerDropdown/DashboardDatePicker";
import {
  dashboardConstants,
  datepickerConstants,
  categoryDropdownConstants,
  EMPTY_STRING,
  outletConstants,
  defaultGridState,
  routesPathConstants,
} from "../../../constants";
import ServerPaginationTable from "../../../shared/components/agGrid/ServerPaginationTable";
import {
  createCompanyDynamicUrl,
  getReceiptPrintDate,
  getRowId,
  getRowStyle,
  getSessionDurationTime,
  isSpinnerEnabled,
  parseToThousandSeperatorDecimalNumber,
  prepareFilterForInventoryHistoryPaginationCall,
  prepareFilterForSessionHistory,
} from "../../../shared/utility";
import TableCustomHeader from "../../../shared/components/table/TableCustomHeader";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import { ShowIcon } from "../../../assets/icons/iconsProvider";
import { PRODUCT_TYPES_ENUMS } from "../../../system/globalEnums";
import { useNavigate } from "react-router";

const RegisterSessions = ({
  outlet,
  getOutlets,
  currentStore,
  spinnerArray,
  getAllSessionsHistory,
}) => {
  //-------reducer
  const { outlets, sessionHistory, currentOutlet } = outlet;

  //-------navigate
  const navigate = useNavigate();

  //-------UseRef
  const gridRef = useRef();

  //-------UseStates
  const [gridState, setGridState] = useState(defaultGridState);
  const [registerOptions, setRegisterOptions] = useState([]);
  const [selectedRegister, setSelectedRegister] = useState(EMPTY_STRING);
  const [timeFrame, setTimeFrame] = useState({
    start: "",
    end: "",
    duration: dashboardConstants.DAILY,
  });

  const getTimeFrame = (value) => {
    setTimeFrame({
      ...timeFrame,
      start: moment
        .utc(value.start)
        .format(datepickerConstants.DATE_TIME_PICKER_DEFAULT_FORMAT),
      end: moment
        .utc(value.end)
        .format(datepickerConstants.DATE_TIME_PICKER_DEFAULT_FORMAT),
      duration: value?.duration,
    });
  };

  //------- handle view session detail
  const handleViewSessionDetail = (data) => {
    navigate(
      createCompanyDynamicUrl(
        routesPathConstants.SELL_AND_TRADE_SESSION_DETAIL
      ),
      {
        state: {
          data: data,
        },
      }
    );
  };

  //-------Coldefs
  const colDefs = useMemo(
    () => [
      {
        headerName: "Outlet",
        field: "outlet",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.createdAt ? (
            <span className="inventory-cell-max-size ">
              {params?.data?.outlet?.name}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Open Time",
        field: "openAt",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.openAt ? (
            <span className="inventory-cell-max-size ">
              <TableCustomHeader
                id={`created-on-in-array-${params?.data?.id}-open`}
                label={
                  <span className="inventory-cell-max-size">
                    {getReceiptPrintDate(params.data.openAt)}
                  </span>
                }
                tooltopText={
                  <p className="text-start fw-normal m-0">
                    {getReceiptPrintDate(params.data.openAt)}
                  </p>
                }
                className="sku-tooltip"
              />
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Close Time",
        field: "closeAt",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.closeAt || params?.data?.closeAt === EMPTY_STRING ? (
            <span className="inventory-cell-max-size ">
              {params?.data?.closeAt ? (
                <TableCustomHeader
                  id={`created-on-in-array-${params?.data?.id}`}
                  label={
                    <span className="inventory-cell-max-size">
                      {getReceiptPrintDate(params.data.closeAt)}
                    </span>
                  }
                  tooltopText={
                    <p className="text-start fw-normal m-0">
                      {getReceiptPrintDate(params.data.closeAt)}
                    </p>
                  }
                  className="sku-tooltip"
                />
              ) : (
                "-"
              )}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Session Length",
        field: "openAt",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.openAt ? (
            <span className="inventory-cell-max-size ">
              {params?.data?.closeAt
                ? getSessionDurationTime(params?.data)
                : "-"}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "User",
        field: "user",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.openBy?.name ? (
            <span className="inventory-cell-max-size ">
              {params?.data?.openBy?.name}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Difference",
        field: "differenceInBalance",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.differenceInBalance ||
          params?.data?.differenceInBalance === 0 ? (
            <span
              className={`inventory-cell-max-size ${
                params?.data?.differenceInBalance < 0
                  ? "text-danger"
                  : "text-primary"
              }`}
            >
              $
              {parseToThousandSeperatorDecimalNumber(
                params.data.differenceInBalance
              )}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "View",
        field: "id",
        sortable: false,
        cellStyle: {
          overflow: "visible",
        },
        maxWidth: 80,
        cellRenderer: (params) =>
          params?.data?.id ? (
            <span
              className="cursor-pointer"
              onClick={() => handleViewSessionDetail(params?.data)}
            >
              <ShowIcon className="cursor-pointer text-primary history-view-icon" />
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
    ],
    // eslint-disable-next-line
    [sessionHistory]
  );

  //-------Grid Data
  const gridData = useMemo(() => {
    setGridState({
      ...gridState,
      pageIndex: sessionHistory?.pageNumber || defaultGridState?.pageIndex,
      totalRecords:
        sessionHistory?.totalRecords || defaultGridState?.totalRecords,
      pageEndRecordNumber:
        sessionHistory?.pageEndRecordNumber ||
        defaultGridState?.pageEndRecordNumber,
      pageStartRecordNumber:
        sessionHistory?.pageStartRecordNumber ||
        defaultGridState?.pageStartRecordNumber,
    });
    return sessionHistory?.outletSessions || [];
  }, [sessionHistory]);

  //-------update gridState
  const updateGridState = (gridState) => {
    getAllSessionsHistory(currentStore?.id, gridState);
    setGridState(gridState);
  };

  //------- UseEffect
  useEffect(() => {
    getOutlets(currentStore?.id, true);
  }, [currentStore]);

  useEffect(() => {
    if (outlets?.length > 0) {
      setRegisterOptions([
        { value: PRODUCT_TYPES_ENUMS.ALL, label: "All Registers" },
        ...outlets?.map((option) => {
          return {
            label: `${option?.name} ${option.isActive ? "" : "(Deleted)"}`,
            value: option?.id,
          };
        }),
      ]);
    }
  }, [outlets]);

  useEffect(() => {
    const currentOutletOption = registerOptions.find(
      (option) => option.value === currentOutlet?.id
    );

    if (currentOutletOption) {
      setSelectedRegister(currentOutletOption);
    } else {
      setSelectedRegister(registerOptions[0] || EMPTY_STRING);
    }
  }, [registerOptions, currentOutlet]);

  useEffect(() => {
    if (
      currentStore &&
      timeFrame.start &&
      timeFrame.end &&
      currentOutlet?.id &&
      selectedRegister?.value
    ) {
      updateGridState({
        ...gridState,
        filters: prepareFilterForSessionHistory(
          timeFrame.start,
          timeFrame.end,
          selectedRegister?.value
        ),
      });
    }
  }, [timeFrame, currentStore, currentOutlet, selectedRegister]);

  //-------Skeleton Array
  const skeletonArray = Array(10).fill({});

  return (
    <>
      <Topbar title="Register Sessions"></Topbar>
      <Row className="m-0">
        <Col
          md={12}
          className="d-flex justify-content-start align-items-center gap-2"
        >
          <DashboardDatePicker label="Date" getTimeFrame={getTimeFrame} />
          <CategoriesDropdown
            options={registerOptions}
            handleChange={(selectedOption) =>
              setSelectedRegister(selectedOption)
            }
            label="Register"
            value={selectedRegister}
            noOptionsMessage="No Register Found"
            placeholder="Select Register"
            SelectIconImage={categoryDropdownConstants.DEFAULT_ICON}
            isClearable={false}
          />
        </Col>
      </Row>
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3 p-0">
        <ServerPaginationTable
          colDefs={colDefs}
          gridRef={gridRef}
          gridData={
            isSpinnerEnabled(spinnerArray, [
              outletConstants.GET_ALL_SESSION_HISTORY,
            ])
              ? skeletonArray
              : gridData
          }
          gridState={gridState}
          getRowId={getRowId}
          getRowStyle={getRowStyle}
          updateGridState={updateGridState}
          paginationPageSize={gridState.pageSize}
          pageSizeOptions={gridState.pageSizeOptions}
          isDisablePaginationActions={isSpinnerEnabled(spinnerArray, [
            outletConstants.GET_CASH_MANAGEMENT_DETAILS,
          ])}
          tableHeight={`calc(100vh - 200px)`}
        />
      </Row>
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  outlet: state.outlet,
  user: state.authentication.user,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getOutlets: (storeId, isActive) =>
    dispatch(outletActions.getOutlets(storeId, isActive)),
  getAllSessionsHistory: (storeId, gridState) =>
    dispatch(outletActions.getAllSessionsHistory(storeId, gridState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterSessions);
