import { invoiceService } from "../../services";
import {
  customToast,
  isProductMetaDataSame,
  handleTransformClientInventoryJsonToDbFormat,
  handleSubmitAddBatchInventory,
} from "../../shared/utility";
import { custom, success, failure } from "./actionsUtilities";
import {
  toastType,
  toastMessages,
  systemConstants,
  defaultGridState,
  invoiceConstants,
} from "../../constants";
import { systemActions } from "./system.action";
import { inventoryActions } from "./inventory.action";

//-------Get All Invoices
const getAllInvoices = (storeId, gridState) => {
  return (dispatch) => {
    dispatch(
      custom(
        invoiceConstants.GET_ALL_INVOICES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    invoiceService
      .getAllInvoices(storeId, gridState)
      .then(
        (response) => {
          dispatch(success(response, invoiceConstants.GET_ALL_INVOICES));
        },
        (error) => {
          dispatch(failure([], invoiceConstants.GET_ALL_INVOICES));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            invoiceConstants.GET_ALL_INVOICES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Add Invoice
const addInvoice = (storeId, data, gridState, handleHideModal) => {
  return (dispatch) => {
    dispatch(
      custom(invoiceConstants.ADD_INVOICE, systemConstants.SPINNER_ACTIVATE)
    );
    invoiceService
      .addInvoice(storeId, data)
      .then(
        (response) => {
          handleHideModal();
          dispatch(getAllInvoices(storeId, gridState));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            invoiceConstants.ADD_INVOICE,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Edit Invoice
const editInvoice = (data, gridState, handleHideModal) => {
  return (dispatch) => {
    dispatch(
      custom(invoiceConstants.ADD_INVOICE, systemConstants.SPINNER_ACTIVATE)
    );
    invoiceService
      .editInvoice(data)
      .then(
        (response) => {
          handleHideModal();
          dispatch(getAllInvoices(data.store?.id, gridState));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            invoiceConstants.ADD_INVOICE,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Delete Invoice
const deleteInvoice = (storeId, id, gridState) => {
  return (dispatch) => {
    dispatch(
      custom(invoiceConstants.DELETE_INVOICE, systemConstants.SPINNER_ACTIVATE)
    );
    invoiceService
      .deleteInvoice(storeId, id)
      .then(
        (response) => {
          customToast(
            toastMessages.INVOICE_DELETED_SUCCESSFULLY,
            toastType.ERROR
          );
          dispatch(getAllInvoices(storeId, gridState));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            invoiceConstants.DELETE_INVOICE,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------handle Add Item In Batch
const setInvoiceBatchInventory = (invoiceBatch) => {
  return (dispatch) => {
    dispatch(
      custom(invoiceBatch, invoiceConstants.SET_INVOICE_BATCH_INVENTORY)
    );
  };
};

//-------Add Vendor
const addVendor = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(invoiceConstants.ADD_NEW_VENDOR, systemConstants.SPINNER_ACTIVATE)
    );
    invoiceService
      .addVendor(storeId, data)
      .then(
        (response) => {
          dispatch(getVendors(storeId));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            invoiceConstants.ADD_NEW_VENDOR,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get Vendor
const getVendors = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(invoiceConstants.GET_VENDORS, systemConstants.SPINNER_ACTIVATE)
    );
    invoiceService
      .getVendors(storeId)
      .then(
        (response) => {
          dispatch(success(response, invoiceConstants.GET_VENDORS));
        },
        (error) => {
          dispatch(failure([], invoiceConstants.GET_VENDORS));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            invoiceConstants.GET_VENDORS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

// invoice, updatedInventory
const fulfillInvoice = (invoice, handleCloseModal, gridState) => {
  return async (dispatch) => {
    try {
      const fulfillInvoiceCallback = (invoice, updatedInventory, gridState) => {
        return async (dispatch) => {
          // fulfill invoice
          let tempInv = {};
          let tempInvoiceInv = {};
          // Map invoice inventory to db inventory according to add batch inventory response (removing extra metadata of invoice inventory and removing products that were not added in db inventory)
          let updatedInvoice = {
            ...invoice,
            updatedAt: new Date(),
            invoiceType: invoiceConstants.FULFILLED,
            inventory: updatedInventory.map((inv) => {
              tempInv = invoice.inventory.find((invoiceInv) => {
                invoiceInv.store = invoice.store;
                tempInvoiceInv =
                  handleTransformClientInventoryJsonToDbFormat(invoiceInv);
                return isProductMetaDataSame(tempInvoiceInv, inv);
              });
              return tempInv
                ? {
                    ...inv,
                    price: {
                      ...inv.price,
                      ...tempInvoiceInv.price,
                    },
                  }
                : inv;
            }),
          };
          if (invoice.id) {
            dispatch(editInvoice(updatedInvoice, gridState, () => {}));
          } else {
            dispatch(
              addInvoice(
                updatedInvoice.store.id,
                updatedInvoice,
                gridState,
                () => {}
              )
            );
          }
        };
      };
      // Draft invoice if not already draft before starting addInventory
      if (!invoice.id) {
        let updatedInvoice = await invoiceService.addInvoice(invoice.store.id, {
          ...invoice,
          invoiceType: invoiceConstants.DRAFT,
        });
        invoice = updatedInvoice;
      }
      // Add inventory
      handleSubmitAddBatchInventory(
        { id: invoice.store?.id, storeName: invoice.store?.name },
        (data) => dispatch(systemActions.activateSpinner(data)),
        (data) => dispatch(systemActions.deactivateSpinner(data)),
        handleCloseModal,
        () => {},
        invoice.inventory,
        (...props) =>
          dispatch(
            inventoryActions.addBatchInventory(...props, {
              isfulfillInvoice: true,
              invoice,
              fulfillInvoiceCallback,
              gridState,
            })
          ),
        invoiceConstants.GET_ALL_INVOICES
      );
    } catch (e) {
      console.log(e);
      customToast(e, toastType.ERROR);
    }
  };
};

const updateFulfilledData = (data, gridState) => {
  return (dispatch) => {
    dispatch(
      custom(invoiceConstants.ADD_INVOICE, systemConstants.SPINNER_ACTIVATE)
    );
    invoiceService
      .editInvoice(data)
      .then(
        (response) => {
          dispatch(getAllInvoices(data.store?.id, gridState));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            invoiceConstants.ADD_INVOICE,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

export const invoiceActions = {
  addVendor,
  addInvoice,
  getVendors,
  editInvoice,
  deleteInvoice,
  getAllInvoices,
  fulfillInvoice,
  updateFulfilledData,
  setInvoiceBatchInventory,
};
