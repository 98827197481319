import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  formatedDateTime,
  formatOutletSessionId,
  getSessionDurationTime,
} from "../../utility";
import SessionSummaryTable from "./SessionSummaryTable";

const SessionDetail = ({
  session,
  isCloseSessionDetails = false,
  detailWidth = " w-25",
  className,
}) => {
  return (
    <Row className={`m-0 mt-2 ${className} ${detailWidth}`}>
      <Col md={4} className="last-closure-detail-heading px-0">
        Outlet
      </Col>
      <Col md={8} className="px-0">
        {session?.outlet?.name}
      </Col>
      <Col md={4} className="last-closure-detail-heading px-0">
        Closure #
      </Col>
      <Col md={8} className="px-0">
        {session?.sessionCount}
      </Col>{" "}
      {isCloseSessionDetails && (
        <>
          <Col md={4} className="last-closure-detail-heading px-0">
            Session ID
          </Col>
          <Col md={8} className="px-0">
            {formatOutletSessionId(session?.sessionCount)}
          </Col>
        </>
      )}
      <Col md={4} className="last-closure-detail-heading px-0">
        User
      </Col>
      <Col md={8} className="px-0">
        {session?.openBy?.name}
      </Col>{" "}
      <Col md={4} className="last-closure-detail-heading px-0">
        Opening Time
      </Col>
      <Col md={8} className="px-0">
        {formatedDateTime(session?.openAt)}
      </Col>
      {isCloseSessionDetails && (
        <>
          <Col md={4} className="last-closure-detail-heading px-0">
            Closing Time
          </Col>
          <Col md={8} className="px-0">
            {session?.closeAt ? formatedDateTime(session?.closeAt) : "-"}
          </Col>
        </>
      )}
      {isCloseSessionDetails && (
        <>
          <Col md={4} className="last-closure-detail-heading px-0">
            Session Length
          </Col>
          <Col md={8} className="px-0">
            {session?.closeAt ? getSessionDurationTime(session) : "-"}
          </Col>
        </>
      )}
    </Row>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  outlet: state.outlet,
  user: state.authentication.user,
  currentStore: state.store.currentStore,
  currentOutlet: state.outlet.currentOutlet,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({});

//-------Export Session Detail Component
export default connect(mapStateToProps, mapDispatchToProps)(SessionDetail);
