import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Modal, Row, Col } from "react-bootstrap";

import Button from "../Buttons/Button";
import { transactionActions } from "../../../redux/actions";
import PrintLabelModal from "../../../views/Inventory/Items/printingLabelModal/PrintLabelModal";
import { viewCartPrintDropdownStyle } from "../../../assets/scss/style/viewCartPrintDropdownStyle";
import SendReceiptModal from "../../../views/SellAndTrade/History/ImportTransactionHistory/SendReceiptModal";
import AddNewCustomerModal from "../../../views/SellAndTrade/NewTransaction/AddNewCustomer/AddNewCustomerModal";
import {
  isTradeTransaction,
  isReturnTransaction,
  createCompanyDynamicUrl,
  parseToThousandSeperatorDecimalNumber,
  calcullateItems,
  parseToDecimalNumber,
  isSaleTransaction,
  getProductDetailCardTags,
  getShortestSku,
  parseJsonObject,
  customToast,
  getReceiptPrintDate,
  getUnitPurchasePrice,
} from "../../utility";
import {
  globalConstants,
  buttonNameConstants,
  routesPathConstants,
  transactionConstants,
  buttonTypeConstants,
  inventoryConstants,
  EMPTY_STRING,
  categoryDropdownConstants,
  toastMessages,
  toastType,
} from "../../../constants";
import SelectDropDown from "../selectDropdown/SelectDropdown";
import { TRADING_CARD_CATEGORIES_ENUMS } from "../../../system/globalEnums";
import CustomerDropdown from "../selectDropdown/CustomerDropdown";
import SelectedCustomerCard from "../customers/SelectedCustomerCard";
import { printReceiptData } from "../../Printers/ReceiptPrinters/printReceipt";
import { prepareReceiptForPrinter } from "../../Printers/ReceiptPrinters/prepareReceiptForPrinter";
import { prepareReceiptForPrinterTrade } from "../../Printers/ReceiptPrinters/prepareReceiptForPrinterTrade";

const TransactionHistoryModal = ({
  user,
  session,
  modalData,
  currentStore,
  allCustomers,
  sendEmailOrSMS,
  isAddCustomerModal,
  defaultReceiptPrinter,
  toggleAddCustomerModal,
  isTransactionHistoryModal,
  toggleTransactionHistoryModal,
  handleUpdateCustomerInSessionHistory,
}) => {
  const navigate = useNavigate();

  //------- useState
  const [customerToEdit, setCustomerToEdit] = useState({});
  const [isAddCustomer, setIsAddCustomer] = useState(false);
  const [isReceiptModal, setIsReceiptModal] = useState(false);
  const [printLabelModal, setPrintLabelModal] = useState(false);
  const [printRecieptData, setprintRecieptData] = useState(modalData);
  const [selectedCustomerFlag, setSelectedCustomerFlag] = useState(false);
  const [selectedRows, setSelectedRows] = useState(
    modalData?.inventory.map((item) => {
      const newObject = {
        sku: getShortestSku(item.sku),
        product_name: item.product_name,
        productType: item.productType,
        price: {
          quantity: item?.price?.quantity,
          type: item?.price?.type,
          unit_sell_price: isTradeTransaction(modalData)
            ? parseToThousandSeperatorDecimalNumber(
                item?.price?.unit_sell_price
              )
            : item?.price?.unit_purchase_price,
        },
        category_name: item?.category_name || item?.category,
      };
      return newObject;
    })
  );

  //-------handle Hide Modal
  const handleHideModal = () => {
    toggleTransactionHistoryModal();
  };

  //------toggle receipt label modal
  const toggleIsReceiptModal = () => {
    setIsReceiptModal(!isReceiptModal);
  };

  //-------set selected customer
  const selectedCustomer = (selectedCustomerFlag) => {
    setSelectedCustomerFlag(selectedCustomerFlag);
  };

  //-------toggle print label modal
  const togglePrintLabelModal = () => {
    setPrintLabelModal(!printLabelModal);
  };

  //-------handle print product label
  const handlePrintProductLabel = () => {
    handleHideModal();
    togglePrintLabelModal();
  };

  //------- handle print sale receipt print
  const handlePrintReceipt = (newTradeData) => {
    if (newTradeData.id.substring(0, 3) == globalConstants.TYPE_ID_TRD) {
      handlePrintReceiptTrade(newTradeData);
    } else {
      const printData = prepareReceiptForPrinter(
        newTradeData,
        user,
        newTradeData.id,
        newTradeData.paymentHistory,
        currentStore,
        allCustomers?.find((customer) => {
          if (customer.id === newTradeData?.customer?.id) {
            return {
              ...newTradeData?.customer,
              currentBalance: customer.currentBalance,
            };
          }
        }) || {},
        [
          ...newTradeData?.inventory?.map((inv) => {
            return {
              ...inv,
              cogs: inv?.price?.unit_purchase_price,
              item: inv?.product_name,
              priceAfterDiscount: inv?.price?.unit_purchase_price,
              itemName: inv?.product_name,
              quantity: inv?.price?.quantity,
              productType: inv?.productType,
              category: inv?.category_name,
              // max: 2,
              // actualPrice: inv?.price?.unit_sell_price,
              // isDiscountApplied: false,
              // discountPercentage: 0,
              // priceAfterDiscount: 1000,
              // actualDiscountPercentage: 0,
            };
          }),
        ],
        newTradeData.discount,
        newTradeData.SubTotal,
        newTradeData.Tax,
        newTradeData.totalAmountToPay,
        newTradeData.cashTenderedAmount,
        newTradeData.cashChangeOwed,
        newTradeData.giftCardPaymentHistory,
        false,
        false,
        newTradeData.discountMethod,
        newTradeData.discountedValue,
        getReceiptPrintDate(newTradeData.createdOn),
        { isReprint: true }
      );
      printReceiptData(printData, defaultReceiptPrinter);
    }
  };

  //------- handle print trade receipt print
  const handlePrintReceiptTrade = (newTradeData) => {
    const partialPayment =
      (newTradeData?.paymentHistory?.[transactionConstants.CASH] || 0) +
        (newTradeData?.paymentHistory?.[transactionConstants.STORE_CREDIT] ||
          0) >
        0 &&
      newTradeData?.totalAmountToPay !== newTradeData?.currentAmountToPay;
    const printData = prepareReceiptForPrinterTrade(
      user,
      newTradeData?.id,
      newTradeData?.paymentHistory,
      currentStore,
      allCustomers?.find((customer) => {
        if (customer.id === newTradeData?.customer?.id) {
          return {
            ...newTradeData?.customer,
            currentBalance: customer.currentBalance,
          };
        }
      }) || {},
      [
        ...newTradeData?.inventory.map((inv) => {
          return {
            ...inv,
            cogs: inv?.price?.unit_purchase_price,
            item: inv?.product_name,
            priceAfterDiscount: partialPayment
              ? getUnitPurchasePrice(newTradeData, inv)
              : inv?.price?.unit_purchase_price,
            itemName: inv?.product_name,
            quantity: inv?.price?.quantity,
            productType: inv?.productType,
            category: inv?.category_name,

            // max: 2,
            // actualPrice: inv?.price?.unit_sell_price,
            // isDiscountApplied: false,
            // discountPercentage: 0,
            // priceAfterDiscount: 1000,
            // actualDiscountPercentage: 0,
          };
        }),
      ],
      newTradeData?.discount,
      newTradeData?.SubTotal,
      newTradeData?.Tax,
      newTradeData?.totalAmountToPay,
      0,
      0,
      false,
      partialPayment,
      false,
      getReceiptPrintDate(newTradeData.createdOn),
      { isReprint: true }
    );
    printReceiptData(printData, defaultReceiptPrinter);
  };

  //------handle Sale Transaction Refund
  const handleIssueRefund = () => {
    navigate(
      createCompanyDynamicUrl(routesPathConstants.SELL_AND_TRADE_NEW_RETURN),
      {
        state: {
          id: modalData?.id,
        },
      }
    );
  };

  //------- handle update customer
  const handleUpdateCustomer = (customer) => {
    const transactionType = isTradeTransaction(modalData)
      ? "trade"
      : isSaleTransaction(modalData)
      ? "transaction"
      : "return";

    const customerData = customer?.id
      ? customer
      : { name: EMPTY_STRING, id: EMPTY_STRING };
    const { id, store } = modalData || {};
    const transactionDetails = {
      id,
      storeId: store?.id,
    };

    handleUpdateCustomerInSessionHistory(
      { customer: customerData },
      transactionDetails,
      transactionType,
      session?.id
    );
  };

  //-------handle add new customer click
  const handleAddNewCustomerClick = () => {
    setCustomerToEdit({});
    toggleAddCustomerModal(true);
  };

  const handleAddCustomerClick = () => {
    setIsAddCustomer(!isAddCustomer);
  };

  //-------handle customer edit click
  const handleCustomerEditClick = () => {
    const customer = allCustomers?.find(
      (customer) => customer.id === modalData?.customer?.id
    );
    if (customer?.id) {
      setCustomerToEdit(customer);
      toggleAddCustomerModal(true);
    }
  };

  return (
    <>
      <Modal
        show={isTransactionHistoryModal}
        onHide={handleHideModal}
        size="md"
        animation={true}
        centered
        backdrop="static"
        className="add-inventory-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <span className="modal-sale-transaction-id">
              {isTradeTransaction(modalData)
                ? "Trade "
                : isReturnTransaction(modalData)
                ? "Return "
                : "Sale "}
              ({modalData?.id})
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Row className="m-0 p-0">
            <Col md={12} className="p-0 mb-2">
              <span className="trans-items-modal view-cart-date">
                {getReceiptPrintDate(modalData?.createdOn)}
              </span>
            </Col>
            <Col md={12} className="p-0 mb-4 mt-2">
              {modalData?.customer?.id ? (
                <SelectedCustomerCard
                  customer={modalData?.customer}
                  isDeleteEditOptions={true}
                  isStoreCredit={false}
                  handleCustomerDeleteClick={() => {
                    if (
                      modalData?.paymentHistory?.totalPaidAmount?.storeCredit >
                      0
                    ) {
                      customToast(
                        toastMessages.UPDATE_CUSTOMER_ERROR,
                        toastType.ERROR
                      );
                    } else {
                      handleUpdateCustomer(false);
                    }
                  }}
                  handleCustomerEditClick={handleCustomerEditClick}
                  className={{
                    parnetDiv: "selected-customer-wrapper gap-3",
                    nameDiv: "selected-customer-wrapper",
                    nameSpan: "trans-product-name-modal-total",
                    editDeleteDiv: "ms-auto",
                    deleteButton: "p-0 opacity-50",
                    editButton: "p-0 opacity-50",
                  }}
                />
              ) : !isAddCustomer ? (
                <Button
                  className="p-0"
                  type="button"
                  label={buttonNameConstants.ADD_CUSTOMER}
                  buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                  handleClick={handleAddCustomerClick}
                />
              ) : (
                <CustomerDropdown
                  value={""}
                  menuPortalTarget=""
                  dropdownIndicator={false}
                  noOptionsMessage="No Customer Found"
                  placeholder="Customer Name, Phone, Email"
                  handleAddCustomerClick={handleAddNewCustomerClick}
                  SelectIconImage={categoryDropdownConstants.SEARCH_ICON}
                  handleChange={(selectedOption) => {
                    if (selectedOption?.value) {
                      handleUpdateCustomer(
                        parseJsonObject(selectedOption?.value)
                      );
                    }
                  }}
                  selectedCustomerFlag={selectedCustomerFlag}
                />
              )}
            </Col>
          </Row>
          <Row className="m-0 p-0 ">
            <div className="container-scrollbar p-0  trans-modal-card-wrapper ">
              <Row className="m-0">
                <Col
                  sm={6}
                  className="px-0 d-flex align-items-center mb-3 history-cart-table-header"
                >
                  Product Name
                </Col>
                <Col
                  sm={3}
                  className="px-0 d-flex align-items-center justify-content-end mb-3 history-cart-table-header"
                >
                  {isTradeTransaction(modalData) ? "Stock Price" : "COGS"}
                </Col>
                <Col
                  sm={3}
                  className="px-0 d-flex align-items-center justify-content-end mb-3 pe-1 history-cart-table-header"
                >
                  {isTradeTransaction(modalData) ? "COGS" : "Stock Price"}
                </Col>
                {modalData?.inventory?.map((product) => {
                  return (
                    <>
                      <Col sm={6} className="px-0 d-flex align-items-center">
                        <div className="d-flex align-items-center gap-1">
                          <span className="trans-items-modal">
                            {product?.price?.quantity}
                          </span>
                          <span className="trans-product-name-modal">
                            {product.product_name}
                          </span>
                          <span className="trans-items-modal">
                            {product?.sku !==
                              transactionConstants.QUICK_TRADE &&
                              `#${getShortestSku(product?.sku)}`}
                          </span>
                        </div>
                      </Col>
                      <Col
                        sm={3}
                        className="px-0 d-flex align-items-center justify-content-end"
                      >
                        <div>
                          <span className="trans-product-name-modal">
                            $
                            {isTradeTransaction(modalData)
                              ? parseToThousandSeperatorDecimalNumber(
                                  product?.price?.unit_sell_price
                                )
                              : parseToThousandSeperatorDecimalNumber(
                                  product?.cogs
                                )}
                          </span>
                        </div>
                      </Col>
                      <Col
                        sm={3}
                        className="px-0 d-flex align-items-center justify-content-end"
                      >
                        <div>
                          <span className="trans-product-name-modal">
                            $
                            {isTradeTransaction(modalData)
                              ? parseToThousandSeperatorDecimalNumber(
                                  product?.price?.unit_purchase_price
                                )
                              : parseToThousandSeperatorDecimalNumber(
                                  product?.price?.unit_purchase_price
                                )}
                          </span>
                        </div>
                      </Col>
                      <Col
                        md={12}
                        className={`m-0 p-0
                       d-flex justify-content-between ${
                         product?.discountPercentage > 0 ? EMPTY_STRING : "mb-3"
                       }`}
                      >
                        <div className="d-flex align-items-center gap-1">
                          <span className="trans-items-modal ms-3">
                            {isTradeTransaction(modalData)
                              ? product?.["category_name"]
                              : product?.category}
                          </span>
                          <span className="trans-items-modal">
                            (
                            {isTradeTransaction(modalData)
                              ? product?.price?.type
                              : product?.type}
                            )
                          </span>
                          {
                            // Show serial number for systems/consoles if found
                            product?.subcategory ===
                              TRADING_CARD_CATEGORIES_ENUMS.SYSTEM &&
                              product?.serialNumber && (
                                <span className="trans-items-modal">
                                  S/N : {product?.serialNumber}
                                </span>
                              )
                          }
                          <span className="trans-items-modal">
                            {getProductDetailCardTags(
                              product.tags?.filter(
                                (tag) =>
                                  tag !== inventoryConstants.CUSTOM_ITEM_TAG
                              ),
                              product.sku
                            )}
                          </span>
                        </div>
                      </Col>
                      {Number(product?.discountPercentage) > 0 && (
                        <>
                          <Col
                            sm={6}
                            className="px-0 d-flex align-items-center mb-3"
                          >
                            <div className="d-flex align-items-center gap-1">
                              <span className="trans-product-name-modal ps-5">
                                {`Discount(${
                                  product.isToggleDiscount
                                    ? `$${product.discountPercentage}`
                                    : `${product.discountPercentage}%`
                                })`}
                              </span>
                            </div>
                          </Col>
                          <Col
                            sm={6}
                            className="px-0 d-flex align-items-center justify-content-end mb-3"
                          >
                            <div>
                              <span className="trans-product-name-modal">
                                - $
                                {parseToThousandSeperatorDecimalNumber(
                                  product.isToggleDiscount
                                    ? parseToThousandSeperatorDecimalNumber(
                                        product.discountPercentage
                                      )
                                    : parseToThousandSeperatorDecimalNumber(
                                        (Number(product.actualPrice) *
                                          Number(product.discountPercentage)) /
                                          100
                                      )
                                )}
                              </span>
                            </div>
                          </Col>
                        </>
                      )}
                    </>
                  );
                })}
              </Row>
            </div>
            <Col
              md={12}
              className="m-0 mt-2 mb-2 p-0 d-flex justify-content-between tran-modal-detail-margin"
            >
              <div className="trans-product-name-modal-text m-0">Subtotal</div>

              <div className="">
                {` $${parseToThousandSeperatorDecimalNumber(
                  modalData?.SubTotal
                )}`}
              </div>
            </Col>
            <>
              {
                // discount is visible in case of sale transaction
                isSaleTransaction(modalData) && (
                  <Col
                    md={12}
                    className="m-0  mb-2 p-0 d-flex justify-content-between tran-modal-detail-margin"
                  >
                    <div className="trans-product-name-modal-text m-0">
                      Discount
                    </div>
                    <div className="">
                      {`$${parseToThousandSeperatorDecimalNumber(
                        modalData?.discount
                      )}`}
                    </div>
                  </Col>
                )
              }
              {
                // tax is visible in case of sale and return transaction
                (isSaleTransaction(modalData) ||
                  isReturnTransaction(modalData)) && (
                  <Col
                    md={12}
                    className="m-0  mb-2 p-0 d-flex justify-content-between tran-modal-detail-margin"
                  >
                    <div className="trans-product-name-modal-text m-0">
                      {`Tax(
            ${parseToDecimalNumber(modalData?.taxPercentage ?? 0)}
            %)`}
                    </div>
                    <div className="">
                      {`$${parseToThousandSeperatorDecimalNumber(
                        modalData?.Tax ?? 0
                      )}`}
                    </div>
                  </Col>
                )
              }
            </>

            <Col
              md={12}
              className="m-0  mb-2 p-0 pt-2 d-flex justify-content-between border-top tran-modal-detail-margin"
            >
              <div className="trans-product-name-modal-total m-0">
                TOTAL ({calcullateItems(modalData?.inventory)} items)
              </div>

              <div className="trans-product-name-modal-total">
                $
                {parseToThousandSeperatorDecimalNumber(
                  modalData?.totalAmountToPay
                )}
              </div>
            </Col>
            {
              // PAYMENT HISTORY: history is generating in case of transaction (sale) and trade and in case of return use the current paid values as there is no split payment feature in return transaction
              isReturnTransaction(modalData) ? (
                <Col
                  md={12}
                  className="m-0 p-0 mb-1 d-flex justify-content-between"
                >
                  <div className="d-flex align-items-center gap-1">
                    <span className="trans-product-name-modal-text">
                      {modalData.PaymentType ===
                      transactionConstants.CREDIT_CARD
                        ? buttonNameConstants.CREDIT_CARD
                        : modalData.PaymentType ===
                          transactionConstants.STORE_CREDIT
                        ? buttonNameConstants.STORE_CREDIT
                        : modalData.PaymentType ===
                          transactionConstants.GIFT_CARD
                        ? modalData?.giftCard?.giftCardName
                        : buttonNameConstants.CASH}
                    </span>
                  </div>
                  <div>
                    <span className="trans-product-name-modal-text">
                      $
                      {parseToThousandSeperatorDecimalNumber(
                        modalData?.totalAmountToPay
                      )}
                    </span>
                  </div>
                </Col>
              ) : (
                modalData?.paymentHistory?.history?.map((history) => {
                  return history.isUndoTransaction ? (
                    <Col
                      md={12}
                      className="m-0 p-0 mb-1 d-flex justify-content-between"
                    >
                      <div className="d-flex align-items-center gap-1">
                        <span className="trans-product-name-modal-text">
                          {history.paymentType ===
                          transactionConstants.CREDIT_CARD
                            ? buttonNameConstants.CREDIT_CARD
                            : history.paymentType ===
                              transactionConstants.STORE_CREDIT
                            ? buttonNameConstants.STORE_CREDIT
                            : history.paymentType ===
                              transactionConstants.GIFT_CARD
                            ? history?.giftCardName
                            : buttonNameConstants.CASH}
                        </span>
                      </div>
                      <div>
                        <span className="trans-product-name-modal-text">
                          $
                          {parseToThousandSeperatorDecimalNumber(
                            history?.currentAmountToPay
                          )}
                        </span>
                      </div>
                    </Col>
                  ) : (
                    ""
                  );
                })
              )
            }
          </Row>
          {modalData?.note && (
            <Col md={12} className="m-0 mt-3   p-0">
              <div className="note-text-div container-scrollbar">
                <p className="trans-items-modal mb-2">Note</p>
                <span className="note-text">{modalData?.note}</span>
              </div>
            </Col>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center gap-2 w-100">
            <Button
              type="button"
              label={buttonNameConstants.CLOSE}
              handleClick={() => handleHideModal()}
              buttonType={buttonTypeConstants.GHOST_BUTTON}
              className="w-100"
            />
            <SelectDropDown
              outerWrapperClassname="w-100"
              menuPortalTarget=""
              isSingleValue="Receipt"
              placeHolder="Receipt"
              handleChange={(option) => {
                if (
                  option?.value ===
                  transactionConstants.RECEIPT_OPTIONS[0].value
                ) {
                  handlePrintReceipt(printRecieptData);
                } else if (
                  option?.value ===
                  transactionConstants.RECEIPT_OPTIONS[1].value
                ) {
                  toggleIsReceiptModal();
                }
              }}
              styles={viewCartPrintDropdownStyle}
              menuPlacement="bottom"
              options={transactionConstants.RECEIPT_OPTIONS}
            />

            {modalData?.id.substring(0, 3) === globalConstants.TYPE_ID_TRA ? (
              <Button
                type="button"
                className="w-100"
                label={buttonNameConstants.ISSUE_REFUND}
                handleClick={handleIssueRefund}
              />
            ) : modalData?.id.substring(0, 3) ===
              globalConstants.TYPE_ID_TRD ? (
              <Button
                type="button"
                className="w-100"
                label={buttonNameConstants.PRINT_PRODUCT_LABEL}
                handleClick={handlePrintProductLabel}
              ></Button>
            ) : (
              ""
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <PrintLabelModal
        printLabelModal={printLabelModal}
        togglePrintLabelModal={togglePrintLabelModal}
        selectedRows={selectedRows}
      />
      <SendReceiptModal
        sendEmailOrSMS={sendEmailOrSMS}
        transactionData={modalData}
        isReceiptModal={isReceiptModal}
        toggleIsReceiptModal={toggleIsReceiptModal}
      />
      <AddNewCustomerModal
        selectedCustomer={selectedCustomer}
        addCustomerModal={isAddCustomerModal}
        customerToEdit={customerToEdit}
      />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  user: state.authentication.user,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  allCustomers: state.customer.allCustomers,
  allTransaction: state.transaction.allTransaction,
  paginationData: state.transaction.allTransaction.paginationData,
  sellTradeHistory: state.transaction.allTransaction.sellTradeHistory,
  isAddCustomerModal: state.transaction.isAddCustomerModal,
  defaultReceiptPrinter: state.printer.defaultReceiptPrinter,
  duplicateHistoryRecords: state.transaction.duplicateHistoryRecords,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  sendEmailOrSMS: (data) => dispatch(transactionActions.sendEmailOrSMS(data)),
  handleUpdateCustomerInSessionHistory: (
    data,
    transactionDetails,
    endPoint,
    sessionId
  ) =>
    dispatch(
      transactionActions.handleUpdateCustomerInSessionHistory(
        data,
        transactionDetails,
        endPoint,
        sessionId
      )
    ),
  toggleAddCustomerModal: (value) =>
    dispatch(transactionActions.toggleAddCustomerModal(value)),
});

//-------Export History Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionHistoryModal);
