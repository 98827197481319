import {
  routesPathConstants,
  printerConstants,
  EMPTY_STRING,
} from "../constants";

//-------Set item to session storage
export const setItemToStorage = (keyname, value) => {
  return sessionStorage.setItem(keyname, value);
};

//-------Get item from session storage
export const getItemFromStorage = (keyname) => {
  return sessionStorage.getItem(keyname);
};

//-------Remove item to session storage
export const removeItemFromStorage = (keyname) => {
  return sessionStorage.removeItem(keyname);
};

//-------Is item In session storage
export const isItemInStorage = (keyname) => {
  return sessionStorage.hasOwnProperty(keyname);
};

//-------Set item to local storage
export const setItemToLocalStorage = (keyname, value) => {
  return localStorage.setItem(keyname, value);
};

//-------Is item In local storage
export const isItemInLocalStorage = (keyname) => {
  return localStorage.hasOwnProperty(keyname);
};

//-------Get item local session storage
export const getItemFromLocalStorage = (keyname) => {
  return localStorage.getItem(keyname);
};

//-------Clear storage
export const clearStorage = (
  isClearSession = true,
  isClearLocal = true,
  isReload = true,
  persistentLocalStorageKeys = [printerConstants.LOCAL_DEVICE_IP]
) => {
  // clear local storage
  if (isClearLocal) {
    // Local storage persistant values will not be deleted
    let percistantValuesMap = new Map();
    let tempValue = EMPTY_STRING;
    persistentLocalStorageKeys.forEach((element) => {
      tempValue = getItemFromLocalStorage(element);
      percistantValuesMap.set(element, tempValue);
    });
    localStorage.clear();
    if (percistantValuesMap.size) {
      for (let mapEnntry of percistantValuesMap) {
        setItemToLocalStorage(mapEnntry[0], mapEnntry[1]);
      }
    }
  }
  // clear session storage
  if (isClearSession) {
    sessionStorage.clear();
  }
  // reload
  if (isReload) {
    window.location.href = routesPathConstants.DEFAULT_PATH;
  }
};
