import { connect } from "react-redux";
import React, { useEffect, useState } from "react";

import NestedSidebarBody from "./NestedSidebarBody";
import { SidebarToggleIcon } from "../../../assets/icons/iconsProvider";
import {
  NESTED_SIDEBAR_OPTIONS,
  routesPathConstants,
  systemConstants,
  EMPLOYEE_NOT_ALLOWED_ROUTES,
  NESTED_SIDEBAR_SETTING_OUTLET,
  NESTED_SIDEBAR_SELL_TRADE_OUTLET_ROUTES,
} from "../../../constants";
import { STORE_CARD_PAYMENT_TYPE_ENUMS } from "../../../system/globalEnums";
import {
  handleNestedSidebarNotAllowedOptions,
  isAdminLogin,
  isEmployeeLogin,
  isManagerLogin,
  isStoreOwnerLogin,
} from "../../../shared/utility";

const NestedSidebar = (props) => {
  const {
    user,
    currentOutlet,
    activeSidebarBtn,
    currentStore: { activeCardPaymentType, isOutletTracking },
  } = props;
  const [nestedSidebarOptionsLocal, setNestedSidebarOptionsLocal] = useState({
    ...NESTED_SIDEBAR_OPTIONS,
  });

  useEffect(() => {
    let updatedOptions = { ...NESTED_SIDEBAR_OPTIONS };

    // only shows settings devices if the current store activeCardPaymentType is VERIFONE
    if (
      !isEmployeeLogin(user) &&
      activeCardPaymentType &&
      activeCardPaymentType === STORE_CARD_PAYMENT_TYPE_ENUMS.THIRD_PARTY
    ) {
      updatedOptions = handleNestedSidebarNotAllowedOptions(updatedOptions, [
        routesPathConstants.SETTINGS_DEVICES,
      ]);
    }
    // store owner not allowed
    if (!isAdminLogin(user)) {
    }
    // manager owner not allowed
    if (!isAdminLogin(user) && !isStoreOwnerLogin(user)) {
    }
    // employee owner not allowed
    if (
      !isStoreOwnerLogin(user) &&
      !isAdminLogin(user) &&
      !isManagerLogin(user)
    ) {
      updatedOptions = handleNestedSidebarNotAllowedOptions(
        updatedOptions,
        EMPLOYEE_NOT_ALLOWED_ROUTES
      );
    }
    //------- outlet not tracking menus
    if (!isOutletTracking) {
      updatedOptions = handleNestedSidebarNotAllowedOptions(
        updatedOptions,
        NESTED_SIDEBAR_SETTING_OUTLET
      );
    }
    if (!currentOutlet?.isCashTracking) {
      updatedOptions = handleNestedSidebarNotAllowedOptions(
        updatedOptions,
        NESTED_SIDEBAR_SELL_TRADE_OUTLET_ROUTES
      );
    }

    setNestedSidebarOptionsLocal(updatedOptions);
  }, [user, activeCardPaymentType, isOutletTracking, currentOutlet]);

  return (
    <>
      {nestedSidebarOptionsLocal[activeSidebarBtn] &&
        nestedSidebarOptionsLocal[activeSidebarBtn].length > 0 && (
          <div
            className={`nested-sidebar p-0 m-0  ${
              props.isNestedSidebarOpen
                ? "nested-sidebar-opened"
                : "nested-sidebar-closed"
            }`}
          >
            <NestedSidebarBody
              tabs={nestedSidebarOptionsLocal[activeSidebarBtn]}
            />
            <div
              className={`toggle-sidebar-btn toggle-nested-sidebar-btn ${
                props.isNestedSidebarOpen && "toggle-sidebar-rotate"
              }`}
              onClick={() => props.toggleNestedSidebar()}
            >
              <SidebarToggleIcon className="text-white toggle-icon" />
            </div>
          </div>
        )}
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  user: state.authentication.user,
  currentStore: state.store.currentStore,
  currentOutlet: state.outlet.currentOutlet,
  activeSidebarBtn: state.system.activeSidebarBtn,
  isNestedSidebarOpen: state.system.isNestedSidebarOpen,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  toggleNestedSidebar: (data) =>
    dispatch({ type: systemConstants.TOGGLE_NESTED_SIDEBAR }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NestedSidebar);
