import React, { useEffect, useMemo, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce";

import {
  getDefaultFilterType,
  isButtonDisable,
  isSpinnerEnabled,
  LoadingIndicator,
  prepareFilterForInventoryHistoryPaginationCall,
  prepareFilterForPaginationCall,
} from "../../../../shared/utility";
import SearchField from "../../../../shared/components/searchField/SearchField";
import {
  defaultGridState,
  EMPTY_STRING,
  inventoryConstants,
} from "../../../../constants";
import {
  CrossIcon,
  SearchIcon,
  BlueBoxIcon,
} from "../../../../assets/icons/iconsProvider";
import FilterDropdowns from "../../../../shared/components/FilterDropdowns/FilterDropdowns";
import { PRODUCT_TYPES_ENUMS } from "../../../../system/globalEnums";
import { connect } from "react-redux";
import InfiniteScrollTable from "../../../../shared/components/agGrid/InfiniteScrollTable";
import { inventoryActions } from "../../../../redux/actions";
import CompanyProductDetailModal from "./CompanyProductDetailModal/CompanyProductDetailModal";

const SearchCompanyInventory = ({
  currentStore,
  spinnerArray,
  itemOrganization,
  companyInventory,
  getCompanyInventory,
  emptyCompanyInventory,
  isSearchCompanyInventoryModal,
  toggleIsSearchCompanyInventoryModal,
}) => {
  //-------Reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;

  //-------useStates
  const [search, setSearch] = useState(EMPTY_STRING);
  const [gridState, setGridState] = useState({
    ...defaultGridState,
  });
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchProductCategory, setSearchProductCategory] =
    useState(EMPTY_STRING);
  const [searchProductSubCategory, setSearchProductSubCategory] =
    useState(EMPTY_STRING);
  const [searchProductType, setSearchProductType] = useState(
    getDefaultFilterType(
      customTypes,
      gridState?.productType || PRODUCT_TYPES_ENUMS.ALL
    )
  );
  const [product, setProduct] = useState({});
  const [isProductDetailModal, setIsProductDetailModal] = useState(false);

  const toggleProductDetailModal = () => {
    setIsProductDetailModal(!isProductDetailModal);
  };

  const columns = useMemo(
    () => [
      {
        id: "product_name",
        header: "Product Name",
        accessorKey: "product_name",
        enableSorting: false,
      },
      {
        id: "productType",
        header: "Product Type",
        accessorKey: "productType",
        enableSorting: false,
      },
      {
        id: "productCategory",
        header: "Product Category",
        accessorKey: "category_name",
        enableSorting: false,
      },
      {
        id: "productSubcategory",
        header: "Product Sub-Category",
        accessorKey: "subcategory",
        enableSorting: false,
      },
    ],
    []
  );

  const handleHideModal = () => {
    emptyCompanyInventory();
    toggleIsSearchCompanyInventoryModal();
  };

  const clearSearchText = () => {
    emptyCompanyInventory();
    setSearch("");
  };

  const updateGridState = (gridState) => {
    getCompanyInventory(currentStore?.companyId, gridState, setIsSearchLoading);
    setGridState(gridState);
  };

  //-------add debounce in serach inevntory
  const handleDebouncedSearch = useDebouncedCallback((value) => {
    updateGridState({ ...gridState, searchQuery: value, pageIndex: 1 });
  }, 1000);

  //-------handle search field change
  const handleSearchFieldChange = (value, isScan) => {
    // setIsScan(isScan);
    setSearch(value);
    setIsSearchLoading(true);
    handleDebouncedSearch(value);
  };

  const gridData = useMemo(() => {
    let filteredData = companyInventory?.inventory || [];
    setGridState({
      ...gridState,
      pageIndex: companyInventory.pageNumber || defaultGridState?.pageIndex,
      totalRecords:
        companyInventory.totalRecords || defaultGridState?.totalRecords,
      pageEndRecordNumber:
        companyInventory.pageEndRecordNumber ||
        defaultGridState?.pageEndRecordNumber,
      pageStartRecordNumber:
        companyInventory.pageStartRecordNumber |
        defaultGridState?.pageStartRecordNumber,
    });

    return filteredData;
  }, [companyInventory]);

  const fetchMoreData = () => {
    updateGridState({
      ...gridState,
      pageSize:
        Number(
          gridState?.pageSize ?? inventoryConstants.ITEM_HISTORY_LIST_LENGTH
        ) + inventoryConstants.ITEM_HISTORY_LIST_LENGTH,
    });
  };

  const handleRowClick = (data) => {
    setProduct(data);
    toggleProductDetailModal();
  };

  //------- get item history paginated
  useEffect(() => {
    if (search) {
      updateGridState({
        ...gridState,
        pageSize: 10,
        filters: prepareFilterForPaginationCall(
          searchProductType,
          searchProductCategory,
          searchProductSubCategory
        ),
      });
    }
  }, [searchProductType, searchProductCategory, searchProductSubCategory]);

  return (
    <>
      <Modal
        centered
        animation={true}
        backdrop="static"
        onHide={handleHideModal}
        show={isSearchCompanyInventoryModal}
        size={"lg"}
        className="add-inventory-modal"
      >
        <Modal.Header closeButton className="add-inventory-modal-header">
          <Modal.Title>
            <span className="add-inventory-modal-header-name">
              Search Company Inventory
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-inventory-modal-body ">
          <Row>
            <Col md={12} className="mb-2 px-0">
              <SearchField
                label={"Search "}
                value={search}
                IconImage={SearchIcon}
                BlueBoxIcon={BlueBoxIcon}
                placeHolder="Search Item Name, UPC or SKU"
                onChange={(e) => handleSearchFieldChange(e.target.value, false)}
                CrossIcon={search ? CrossIcon : ""}
                clearSearchText={clearSearchText}
                LoadingIndicator={isSearchLoading && LoadingIndicator}
              />
            </Col>
            <Col md={12} className="px-0 ">
              <div className={`w-100`}>
                <FilterDropdowns
                  customTypes={customTypes}
                  customCategories={customCategories}
                  customSubCategories={customSubCategories}
                  setSearchProductType={setSearchProductType}
                  setSearchProductCategory={setSearchProductCategory}
                  setSearchProductSubCategory={setSearchProductSubCategory}
                  defaultProductType={PRODUCT_TYPES_ENUMS.ALL}
                  isDisabledAll={isButtonDisable(
                    spinnerArray,
                    inventoryConstants.GET_ALL_INVENTORY
                  )}
                />
              </div>
            </Col>
            <Col md={12} className="px-0  py-2">
              <InfiniteScrollTable
                columns={columns}
                data={gridData || []}
                update={fetchMoreData}
                handleRowClick={handleRowClick}
                hasMore={
                  companyInventory?.pageSize < companyInventory.totalRecords
                }
                isLoading={isSpinnerEnabled(
                  spinnerArray,
                  inventoryConstants.GET_INVENTORY_HISTORY
                )}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <CompanyProductDetailModal
        product={product}
        isProductDetailModal={isProductDetailModal}
        toggleProductDetailModal={toggleProductDetailModal}
      />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  itemOrganization: state.itemOrganization,
  companyInventory: state.inventory.companyInventory,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getCompanyInventory: (companyId, gridState, setIsSearchLoading) =>
    dispatch(
      inventoryActions.getCompanyInventory(
        companyId,
        gridState,
        setIsSearchLoading
      )
    ),
  emptyCompanyInventory: () =>
    dispatch(inventoryActions.emptyCompanyInventory()),
});

//-------Export Search Company Inventory Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchCompanyInventory);
