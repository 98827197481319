//-------Import All Reducers
import { user } from "./user.reducer";
import { store } from "./store.reducer";
import { outlet } from "./outlet.reducer";
import { device } from "./device.reducer";
import { system } from "./system.reducer";
import { invoice } from "./invoice.reducer";
import { company } from "./company.reducer";
import { printer } from "./printer.reducer";
import { customer } from "./customer.reducer";
import { dashboard } from "./dashboard.reducer";
import { systemUser } from "./systemUser.reducer";
import { inventory } from "./inventory.reducer";
import { transaction } from "./transaction.reducer";
import { tradeDiscount } from "./tradeInDiscount.reducer";
import { authentication } from "./authentication.reducer";
import { itemOrganization } from "./itemOrganization.reducer";

//-------Export All Reducers
const AllReducers = {
  user,
  store,
  system,
  outlet,
  device,
  company,
  invoice,
  printer,
  customer,
  dashboard,
  inventory,
  systemUser,
  transaction,
  tradeDiscount,
  authentication,
  itemOrganization,
};
export default AllReducers;
