import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";

import AddNewOutlet from "./AddNewOutlet/AddNewOutlet";
import Topbar from "../../../shared/components/topbar/Topbar";
import {
  AddIcon,
  DeleteIcon,
  NewEditIcon,
} from "../../../assets/icons/iconsProvider";
import Button from "../../../shared/components/Buttons/Button";
import {
  buttonNameConstants,
  EMPTY_STRING,
  outletConstants,
} from "../../../constants";
import AGGridTable from "../../../shared/components/agGrid/AGGridTable";
import {
  isSpinnerEnabled,
  getRowStyle,
  getOutletStatus,
} from "../../../shared/utility";
import AGGridToolTip from "../../../shared/components/toolTipComponent/AGGridToolTip";
import { outletActions } from "../../../redux/actions";

const Outlets = (props) => {
  //-------Props
  const { outlet, spinnerArray, getOutlets, deleteOutlet, currentStore } =
    props;

  //-------outlet reducer
  const { outlets, currentOutlet } = outlet;

  //-------UseRef
  const gridRef = useRef();

  //------default coldefs
  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { display: "flex", alignItems: "center" },
      resizable: false,
      tooltipComponent: AGGridToolTip,
    };
  }, []);

  //-------edit outlet
  const handleEditOutlet = (row) => {
    setSelectedOutlet(row);
    toggleAddNewOutletModal();
  };

  //------remove outlet
  const handleRemoveOutlet = (row) => {
    deleteOutlet(row.store.id, row.id);
  };

  //-------outlet coldefs
  const OutletColDefs = useMemo(
    () => [
      {
        headerName: "Outlet Name",
        field: "name",
        sortable: false,
        cellRenderer: (row) => {
          return <span className="outlet-grid-name">{row.data.name}</span>;
        },
      },
      {
        headerName: "Status",
        field: "status",
        sortable: false,
        cellRenderer: (row) => {
          return getOutletStatus(row.data)
            ? outletConstants.ACTIVE
            : outletConstants.INACTIVE;
        },
      },
      {
        headerName: "Cash Tracking",
        field: "isCashTracking",
        sortable: false,
        cellRenderer: (row) => {
          return row.data.isCashTracking
            ? outletConstants.ENABLED
            : outletConstants.DISABLED;
        },
      },
      {
        headerName: "Manage",
        field: "actions",
        sortable: false,
        cellRenderer: (row) => {
          return (
            <div className="outlet-grid-actions d-flex justify-content-center align-items-center gap-3">
              <button onClick={() => handleEditOutlet(row.data)}>
                Edit Outlet
                <NewEditIcon className="ms-2 edit-icon" />
              </button>
              <div className="action-buttons-seperator"></div>
              <button onClick={() => handleRemoveOutlet(row.data)}>
                Remove <DeleteIcon className="ms-2 delete-icon" />
              </button>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [outlets]
  );

  //-------useStates
  const [searchText, setSearchText] = useState(EMPTY_STRING);
  const [selectedOutlet, setSelectedOutlet] = useState({});
  const [isAddNewOutletModal, setIsAddNewOutletModal] = useState(false);

  //-------Toggle Add New Outlet Modal
  const toggleAddNewOutletModal = () => {
    if (isAddNewOutletModal) {
      setSelectedOutlet({});
    }
    setIsAddNewOutletModal(!isAddNewOutletModal);
  };

  //-------handle add new outlet
  const handleAddNewOutlet = () => {
    toggleAddNewOutletModal();
  };

  //-------get row id
  const getRowId = useCallback((params) => params.data.id, []);

  //------- grid Data
  const gridData = useMemo(() => {
    return outlets;
  }, [outlets]);

  useEffect(() => {
    if (currentStore?.id) {
      getOutlets(currentStore?.id);
    }
  }, [currentStore]);

  return (
    <>
      <Topbar title="Outlets"></Topbar>
      <Row className="m-0 w-100 user-screen-wrapper">
        <Col md={9}></Col>
        <Col
          md={3}
          className="d-flex justify-content-end align-items-center gap-3 px-2"
        >
          <Button
            label={buttonNameConstants.ADD_CURRENT_DEVICE_AS_OUTLET}
            IconImage={AddIcon}
            handleClick={handleAddNewOutlet}
            isDisabled={currentOutlet?.id}
          />
        </Col>
      </Row>
      <AddNewOutlet
        outletData={selectedOutlet}
        isAddNewOutletModal={isAddNewOutletModal}
        toggleAddNewOutletModal={toggleAddNewOutletModal}
      />
      <Col md={12} className="mt-3 px-2 outlet-table-style">
        <AGGridTable
          gridRef={gridRef}
          colDefs={OutletColDefs}
          getRowId={getRowId}
          gridData={gridData}
          getRowStyle={getRowStyle}
          searchText={searchText}
          defaultColDef={defaultColDef}
          pagination={false}
          tableHeight={`calc(100vh - 150px)`}
          rowHeight={outletConstants.OUTLET_GRID_ROW_HEIGHT}
        />
      </Col>
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  user: state.user,
  outlet: state.outlet,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getOutlets: (storeId) => dispatch(outletActions.getOutlets(storeId)),
  deleteOutlet: (storeId, outletId) =>
    dispatch(outletActions.deleteOutlet(storeId, outletId)),
});

//-------Connecting the Outlet component to the redux store
export default connect(mapStateToProps, mapDispatchToProps)(Outlets);
