const handleMapInvHistoryForSalesGraph = (invHistoryArr) => {
  const invHistoryMap = new Map();
  let tempInvHistory = {};
  let transactionHistory = [];
  let tempInv = [];
  let tempSubtotal = 0;
  let tempTax = 0;
  let tempDiscount = 0;
  let tempTotalAmountToPay = 0;
  invHistoryArr.forEach((invHistory) => {
    tempInvHistory = invHistoryMap.get(invHistory.TID);
    if (tempInvHistory) {
      invHistoryMap.set(invHistory.TID, [...tempInvHistory, invHistory]);
    } else {
      invHistoryMap.set(invHistory.TID, [invHistory]);
    }
  });

  for (let mapEntry of invHistoryMap.entries()) {
    tempTax = 0;
    tempSubtotal = 0;
    tempDiscount = 0;
    tempInv = mapEntry[1];
    tempTotalAmountToPay = 0;

    tempInv.forEach((inv) => {
      tempTax += inv.product.price?.tax ?? 0;
      tempDiscount += inv.product.price?.discount ?? 0;
      tempSubtotal +=
        (inv.product.price?.quantity ?? 0) *
        (inv.product.price?.unit_sell_price ?? 0);
      tempTotalAmountToPay +=
        (inv.product.price?.quantity ?? 0) *
          (inv.product.price?.unit_sell_price ?? 0) +
        (inv.product.price?.tax ?? 0);
    });
    transactionHistory.push({
      id: tempInv[0].TID,
      inventory: tempInv,
      discount: tempDiscount,
      Tax: tempTax,
      SubTotal: tempSubtotal,
      totalAmountToPay: tempTotalAmountToPay,
      createdOn: tempInv[0].createdOn,
    });
  }

  return transactionHistory;
};

export { handleMapInvHistoryForSalesGraph };
