import React from "react";
import { Col, Row } from "react-bootstrap";
import OpenRegister from "../../../views/SellAndTrade/OpenCloseRegister/OpenRegister/OpenRegister";
import { isSessionOpenedByCurrentUser } from "../../utility";
import { connect } from "react-redux";
import { toggleSwitchButtonConstants } from "../../../constants";

const IsRegisterClosedOrNotConfigured = ({
  outlet,
  user,
  children,
  currentStore,
}) => {
  const { currentOutlet, isTillOpen } = outlet;

  return currentStore?.[toggleSwitchButtonConstants.IS_OUTLET_TRACKING] &&
    currentOutlet?.isCashTracking ? (
    currentOutlet?.id ? (
      isTillOpen ? (
        isSessionOpenedByCurrentUser(outlet, user) ? (
          children
        ) : (
          <Row className="m-0 h-100">
            <Col
              md={12}
              className="h-100 d-flex flex-column justify-content-center align-items-center gap-2"
            >
              <span className="text-center no-outlet-heading">
                Register Not Available
              </span>
              <span className="text-center no-outlet-sub-heading">
                This register is in-use by{" "}
                <span className="user-name-heading ">
                  {currentOutlet?.register?.openBy?.name}
                </span>
              </span>{" "}
              <span className="text-center no-outlet-sub-heading">
                They must close it before you can process transactions.
              </span>
            </Col>
          </Row>
        )
      ) : (
        <Row className="m-0 h-100 d-flex justify-content-center align-items-center">
          <OpenRegister heading="Register Closed" formWidth={12} />
        </Row>
      )
    ) : (
      <Row className="m-0 h-100">
        <Col
          md={12}
          className="h-100 d-flex flex-column justify-content-center align-items-center gap-2"
        >
          <span className="text-center no-outlet-heading">
            No Outlet Configured for the Current Device
          </span>
          <span className="text-center no-outlet-sub-heading">
            Configure this device as an Outlet to process Transactions.
          </span>
        </Col>
      </Row>
    )
  ) : (
    children
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  outlet: state.outlet,
  user: state.authentication.user,
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({});

//-------Export transaction Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IsRegisterClosedOrNotConfigured);
