import { Navigate } from "react-router-dom";

import Login from "../views/Login/Login";
import Payouts from "../views/Payouts/Payouts";
import Billing from "../views/Billing/Billing";
import ForgotPin from "../views/Login/ForgotPin";
import { routesPathConstants } from "../constants";
import Customers from "../views/Customers/Customers";
import Dashboard from "../views/Dashboard/Dashboard";
import AdminStores from "../views/Admin/Stores/Stores";
import Outlets from "../views/Settings/Outlets/Outlets";
import EmployeeLogin from "../views/Login/EmployeeLogin";
import ResetPassword from "../views/Login/ResetPassword";
import AdminDevices from "../views/Admin/Devices/Devices";
import SettingsUsers from "../views/Settings/Users/Users";
import Companies from "../views/Admin/Companies/Companies";
import ForgotPassword from "../views/Login/ForgotPassword";
import Invoices from "../views/Inventory/Invoices/Invoices";
import AdminStoresAdd from "../views/Admin/Stores/AddStore";
import InventoryItems from "../views/Inventory/Items/Items";
import AdminStoresEdit from "../views/Admin/Stores/EditStore";
import NewTrade from "../views/SellAndTrade/NewTrade/NewTrade";
import SettingsDevices from "../views/Settings/Devices/Devices";
import SystemUsers from "../views/Admin/SystemUsers/SystemUsers";
import InventoryHistory from "../views/Inventory/History/History";
import InventorySummary from "../views/Inventory/Summary/Summary";
import SettingsDiscount from "../views/Settings/Discount/Discount";
import SettingsTradeIns from "../views/Settings/TradeIns/TradeIns";
import AddCustomer from "../views/Customers/AddCustomer/AddCustomer";
import SellAndTradeDrafts from "../views/SellAndTrade/Drafts/Drafts";
import PrinterSettings from "../views/PrinterSettings/PrinterSettings";
import UserInformation from "../views/UserInformation/UserInformation";
import AddNewUser from "../views/Settings/Users/AddNewUser/AddNewUser";
import PresetPrices from "../views/Settings/PresetPrices/PresetPrices";
import SalesSummary from "../views/Analytics/SalesSummary/SalesSummary";
import SellAndTradeHistory from "../views/SellAndTrade/History/History";
import EditDevice from "../views/Settings/Devices/EditDevice/EditDevice";
import InventoryStockAudit from "../views/Inventory/StockAudit/StockAudit";
import AddNewDevice from "../views/Admin/Devices/AddNewDevice/AddNewDevice";
import SellAndTradeNewReturn from "../views/SellAndTrade/NewReturn/NewReturn";
import SettingsPriceChanges from "../views/Settings/PriceChanges/PriceChanges";
import CashManagement from "../views/SellAndTrade/CashManagement/CashManagement";
import EditInventory from "../views/Inventory/Items/EditInventory/EditInventory";
import InventoryPriceChanges from "../views/Inventory/PriceChanges/PriceChanges";
import AddNewCompany from "../views/Admin/Companies/AddNewCompany/AddNewCompany";
import ChangePrice from "../views/Inventory/PriceChanges/ChangePrice/ChangePrice";
import ItemOrganization from "../views/Settings/ItemOrganization/ItemOrganization";
import CreateDiscount from "../views/Settings/Discount/CreateDiscount/CreateDiscount";
import RegisterSessions from "../views/SellAndTrade/RegisterSessions/RegisterSessions";
import OpenCloseRegister from "../views/SellAndTrade/OpenCloseRegister/OpenCloseRegister";
import SettingsReceiptAndLabels from "../views/Settings/ReceiptAndLabels/ReceiptAndLabels";
import AddNewSystemUser from "../views/Admin/SystemUsers/AddNewSystemUser/AddNewSystemUser";
import NewTradePayment from "../views/SellAndTrade/NewTrade/NewTradePayment/NewTradePayment";
import SellAndTradeNewTransaction from "../views/SellAndTrade/NewTransaction/NewTransaction";
import SettingsShopifyConnection from "../views/Settings/ShopifyConnection/ShopifyConnection";
import SessionDetailHistory from "../views/SellAndTrade/RegisterSessions/SessionDetail/SessionDetailHistory";
import NewTransactionPayment from "../views/SellAndTrade/NewTransaction/NewTransationPayment/NewTransactionPayment";

//-------Create routes for the components
export const getRoutes = (domainUrl) => [
  {
    path: routesPathConstants.DEFAULT_PATH,
    component: <Navigate to={`${domainUrl}${routesPathConstants.LOGIN}`} />,
    protected: false,
  },
  {
    path: `${routesPathConstants.RESET_PASSWORD}`,
    component: <ResetPassword />,
    isEmployeeRoute: true,
  },
  {
    path: `${routesPathConstants.RESET_PASSWORD}`,
    component: <ResetPassword />,
    protected: false,
  },
  {
    path: routesPathConstants.UNKNOWN_PATH,
    component: <Navigate to={`${domainUrl}${routesPathConstants.LOGIN}`} />,
    protected: false,
  },
  {
    path: `${domainUrl}${routesPathConstants.DEFAULT_PATH}`,
    component: <Navigate to={`${domainUrl}${routesPathConstants.DASHBOARD}`} />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.UNKNOWN_PATH}`,
    component: <Navigate to={`${domainUrl}${routesPathConstants.DASHBOARD}`} />,
    protected: true,
  },
  {
    path: `${routesPathConstants.DEFAULT_PATH}`,
    component: (
      <Navigate to={`${domainUrl}${routesPathConstants.EMPLOYEE_LOGIN}`} />
    ),
    isEmployeeRoute: true,
  },
  {
    path: `${routesPathConstants.UNKNOWN_PATH}`,
    component: (
      <Navigate to={`${domainUrl}${routesPathConstants.EMPLOYEE_LOGIN}`} />
    ),
    isEmployeeRoute: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.EMPLOYEE_LOGIN}`,
    component: <EmployeeLogin />,
    isEmployeeRoute: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.FORGOT_PASSWORD}`,
    component: <ForgotPassword />,
    protected: false,
  },
  {
    path: `${domainUrl}${routesPathConstants.FORGOT_PIN}`,
    component: <ForgotPin />,
    isEmployeeRoute: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.LOGIN}`,
    component: <Login />,
    protected: false,
  },
  {
    path: `${domainUrl}${routesPathConstants.DASHBOARD}`,
    component: <Dashboard />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION}`,
    component: <SellAndTradeNewTransaction />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SELL_AND_TRADE_NEW_RETURN}`,
    component: <SellAndTradeNewReturn />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SELL_AND_TRADE_HISTORY}`,
    component: <SellAndTradeHistory />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SELL_AND_TRADE_DRAFTS}`,
    component: <SellAndTradeDrafts />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.INVENTORY_ITEMS}`,
    component: <InventoryItems />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.INVENTORY_EDIT}`,
    component: <EditInventory />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.INVENTORY_ITEMS_HISTORY}`,
    component: <InventoryHistory />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.INVENTORY_ITEMS_SUMMARY}`,
    component: <InventorySummary />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.INVENTORY_ITEMS_PRICE_CHANGES}`,
    component: <InventoryPriceChanges />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.INVENTORY_ITEMS_STOCK_AUDIT}`,
    component: <InventoryStockAudit />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.CUSTOMERS}`,
    component: <Customers />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_USERS}`,
    component: <SettingsUsers />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_DEVICES}`,
    component: <SettingsDevices />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_OUTLETS}`,
    component: <Outlets />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_DISCOUNT}`,
    component: <SettingsDiscount />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_DISCOUNT_CREATE}`,
    component: <CreateDiscount />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_PRICE_CHANGES}`,
    component: <SettingsPriceChanges />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_TRADE_INS}`,
    component: <SettingsTradeIns />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_RECEIPT_AND_LABELS}`,
    component: <SettingsReceiptAndLabels />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_SHOPIFY_CONNECTION}`,
    component: <SettingsShopifyConnection />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ADD_NEW_USER}`,
    component: <AddNewUser />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_USERS_EDIT_USER}`,
    component: <AddNewUser />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_DEVICES_ADD}`,
    component: <AddNewDevice />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_DEVICES_EDIT}`,
    component: <EditDevice />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.PRINTER_SETTINGS}`,
    component: <PrinterSettings />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_PRINTER_SETTINGS}`,
    component: <PrinterSettings />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.USER_INFORMATION}`,
    component: <UserInformation />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.BILLING}`,
    component: <Billing />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.PAYOUTS}`,
    component: <Payouts />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ADMIN_STORES}`,
    component: <AdminStores />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ADMIN_STORES_ADD}`,
    component: <AdminStoresAdd />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ADMIN_STORES_EDIT}`,
    component: <AdminStoresEdit />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ADMIN_DEVICES}`,
    component: <AdminDevices />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ADMIN_DEVICES_ADD}`,
    component: <AddNewDevice />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ADMIN_DEVICES_EDIT}`,
    component: <AddNewDevice />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ADMIN_SYSTEMUSERS}`,
    component: <SystemUsers />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ADMIN_SYSTEMUSERS_ADD}`,
    component: <AddNewSystemUser />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ADMIN_SYSTEMUSERS_EDIT}`,
    component: <AddNewSystemUser />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.CUSTOMERS_EDIT}`,
    component: <AddCustomer />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.CUSTOMERS_ADD}`,
    component: <AddCustomer />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SELL_AND_TRADE_NEW_TRADE}`,
    component: <NewTrade />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION_PAYMENT}`,
    component: <NewTransactionPayment />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SELL_AND_TRADE_NEW_TRADE_PAYMENT}`,
    component: <NewTradePayment />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SELL_AND_TRADE_OPEN_CLOSE_REGISTER}`,
    component: <OpenCloseRegister />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SELL_AND_TRADE_CASH_MANAGEMENT}`,
    component: <CashManagement />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SELL_AND_TRADE_SESSION_DETAIL}`,
    component: <SessionDetailHistory />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SELL_AND_TRADE_REGISTER_SESSIONS}`,
    component: <RegisterSessions />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.CHANGE_PRICE}`,
    component: <ChangePrice />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_PRESET_PRICES}`,
    component: <PresetPrices />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.SETTINGS_ITEM_ORGANIZATION}`,
    component: <ItemOrganization />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ADMIN_COMPANIES}`,
    component: <Companies />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ADMIN_COMPANIES_ADD}`,
    component: <AddNewCompany />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ADMIN_COMPANIES_EDIT}`,
    component: <AddNewCompany />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.ANALYTICS_SALES_SUMMARY}`,
    component: <SalesSummary />,
    protected: true,
  },
  {
    path: `${domainUrl}${routesPathConstants.INVENTORY_INVOICES}`,
    component: <Invoices />,
    protected: true,
  },
];

//-------Export getRoutes function
export default getRoutes;
