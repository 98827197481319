import Select from "react-select";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { companyService } from "../../../services";
import { sidebarSelectFieldStyle } from "../../../assets/scss/style";
import { getItemFromStorage, setItemToStorage } from "../../../system/storage";
import {
  storeActions,
  itemOrganizationActions,
  inventoryActions,
  tradeInDiscountActions,
  printerActions,
  customerActions,
  transactionActions,
  systemUserActions,
  outletActions,
} from "../../../redux/actions";
import {
  storeConstants,
  globalConstants,
  sessionStorageConstants,
  routesPathConstants,
  inventoryConstants,
  transactionConstants,
  transactionDefaultData,
  tradeTransactionDefaultData,
  toastMessages,
  toastType,
  toggleSwitchButtonConstants,
} from "../../../constants";
import {
  createCompanyDynamicUrl,
  customToast,
  getCurrentMacAddress,
  getCurrentOutletfromLocalStorage,
  getPaginationFilterParamsFromGridState,
  getStoreSaleCartDetailsFromStorage,
} from "../../../shared/utility";
import { getMacAddress } from "../../../shared/Printers/ReceiptPrinters/printReceipt";

const SidebarHeader = (props) => {
  const {
    user,
    stores,
    getTags,
    getMarkup,
    getMargin,
    emptyOutlet,
    currentStore,
    getAllDiscount,
    setNewTradeData,
    setCurrentStore,
    setTransactionData,
    getCustomCategories,
    setAddBatchInventory,
    getAvailablePrinters,
    getCustomProductTypes,
    getOutletByMacAddress,
    getCustomSubCategories,
    updateUserDefaultStore,
    getPaginationCustomers,
    setAddTradeBatchInventory,
    emptyPriceChartingProductObject,
  } = props;
  const [storeCompany, setStoreCompany] = useState("");
  const navigate = useNavigate();

  //------- Hande Store Change from header
  const handleStoreChange = (selectedOption) => {
    setItemToStorage(sessionStorageConstants.STORE_INDEX, selectedOption.value);

    //------ get recent selected store of current user
    const currentStore = stores[selectedOption.value];
    updateUserDefaultStore({
      userId: user?.id,
      store: {
        id: currentStore?.id,
        name: currentStore?.storeName,
      },
    });
    setCurrentStore(currentStore);
    navigate(createCompanyDynamicUrl(routesPathConstants.DEFAULT_PATH));
  };

  useEffect(() => {
    props.getStores(user);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentStore) {
      companyService.getCompanyById(currentStore?.companyId).then(
        (response) => {
          setStoreCompany(response);
        },
        (error) => {}
      );
      getAllDiscount(currentStore?.id);
      getMargin(currentStore?.id);
      getMarkup(currentStore?.id);
      getCustomProductTypes(currentStore.id);
      getCustomCategories(currentStore.id);
      getCustomSubCategories(currentStore.id);
      getTags(currentStore?.id);
      getAvailablePrinters(currentStore.id);
      getPaginationCustomers(currentStore.companyId, currentStore.id);

      setAddBatchInventory([]);
      emptyPriceChartingProductObject();

      const transactionDataObject = getStoreSaleCartDetailsFromStorage(
        transactionConstants.NEW_SALE_PAGE_DATA,
        currentStore?.id,
        transactionDefaultData
      );

      const tradeDataObject = getStoreSaleCartDetailsFromStorage(
        transactionConstants.NEW_TRADE_PAGE_DATA,
        currentStore?.id,
        tradeTransactionDefaultData
      );

      const tradeBatchInventory = getStoreSaleCartDetailsFromStorage(
        transactionConstants.TRADE_BATCH_DATA,
        currentStore?.id,
        []
      );
      setNewTradeData(tradeDataObject, currentStore?.id);
      setTransactionData(transactionDataObject, currentStore?.id);
      setAddTradeBatchInventory(tradeBatchInventory, currentStore?.id);
      if (currentStore?.[toggleSwitchButtonConstants.IS_OUTLET_TRACKING]) {
        getCurrentMacAddress(currentStore, getOutletByMacAddress);
      } else {
        emptyOutlet();
      }
    } // eslint-disable-next-line
  }, [currentStore]);

  return (
    <div className="w-100 d-flex justify-content-start align-items-center gap-3 ps-2">
      {currentStore?.logo && (
        <div>
          <img
            src={currentStore.logo}
            alt=""
            className="collect-pos-logo-image"
          />
        </div>
      )}
      {!storeCompany?.isDummyCompany ? (
        <span>{storeCompany?.companyName}</span>
      ) : (
        ""
      )}
      <Select
        name="store"
        id="store"
        options={stores.map((store, index) => ({
          label: store.storeName,
          value: index,
        }))}
        value={{
          label: currentStore.storeName,
          value: Number(
            getItemFromStorage(sessionStorageConstants.STORE_INDEX)
          ),
        }}
        isSearchable={false}
        placeholder={"Store.."}
        styles={sidebarSelectFieldStyle}
        onChange={handleStoreChange}
      />
    </div>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  stores: state.store.stores,
  user: state.authentication.user,
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getStores: (data) => dispatch(storeActions.getStores(data)),
  getCustomProductTypes: (storeId) =>
    dispatch(itemOrganizationActions.getCustomProductTypes(storeId)),
  getCustomCategories: (storeId) =>
    dispatch(itemOrganizationActions.getCustomCategories(storeId)),
  getCustomSubCategories: (storeId) =>
    dispatch(itemOrganizationActions.getCustomSubCategories(storeId)),
  setCurrentStore: (data) =>
    dispatch({
      type: storeConstants.SET_CURRENT_STORE + globalConstants.SUCCESS,
      data: data,
    }),
  setAddBatchInventory: (data) =>
    dispatch({
      type:
        inventoryConstants.SET_ADD_BATCH_INVENTORY + globalConstants.SUCCESS,
      data: data,
    }),
  setTransactionData: (data, storeId) =>
    dispatch(transactionActions.setTransactionData(data, storeId)),
  setNewTradeData: (data, storeId) =>
    dispatch(transactionActions.setNewTradeData(data, storeId)),
  setAddTradeBatchInventory: (data, storeId) =>
    dispatch(transactionActions.setAddTradeBatchInventory(data, storeId)),
  emptyPriceChartingProductObject: () =>
    dispatch(inventoryActions.emptyPriceChartingProductObject()),
  getMarkup: (storeId) => dispatch(tradeInDiscountActions.getMarkup(storeId)),
  getMargin: (storeId) => dispatch(tradeInDiscountActions.getMargin(storeId)),
  getTags: (storeId) => dispatch(inventoryActions.getTags(storeId)),
  getAvailablePrinters: (storeId) =>
    dispatch(printerActions.getAvailablePrinters(storeId)),
  getAllDiscount: (storeId) =>
    dispatch(tradeInDiscountActions.getAllDiscount(storeId)),
  getPaginationCustomers: (companyId, storeId) =>
    dispatch(customerActions.getPaginationCustomers(companyId, storeId)),
  updateUserDefaultStore: (data) =>
    dispatch(systemUserActions.updateUserDefaultStore(data)),
  getOutletByMacAddress: (storeId, macAddress) =>
    dispatch(outletActions.getOutletByMacAddress(storeId, macAddress)),
  emptyOutlet: () => dispatch(outletActions.emptyOutlet()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
