import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import {
  EditIcon,
  ResetCartIcon,
} from "../../../../assets/icons/iconsProvider";
import { EMPTY_STRING, transactionConstants } from "../../../../constants";
import EditTradeBatchOfferModal from "../../../SellAndTrade/NewTrade/AddTradeBatch/EditTradeBatchOfferModal";
import {
  parseToDecimalNumber,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";

const EditOrResetCogs = ({
  data,
  cogsPrice,
  isDisabled,
  handleRefresh,
  handleCogsChange,
}) => {
  //--------useState
  const [isEditOfferModalOpen, setIsEditOfferModalOpen] =
    useState(EMPTY_STRING);

  const offerPercentages = {
    cogOfferPercentage:
      (data?.totalMarketPrice && (cogsPrice / data?.totalMarketPrice) * 100) ||
      0,
  };

  //-------open edit offer modal
  const toggleEditOfferModal = (value) => setIsEditOfferModalOpen(value);

  return (
    <>
      <Row className=" m-0 w-100 edit-total-cash-trade-wrapper py-2">
        <Col md={6} className="d-flex align-items-center">
          <span className="batch-edit-offer-heading">Total COGS</span>
        </Col>
        <Col
          md={6}
          className="d-flex justify-content-end align-items-center gap-2 position-relative"
        >
          <span className="edit-trade-batch-offer-field">
            ${parseToThousandSeperatorDecimalNumber(cogsPrice || 0)}
            <span className="ms-2">
              (
              {`${parseToDecimalNumber(
                offerPercentages?.cogOfferPercentage || 0
              )} %`}
              )
            </span>
          </span>
          <button
            className="action-Button-Wrapper ps-0 pe-0 reset-total-button cursor-none"
            onClick={() => {
              toggleEditOfferModal(transactionConstants.EDIT_COGS_MODAL);
            }}
            disabled={isDisabled}
          >
            <EditIcon className=" edit-reset-icon" />
          </button>
          {isEditOfferModalOpen === transactionConstants.EDIT_COGS_MODAL ? (
            <EditTradeBatchOfferModal
              data={data}
              handleSubmit={handleCogsChange}
              headingLabel={"Edit Total Cash Offer"}
              value={cogsPrice}
              isEditOfferModalOpen={isEditOfferModalOpen}
              toggleEditOfferModal={toggleEditOfferModal}
              percentage={offerPercentages?.cogOfferPercentage}
            />
          ) : (
            ""
          )}
          <button
            className={`action-Button-Wrapper ps-0 pe-0 reset-total-button`}
            onClick={() => handleRefresh()}
            disabled={isDisabled}
          >
            <ResetCartIcon className={``} />
          </button>
        </Col>
      </Row>
    </>
  );
};

export default EditOrResetCogs;
