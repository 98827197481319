import { updateObject } from "../../shared/utility";
import {
  EMPTY_STRING,
  globalConstants,
  outletConstants,
} from "../../constants";

//------initialState
const initialState = {
  outlets: [],
  lastSession: {},
  currentOutlet: {},
  currentSession: {},
  isSessionOpen: false,
  isTillOpen: false,
  lastSessionId: EMPTY_STRING,
  currentSessionId: EMPTY_STRING,
  cashManangementDetails: [],
  sessionHistory: [],
  singleSessionHistory: {},
};

//-------Export Outlet Reducer
export const outlet = (state = initialState, action) => {
  switch (action.type) {
    //-------Get outlets success case
    case outletConstants.GET_OUTLETS + globalConstants.SUCCESS:
      return updateObject(state, {
        outlets: action.data,
      });
    //-------Get outlets failure case
    case outletConstants.GET_OUTLETS + globalConstants.FAILURE:
      return updateObject(state, {
        outlets: [],
      });
    //-------Get outlet by mac address success case
    case outletConstants.GET_OUTLET_BY_MAC_ADDRESS + globalConstants.SUCCESS:
      return updateObject(state, {
        currentOutlet: action.data,
        isTillOpen: action?.data?.register?.isTillOpen,
        isSessionOpen: action?.data?.register?.isOpen,
        lastSessionId: action?.data?.register?.lastSessionId,
        currentSessionId: action?.data?.register?.currentSessionId,
      });
    //-------Get outlet by mac address failure case
    case outletConstants.GET_OUTLET_BY_MAC_ADDRESS + globalConstants.FAILURE:
      return updateObject(state, {
        currentOutlet: {},
        lastSessionId: EMPTY_STRING,
        currentSessionId: EMPTY_STRING,
        isSessionOpen: false,
        isTillOpen: false,
      });
    //-------Get Cash Management Details success case
    case outletConstants.GET_CASH_MANAGEMENT_DETAILS + globalConstants.SUCCESS:
      return updateObject(state, {
        cashManangementDetails: action.data,
      });
    //------- Get Cash Management Details failure case
    case outletConstants.GET_CASH_MANAGEMENT_DETAILS + globalConstants.FAILURE:
      return updateObject(state, {
        cashManangementDetails: [],
      });
    //-------Get Last Session Details success case
    case outletConstants.GET_LAST_SESSION_DETAILS + globalConstants.SUCCESS:
      return updateObject(state, {
        lastSession: action.data,
      });
    //-------Get Last Session Details failure case
    case outletConstants.GET_LAST_SESSION_DETAILS + globalConstants.FAILURE:
      return updateObject(state, {
        lastSession: {},
      });
    //-------Get Current Session Details success case
    case outletConstants.GET_CURRENT_SESSION_DETAILS + globalConstants.SUCCESS:
      return updateObject(state, {
        currentSession: action.data,
      });
    //-------Get Current Session Details failure case
    case outletConstants.GET_CURRENT_SESSION_DETAILS + globalConstants.FAILURE:
      return updateObject(state, {
        currentSession: {},
      });
    //-------Get All Session History success case
    case outletConstants.GET_ALL_SESSION_HISTORY + globalConstants.SUCCESS:
      return updateObject(state, {
        sessionHistory: action.data,
      });
    //-------Get All Session History failure case
    case outletConstants.GET_ALL_SESSION_HISTORY + globalConstants.FAILURE:
      return updateObject(state, {
        sessionHistory: [],
      });
    //-------Get Single Session History success case
    case outletConstants.GET_SESSION_HISTORY + globalConstants.SUCCESS:
      return updateObject(state, {
        singleSessionHistory: action.data,
      });
    //-------Get Single Session History failure case
    case outletConstants.GET_SESSION_HISTORY + globalConstants.FAILURE:
      return updateObject(state, {
        singleSessionHistory: {},
      });
    //-------Default State
    default:
      return state;
  }
};
