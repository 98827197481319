import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";

import Button from "../../../shared/components/Buttons/Button";

import { useNavigate } from "react-router";

import { systemUserActions } from "../../../redux/actions";
import SearchField from "../../../shared/components/searchField/SearchField";
import CustomTooltip from "../../../shared/components/toolTipComponent/CustomTooltip";
import Topbar from "../../../shared/components/topbar/Topbar";
import {
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  systemUserConstants,
  globalConstants,
  systemConstants,
  SYSTEM_MODAL_TYPES,
} from "../../../constants";
import {
  AddIcon,
  EditIcon,
  DeleteIcon,
  SearchIcon,
  ActionDotsIcons,
  CrossIcon,
  BlueBoxIcon,
} from "../../../assets/icons/iconsProvider";
import DataTableComponent from "../../../shared/components/table/ReactDataTableComponent";
import {
  createCompanyDynamicUrl,
  isDisableUserDelete,
  isSpinnerEnabled,
} from "../../../shared/utility";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import { custom } from "../../../redux/actions/actionsUtilities";
import DeleteUserModal from "../../../shared/components/users/DeleteUserModal";

const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

//-------System Users component start here
const SystemUsers = (props) => {
  const {
    systemUser,
    getAllSystemUsers,
    deleteSystemUser,
    currentStore,
    user,
    spinnerArray,
    setSystemModal,
  } = props;
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();

  const [searchText, setSearchText] = useState("");
  const [gridState, setGridState] = useState(defaultGridState);
  const navigate = useNavigate();
  let numOfRenders = useRef(0);
  //-------System User reducer
  const { allSystemUsers } = systemUser;

  //-------Handle System user edit click
  const handleEditClick = (row) => {
    navigate(
      createCompanyDynamicUrl(routesPathConstants.ADMIN_SYSTEMUSERS_EDIT),
      {
        state: {
          data: row,
          isEdit: true,
        },
      }
    );
  };

  //-------Handle System user delete click
  const handleDeleteClick = (row) => {
    if (row?.id) {
      deleteSystemUser(row.id, currentStore.id, user);
      // reset pagination
      numOfRenders.current++;
    }
  };

  //-------Handle add button click
  const handleAddClick = () => {
    navigate(
      createCompanyDynamicUrl(routesPathConstants.ADMIN_SYSTEMUSERS_ADD),
      {
        state: {
          isEdit: false,
        },
      }
    );
  };

  const columnHeaders = [
    {
      id: "firstName",
      name: "First Name",
      selector: (row) => row.firstName,
      cell: (row) => (
        <span className="users-cell-max-size">{row.firstName}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "lastName",
      name: "Last Name",
      selector: (row) => row.lastName,
      cell: (row) => (
        <span className="users-cell-max-size">{row.lastName}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => <span className="users-cell-max-size">{row.email}</span>,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            <CustomTooltip anchorSelect={`#tool${row.id}`}>
              <Button
                label={buttonNameConstants.EDIT}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={EditIcon}
                handleClick={() => handleEditClick(row)}
              />
              <Button
                label={buttonNameConstants.DELETE}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={DeleteIcon}
                isDisabled={isDisableUserDelete(row, user)}
                handleClick={() => {
                  setSystemModal({
                    type: SYSTEM_MODAL_TYPES.DELETE_USER_MODAL,
                    metaData: row,
                  });
                }}
              />
            </CustomTooltip>
            <button id={`tool${row.id}`} className="action-Button-Wrapper">
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];

  const [columns, setColumns] = useState(columnHeaders);

  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };

  //-------Handle searchfield change
  const handleFilterData = (e) => {
    const filterList = allSystemUsers.filter((row) => {
      return (
        String(row.firstName + row.lastName + row.email + row.role)
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    // reset pagination
    numOfRenders.current++;
    return filterList;
  };
  const clearSearchText = () => {
    setSearchText("");
  };
  //-------useEffects
  useEffect(() => {
    getAllSystemUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setColumns(columnHeaders);
  }, [currentStore]);
  //-------Return System Users Component
  return (
    <>
      <Topbar title="Superadmins"></Topbar>
      <Row className="m-0 w-100 user-screen-wrapper">
        {isSpinnerEnabled(
          spinnerArray,
          systemUserConstants.GET_ALL_SYSTEM_USERS
        ) ? (
          <>
            <Col md={5}>
              <DropdownSkeleton width={"100%"} />
            </Col>
            <Col md={3}></Col>
            <Col
              md={4}
              className="d-flex justify-content-end align-items-center gap-3"
            >
              <DropdownSkeleton />
            </Col>
          </>
        ) : (
          <>
            <Col md={5}>
              <SearchField
                onChange={(e) => setSearchText(e.target.value)}
                IconImage={SearchIcon}
                label="Search"
                placeHolder="Type or Scan"
                value={searchText}
                CrossIcon={searchText ? CrossIcon : ""}
                clearSearchText={clearSearchText}
                BlueBoxIcon={BlueBoxIcon}
              />
            </Col>
            <Col md={3}></Col>
            <Col
              md={4}
              className="d-flex justify-content-end align-items-end gap-3"
            >
              <Button
                label={buttonNameConstants.ADD}
                IconImage={AddIcon}
                handleClick={handleAddClick}
              />
            </Col>
          </>
        )}
      </Row>
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3">
        <DataTableComponent
          className="customers-table"
          key={numOfRenders.current}
          columns={columns}
          data={searchText ? handleFilterData() : allSystemUsers}
          onSort={onTableSort}
          defaultSortFieldId={defaultSortFieldId}
          gridState={gridState}
          setGridState={updateGridState}
          total={
            searchText ? handleFilterData()?.length : allSystemUsers?.length
          }
        />
      </Row>
      <DeleteUserModal handleYesButtonClick={handleDeleteClick} />
    </>
  );
}; //-------System Users component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  systemUser: state.systemUser,
  user: state.authentication.user,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  company: state.authentication.company,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getAllSystemUsers: () => dispatch(systemUserActions.getAllSystemUsers()),
  deleteSystemUser: (id, storeId, currentUser) => {
    dispatch(systemUserActions.deleteSystemUser(id, storeId, currentUser));
  },
  setSystemModal: (data) =>
    dispatch(custom(data, systemConstants.SET_SYSTEM_MODAL)),
});

//-------Export System Users Component
export default connect(mapStateToProps, mapDispatchToProps)(SystemUsers);
