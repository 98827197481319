import React, { useState } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import { buttonNameConstants, outletConstants } from "../../../../constants";
import { outletActions } from "../../../../redux/actions";
import { isSpinnerEnabled } from "../../../../shared/utility";
import StartSelling from "./StartSelling";
import { handleOpenCashDrawer } from "../../../../shared/Printers/ReceiptPrinters/prepareReceiptForPrinter";

const OpenRegister = (props) => {
  const {
    user,
    currentOutlet,
    currentStore,
    formWidth = 5,
    heading = "No Register Open",
    spinnerArray,
    printer,
    openRegister,
    isTillOpen,
    isSessionOpen,
  } = props;
  const { defaultReceiptPrinter } = printer;
  const [isStartSellingForm, setIsStartSellingFrom] = useState(false);

  //------- handle open register
  const handleOpenRegister = () => {
    const dataToSend = {
      openBy: {
        userId: user?.id,
        name: `${user.firstName} ${user.lastName}`,
      },
      store: { id: currentStore?.id, name: currentStore?.storeName },
      outlet: { id: currentOutlet?.id, name: currentOutlet?.name },
    };
    openRegister(dataToSend, currentOutlet?.macAddress, setIsStartSellingFrom);
  };

  return isStartSellingForm || isSessionOpen ? (
    <StartSelling {...props} setIsStartSellingFrom={setIsStartSellingFrom} />
  ) : (
    <Row className="m-0">
      <Col md={formWidth} className="mx-auto">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="open-register-heading mb-2">{heading}</span>
          <p className="open-register-sub-heading mb-0">
            Click below to open the till and
          </p>
          <p className="open-register-sub-heading mb-0">get started.</p>
        </div>
        <Row className="m-0 mt-4">
          <Col md={12} className="px-0 mt-2">
            <Button
              isDisabled={isSpinnerEnabled(spinnerArray, [
                outletConstants.OPEN_REGISTER,
                outletConstants.GET_OUTLET_BY_MAC_ADDRESS,
              ])}
              label={buttonNameConstants.OPEN_REGISTER}
              className="w-100 open-register-button"
              handleClick={() => {
                setIsStartSellingFrom(true);
                handleOpenCashDrawer(defaultReceiptPrinter);
                handleOpenRegister();
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  printer: state.printer,
  user: state.authentication.user,
  spinnerArray: state.system.spinnerArray,
  currentStore: state.store.currentStore,
  currentOutlet: state.outlet.currentOutlet,
  currentSessionId: state.outlet.currentSessionId,
  isTillOpen: state.outlet.isTillOpen,
  isSessionOpen: state.outlet.isSessionOpen,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  openRegister: (data, macAddress, setIsStartSellingFrom) =>
    dispatch(
      outletActions.openRegister(data, macAddress, setIsStartSellingFrom)
    ),
});

//-------Export Open Register Component
export default connect(mapStateToProps, mapDispatchToProps)(OpenRegister);
