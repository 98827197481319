import { components } from "react-select";
import {
  globalConstants,
  inventoryConstants,
  invoiceConstants,
  toastMessages,
  toastType,
  toggleSwitchButtonConstants,
  transactionConstants,
} from "../constants";
import {
  PRODUCT_CONDITIONS_ENUMS,
  PRODUCT_TYPES_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../system/globalEnums";
import { SearchIcon } from "../assets/icons/iconsProvider";
import {
  calculateOfferPrices,
  convertToFixedPrecision,
  convertToUsd,
  customToast,
  getShortestSku,
  getProductSubCategory,
  getTotalCogsOfBatchProducts,
  parseToDecimalNumber,
  parseToNumber,
  getTypeOrCategoryObject,
} from "./utility";

//-------check is product trading card
export const isTradingCard = (product) =>
  product.genre === TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD ||
  product.genre === TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD ||
  product.genre === TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD;

//-------check is product Video Game
export const isVideoGame = (product) => !isTradingCard(product);

//-------check is store custom product
export const isStoreCustomProduct = (product, key = "sourceApi") =>
  product[key] === inventoryConstants.STORE_CUSTOM_PRODUCT;

//-------craete combine list for the api result list
export const createCombinedList = (apiData, sourceApi) => {
  return apiData.map((item) => {
    if (sourceApi === inventoryConstants.PRICECHARTING_API) {
      return {
        id: item.id,
        price: convertToUsd(item["cib-price"]) ?? 0,
        name: item[inventoryConstants.PRICECHARTING_PRODUCT_NAME],
        category:
          item.genre === TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
            ? TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
            : item[inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME],
        sourceApi: sourceApi,
        genre: item.genre,
        upc: item.upc || globalConstants.EMPTY_STRING,
        productObject: item,
      };
    } else if (sourceApi === inventoryConstants.POKEMON_API) {
      var pricesPath = item?.["tcgplayer"]?.["prices"];
      var firstObjectKey = Object.keys(pricesPath ?? {})[0];
      var marketPrice = pricesPath?.[firstObjectKey]?.["market"];
      return {
        id: item.id,
        name: `${item.name} (${item.set.name} - ${item.number}/${item.set.printedTotal})`,
        category: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
        sourceApi: sourceApi,
        genre: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
        price: marketPrice ?? 0,
        upc: globalConstants.EMPTY_STRING,
      };
    } else if (sourceApi === inventoryConstants.SCRYFALL_API) {
      return {
        id: item.id,
        name: `${item.name} (${item.set_name} - ${item.collector_number})`,
        category: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
        sourceApi: sourceApi,
        price: item?.prices?.usd ?? 0,
        genre: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
        upc: globalConstants.EMPTY_STRING,
      };
    } else if (sourceApi === inventoryConstants.STORE_CUSTOM_PRODUCT) {
      return {
        id: getShortestSku(item.sku),
        name: `${item.product_name}`,
        category: item.category_name,
        sourceApi: sourceApi,
        price: item?.price?.unit_sell_price ?? 0,
        genre: item.category_name,
        subcategory: item.subcategory,
        upc: item.upc,
        productObject: item,
        productType: item.productType,
      };
    }
  });
};

//------custom input for react select
export const CustomInput = (props) => (
  <div>
    <components.Input {...props} />
    <SearchIcon className="dropdown-icon" />
  </div>
);

//--------Add Batch Inventory On Change Product Quantity
export const onChangeProductQuantity = (
  Id,
  newQuantity,
  addBatchInventory,
  setAddBatchInventory,
  handlePercentageFlag
) => {
  newQuantity = Number(newQuantity);

  // Find the item to update
  const itemToUpdate = addBatchInventory.find((item) => item.id === Id);

  if (itemToUpdate) {
    // Update the quantity directly
    itemToUpdate.inStockQuantity = newQuantity;

    // Create a new array to trigger React's re-render
    setAddBatchInventory(handlePercentageFlag([...addBatchInventory]));
  }
};

// for Calculate each product percentage in batch
export const handleCalculateEachProductPercentage = (
  percentageFlagBatchInv
) => {
  const totalCost = getTotalCogsOfBatchProducts(percentageFlagBatchInv);

  return percentageFlagBatchInv.map((item) => {
    if (!item.isItemLocked) {
      const itemTotalCostOfGoods =
        isStoreCustomProduct(item, "apiSource") && !item.trackQuantity
          ? Number(item.costOfGoods * 1)
          : Number(item.costOfGoods * item.inStockQuantity);
      const cogsPercentage =
        totalCost > 0 ? (itemTotalCostOfGoods / totalCost) * 100 : 0;

      return {
        ...item,
        cogsPercentage: convertToFixedPrecision(cogsPercentage),
        costOfGoods:
          cogsPercentage === 0
            ? inventoryConstants.DEFAULT_OFFER_VALUE
            : item.costOfGoods,
      };
    }

    return item;
  });
};

//-------handle cogs of batch product
export const batchCostOfGoodsChange = (
  Id,
  newOffer,
  addBatchInventory,
  setAddBatchInventory
) => {
  const updatedInventory = addBatchInventory.map((item) => {
    if (item.id === Id) {
      return {
        ...item,
        costOfGoods: newOffer,
      };
    }
    return item;
  });
  setAddBatchInventory(handleCalculateEachProductPercentage(updatedInventory));
};

//-------handle onchnage product stock price
export const batchStockPriceChange = (
  Id,
  newPrice,
  currentStore,
  addBatchInventory,
  setAddBatchInventory
) => {
  const updatedInventory = addBatchInventory.map((item) => {
    if (item.id === Id) {
      const updatedItem = {
        ...item,
        inStockPrice: newPrice,
        marketPrice: isStoreCustomProduct(item, "apiSource")
          ? newPrice
          : item.marketPrice,
      };
      // Calculate new offers based on newPrice or marketPrice
      if (newPrice !== 0) {
        const priceToUse = isStoreCustomProduct(updatedItem, "apiSource")
          ? newPrice
          : currentStore?.[
              toggleSwitchButtonConstants.ALWAYS_USE_STOCK_PRICE_FOR_COGS
            ]
          ? newPrice
          : Math.min(newPrice, item.marketPrice);
        const { cashOffer } = calculateOfferPrices(
          priceToUse,
          item?.tradeInMarginTypeObject?.marginObject,
          item?.tradeInMarginTypeObject?.marginObject.cashMarginPercentage,
          item?.tradeInMarginTypeObject?.marginObject.tradeinMarginPercentage
        );
        updatedItem.costOfGoods = parseToDecimalNumber(cashOffer);
      }
      return updatedItem;
    }
    return item;
  });
  setAddBatchInventory(updatedInventory);
};

//--------handle delete product from batch
export const handleBatchProductDelete = (
  Id,
  addBatchInventory,
  setAddBatchInventory
) => {
  const updatedInventory = addBatchInventory.filter((item) => item.id !== Id);

  setAddBatchInventory(
    handleCalculateEachProductPercentage(
      updatedInventory?.map((item, index) => ({ ...item, id: index })) || []
    )
  );
};

//-------handle Batch Bulk Delete
export const handleBatchBulkDelete = (
  toggledClearRows,
  addBatchInventory,
  setToggleClearRows,
  setAddBatchInventory,
  rowsToUpdateCondition,
  setRowsToUpdateCondition
) => {
  const itemToDeleteId = rowsToUpdateCondition.map((item) => item.id);

  const updatedInventory = addBatchInventory.filter(
    (item) => !itemToDeleteId.includes(item.id)
  );
  setAddBatchInventory(
    handleCalculateEachProductPercentage(
      updatedInventory?.map((item, index) => ({ ...item, id: index })) || []
    )
  );
  setToggleClearRows(!toggledClearRows);
  setRowsToUpdateCondition([]);
  // Additional actions, such as showing a success message or triggering further logic
};

//-------handle bulk update condition button click
export const handleBulkUpdateCondition = (
  isBulkModal,
  setIsBulkModal,
  rowsToUpdateCondition
) => {
  const sameProductType = rowsToUpdateCondition.every((item, index, array) => {
    return index === 0 || item["productType"] === array[0]["productType"];
  });

  if (sameProductType) {
    setIsBulkModal(!isBulkModal);
  } else {
    customToast("Selected rows has not same product Type", toastType.ERROR);
  }
};

//--------handle Update Edit product In batch
export const addToCartInBatchTrade = (
  editProduct,
  addBatchInventory,
  setEditTradeObject,
  setAddBatchInventory
) => {
  // Find the index of the item to be edited
  const index = addBatchInventory.findIndex(
    (item) => item.id === editProduct.id
  );

  if (index !== -1) {
    // Remove the item from its original position
    const updatedInventory = [
      ...addBatchInventory.slice(0, index),
      ...addBatchInventory.slice(index + 1),
    ];

    // Insert the edited product at the same index
    updatedInventory.splice(index, 0, editProduct);

    // Update the state with the updated inventory and reset other states
    setAddBatchInventory(
      handleCalculateEachProductPercentage(updatedInventory)
    );
    setEditTradeObject("");
  }
};

//-------handle total Cogs value Change
export const handleTotalCogsValueChange = (
  data,
  setTotalCost,
  addBatchInventory,
  setAddBatchInventory
) => {
  //-------handle total offer value change
  const value = Number(data.value);

  // Step 1: Check if all items are locked
  const hasUnlockedItems = addBatchInventory.some((item) => !item.isItemLocked);
  if (!hasUnlockedItems) {
    customToast(toastMessages.ALL_ITEMS_LOCKED_ERROR, toastType.ERROR);
    return; // Exit early if no unlocked items
  }

  // Step 2: Validate the inventory data for 0 or empty values
  let isInvalid = addBatchInventory.some((inv) => {
    return (
      Number(inv.inStockQuantity) === 0 || // Check if inStockQuantity is 0
      Number(inv.costOfGoods) === 0 || // Check if costOfGoods is 0
      inv.costOfGoods === "" // Check if costOfGoods is empty
    );
  });
  if (isInvalid) {
    customToast("Quantity & Cost of Goods Cannot be 0", toastType.ERROR);
    return; // Exit early if invalid fields are found
  }

  // Step 3: Handle the case where offerBy.value is 1 (flat rate for all products)
  if (data.offerBy.value === 1) {
    // Update all unlocked items with the flat value
    const finalUpdatedBatchInventory = addBatchInventory.map((item) => {
      if (item.isItemLocked) {
        return item; // Skip locked items
      }
      let flatOfferValue = data?.isPercentageOffer
        ? parseToDecimalNumber(value)
        : parseToDecimalNumber(
            (Number(value) / 100) * Number(item?.marketPrice || 0)
          );
      flatOfferValue =
        flatOfferValue <= 0
          ? inventoryConstants.DEFAULT_OFFER_VALUE
          : flatOfferValue;

      return {
        ...item,
        costOfGoods: flatOfferValue,
      };
    });

    // Step 4: Update the state with the final inventory after applying the flat rate
    setAddBatchInventory(finalUpdatedBatchInventory);
    return; // Exit after applying the flat rate to avoid further processing
  }

  // Step 5: Continue with the original process for offerBy.value other than 1
  // Recalculate the cost of goods percentages before proceeding
  const updatedBatchInventory =
    handleCalculateEachProductPercentage(addBatchInventory);

  // Calculate total cogs for locked items based on the type of offer (costOfGoods)
  const lockedTotalOffer = parseToDecimalNumber(
    updatedBatchInventory.reduce((total, item) => {
      if (item.isItemLocked) {
        return total + Number(item.costOfGoods);
      }
      return total;
    }, 0) ?? 0
  );

  // Step 6: Check if the incoming value is less than or equal to the total offer of locked items
  if (value <= Number(lockedTotalOffer)) {
    customToast(toastMessages.VALUE_LESS_THAN_LOCKED_ITEMS, toastType.ERROR);
    return; // Exit early if the value is invalid
  }

  // Step 7: Parse the input value
  const inputValue = parseToDecimalNumber(value - Number(lockedTotalOffer));
  setTotalCost(inputValue);

  // Step 8: Update the batch inventory based on cost of goods
  let finalUpdatedBatchInventory = updatedBatchInventory.map((item) => {
    // Skip locked items (no need to update their offer values)
    if (item.isItemLocked) {
      return item; // Return unchanged item for locked items
    }

    let offerValue = 0;
    // Calculate the cost of goods for the item
    offerValue =
      (inputValue * Number(item.cogsPercentage)) /
      100 /
      Number(item.inStockQuantity);

    // Apply the minimum threshold for costOfGoods (if it falls below the threshold, set it to the default value)
    offerValue = parseToDecimalNumber(
      offerValue <= inventoryConstants.DEFAULT_OFFER_VALUE
        ? inventoryConstants.DEFAULT_OFFER_VALUE
        : offerValue
    );

    return {
      ...item,
      costOfGoods: parseToDecimalNumber(offerValue),
    };
  });

  // Step 9: Handle the difference in the updated inventory
  const totalOffer = getTotalCogsOfBatchProducts(finalUpdatedBatchInventory);
  const difference = parseToNumber(Number(inputValue) - Number(totalOffer));

  if (difference < 0 || difference > 0) {
    let productIndex = 0;
    // Step 10: Filter out locked items completely when calculating the product index
    const nonLockedBatchInventory = finalUpdatedBatchInventory.filter(
      (item) => !item.isItemLocked
    );
    // Step 11: Handle positive or negative difference in the respective offer (costOfGoods)
    if (difference < 0) {
      productIndex = nonLockedBatchInventory.reduce(
        (maxIndex, obj, idx, array) => {
          return obj.costOfGoods > array[maxIndex].costOfGoods ? idx : maxIndex;
        },
        0
      );
    } else {
      productIndex = nonLockedBatchInventory.reduce(
        (minIndex, obj, idx, array) => {
          return obj.costOfGoods < array[minIndex].costOfGoods ? idx : minIndex;
        },
        0
      );
    }
    // Step 12: Update the offer for the selected product based on the calculated difference
    let newOfferValue = parseToDecimalNumber(
      Number(nonLockedBatchInventory[productIndex].costOfGoods) + difference
    );
    // Ensure offer doesn't go below the minimum threshold (0.01)
    if (newOfferValue < inventoryConstants.DEFAULT_OFFER_VALUE) {
      let remainingDifference =
        difference -
        (inventoryConstants.DEFAULT_OFFER_VALUE -
          nonLockedBatchInventory[productIndex].costOfGoods);
      nonLockedBatchInventory[productIndex].costOfGoods =
        inventoryConstants.DEFAULT_OFFER_VALUE;
      // If there's still remaining difference, redistribute it across other unlocked products
      for (let i = 0; i < nonLockedBatchInventory.length; i++) {
        if (i === productIndex) continue;

        let otherProduct = nonLockedBatchInventory[i];
        let potentialNewOfferValue =
          otherProduct.costOfGoods - remainingDifference;

        if (potentialNewOfferValue >= inventoryConstants.DEFAULT_OFFER_VALUE) {
          otherProduct.costOfGoods = parseToDecimalNumber(
            potentialNewOfferValue
          );
          remainingDifference = 0;
          break;
        } else {
          remainingDifference -=
            otherProduct.costOfGoods - inventoryConstants.DEFAULT_OFFER_VALUE;
          otherProduct.costOfGoods = inventoryConstants.DEFAULT_OFFER_VALUE;
        }
      }
    } else {
      nonLockedBatchInventory[productIndex].costOfGoods = newOfferValue;
    }
    // Step 13: Rebuild the final updated batch inventory without modifying locked items
    finalUpdatedBatchInventory = finalUpdatedBatchInventory.map((item) => {
      if (item.isItemLocked) {
        return item; // Return the locked items unchanged
      }

      const updatedItem = nonLockedBatchInventory.find(
        (updatedItem) => updatedItem.id === item.id // Assuming each item has a unique 'id'
      );

      return updatedItem ? updatedItem : item; // Replace the unlocked item with the updated one
    });
  }
  // Step 14: Update the inventory list state with the final values
  setAddBatchInventory(finalUpdatedBatchInventory);
};

//-------Prepare Filter For Invoice Table
export const prepareFilterForInvoiceTable = (status, startDate, endDate) => {
  let filters = "";
  if (status && status?.value !== PRODUCT_TYPES_ENUMS.ALL) {
    filters = filters + `AND c.invoiceType = '${status?.value}' `;
  }

  if (startDate) {
    filters = filters + `AND c.createdAt > '${startDate}' `;
  }

  if (endDate) {
    filters = filters + `AND c.createdAt < '${endDate}' `;
  }

  return filters;
};

export const getTotalQuantityOfInvoiceBatch = (inventory, invoiceType) => {
  if (invoiceType === invoiceConstants.FULFILLED) {
    return inventory.reduce(
      (acc, item) =>
        acc +
        (isStoreCustomProduct(item, "apiSource") && !item.trackQuantity
          ? 1
          : Number(item.price.quantity)),
      0
    );
  } else {
    return inventory.reduce(
      (acc, item) =>
        acc +
        (isStoreCustomProduct(item, "apiSource") && !item.trackQuantity
          ? 1
          : Number(item.inStockQuantity)),
      0
    );
  }
};

export const getInvoiceTotalCostOfGoods = (inventory, invoiceType) => {
  if (invoiceType === invoiceConstants.FULFILLED) {
    return inventory.reduce(
      (acc, item) =>
        acc + isStoreCustomProduct(item, "apiSource") && !item.trackQuantity
          ? Number(1 * item.price.unit_purchase_price)
          : Number(item.price.quantity * item.price.unit_purchase_price),
      0
    );
  } else {
    return inventory.reduce(
      (acc, item) =>
        acc + isStoreCustomProduct(item, "apiSource") && !item.trackQuantity
          ? Number(1 * item.costOfGoods)
          : Number(item.inStockQuantity * item.costOfGoods),
      0
    );
  }
};

export const getTotalStockPriceOfInvoiceBatch = (inventory, invoiceType) => {
  if (invoiceType === invoiceConstants.FULFILLED) {
    return inventory.reduce((acc, currentInv) => {
      return isStoreCustomProduct(currentInv, "apiSource") &&
        !currentInv.trackQuantity
        ? Number(currentInv.price.unit_sell_price * 1)
        : Number(currentInv.price.unit_sell_price * currentInv.price.quantity) +
            acc;
    }, 0);
  } else {
    return inventory.reduce((acc, currentInv) => {
      return isStoreCustomProduct(currentInv, "apiSource") &&
        !currentInv.trackQuantity
        ? Number(currentInv.inStockPrice * 1)
        : Number(currentInv.inStockPrice * currentInv.inStockQuantity) + acc;
    }, 0);
  }
};

export const handleTransformClientInventoryJsonToDbFormat = (product) => {
  return {
    // CAUTION SKU VALUE
    sku: [inventoryConstants.AUTO_GENERATED],
    product_id: product.productId,
    product_name: product.productName,
    category_name: product.consoleName,
    store: product.store,
    date_added: new Date(),
    price: {
      unit_purchase_price: product.costOfGoods,
      unit_sell_price: product.inStockPrice,
      quantity: product.inStockQuantity,
      type: product.productCondition,
      marketPrice: product.marketPrice,
    },
    productType: product.productType,
    upc: product.upc,
    additionalCheckList: [],
    tags: product?.tags
      ? product.tags.map((tag) =>
          product?.apiSource === inventoryConstants.STORE_CUSTOM_PRODUCT
            ? tag
            : tag.label
        )
      : [],
    cardRarity: product?.rarity?.label || "",
    cardNumber: "",
    apiSource: product.apiSource,
    imgUrl: product?.imgUrl,
    epid: product.epid,
    tcgPlayerUrl: product.tcgPlayerUrl,
    imgUrl: product.imgUrl,
    subcategory:
      product.productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
        ? getProductSubCategory(product.genre)
        : globalConstants.EMPTY_STRING,
    description: "",
    serialNumber: product.serialNumber,
    trackQuantity:
      product?.trackQuantity === undefined ? true : product.trackQuantity,
  };
};

//-------handle submit add batch inventory
export const handleSubmitAddBatchInventory = async (
  currentStore,
  activateSpinner,
  deactivateSpinner,
  handleCloseModal,
  handleClearFilter,
  addBatchInventory,
  addBatchInventorySubmit,
  spinnerConstant = transactionConstants.ADD_TRADE_BATCH_ITEM
) => {
  activateSpinner(spinnerConstant);
  // validations
  let isInvalid = addBatchInventory.some((inv) => {
    let quantity = isStoreCustomProduct(inv, "apiSource")
      ? 1
      : Number(inv.inStockQuantity);

    return quantity === 0 || Number(inv.inStockPrice) === 0;
  });

  if (isInvalid) {
    customToast("Quantity & Stock Price Cannot be 0", toastType.ERROR);
    deactivateSpinner(spinnerConstant);
    return;
  }

  let data = [];
  // const margedBacthInventory = mergeBatchProducts(addBatchInventory);
  const margedBacthInventory = addBatchInventory;
  for (let index = 0; index < margedBacthInventory.length; index++) {
    const product = margedBacthInventory[index];
    product.store = {
      id: currentStore.id,
      name: currentStore.storeName,
    };
    data.push(handleTransformClientInventoryJsonToDbFormat(product));
  }

  deactivateSpinner(spinnerConstant);
  addBatchInventorySubmit(data, handleCloseModal, handleClearFilter);
};

//-------get condition list according to product type
export const getCustomProductConditionList = (productType, customTypes) => {
  return getTypeOrCategoryObject(customTypes, productType)?.productType ===
    PRODUCT_TYPES_ENUMS.VIDEO_GAME
    ? [
        ...inventoryConstants.VIDEO_GAME_CONDITION,
        {
          value: PRODUCT_CONDITIONS_ENUMS.USED,
          label: PRODUCT_CONDITIONS_ENUMS.USED,
        },
      ]
    : getTypeOrCategoryObject(customTypes, productType)?.productType ===
      PRODUCT_TYPES_ENUMS.TRADING_CARD
    ? [
        ...inventoryConstants.TRADING_CARD_CONDITION,
        ...inventoryConstants.OTHER_CONDITION,
      ]
    : inventoryConstants.OTHER_CONDITION;
};
