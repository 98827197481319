import {
  buttonNameConstants,
  buttonTypeConstants,
  outletConstants,
  routesPathConstants,
  toastMessages,
  toastType,
  toggleSwitchButtonConstants,
} from "../../constants";
import { PRODUCT_TYPES_ENUMS } from "../../system/globalEnums";
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from "../../system/storage";
import { getMacAddress } from "../Printers/ReceiptPrinters/printReceipt";
import {
  createCompanyDynamicUrl,
  customToast,
  parseJsonObject,
  updateCartLocalStorage,
} from "../utility";

//------- Add Or Remove Cash Modal Configuration
export const getModalConfiguration = (modalType) => {
  const config = {
    [outletConstants.ADD_CASH_MODAL]: {
      modalTitle: buttonNameConstants.ADD_CASH,
      buttonType: buttonTypeConstants.BLUE_BUTTON,
      radioButtonOptions: [
        outletConstants.CASH_IN,
        outletConstants.PETTY_CASH_IN,
      ],
      defaultOption: outletConstants.CASH_IN,
    },
    [outletConstants.REMOVE_CASH_MODAL]: {
      modalTitle: buttonNameConstants.REMOVE_CASH,
      buttonType: buttonTypeConstants.RED_BUTTON_FILL,
      radioButtonOptions: [
        outletConstants.CASH_OUT,
        outletConstants.PETTY_CASH_OUT,
      ],
      defaultOption: outletConstants.CASH_OUT,
    },
  };

  return (
    config[modalType] || {
      modalTitle: "",
      buttonType: "",
      radioButtonOptions: [],
      defaultOption: "",
    }
  );
};

//------- is Session opened By Current User
export const isSessionOpenedByCurrentUser = (outlet, user) => {
  return outlet?.currentOutlet?.register?.openBy?.userId === user?.id;
};

//------- is Session Closed By Current User
export const prepareFilterForSessionHistory = (
  startDate,
  endDate,
  outletId
) => {
  let filters = "";

  if (startDate) {
    filters = filters + `AND c.openAt > '${startDate}' `; //AND (c.closeAt > '${startDate}' OR c.closeAt = '')
  }

  if (endDate) {
    filters = filters + `AND c.openAt < '${endDate}' `; // AND (c.closeAt < '${endDate}' OR c.closeAt = '')
  }

  if (outletId) {
    if (outletId !== PRODUCT_TYPES_ENUMS.ALL) {
      filters = filters + `AND c.outlet.id = '${outletId}' `;
    }
  }

  return filters;
};

//------- get Session Duration
export const getSessionDurationTime = (session) => {
  const openAt = new Date(session.openAt);
  const closeAt = new Date(session.closeAt);
  const diff = closeAt - openAt;
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor((diff / 1000 / 60) % 60);
  return `${hours}h ${minutes}m`;
};

//------- check session is open or not also in use or not or outlet is configured or not
export const isTransactionAllowed = (outlet, user, currentStore) => {
  if (!currentStore?.[toggleSwitchButtonConstants.IS_OUTLET_TRACKING]) {
    return false;
  }

  if (!outlet?.currentOutlet?.isCashTracking) {
    return false;
  }

  if (!outlet?.currentOutlet?.id) {
    customToast(toastMessages.OUTLET_IS_NOT_CONFIGURED, toastType.ERROR);
    return true;
  }

  if (!outlet?.isTillOpen) {
    customToast(toastMessages.REGISTER_IS_CLOSED, toastType.ERROR);
    return true;
  }

  if (!isSessionOpenedByCurrentUser(outlet, user)) {
    customToast(
      `This register is in-use by ${outlet?.currentOutlet?.register?.openBy?.name}`,
      toastType.ERROR
    );
    return true;
  }
};

export const getOutletStatus = (outlet) => {
  // return outlet.loggedInUsers.length !== 0;
  return outlet.register.isOpen;
};

//-------combine session history
export const combineSessionHistory = (sessionHistory) => {
  const combinedTransactions = [];

  // Helper arrow function to add a transaction
  const addTransaction = (
    id,
    time,
    user,
    amount,
    note,
    activityType,
    entryObject = null
  ) => {
    const transaction = {
      id,
      time,
      user,
      amount,
      note: note || null, // Add note if available
      activityType: activityType, // Default to activity if activityType is not provided
      hasObject: false, // Default to false
    };

    // If the entry is Sale, Trade, or Return, store the whole object and set hasObject to true
    if (
      entryObject &&
      (activityType === outletConstants.SALE_ENTERY ||
        activityType === outletConstants.TRADE_ENTERY ||
        activityType === outletConstants.RETURN_ENTERY)
    ) {
      transaction.entryObject = entryObject;
      transaction.hasObject = true;
    }

    combinedTransactions.push(transaction);
  };

  // Process Cash In/Out Entries if the array is not empty
  if (
    sessionHistory.cashInOutEntries &&
    sessionHistory.cashInOutEntries.length > 0
  ) {
    sessionHistory.cashInOutEntries.forEach((entry) => {
      addTransaction(
        entry.id,
        entry.createdAt,
        entry.user.name,
        entry.amount,
        entry.note,
        entry.type // activityType is the same as type for cash entries
      );
    });
  }

  const tradeTransactions = sessionHistory.tradeTransactions?.filter(
    (trade) => Number(trade.paymentHistory.totalPaidAmount.cash) > 0
  );

  const saleTransactions = sessionHistory.saleTransactions?.filter(
    (sale) => Number(sale.paymentHistory.totalPaidAmount.cash) > 0
  );

  const returnTransactions = sessionHistory.returnTransactions?.filter(
    (returnTrans) => Number(returnTrans.paymentHistory.totalPaidAmount.cash) > 0
  );

  // Process Trade Transactions if the array is not empty
  if (tradeTransactions && tradeTransactions.length > 0) {
    tradeTransactions.forEach((trade) => {
      addTransaction(
        trade.id,
        trade.createdOn,
        trade.customer.name || "Unknown",
        trade.paymentHistory.totalPaidAmount.cash,
        trade.note,
        outletConstants.TRADE_ENTERY,
        trade // Pass the entire trade object
      );
    });
  }

  // Process Sale Transactions if the array is not empty
  if (saleTransactions && saleTransactions.length > 0) {
    saleTransactions.forEach((sale) => {
      addTransaction(
        sale.id,
        sale.createdOn,
        sale.customer.name || "Unknown",
        sale.paymentHistory.totalPaidAmount.cash,
        sale.note,
        outletConstants.SALE_ENTERY,
        sale // Pass the entire sale object
      );
    });
  }

  // Process Return Transactions if the array is not empty
  if (returnTransactions && returnTransactions.length > 0) {
    returnTransactions.forEach((returnTrans) => {
      addTransaction(
        returnTrans.id,
        returnTrans.createdOn,
        returnTrans.customer.name || "Unknown",
        returnTrans.paymentHistory.totalPaidAmount.cash,
        returnTrans.note,
        outletConstants.RETURN_ENTERY,
        returnTrans // Pass the entire return object
      );
    });
  }

  return combinedTransactions.sort(
    (a, b) => new Date(a.time) - new Date(b.time)
  );
};

//-------get only time of transaction
export const getTimeOfTransaction = (time) => {
  const date = new Date(time);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;
  return `${hours}:${minutesStr} ${ampm}`;
};

export const formatedDateTime = (date) => {
  return new Date(date).toLocaleString("en-US", {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

export const isCashOut = (type) =>
  [
    outletConstants.CASH_OUT,
    outletConstants.PETTY_CASH_OUT,
    outletConstants.TRADE_ENTERY,
    outletConstants.RETURN_ENTERY,
  ].includes(type);

export const formatOutletSessionId = (sessionCount = 0) => {
  return `Session-${sessionCount}`;
};

export const updateOutletInLocalStorage = (storeId, data) => {
  updateCartLocalStorage(outletConstants.STORE_OUTLETS, data, storeId);
};

export const getCurrentOutletfromLocalStorage = (storeId) => {
  let transactionDataObject = {};

  const outletsList = parseJsonObject(
    getItemFromLocalStorage(outletConstants.STORE_OUTLETS)
  );

  if (outletsList) {
    const findCurrentStoreData = outletsList.find(
      (outletData) => outletData.storeId === storeId
    );
    transactionDataObject = findCurrentStoreData?.data || {};
  }
  return transactionDataObject;
};

export const checkIsOutletOpen = (
  navigate,
  route = routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION,
  currentStore,
  getCurrentMacAddress,
  getOutletByMacAddress
) => {
  const outlet = getCurrentOutletfromLocalStorage(currentStore?.id);

  if (outlet && outlet?.isCashTracking && !outlet?.register?.isOpen) {
    getCurrentMacAddress(currentStore, getOutletByMacAddress);
    customToast(toastMessages.REGISTER_IS_CLOSED_IN_OTHER_TAB, toastType.ERROR);
    if (navigate) {
      navigate(createCompanyDynamicUrl(route));
    }
    return true;
  }
};

export const getCurrentMacAddress = async (
  currentStore,
  getOutletByMacAddress
) => {
  const macAddress = await getMacAddress();
  if (!macAddress) {
    customToast(toastMessages.MAC_ADDRESS_NOT_FOUND, toastType.ERROR);
    return;
  }
  getOutletByMacAddress(currentStore?.id, macAddress);
};
