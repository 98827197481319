import { connect } from "react-redux";
import Select, { components } from "react-select";
import React, { useEffect, useState, useRef } from "react";
import { AsyncPaginate } from "react-select-async-paginate";

import Button from "../Buttons/Button";
import { customerService } from "../../../services";
import { customerActions } from "../../../redux/actions";
import { customerDropdownStyle } from "../../../assets/scss/style";
import CustomerImage from "../../../assets/images/customerImage.svg";
import {
  getCustomerFullName,
  getLockCustomerPayload,
  LoadingIndicator,
  parseToThousandSeperatorDecimalNumber,
} from "../../utility";
import {
  AddIcon,
  BoxIcon,
  ClockIcon,
  SearchIcon,
  CategoryIcon,
  BendArrowIcon,
  TransactionIcon,
  InventoryConditionTypeIcon,
} from "../../../assets/icons/iconsProvider";
import {
  systemConstants,
  globalConstants,
  defaultGridState,
  customerConstants,
  buttonNameConstants,
  buttonTypeConstants,
  categoryDropdownConstants,
  EMPTY_STRING,
} from "../../../constants";
import {
  stringifyObject,
  getPaginationFilterParamsFromGridState,
} from "../../utility";

const CustomerDropdown = ({
  value,
  options,
  disabled,
  className,
  allCustomers,
  currentStore,
  handleChange,
  SelectIconImage,
  isClearable = true,
  customerDropdownKey,
  isSearchable = true,
  selectedCustomerFlag,
  handleAddCustomerClick,
  isLockCustomer = false,
  dropdownIndicator = true,
  isGuestCustomers = false,
  noOptionsMessage = "No Customer Found",
  placeholder = "Customer Name, Phone, Email.",
  defaultValue = globalConstants.EMPTY_STRING,
  menuPortalTarget = document.querySelector("body"),
}) => {
  // initializations
  const isAddNewCustomerRef = useRef(true);
  const prevSearchRef = useRef(EMPTY_STRING);
  const optionsRef = useRef([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [gridState, setGridState] = useState(defaultGridState);

  // helper functions
  const loadOptions = async (search, prevOptions) => {
    let hasMore = false;
    let mappedCustomers = [];
    let updatedGridState = {
      ...gridState,
      pageIndex:
        prevSearchRef.current === search ? Number(gridState?.pageIndex) : 1,
      searchQuery: search,
    };

    try {
      const res = await customerService.getPaginationCustomers(
        currentStore?.companyId,
        currentStore?.id,
        getPaginationFilterParamsFromGridState(updatedGridState),
        isGuestCustomers && updatedGridState.pageIndex === 1
      );
      // Check if res is valid and process it
      if (res && res.customers) {
        // Update grid state for pagination
        hasMore = updatedGridState.pageIndex < res.totalPages;
        updatedGridState.pageIndex++;
        prevSearchRef.current = search;
        setGridState(updatedGridState);
        mappedCustomers = [
          ...mapCustomersList(
            res.customers,
            currentStore,
            handleAddCustomerClick,
            isAddNewCustomerRef,
            isLockCustomer
          ),
        ];
      }
    } catch (error) {
      console.error("Error Fetching the customers:", error);
    } finally {
      return {
        options: mappedCustomers,
        hasMore: hasMore,
      };
    }
  };
  const onChange = (e) => {
    if (e && e.value !== customerConstants.ADD_NEW_CUSTOMER_BUTTON) {
      handleChange(e);
      const selectedCustomer = JSON.parse(e.value);
      if (isLockCustomer && selectedCustomer.isGuestCustomer) {
        handleLockCustomer(selectedCustomer);
      }
    }
  };
  const handleLockCustomer = (customer) => {
    customerService.lockCustomer(
      getLockCustomerPayload(customer, currentStore, true)
    );
  };

  useEffect(() => {
    if (selectedCustomerFlag) {
      setIsMenuOpen(false);
    }
  }, [selectedCustomerFlag]);
  useEffect(() => {
    // reset the async select options and grid state
    setGridState(defaultGridState);
    isAddNewCustomerRef.current = true;
  }, [customerDropdownKey]);

  return (
    <>
      <AsyncPaginate
        key={customerDropdownKey}
        value={value}
        onChange={onChange}
        isDisabled={disabled}
        debounceTimeout={1000}
        menuIsOpen={isMenuOpen}
        loadOptions={loadOptions}
        isClearable={isClearable}
        placeholder={placeholder}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        styles={customerDropdownStyle}
        getOptionValue={(e) => e.value}
        getOptionLabel={(e) => e.label}
        className={`w-100 ${className}`}
        menuPortalTarget={menuPortalTarget}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        noOptionsMessage={() => {
          return noOptionsMessage;
        }}
        components={
          dropdownIndicator
            ? {
                IndicatorSeparator: () => null,
                Input: CustomInput,
                LoadingIndicator,
              }
            : {
                IndicatorSeparator: () => null,
                DropdownIndicator: () => "",
                Input: CustomInput,
                LoadingIndicator,
              }
        }
      />
    </>
  );
};

// helper functions
const mapCustomersList = (
  allCustomers,
  currentStore,
  handleAddCustomerClick,
  isAddNewCustomerRef,
  isLockCustomer
) => {
  let tempIsLockCustomer = false;
  let mappedCustomers = allCustomers?.map((customer, index) => {
    tempIsLockCustomer = isLockCustomer && customer.isCustomerInUseOfTrade;

    return {
      value: stringifyObject({
        firstName: customer?.firstName,
        lastName: customer?.lastName,
        email: customer?.email,
        mobile: customer?.mobile,
        currentBalance: customer?.currentBalance,
        companyCustomerId: customer?.companyCustomerId,
        store: {
          id: currentStore?.id,
          name: currentStore?.storeName,
        },
        id: customer?.id,
        name: getCustomerFullName(customer),
        isGuestCustomer: customer?.isGuestCustomer,
        expirationDate: customer.expirationDate,
      }),
      label: (
        <div
          id={customer?.id}
          key={customer?.id}
          className={`d-flex align-items-center justify-content-center gap-2 
          ${tempIsLockCustomer && "disabled-number-field"}
        `}
        >
          <span>
            <img
              src={CustomerImage}
              className="new-sale-customer-image"
              alt="customer"
            />
          </span>
          <p className="m-0">{getCustomerFullName(customer)}</p>
          <p className="m-0 ms-auto">
            ${parseToThousandSeperatorDecimalNumber(customer.currentBalance)}
          </p>
        </div>
      ),
      isDisabled: tempIsLockCustomer,
    };
  });

  // add addNewCustomer button in the list
  if (handleAddCustomerClick && isAddNewCustomerRef.current) {
    mappedCustomers.unshift({
      value: customerConstants.ADD_NEW_CUSTOMER_BUTTON,
      label: (
        <Button
          label={buttonNameConstants.ADD_CUSTOMER}
          buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
          IconImage={AddIcon}
          handleClick={handleAddCustomerClick}
          className={"add-customer-button-select w-100"}
        />
      ),
    });
    isAddNewCustomerRef.current = false;
  }

  return mappedCustomers;
};
const CustomInput = (props) => (
  <div>
    <SearchIcon className="dropdown-icon" />
    <components.Input {...props} />
  </div>
);

//-------Mapping the component's props to the reducer's state and actions
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
  allCustomers: state.customer.allCustomers,
  customerDropdownKey: state.customer.customerDropdownKey,
});
const mapDispatchToProps = (dispatch) => ({});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDropdown);
