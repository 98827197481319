import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { outletActions } from "../../../../redux/actions";
import {
  buttonNameConstants,
  buttonTypeConstants,
  defaultGridState,
  EMPTY_STRING,
  outletConstants,
} from "../../../../constants";
import Button from "../../../../shared/components/Buttons/Button";
import SessionDetail from "../../../../shared/components/sessionSummaryTable/SessionDetail";
import { Col, Row } from "react-bootstrap";
import { isSpinnerEnabled } from "../../../../shared/utility";
import InputTextArea from "../../../../shared/components/inputTextArea/InputTextArea";
import { Form, Formik } from "formik";
import SessionSummaryTable from "../../../../shared/components/sessionSummaryTable/SessionSummaryTable";
import { handleOpenCashDrawer } from "../../../../shared/Printers/ReceiptPrinters/prepareReceiptForPrinter";

const CloseRegister = (props) => {
  //------- props
  const {
    user,
    outlet,
    addCash,
    printer,
    currentStore,
    spinnerArray,
    closeRegister,
    getSessionHistory,
    getCurrentSessionDetails,
  } = props;

  //------- outlet reducer
  const {
    currentOutlet,
    currentSessionId,
    currentSession,
    singleSessionHistory,
  } = outlet;
  //------- initial values
  const initialValues = {
    note: EMPTY_STRING,
  };
  const { defaultReceiptPrinter } = printer;
  const [countedBalance, setCountedBalance] = useState(0);

  //------- handle close register
  const handleCloseRegister = (values) => {
    const dataToSend = {
      id: currentSessionId,
      expectedBalance: singleSessionHistory?.expectedBalance,
      countedBalance: countedBalance,
      differenceInBalance:
        Number(singleSessionHistory?.expectedBalance) - Number(countedBalance),
      closeNote: values?.note || EMPTY_STRING,
      store: { id: currentStore?.id, name: currentStore?.storeName },
      outlet: { id: currentOutlet?.id, name: currentOutlet?.name },
    };
    closeRegister(dataToSend, currentOutlet?.macAddress);
  };

  const handleCountValueChange = (value) => {
    setCountedBalance(value);
  };

  const handleOpenDrawer = () => {
    handleOpenCashDrawer(defaultReceiptPrinter);
    const dataToSend = {
      note: EMPTY_STRING,
      amount: 0,
      type: outletConstants.CASH_DRAWER_OPEN,
      user: {
        userId: user?.id,
        name: `${user.firstName} ${user.lastName}`,
      },
      sessionId: currentSessionId,
      store: {
        id: currentStore?.id,
        name: currentStore?.storeName,
      },
      outlet: { id: currentOutlet?.id, name: currentOutlet?.name },
    };

    addCash(dataToSend, EMPTY_STRING, defaultGridState, () => {});
  };

  //------- useEffect
  useEffect(() => {
    if (currentOutlet && currentOutlet && currentSessionId) {
      getCurrentSessionDetails(
        currentStore?.id,
        currentOutlet?.id,
        currentSessionId
      );
    }
  }, [currentStore, currentOutlet, currentSessionId]);

  useEffect(() => {
    if (currentStore?.id && currentSession?.id) {
      getSessionHistory(currentStore?.id, currentSession?.id);
    }
  }, [currentSession]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleCloseRegister}>
      {() => (
        <Form>
          {" "}
          <Row>
            <Col md={12}>
              <span className="last-closure-heading register-details-heading">
                Register Details
              </span>
            </Col>
            <SessionDetail session={currentSession} detailWidth={"w-50"} />
            <hr className="my-3" />
            <SessionSummaryTable
              isCloseRegisterTable={{
                value: countedBalance,
                handleChnage: handleCountValueChange,
              }}
              isDownloadButton={false}
              session={currentSession}
              tableHeading="Payments Summary"
              sessionDetailHistory={singleSessionHistory}
            />
            <hr className="my-3" />
            <Col md={2} className="ps-md-0">
              <span className="last-closure-heading register-details-heading">
                Closing Summary
              </span>
            </Col>
            <Col md={10} className="d-flex flex-column align-items-start gap-3">
              <InputTextArea
                label="Note"
                name="note"
                placeHolder="Add a register closure note..."
                className="w-100"
              />
            </Col>
            <Col md={2} className="ps-md-0"></Col>
            <Col md={10} className="mt-3 d-flex gap-3 align-items-center">
              <Button
                isDisabled={
                  isSpinnerEnabled(spinnerArray, [
                    outletConstants.CLOSE_REGISTER,
                    outletConstants.GET_OUTLET_BY_MAC_ADDRESS,
                  ]) || Number(countedBalance) <= 0
                }
                type="submit"
                label="Close Register"
              />
              <Button
                type="button"
                label={buttonNameConstants.OPEN_CASH_DRAWER}
                handleClick={() => {
                  handleOpenDrawer();
                }}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  outlet: state.outlet,
  spinnerArray: state.system.spinnerArray,
  user: state.authentication.user,
  currentStore: state.store.currentStore,
  printer: state.printer,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  closeRegister: (data, macAddress) =>
    dispatch(outletActions.closeRegister(data, macAddress)),
  getCurrentSessionDetails: (storeId, outletId, sessionId) =>
    dispatch(
      outletActions.getCurrentSessionDetails(storeId, outletId, sessionId)
    ),
  getSessionHistory: (storeId, sessionId) =>
    dispatch(outletActions.getSessionHistory(storeId, sessionId)),
  addCash: (data, message, gridState, handleHideModal) =>
    dispatch(outletActions.addCash(data, message, gridState, handleHideModal)),
});

//-------Export Open Register Component
export default connect(mapStateToProps, mapDispatchToProps)(CloseRegister);
