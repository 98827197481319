import React from "react";

const SimpleInputTextField = ({
  label,
  value,
  onChange,
  maxLength = 50,
  placeHolder,
  wrapperClassName,
}) => {
  return (
    <div>
      {label && <label className="field-label">{label}</label>}
      <div
        className={`input-field-wrapper ${
          wrapperClassName && wrapperClassName
        }`}
      >
        <input
          type="text"
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          className="input-textfield input-textfield-without-icon"
          placeholder={placeHolder}
        />
      </div>
    </div>
  );
};

export default SimpleInputTextField;
