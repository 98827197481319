import { custom } from "./actionsUtilities";
import { success } from "./actionsUtilities";
import { authenticationService, outletService } from "../../services";
import {
  authenticationConstants,
  routesPathConstants,
  systemConstants,
  toastMessages,
  toastType,
} from "../../constants";
import { clearStorage, removeItemFromStorage } from "../../system/storage";
import { getUserInfoFromToken } from "../../system/encryption";
import {
  customToast,
  isAdminLogin,
  isCompanyLogin,
  handleUnlockGuestCustomersInTrade,
} from "../../shared/utility";
import { systemActions } from "./system.action";
import { outletActions } from "./outlet.action";
import { getMacAddress } from "../../shared/Printers/ReceiptPrinters/printReceipt";

//-------Login function to handle user login
const companyLogin = (userLoginData) => {
  return (dispatch) => {
    dispatch(
      custom(
        authenticationConstants.EMPLOYEE_LOGIN,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    authenticationService
      .companyLogin(userLoginData)
      .then(
        (response) => {
          const userInfo = getUserInfoFromToken(response.accessToken);

          if (isAdminLogin(userInfo)) {
            dispatch(
              success(
                { ...response, user: userInfo },
                authenticationConstants.EMPLOYEE_LOGIN
              )
            );
          } else {
            dispatch(
              success(
                { ...response, company: userInfo },
                authenticationConstants.COMPANY_LOGIN
              )
            );
          }
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            authenticationConstants.EMPLOYEE_LOGIN,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Employee Login function to handle employee login
const employeeLogin = (userLoginData, companyId) => {
  return (dispatch) => {
    dispatch(
      custom(
        authenticationConstants.EMPLOYEE_LOGIN,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    authenticationService
      .employeeLogin(userLoginData, companyId)
      .then(
        (response) => {
          const user = getUserInfoFromToken(response.accessToken);
          dispatch(
            success(
              { ...response, user: user },
              authenticationConstants.EMPLOYEE_LOGIN
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            authenticationConstants.EMPLOYEE_LOGIN,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const employeeLogout = async () => {
  outletService.editOutletLoggedInUsers({
    macAddress: await getMacAddress(),
  });
  removeItemFromStorage(process.env.REACT_APP_PAYLOAD);
  window.location.href = routesPathConstants.DEFAULT_PATH;
};
//-------Company Logout Action
const companyLogout = () => {
  return (dispatch) => {
    dispatch(
      custom(
        authenticationConstants.COMPANY_LOGOUT,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    // handle unlock guest customers of trade transactions (traades with no split payments);
    handleUnlockGuestCustomersInTrade();
    clearStorage();
    dispatch(success(false, authenticationConstants.COMPANY_LOGOUT));
    dispatch(
      custom(
        authenticationConstants.COMPANY_LOGOUT,
        systemConstants.SPINNER_DEACTIVATE
      )
    );
  };
};

//------forgot password
const forgotPassword = (data, companyId) => {
  return (dispatch) => {
    dispatch(
      custom(
        authenticationConstants.FORGOT_PASSWORD,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    authenticationService
      .forgotPassword(data, companyId)
      .then(
        (response) => {
          customToast(
            toastMessages.PASSWORD_RESET_LINK_SENT_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(
            systemActions.pageRedireaction(routesPathConstants.DEFAULT_PATH)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            authenticationConstants.FORGOT_PASSWORD,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//------save password
const savePassword = (data, role) => {
  return (dispatch) => {
    dispatch(
      custom(
        authenticationConstants.RESET_PASSWORD,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    authenticationService
      .savePassword(data)
      .then(
        (response) => {
          customToast(
            isAdminLogin(role) || isCompanyLogin(role)
              ? toastMessages.PASSWORD_UPDATED_SUCCESSFULLY
              : toastMessages.PIN_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(
            systemActions.pageRedireaction(routesPathConstants.DEFAULT_PATH)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            authenticationConstants.RESET_PASSWORD,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//------save password
const isLinkExpired = (data) => {
  return (dispatch) => {
    authenticationService.isLinkExpired(data).then(
      (response) => {},
      (error) => {
        customToast(error, toastType.ERROR);
        dispatch(
          systemActions.pageRedireaction(routesPathConstants.DEFAULT_PATH)
        );
      }
    );
  };
};

//-------Export Authentication Actions
export const authenticationActions = {
  companyLogin,
  employeeLogin,
  companyLogout,
  employeeLogout,
  forgotPassword,
  savePassword,
  isLinkExpired,
};
