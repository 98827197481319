import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Col, Row } from "react-bootstrap";

import { EMPTY_STRING } from "../../../constants";
import { ClockIcon } from "../../../assets/icons/iconsProvider";

const NewDatePicker = ({
  IconImage,
  label,
  onDateChange,
  value,
  minDate,
  isDisabled = false,
  className,
  isFormik = false,
  name = EMPTY_STRING,
  setFieldValue = () => {},
  placeholderText = EMPTY_STRING,
}) => {
  return (
    <Row className={`m-0 filter-datepicker ${className}`}>
      {label && <label className="field-label">{label}</label>}
      <Col className="p-0 position-relative">
        {IconImage && <ClockIcon className="date-icon-image-container" />}
        <DatePicker
          selected={isFormik ? (value && new Date(value)) || null : value}
          onChange={(e) => {
            isFormik ? setFieldValue(name, e) : onDateChange(e);
          }}
          minDate={minDate}
          className={className}
          disabled={isDisabled}
          placeholderText={placeholderText}
        />
      </Col>
    </Row>
  );
};

export default NewDatePicker;
