import { systemActions } from "./system.action";
import { customToast } from "../../shared/utility";
import { custom, success } from "./actionsUtilities";
import { systemUserService } from "../../services/systemUser.service";
import {
  toastType,
  toastMessages,
  systemConstants,
  routesPathConstants,
  systemUserConstants,
  authenticationConstants,
  DEFAULT_SYSTEM_MODAL,
} from "../../constants";
import { authenticationActions } from "./authentication.action";
import { encryptObject, getUserInfoFromToken } from "../../system/encryption";
import { setItemToStorage } from "../../system/storage";

//-------Get all users
const getAllSystemUsers = () => {
  return (dispatch) => {
    dispatch(
      custom(
        systemUserConstants.GET_ALL_SYSTEM_USERS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    systemUserService
      .getAllSystemUsers(true)
      .then(
        (response) => {
          dispatch(
            success(response || [], systemUserConstants.GET_ALL_SYSTEM_USERS)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            systemUserConstants.GET_ALL_SYSTEM_USERS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get user by id
const getSystemUserById = (id) => {
  return (dispatch) => {
    dispatch(
      custom(
        systemUserConstants.GET_SYSTEM_USER_BY_ID,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    systemUserService
      .getSystemUserById(id)
      .then(
        (response) => {
          return response;
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            systemUserConstants.GET_SYSTEM_USER_BY_ID,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Add new user
const addNewSystemUser = (data) => {
  return (dispatch) => {
    dispatch(
      custom(
        systemUserConstants.ADD_NEW_SYSTEM_USER,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    systemUserService
      .addNewSystemUser(data)
      .then(
        (response) => {
          customToast(
            toastMessages.ADMIN_ADDED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(
            systemActions.pageRedireaction(
              routesPathConstants.ADMIN_SYSTEMUSERS
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            systemUserConstants.ADD_NEW_SYSTEM_USER,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Update user
const updateSystemUser = (data) => {
  return (dispatch) => {
    dispatch(
      custom(
        systemUserConstants.UPDATE_SYSTEM_USER,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    systemUserService
      .updateSystemUser(data, true)
      .then(
        (response) => {
          customToast(
            toastMessages.ADMIN_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(
            systemActions.pageRedireaction(
              routesPathConstants.ADMIN_SYSTEMUSERS
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            systemUserConstants.UPDATE_SYSTEM_USER,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Delete user
const deleteSystemUser = (id, storeId, currentUser) => {
  return (dispatch) => {
    dispatch(
      custom(
        systemUserConstants.DELETE_SYSTEM_USER,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    systemUserService
      .deleteSystemUser(id, storeId, true)
      .then(
        () => {
          customToast(
            toastMessages.ADMIN_DELETED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(custom(null, systemConstants.SET_SYSTEM_MODAL));
          if (currentUser?.id === id) {
            authenticationActions.employeeLogout();
          }

          dispatch(getAllSystemUsers());
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            systemUserConstants.DELETE_SYSTEM_USER,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

/**
 * Updates the default store for a user.
 * This function records the most recently accessed store for a user,
 * so it can be set as their default store the next time they log in.
 */
const updateUserDefaultStore = (data) => {
  return (dispatch) => {
    dispatch(
      custom(
        systemUserConstants.UPDATE_SYSTEM_USER,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    systemUserService
      .updateUserDefaultStore(data)
      .then(
        (response) => {
          if (response.accessToken) {
            const encryptedPayload = encryptObject({
              ...response,
            });
            setItemToStorage(process.env.REACT_APP_PAYLOAD, encryptedPayload);

            const user = getUserInfoFromToken(response.accessToken);
            dispatch(
              success(
                { ...response, user: user },
                authenticationConstants.EMPLOYEE_LOGIN
              )
            );
          }
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            systemUserConstants.UPDATE_SYSTEM_USER,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Export Authentication Actions
export const systemUserActions = {
  addNewSystemUser,
  getAllSystemUsers,
  getSystemUserById,
  deleteSystemUser,
  updateSystemUser,
  updateUserDefaultStore,
};
