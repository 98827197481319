import {
  systemConstants,
  globalConstants,
  DEFAULT_SYSTEM_MODAL,
} from "../../constants";
import { updateObject } from "../../shared/utility";
import { SIDEBAR_BUTTONS } from "../../constants";

const defaultTab = Object.values(SIDEBAR_BUTTONS).find((route) => {
  return (
    route
      .toLowerCase()
      .includes(window.location.pathname.toLowerCase().slice(1, 4)) && route
  );
});

const initialState = {
  spinnerArray: [],
  isLoading: false,
  isRedirect: false,
  pageToRedirect: globalConstants.EMPTY_STRING,
  isSidebarOpen: true,
  isNestedSidebarOpen: true,
  isServerSearchLoading: false,
  systemModal: DEFAULT_SYSTEM_MODAL,
  activeSidebarBtn: defaultTab ? defaultTab : SIDEBAR_BUTTONS.DASHBOARD,
};

//-------Export System Reducer
export const system = (state = initialState, action) => {
  switch (action.type) {
    //-------Activate Spinner case
    case systemConstants.SPINNER_ACTIVATE:
      return updateObject(state, {
        isLoading: true,
        spinnerArray: [...state.spinnerArray, action.data],
      });
    //-------Deactivate Spinner case
    case systemConstants.SPINNER_DEACTIVATE:
      return updateObject(state, {
        isLoading: false,
        spinnerArray: state.spinnerArray.filter((p) => p !== action.data),
      });
    // Toggle Sidebar
    case systemConstants.TOGGLE_SIDEBAR:
      return updateObject(state, {
        isSidebarOpen: !state.isSidebarOpen,
      });
    case systemConstants.TOGGLE_NESTED_SIDEBAR:
      return updateObject(state, {
        isNestedSidebarOpen: !state.isNestedSidebarOpen,
      });
    case systemConstants.SET_ACTIVE_SIDEBAR_BTN:
      return updateObject(state, {
        activeSidebarBtn: action.data,
      });
    //-------User added successfully
    case systemConstants.TOGGLE_REDIRECT_PAGE:
      return updateObject(state, {
        pageToRedirect: action.data,
        isRedirect: !state.isRedirect,
      });
    case systemConstants.SET_IS_SERVER_SEARCH_LOADING:
      return updateObject(state, {
        isServerSearchLoading: action.data,
      });
    case systemConstants.SET_SYSTEM_MODAL:
      return updateObject(state, {
        systemModal: updateObject(
          state.systemModal,
          action.data || DEFAULT_SYSTEM_MODAL
        ),
      });

    //-------Default State
    default:
      return state;
  }
};
