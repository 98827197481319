import React, { useState } from "react";
import { connect } from "react-redux";
import { Col, Modal, Row } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import {
  buttonNameConstants,
  EMPTY_STRING,
  inventoryConstants,
  invoiceConstants,
  transactionConstants,
} from "../../../../constants";
import {
  getInvoiceTotalCostOfGoods,
  getProductDetailCardTags,
  getProductSubCategory,
  getShortestSku,
  getTotalCogsOfBatchProducts,
  getTotalQuantityOfInvoiceBatch,
  getTotalStockPriceOfInvoiceBatch,
  isSpinnerEnabled,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";
import { TRADING_CARD_CATEGORIES_ENUMS } from "../../../../system/globalEnums";
import NewDatePicker from "../../../../shared/components/datePickerDropDown/NewDatePicker";
import { ClockIcon } from "../../../../assets/icons/iconsProvider";
import VendorDropdown from "../AddInvoice/VendorDropdown";
import { invoiceActions } from "../../../../redux/actions";

const ViewInvoice = ({
  spinnerArray,
  invoiceData,
  invoiceGridState,
  updateFulfilledData,
  isViewInvoiceModal,
  toggleViewInvoiceModal,
}) => {
  //------handle hide modal
  const handleHideModal = () => {
    toggleViewInvoiceModal(false);
  };

  const [invoiceDate, setInvoiceDate] = useState(invoiceData.invoiceDate);

  const handleVendorChange = (option) => {
    if (invoiceData?.vendor?.id !== option.value) {
      updateFulfilledData(
        {
          ...invoiceData,
          vendor: {
            id: option?.value,
            name: option?.label,
          },
        },
        invoiceGridState
      );
    }
  };

  const handleInvoiceDateChange = (value) => {
    setInvoiceDate(value);
    updateFulfilledData(
      {
        ...invoiceData,
        invoiceDate: value,
      },
      invoiceGridState
    );
  };

  return (
    <Modal
      size="lg"
      centered
      backdrop="static"
      animation={true}
      onHide={handleHideModal}
      show={isViewInvoiceModal}
      className={`add-inventory-modal`}
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            View Invoice ({invoiceData?.invoiceId})
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body py-0">
        <Row className="m-0">
          <Col sm={3} className="mb-3 ps-sm-0">
            <NewDatePicker
              isDisabled={isSpinnerEnabled(spinnerArray, [
                invoiceConstants.ADD_INVOICE,
                invoiceConstants.GET_VENDORS,
                invoiceConstants.ADD_NEW_VENDOR,
              ])}
              IconImage={ClockIcon}
              noOptionsMessage="No Date Found"
              placeholder="Date"
              value={invoiceDate}
              onDateChange={handleInvoiceDateChange}
            />
          </Col>
          <Col sm={3} className="mb-3 ps-sm-0">
            <VendorDropdown
              label=""
              isVendor={{
                value: invoiceData.vendor.id,
                label: invoiceData.vendor.name,
              }}
              getSelectedVendor={handleVendorChange}
              isDisabled={isSpinnerEnabled(spinnerArray, [
                invoiceConstants.ADD_INVOICE,
                invoiceConstants.GET_VENDORS,
                invoiceConstants.ADD_NEW_VENDOR,
              ])}
            />
          </Col>
          <Col sm={6}></Col>
          <Col
            sm={1}
            className="px-0 d-flex align-items-center mb-3 history-cart-table-header"
          >
            Qty
          </Col>
          <Col
            sm={5}
            className="px-0 d-flex align-items-center mb-3 history-cart-table-header"
          >
            Item
          </Col>
          <Col
            sm={3}
            className="px-0 d-flex align-items-center justify-content-end mb-3 history-cart-table-header"
          >
            Unit Cost
          </Col>
          <Col
            sm={3}
            className="px-0 d-flex align-items-center justify-content-end mb-3 pe-1 history-cart-table-header"
          >
            Unit Sell Price
          </Col>
          <hr />
          {invoiceData?.inventory?.map((item) => (
            <>
              <Col sm={1} className="px-0 d-flex align-items-center mb-3 ">
                {item?.price?.quantity || 0}
              </Col>
              <Col sm={5} className="px-0 d-flex flex-column gap-1 mb-3 ">
                <div className="d-flex align-items-center">
                  <span className="trans-product-name-modal invoice-view-product-name">
                    {item.product_name}
                  </span>
                  <span className="trans-items-modal ms-2">
                    {item?.sku !== transactionConstants.QUICK_TRADE &&
                      `#${getShortestSku(item?.sku)}`}
                  </span>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <span className="trans-items-modal ps-2">
                    {item.category_name}
                  </span>
                  <span className="trans-items-modal">({item.price.type})</span>
                  {item.subcategory === TRADING_CARD_CATEGORIES_ENUMS.SYSTEM &&
                    item?.serialNumber && (
                      <span className="trans-items-modal">
                        S/N : {item?.serialNumber}
                      </span>
                    )}
                  <span className="trans-items-modal">
                    {getProductDetailCardTags(
                      item.tags?.filter(
                        (tag) => tag !== inventoryConstants.CUSTOM_ITEM_TAG
                      ),
                      item.sku
                    )}
                  </span>
                </div>
              </Col>
              <Col
                sm={3}
                className="px-0 d-flex align-items-center justify-content-end mb-3 "
              >
                $
                {parseToThousandSeperatorDecimalNumber(
                  item?.price?.unit_purchase_price || 0
                )}
              </Col>
              <Col
                sm={3}
                className="px-0 d-flex align-items-center justify-content-end mb-3 pe-1 "
              >
                $
                {parseToThousandSeperatorDecimalNumber(
                  item?.price?.unit_sell_price || 0
                )}
              </Col>
            </>
          ))}
          <hr />

          <Col
            sm={6}
            className="px-0 d-flex align-items-center mb-3 history-cart-table-header"
          >
            TOTAL (
            {getTotalQuantityOfInvoiceBatch(
              invoiceData?.inventory,
              invoiceData?.invoiceType
            )}{" "}
            items)
          </Col>
          <Col
            sm={3}
            className="px-0 d-flex align-items-center justify-content-end mb-3 history-cart-table-header"
          >
            $
            {parseToThousandSeperatorDecimalNumber(
              getInvoiceTotalCostOfGoods(
                invoiceData.inventory || [],
                invoiceData?.invoiceType
              )
            )}
          </Col>
          <Col
            sm={3}
            className="px-0 d-flex align-items-center justify-content-end mb-3 pe-1 history-cart-table-header"
          >
            $
            {parseToThousandSeperatorDecimalNumber(
              getTotalStockPriceOfInvoiceBatch(
                invoiceData.inventory || [],
                invoiceData?.invoiceType
              )
            )}
          </Col>
        </Row>
        <Row className="m-0">
          <Col md={12} className="mb-3 px-0">
            <div className="input-field-wrapper input-textarea-wrapper invoice-textarea mt-2">
              <textarea
                value={invoiceData?.note}
                placeholder="Notes..."
                disabled={true}
                className={`input-textfield ps-3 invoice-textarea`}
              />
            </div>
          </Col>
          <Col
            md={12}
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              label={buttonNameConstants.CLOSE}
              handleClick={handleHideModal}
              className="w-50"
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  updateFulfilledData: (data, gridState) =>
    dispatch(invoiceActions.updateFulfilledData(data, gridState)),
});

//-------Export AddInvoice Component
export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoice);
