import React from "react";
import { Field, ErrorMessage } from "formik";

import ErrorTextMessage from "../errorTextMessage/ErrorTextMessage";

//-------InputTextArea component start here
const InputTextArea = (props) => {
  const {
    name,
    placeHolder,
    type = "text",
    label,
    disabled = false,
    className,
    maxLength = 50,
  } = props;

  //-------Return InputTextArea Component
  return (
    <div className="w-100">
      {label && (
        <label className="field-label" htmlFor={name}>
          {label}
        </label>
      )}

      <div className="input-field-wrapper input-textarea-wrapper">
        <Field
          className={`input-textfield input-textarea-field input-textfield-without-icon ${className}`}
          type={type}
          as="textarea"
          maxLength={maxLength}
          id={name}
          name={name}
          placeholder={placeHolder}
          autoComplete="off"
          disabled={disabled}
        />
      </div>
      <ErrorMessage name={name} component={ErrorTextMessage} />
    </div>
  ); //-------InputTextArea component end here
};

//-------Export InputTextArea Component
export default InputTextArea;
