import React from "react";
import { buttonTypeConstants, EMPTY_STRING } from "../../../constants";

//-------Button component start here
const Button = (props) => {
  const {
    handleClick,
    type,
    label,
    buttonType,
    IconImage,
    className = "",
    isDisabled = false,
    title = EMPTY_STRING,
  } = props;

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevents button from being triggered
    }
  };

  return (
    <button
      onKeyDown={handleKeyDown}
      type={type}
      title={title}
      disabled={isDisabled}
      className={`d-flex justify-content-center ${
        isDisabled && "disable-button"
      } ${className} gap-1 main-purple-button ${
        IconImage && "button-with-icon"
      } ${
        buttonType === buttonTypeConstants.GHOST_BUTTON
          ? "ghost-cancel-button "
          : buttonType === buttonTypeConstants.LIGHT_PURPLE
          ? "light-purple-button"
          : buttonType === buttonTypeConstants.ACTION_BUTTON
          ? "action-buttons"
          : buttonType === buttonTypeConstants.BLUE_BUTTON
          ? "blue-button"
          : buttonType === buttonTypeConstants.GHOST_BUTTON_2
          ? "gray-button"
          : buttonType === buttonTypeConstants.RED_BUTTON
          ? "red-button"
          : buttonType === buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE
          ? "transparent-button-purple"
          : buttonType === buttonTypeConstants.GREEN_BUTTON
          ? "green-button"
          : buttonType === buttonTypeConstants.TRANSPARENT_BUTTON_GHOST
          ? "transparent-button-ghost"
          : buttonType === buttonTypeConstants.BLACK_BUTTON
          ? "black-button"
          : buttonType === buttonTypeConstants.RED_BUTTON_FILL
          ? "red-button-fill"
          : ""
      }`}
      onClick={handleClick}
    >
      {IconImage && <IconImage className="button-icon-image" />}
      {label}
    </button>
  );
}; //-------Button component end here

export default Button;
