import {
  EMPTY_STRING,
  printerConstants,
  transactionConstants,
} from "../../../constants";
import {
  isSystemProduct,
  getReceiptPrintDate,
  parseToDecimalNumber,
  parseToThousandSeperatorDecimalNumber,
  isTradeTransaction,
} from "../../utility";

//------- cut the item name if exceed the max length
export const sliceItemName = (itemName) => {
  return itemName.length >= printerConstants.RECEIPT_ITEM_MAX_LENGTH
    ? `${itemName.slice(0, printerConstants.RECEIPT_ITEM_MAX_LENGTH)}...`
    : itemName +
        printerConstants.SPACE.repeat(
          printerConstants.RECEIPT_ITEM_MAX_LENGTH + 3 - itemName.length
        );
};

//------- prepare the each row for an item in the receipt
//------- with name, quantity and price
export const prepareItemRow = (itemName, quantity, price) => {
  const formatteditemName = sliceItemName(itemName);
  const formattedString = `${formatteditemName.padEnd(
    printerConstants.RECEIPT_ITEM_MAX_LENGTH +
      printerConstants.DOUBLE_SPACE_AFTER_ITEM,
    " "
  )}  ${quantity
    .slice(0, printerConstants.RECEIPT_QTY_MAX_LENGTH)
    .padEnd(printerConstants.RECEIPT_QTY_MAX_LENGTH, " ")}  ${price
    .slice(-printerConstants.RECEIPT_PRICE_MAX_LENGTH)
    .padStart(printerConstants.RECEIPT_PRICE_MAX_LENGTH, " ")}`;
  const itemRow = formattedString.slice(0, printerConstants.RECEIPT_ROW_LENGTH);
  return itemRow;
};

//------- get list of all items in a receipt with
//------- sliced name, quanity and price
export const getItemsList = (
  receiptItems,
  giftReceipt,
  isItemizedBreakdown
) => {
  let itemsList = receiptItems.map(
    (item) =>
      `${prepareItemRow(
        item.itemName,
        isItemizedBreakdown ? item.quantity.toString() : EMPTY_STRING,
        isItemizedBreakdown
          ? `${!giftReceipt ? `$${Number(item.actualPrice).toFixed(2)}` : ""}`
          : item.quantity.toString()
      )}${
        isSystemProduct(item?.subcategory) && item?.serialNumber
          ? `\x0A${prepareItemRow(
              `S/N:${item.serialNumber}`,
              EMPTY_STRING,
              EMPTY_STRING
            )}`
          : ""
      }${
        Number(item?.discountPercentage) > 0
          ? `\x0A${prepareItemRow(
              `   Discount (${
                item.isToggleDiscount
                  ? `$${item.discountPercentage}`
                  : `${item.discountPercentage}%`
              })`,
              EMPTY_STRING,
              `-$${
                item.isToggleDiscount
                  ? parseToDecimalNumber(item.discountPercentage)
                  : parseToDecimalNumber(
                      (Number(item.actualPrice) *
                        Number(item.discountPercentage)) /
                        100
                    )
              }`
            )}`
          : ""
      }`
  );
  return itemsList;
};

//------- divide a row to two equal side to place content left and right
export const alignValueLeftAndRight = (leftItem, rightItem) => {
  const lenVal1 = Math.min(leftItem.length, printerConstants.LEFT_ITEM_LENGTH); // Maximum length for val1
  const lenVal2 = Math.min(
    rightItem.length,
    printerConstants.RIGHT_ITEM_LENGTH
  ); // Maximum length for val2

  const formattedVal1 = leftItem
    .slice(0, lenVal1)
    .padEnd(printerConstants.LEFT_ITEM_LENGTH, " ");
  const formattedVal2 = rightItem
    .slice(0, lenVal2)
    .padStart(printerConstants.RIGHT_ITEM_LENGTH, " ");

  const result = formattedVal1 + "  " + formattedVal2;
  return result.slice(0, printerConstants.RECEIPT_ROW_LENGTH); // Ensure the total length is 42
};

//------- get total quantity of the receipt items
export const getTotalItemsQuantity = (receiptItems) => {
  let totalQuantity = 0;
  receiptItems.map((item) => {
    totalQuantity += Number(item.quantity);
  });

  return totalQuantity;
};

// Receipt header section in sell/trade (store info, logo etc)
export const getPrintReceiptHeaderSection = (props) => {
  const {
    id,
    type,
    user,
    dateTime,
    isReprint,
    storeDetails,
    openCashDrawer,
    selectedCustomer,
  } = props;
  try {
    //------ add store logo on receipt if uploaded in store
    const storeLogoImage = storeDetails?.showOnReceipt
      ? {
          type: "raw",
          format: "image",
          flavor: "file",
          data: storeDetails?.logo,
          options: {
            language: "ESCPOS",
            dotDensity: "double",
            width: 20,
          },
        }
      : "";

    return [
      `${
        type?.totalPaidAmount[transactionConstants.CASH] > 0 && openCashDrawer
          ? "\x10" + "\x14" + "\x01" + "\x00" + "\x05"
          : "" // Generate Pulse to kick-out cash drawer**
      }`,
      "\x1B" + "\x61" + "\x31", // Center align
      storeLogoImage,
      `${storeDetails?.showOnReceipt ? "\x0A" : ""}`,
      "\x1B" + "\x40", // init
      "\x1B" + "\x61" + "\x31", // center align
      "\x1B" + "\x21" + "\x30", // em mode on
      //Store Name
      `${storeDetails?.storeName}`,
      "\x1B" + "\x21" + "\x0A" + "\x1B" + "\x45" + "\x0A", // em mode off
      "\x0A", //Line Break
      "\x0A", //Line Break
      //Store Address
      `${storeDetails?.phoneNumber}` + "\x0A",
      `${`${storeDetails.line1} ${storeDetails.line2}`}` + "\x0A",
      `${`${storeDetails.city} ${storeDetails.state} ${storeDetails.zip}`}` +
        "\x0A",
      // Receipt Reprint Text
      ...(isReprint
        ? [
            `** REPRINT **`,
            "\x0A", // line break
            getReceiptPrintDate(new Date()),
          ]
        : []),
      "\x0A", // line break
      "\x1B" + "\x4D" + "\x30", // normal text
      "__________________________________________" + "\x0A",
      "\x0A", // line break
      "\x1B" + "\x61" + "\x30", // left align
      //Receipt Details
      `${isTradeTransaction({ id }) ? "Trade Id" : "Sale ID"} : ${id}` + "\x0A",
      `Associate : ${`${user.firstName} ${user.lastName}`}` + "\x0A",
      `Date : ${dateTime}` + "\x0A",
      "__________________________________________" + "\x0A",
      "\x0A", // line break
      `${
        selectedCustomer?.id && selectedCustomer?.firstName
          ? `Customer : ${selectedCustomer?.firstName} ${selectedCustomer?.lastName}` +
            "\x0A" +
            `Store Credit: ${parseToThousandSeperatorDecimalNumber(
              selectedCustomer?.currentBalance
            )}` +
            "\x0A"
          : selectedCustomer?.name
          ? `Customer : ${selectedCustomer?.name} ` + "\x0A"
          : ""
      }`,
      `${
        selectedCustomer?.id && selectedCustomer?.firstName
          ? "__________________________________________" + "\x0A" + "\x0A"
          : selectedCustomer?.name
          ? "__________________________________________" + "\x0A" + "\x0A"
          : ""
      }`,
      "\x1B" + "\x45" + "\x0D", // bold on
      "\x1B" + "\x61" + "\x30", // left align
    ];
  } catch (e) {
    console.log("Error preparing receipt header", e);
    return [];
  }
};
