import React from "react";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import { Col, Modal, Row } from "react-bootstrap";

import { customToast, getUserFullName } from "../../../../shared/utility";
import { outletActions } from "../../../../redux/actions";
import Button from "../../../../shared/components/Buttons/Button";
import { DollarIcon } from "../../../../assets/icons/iconsProvider";
import SwitchButton from "../../../../shared/components/SwitchButton/SwitchButton";
import { getMacAddress } from "../../../../shared/Printers/ReceiptPrinters/printReceipt";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import { addNewOutletValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import {
  toastType,
  toastMessages,
  outletConstants,
  buttonNameConstants,
  buttonTypeConstants,
  toggleSwitchButtonConstants,
  EMPTY_STRING,
  ROLES,
} from "../../../../constants";

const AddNewOutlet = (props) => {
  //-------Props
  const {
    user,
    addOutlet,
    editOutlet,
    outletData,
    currentStore,
    isAddNewOutletModal,
    toggleAddNewOutletModal,
  } = props;
  const isEdit = outletData.id ? true : false;
  //-------Initial Values
  const initialValues = {
    outletName: isEdit ? outletData.name : EMPTY_STRING,
    [toggleSwitchButtonConstants.IS_CASH_TRACKING_ENABLED]: isEdit
      ? outletData.isCashTracking
      : false,
    defaultStartingBalance: isEdit
      ? outletData.register.defaultStartingBalance
      : 0,
  };

  //-------On switch change
  const handleSwitchButtonChange = (value, values, setFieldValue) => {
    setFieldValue(value, !values[value]);
  };

  //-------Handle Add New Outlet Submit
  const handleAddNewOutletSubmit = async (values) => {
    const macAddress = await getMacAddress();

    //------- Do not add outlet if macAddress not found
    if (!macAddress) {
      customToast(toastMessages.MAC_ADDRESS_NOT_FOUND, toastType.ERROR);
      return;
    }
    const dataToSend = {
      name: values.outletName,
      macAddress: macAddress,
      isCashTracking:
        values[toggleSwitchButtonConstants.IS_CASH_TRACKING_ENABLED],
      loggedInUsers:
        user.role === ROLES.ADMIN
          ? []
          : [
              {
                userId: user.id,
                name: getUserFullName(user),
              },
            ],
      register: {
        defaultStartingBalance: values.defaultStartingBalance,
        openBy: {
          userId: EMPTY_STRING,
          name: EMPTY_STRING,
        },
      },
      store: {
        id: currentStore?.id,
        name: currentStore?.storeName,
      },
    };
    addOutlet(currentStore?.id, dataToSend, toggleAddNewOutletModal);
  };

  const handleEditOutletSubmit = async (values) => {
    const updatedOutletData = {
      id: outletData.id,
      name: values.outletName,
      isCashTracking:
        values[toggleSwitchButtonConstants.IS_CASH_TRACKING_ENABLED],
      register: {
        defaultStartingBalance: values[
          toggleSwitchButtonConstants.IS_CASH_TRACKING_ENABLED
        ]
          ? values.defaultStartingBalance
          : 0,
      },
      store: {
        id: currentStore?.id,
        name: currentStore?.storeName,
      },
    };
    editOutlet(updatedOutletData, toggleAddNewOutletModal, outletData);
  };

  return (
    <Modal
      show={isAddNewOutletModal}
      onHide={toggleAddNewOutletModal}
      size="md"
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal"
    >
      <Formik
        initialValues={initialValues}
        validate={addNewOutletValidationSchema}
        onSubmit={isEdit ? handleEditOutletSubmit : handleAddNewOutletSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>
                {isEdit ? "Edit Outlet" : "Add New Outlet"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
              <Row className="m-0 p-0">
                <Col md={12} className="mb-3 px-0">
                  <InputTextField
                    label="Outlet Name"
                    name="outletName"
                    placeHolder="Enter Outlet Name..."
                  />
                </Col>
                <Col md={12} className="mb-3 px-0">
                  Cash Tracking
                </Col>
                <Col md={12} className="mb-3 px-0 d-flex justify-content-start">
                  <SwitchButton
                    onSwitchChange={(value) =>
                      handleSwitchButtonChange(value, values, setFieldValue)
                    }
                    id={toggleSwitchButtonConstants.IS_CASH_TRACKING_ENABLED}
                    isSwitchOn={
                      values[
                        toggleSwitchButtonConstants.IS_CASH_TRACKING_ENABLED
                      ]
                    }
                    label={
                      values[
                        toggleSwitchButtonConstants.IS_CASH_TRACKING_ENABLED
                      ]
                        ? outletConstants.ENABLED
                        : outletConstants.DISABLED
                    }
                    className={"outlet-switch-button"}
                  />
                </Col>
                {values[
                  toggleSwitchButtonConstants.IS_CASH_TRACKING_ENABLED
                ] && (
                  <Col md={12} className="mb-3 px-0">
                    <InputNumberField
                      label="Default Starting Balance"
                      name="defaultStartingBalance"
                      IconImage={DollarIcon}
                      isHandleKeyDown={true}
                    />
                  </Col>
                )}
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col md={7} className=" d-flex gap-2">
                <Button
                  type="button"
                  label={buttonNameConstants.CANCEL}
                  handleClick={toggleAddNewOutletModal}
                  buttonType={buttonTypeConstants.GHOST_BUTTON}
                  className="w-100"
                ></Button>
                <Button
                  type="submit"
                  className="w-100"
                  label={buttonNameConstants.SAVE}
                ></Button>
              </Col>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  user: state.authentication.user,
  outlet: state.outlet,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getOutlets: (storeId) => dispatch(outletActions.getOutlets(storeId)),
  addOutlet: (storeId, data, toggleAddNewOutletModal) =>
    dispatch(outletActions.addOutlet(storeId, data, toggleAddNewOutletModal)),
  editOutlet: (updatedOutletData, toggleAddNewOutletModal, outletData) =>
    dispatch(
      outletActions.editOutlet(
        updatedOutletData,
        toggleAddNewOutletModal,
        outletData
      )
    ),
});

//-------Connecting the Outlet component to the redux store
export default connect(mapStateToProps, mapDispatchToProps)(AddNewOutlet);
