import { connect } from "react-redux";
import Select, { components } from "react-select";
import React, { useEffect, useRef, useState } from "react";

import { invoiceActions } from "../../../../redux/actions";
import { isSpinnerEnabled } from "../../../../shared/utility";
import { AddIcon } from "../../../../assets/icons/iconsProvider";
import Button from "../../../../shared/components/Buttons/Button";
import { vendorDropdownStyle } from "../../../../assets/scss/style";
import {
  EMPTY_STRING,
  invoiceConstants,
  buttonNameConstants,
  buttonTypeConstants,
} from "../../../../constants";

const VendorDropdown = ({
  vendors,
  addVendor,
  getVendors,
  spinnerArray,
  currentStore,
  getSelectedVendor,
  isVendor = EMPTY_STRING,
  isDisabled = false,
  label = "Vendor",
}) => {
  //-------select ref
  const selectRef = useRef(null);

  //-------useState
  const [vendorOptions, setVendorOptions] = useState([]);
  const [inputValue, setInputValue] = useState(EMPTY_STRING);
  const [selectedVendor, setSelectedVendor] = useState(
    isVendor || EMPTY_STRING
  );

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <div className="d-flex align-items-center gap-4">{children}</div>
    </components.SingleValue>
  );

  //-------handle Add Vendor Click
  const handleAddVendorClick = () => {
    selectRef.current?.blur();
    if (!inputValue) {
      return;
    }
    const dataToSend = {
      miscData: { vendorName: inputValue },
      store: { id: currentStore?.id, name: currentStore?.storeName },
      type: invoiceConstants.INVOICE_VENDOR,
    };
    setSelectedVendor(inputValue);
    addVendor(currentStore?.id, dataToSend);
  };

  useEffect(() => {
    if (currentStore) {
      getVendors(currentStore?.id);
    }
  }, [currentStore]);

  useEffect(() => {
    const vendorOptions =
      vendors?.map((vendor) => ({
        value: vendor.id,
        label: vendor.miscData.vendorName,
      })) || [];
    setVendorOptions([
      ...vendorOptions,
      {
        value: invoiceConstants.ADD_NEW_VENDOR,
        label: (
          <Button
            label={buttonNameConstants.ADD_VENDOR}
            buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
            IconImage={AddIcon}
            handleClick={handleAddVendorClick}
            className={"add-customer-button-select w-100"}
          />
        ),
      },
    ]);
    const vendor =
      vendorOptions?.find((vendor) => vendor.label === selectedVendor) ||
      EMPTY_STRING;
    setSelectedVendor(isVendor || vendor);
    getSelectedVendor(isVendor || vendor);
  }, [vendors]);

  const noOptionFound = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center gap-2">
        <span>No Vendor Found</span>
        <Button
          label={buttonNameConstants.ADD_VENDOR}
          buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
          IconImage={AddIcon}
          handleClick={handleAddVendorClick}
          className={"add-customer-button-select w-100"}
        />
      </div>
    );
  };

  return (
    <div className={``}>
      {label && <label className="field-label">{label}</label>}
      <Select
        ref={selectRef}
        className=""
        options={vendorOptions}
        getOptionLabel={(e) => e.label}
        getOptionValue={(e) => e.value}
        // isSearchable={isSearchable}
        components={{
          IndicatorSeparator: () => null,
          SingleValue,
        }}
        styles={vendorDropdownStyle}
        onChange={(option) => {
          if (option?.value !== invoiceConstants.ADD_NEW_VENDOR) {
            setSelectedVendor(option);
            getSelectedVendor(option);
          }
          selectRef.current?.blur();
        }}
        noOptionsMessage={noOptionFound}
        placeholder="Select Vendor..."
        // isClearable={isClearable}
        value={selectedVendor}
        isDisabled={
          isSpinnerEnabled(
            spinnerArray,
            invoiceConstants.ADD_NEW_VENDOR,
            invoiceConstants.GET_VENDORS,
            invoiceConstants.ADD_INVOICE
          ) || isDisabled
        }
        inputValue={inputValue}
        onInputChange={(e) => {
          setInputValue(e);
        }}
      />
    </div>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  vendors: state.invoice.vendors,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getVendors: (storeId) => dispatch(invoiceActions.getVendors(storeId)),
  addVendor: (storeId, data) =>
    dispatch(invoiceActions.addVendor(storeId, data)),
});

//-------Export Vendor Dropdown Component
export default connect(mapStateToProps, mapDispatchToProps)(VendorDropdown);
