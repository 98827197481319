import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import BarcodeReader from "react-barcode-reader";
import { useDebouncedCallback } from "use-debounce";
import React, { useEffect, useState, useRef } from "react";

import AddGiftCard from "./AddGiftCard/AddGiftCard";
import AddCustomItem from "./AddCustomItem/AddCustomItem";
import InfiniteScroll from "react-infinite-scroll-component";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import { PRODUCT_TYPES_ENUMS } from "../../../system/globalEnums";
import CustomerImage from "../../../assets/images/customerImage.svg";
import AddNewCustomerModal from "./AddNewCustomer/AddNewCustomerModal";
import SearchField from "../../../shared/components/searchField/SearchField";
import SwitchButton from "../../../shared/components/SwitchButton/SwitchButton";
import FilterDropdowns from "../../../shared/components/FilterDropdowns/FilterDropdowns";
import SelectedCustomerCard from "../../../shared/components/customers/SelectedCustomerCard";
import ConfirmationModal from "../../Inventory/Items/ConfirmationModal/ConfirmationModal";
import CustomerDropdown from "../../../shared/components/selectDropdown/CustomerDropdown";
import ProductCartCard from "../../../shared/components/productDetailCard/ProductCartCard";
import SimpleNumberField from "../../../shared/components/inputNumberField/SimpleNumberField";
import ProductDetailCard from "../../../shared/components/productDetailCard/ProductDetailCard";
import IsRegisterClosedOrNotConfigured from "../../../shared/components/isRegisterClosedOrNotConfigured/IsRegisterClosedOrNotConfigured";
import {
  customToast,
  isArraySame,
  parseToNumber,
  getShortestSku,
  parseJsonObject,
  stringifyObject,
  LoadingIndicator,
  getProductDiscount,
  getDefaultFilterType,
  getCountOfTotalItems,
  parseToDecimalNumber,
  handleTransactionData,
  getSumOfPaymentHistory,
  createCompanyDynamicUrl,
  prepareFilterForPaginationCall,
  parseToThousandSeperatorDecimalNumber,
  handleCalculateTotalCogsAndStockPrice,
  isSessionOpenedByCurrentUser,
  isTransactionAllowed,
  roundNumber,
} from "../../../shared/utility";
import {
  systemActions,
  inventoryActions,
  transactionActions,
} from "../../../redux/actions";
import {
  toastType,
  EMPTY_STRING,
  toastMessages,
  globalConstants,
  defaultGridState,
  buttonNameConstants,
  routesPathConstants,
  buttonTypeConstants,
  transactionConstants,
  transactionDefaultData,
  categoryDropdownConstants,
  toggleSwitchButtonConstants,
  SALE_TRANSACTION_ACTIVITY_TYPES,
} from "../../../constants";
import {
  AddIcon,
  CashIcon,
  CrossIcon,
  DeleteIcon,
  SearchIcon,
  BlueBoxIcon,
  ParkSaleIcon,
  ResetCartIcon,
  RetriveSaleIcon,
} from "../../../assets/icons/iconsProvider";
// import RetriveSaleModal from "./RetriveSaleModal/RetriveSaleModal";

//-------NewTransaction component start here
const NewTransaction = (props) => {
  const {
    user,
    outlet,
    customer,
    inventory,
    currentStore,
    tradeDiscount,
    activateSpinner,
    transactionData,
    itemOrganization,
    emptyAllInventory,
    handleNewParkSale,
    deactivateSpinner,
    setTransactionData,
    isAddCustomerModal,
    getPaginatedInventory,
    toggleAddCustomerModal,
  } = props;

  //-----invcentory reducer
  const { allInventory } = inventory;
  const { allDiscount } = tradeDiscount;
  //-------Reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;

  const cartRef = useRef(null);

  const navigate = useNavigate();
  //-------usestate
  const [searchProductType, setSearchProductType] = useState(
    getDefaultFilterType(customTypes, PRODUCT_TYPES_ENUMS.ALL)
  );
  const [searchProductCategory, setSearchProductCategory] = useState(
    globalConstants.EMPTY_STRING
  );
  const [searchProductSubCategory, setSearchProductSubCategory] =
    useState(EMPTY_STRING);

  let cartItemsCount = useRef(transactionData?.inventory?.length ?? 0);
  //-------reducer
  const { allCustomers } = customer;

  const [search, setSearch] = useState(EMPTY_STRING);
  const [isScan, setIsScan] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [customerToEdit, setCustomerToEdit] = useState({});
  const [giftCardModal, setGiftCardModal] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [customItemModal, setCustomItemModal] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [selectedCustomerFlag, setSelectedCustomerFlag] = useState(false);
  const [saleProductState, setSaleProductState] = useState(defaultGridState);
  // const [retriveSaleModal, setRetriveSaleModal] = useState(false);

  //------Toggle custom item modal
  const toggleCustomItemModal = () => {
    setCustomItemModal(!customItemModal);
  };

  //-------Toggle confirmation modal
  const toggleConfirmationModal = () => {
    setIsConfirmationModal(!isConfirmationModal);
  };

  //-------handle conformation actions
  const handleConfirmYesButtonClick = () => {
    resetTransationPage();
    toggleConfirmationModal();
  };

  const handleConfirmNoButtonClick = () => {
    toggleConfirmationModal();
  };

  //------Toggle custom item modal
  const toggleGiftCardModal = () => {
    setGiftCardModal(!giftCardModal);
  };

  // //------Toggle retrive sale modal
  // const toggleRetriveSaleModal = () => {
  //   setRetriveSaleModal(!retriveSaleModal);
  // };

  //-------handle custom item click
  const handleCustomItemClick = () => {
    toggleCustomItemModal();
  };

  //-------handle custom item click
  const handleGiftCardClick = () => {
    toggleGiftCardModal();
  };

  const updateGridState = (gridState) => {
    setSaleProductState(gridState);
    getPaginatedInventory(currentStore?.id, gridState);
  };

  const fetchMoreData = () => {
    updateGridState({
      ...saleProductState,
      pageSize:
        Number(
          saleProductState?.pageSize ?? process.env.REACT_APP_GRID_PAGE_SIZE
        ) + 20,
    });
  };

  //-------handle search field change
  const handleSearchFieldChange = (value, isScan) => {
    setIsScan(isScan);
    setSearch(value);
    setIsSearchLoading(true);
    handleDebouncedSearch(value);
  };

  //-------add debounce in serach inevntory
  const handleDebouncedSearch = useDebouncedCallback((value) => {
    // searchProduct(value);
    if (value) {
      updateGridState({
        ...saleProductState,
        pageSize:
          saleProductState?.pageSize ?? process.env.REACT_APP_GRID_PAGE_SIZE,
        searchQuery: value,
        filters: prepareFilterForPaginationCall(
          searchProductType,
          searchProductCategory,
          searchProductSubCategory
        ),
      });
    } else {
      setProductsList([]);
    }
  }, 1000);

  //-------handle add customer click
  const handleAddCustomerClick = (customerToEdit = {}) => {
    setCustomerToEdit(customerToEdit);
    toggleAddCustomerModal(true);
  };

  //------update Transaction Data
  const updateTransactionData = (data, activityName) => {
    handleTransactionData(
      data,
      user,
      currentStore,
      activityName,
      transactionData,
      setTransactionData
    );
  };

  const handleUpdateProductListItemsQuantity = (
    productsList,
    cartItemsList,
    setProductsList
  ) => {
    setProductsList(
      productsList.map((filteredProduct) => {
        // Find matching product in the cart based on SKU
        const inventoryProduct = cartItemsList.find(
          (invProduct) =>
            getShortestSku(invProduct.sku) ===
            getShortestSku(filteredProduct.sku)
        );

        if (inventoryProduct) {
          // If the item is in the cart, decrease the quantity in the filtered products list
          const updatedProduct = {
            ...filteredProduct,
            quantity:
              filteredProduct.originalQuantity - inventoryProduct.quantity,
          };

          return updatedProduct;
        }

        // If no match found, the quantity of the product should be reset to its original quantity
        const updatedProduct = {
          ...filteredProduct,
          quantity: filteredProduct.originalQuantity,
        };

        return updatedProduct;
      })
    );
  };
  //-------Add item in cart section
  const addItemInCart = (newItem) => {
    const itemToAdd = { ...newItem, quantity: 1 };

    let updatedInventory = [];

    if (
      !currentStore[toggleSwitchButtonConstants.IS_SALE_OUT_OF_STOCK] &&
      newItem.trackQuantity &&
      newItem.quantity <= 0
    ) {
      return customToast(
        `"${newItem?.itemName}" is  Out-of-Stock.`,
        toastType.ERROR
      );
    }

    const existingItem = transactionData?.inventory?.find((item) =>
      isArraySame(item?.sku, newItem?.sku)
    );
    if (existingItem) {
      if (existingItem.quantity >= newItem?.max || newItem?.serialNumber) {
        return customToast(
          `Maximum quantity of "${newItem?.itemName}" is "${newItem?.quantity}".`,
          toastType.ERROR
        );
      } else {
        const updatedItems = transactionData?.inventory.map((item) => {
          if (item?.sku === existingItem?.sku) {
            return { ...item, quantity: item?.quantity + 1 };
          }
          return item;
        });
        updatedInventory = updatedItems;
      }
    } else {
      let discountPercentage = 0;
      let actualPrice = itemToAdd?.price;
      let priceAfterDiscount = itemToAdd?.price;
      let isDiscountApplied = false;
      const discountedProduct = getProductDiscount(itemToAdd, allDiscount);
      if (discountedProduct) {
        isDiscountApplied = true;
        discountPercentage = discountedProduct?.discountPercentage;
        actualPrice = itemToAdd?.price;
        priceAfterDiscount =
          actualPrice - actualPrice * (discountPercentage / 100);
      }

      const item = {
        ...itemToAdd,
        actualPrice: actualPrice,
        isDiscountApplied: isDiscountApplied,
        discountPercentage: discountPercentage,
        priceAfterDiscount: parseToDecimalNumber(priceAfterDiscount),
        actualDiscountPercentage: discountPercentage,
        quantity: itemToAdd?.isCustomItem
          ? newItem?.quantity
          : itemToAdd?.quantity,
      };

      updatedInventory = [...transactionData?.inventory, item];
    }
    handleUpdateProductListItemsQuantity(
      productsList,
      updatedInventory,
      setProductsList
    );

    updateTransactionData(
      {
        ...transactionData,
        inventory: updatedInventory,
      },
      SALE_TRANSACTION_ACTIVITY_TYPES.ADD_ITEM
    );
  };

  //-------handle product card click
  const handleProductCardClick = (selectedProductCard) => {
    //------- check session is open or not also in use or not or outlet is configured or not
    if (isTransactionAllowed(outlet, user, currentStore)) {
      return;
    }
    const priceValue = parseToDecimalNumber(selectedProductCard?.price);
    addItemInCart({
      price: priceValue,
      id: selectedProductCard?.id,
      sku: selectedProductCard?.sku,
      epid: selectedProductCard?.epid,
      cogs: selectedProductCard?.cogs,
      tags: selectedProductCard?.tags,
      type: selectedProductCard?.type,
      item: selectedProductCard?.label,
      isSingleItemDiscountField: false,
      isToggleDiscount: false,
      quantity: selectedProductCard?.quantity,
      itemName: selectedProductCard?.itemName,
      max:
        currentStore?.[toggleSwitchButtonConstants.IS_SALE_OUT_OF_STOCK] ||
        !selectedProductCard?.trackQuantity
          ? 9999
          : Number(selectedProductCard?.originalQuantity),
      category: selectedProductCard?.category_name,
      productType: selectedProductCard?.productType,
      tcgPlayerUrl: selectedProductCard?.tcgPlayerUrl,
      subcategory: selectedProductCard?.subcategory,
      total:
        selectedProductCard?.price?.unit_sell_price *
        selectedProductCard?.price?.quantity,
      label: `${selectedProductCard?.product_id}/${selectedProductCard?.category_name}/${selectedProductCard?.product_name}/${selectedProductCard?.price?.type}`,
      serialNumber: selectedProductCard?.serialNumber || EMPTY_STRING,
      trackQuantity: selectedProductCard?.trackQuantity,
      upc: selectedProductCard?.upc || EMPTY_STRING,
      tags: selectedProductCard?.tags || [],
    });
  };

  //-------delete item from cart section
  const handleDeleteCartItem = (event, sku) => {
    event.stopPropagation();

    // Find the item to delete from the cart
    const itemToDelete = transactionData?.inventory?.find(
      (item) => getShortestSku(item?.sku) === getShortestSku(sku)
    );

    // If item is found, we can proceed with the deletion
    if (itemToDelete) {
      const updatedInventory = transactionData?.inventory?.filter(
        (item) => getShortestSku(item?.sku) !== getShortestSku(sku)
      );

      // Update the transaction data (inventory without the deleted item)
      updateTransactionData(
        {
          ...transactionData,
          inventory: updatedInventory,
        },
        SALE_TRANSACTION_ACTIVITY_TYPES.DELETE_ITEM
      );

      // Update the quantity of the products list to reflect the deleted item
      handleUpdateProductListItemsQuantity(
        productsList,
        updatedInventory,
        setProductsList
      );
    }
  };

  //-------handle discount Method
  const handleDiscountMethod = (id, value) => {
    updateTransactionData(
      {
        ...transactionData,
        discountedValue: 0,
        discountMethod: value,
      },
      SALE_TRANSACTION_ACTIVITY_TYPES.APPLY_DISCOUNT
    );
  };

  //-------handle upc or sku barcode scanning
  const handleScan = (data) => {
    if (!addCustomerModal) {
      handleSearchFieldChange(data, true);
    }
  };

  //------- handle quantity change in cart section
  const handleQuantityChange = (skuNumber, newQuantity) => {
    // Step 1: Update the cart with the new quantity
    const updatedCartItems = transactionData.inventory.map((item) =>
      item.sku === skuNumber ? { ...item, quantity: newQuantity } : item
    );

    // Update the transaction data (cart items) with the new cart state
    updateTransactionData(
      { ...transactionData, inventory: updatedCartItems },
      SALE_TRANSACTION_ACTIVITY_TYPES.CHANGE_ITEM_QUANTITY
    );

    // Step 2: Update the products list to reflect the change in the cart
    handleUpdateProductListItemsQuantity(
      productsList,
      updatedCartItems,
      setProductsList
    );
  };
  //-------reset transaction page
  const resetTransationPage = () => {
    setProductsList([]);
    setSearch("");
    updateTransactionData(
      transactionDefaultData,
      SALE_TRANSACTION_ACTIVITY_TYPES.RESET_TRANSACTION
    );
  };

  //-------handle scan error
  const handleError = (error) => {};

  const handlePayClick = () => {
    if (transactionData.inventory?.length > 0) {
      // handle component unmounting
      cartItemsCount.current = 0;

      //------- currentAmountToPay
      const currentAmountToPay = parseToDecimalNumber(
        parseToNumber(transactionData.totalAmountToPay) -
          getSumOfPaymentHistory(transactionData.paymentHistory)
      );

      //-------Transaction Data
      const dataToSend = {
        ...transactionData,
        store: currentStore || "",
        currentAmountToPay: currentAmountToPay,
        giftCard: globalConstants.EMPTY_OBJECT,
        paymentMethod: globalConstants.EMPTY_STRING,
        taxPercentage: currentStore?.defaultTax,
      };
      let globalDiscountInPercentage = dataToSend.discountMethod
        ? Number(dataToSend.discountedValue)
        : null;
      if (globalDiscountInPercentage === null) {
        // find the percentage of the discount as it is value discount
        globalDiscountInPercentage =
          (Number(dataToSend.discount) / Number(dataToSend.subTotal)) * 100;
      }
      dataToSend.inventory = dataToSend.inventory.map((inv) => {
        return {
          ...inv,
          priceAfterGlobalDiscount: parseToDecimalNumber(
            Number(inv.priceAfterDiscount)
              ? Number(inv.priceAfterDiscount) -
                  (Number(inv.priceAfterDiscount) *
                    globalDiscountInPercentage) /
                    100
              : 0
          ),
        };
      });

      updateTransactionData(
        dataToSend,
        SALE_TRANSACTION_ACTIVITY_TYPES.MOVED_TO_PAYEMNT_PAGE
      );
      navigate(
        createCompanyDynamicUrl(
          routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION_PAYMENT
        ),
        {
          state: {
            currentStore: currentStore,
          },
        }
      );
    } else {
      customToast(toastMessages.PLEASE_SELECT_SOME_PRODUCT, toastType.ERROR);
    }
  };

  //-------handle discount value change
  const handleDiscountValueChange = (value) => {
    updateTransactionData(
      {
        ...transactionData,
        discountedValue: value,
      },
      SALE_TRANSACTION_ACTIVITY_TYPES.APPLY
    );
  };

  const handleCustomDiscountValueChange = (skuNumber, value) => {
    const updatedCartItems = transactionData.inventory.map((item) => {
      if (item.sku === skuNumber) {
        const discount = parseToNumber(value);
        const itemPrice = parseToNumber(item.actualPrice);
        const priceAfterCustomDiscount = !item.isToggleDiscount
          ? itemPrice - itemPrice * (discount / 100)
          : itemPrice - discount;
        return {
          ...item,
          discountPercentage: value,
          priceAfterDiscount: parseToDecimalNumber(priceAfterCustomDiscount),
        };
      } else {
        return item;
      }
    });
    updateTransactionData(
      { ...transactionData, inventory: updatedCartItems },
      SALE_TRANSACTION_ACTIVITY_TYPES.APPLY_SINGLE_ITEM_DISCOUNT
    );
  };

  //-------handle cart section category discount click
  const handleDiscountChange = (event, skuNumber) => {
    event.stopPropagation();
    const updatedCartItems = transactionData.inventory.map((item) =>
      item.sku === skuNumber
        ? {
            ...item,
            isDiscountApplied: !item.isDiscountApplied,
            priceAfterDiscount: !item.isDiscountApplied
              ? parseToDecimalNumber(
                  item.actualPrice -
                    item.actualPrice * (item.actualDiscountPercentage / 100)
                )
              : parseToDecimalNumber(item.actualPrice),
          }
        : item
    );
    updateTransactionData(
      { ...transactionData, inventory: updatedCartItems },
      SALE_TRANSACTION_ACTIVITY_TYPES.APPLY_SINGLE_ITEM_DISCOUNT
    );
  };

  //--------handle price change in cart item
  const handlePriceChange = (sku, value) => {
    const inputValue = value;

    if (inputValue < 999999) {
      const updatedCartItems = transactionData.inventory.map((item) =>
        item.sku === sku
          ? {
              ...item,
              isDiscountApplied: !item.isDiscountApplied,
              actualPrice: inputValue,
              priceAfterDiscount: inputValue,
              discountPercentage: 0,
            }
          : item
      );
      updateTransactionData(
        {
          ...transactionData,
          inventory: updatedCartItems,
        },
        SALE_TRANSACTION_ACTIVITY_TYPES.ITEM_PRICE_CHANGE
      );
    }
  };

  const handleParkSaleClick = async () => {
    if (transactionData.inventory?.length > 0) {
      activateSpinner(transactionConstants.MAKE_TRANSACTION);
      const transactionId =
        transactionData?.id || (await transactionActions.getTransactionId());
      deactivateSpinner(transactionConstants.MAKE_TRANSACTION);
      handleNewParkSale(
        { ...transactionData, id: transactionId },
        currentStore,
        outlet
      );
      resetTransationPage();
    } else {
      customToast(toastMessages.PLEASE_SELECT_SOME_PRODUCT, toastType.ERROR);
    }
  };
  const selectedCustomer = (selectedCustomerFlag) => {
    setSelectedCustomerFlag(selectedCustomerFlag);
  };

  // Function to scroll the container to the bottom
  const scrollToBottom = () => {
    if (cartRef?.current) {
      cartRef.current.scrollTop = cartRef?.current?.scrollHeight;
    }
  };

  const handleResetCart = () => {
    if (transactionData?.inventory?.length) {
      toggleConfirmationModal();
    }
  };

  const ScrollLoadingConponent = () => {
    return (
      <div className="w-100 py-2 d-flex justify-content-center align-items-center">
        Loading...
      </div>
    );
  };

  //------toggle single item discount
  const handleSingleItemDiscountField = (skuNumber) => {
    const updatedCartItems = transactionData?.inventory?.map((item) =>
      item?.sku === skuNumber
        ? {
            ...item,
            isSingleItemDiscountField: !item?.isSingleItemDiscountField,
          }
        : item
    );
    updateTransactionData(
      { ...transactionData, inventory: updatedCartItems },
      SALE_TRANSACTION_ACTIVITY_TYPES.APPLY_SINGLE_ITEM_DISCOUNT
    );
  };

  const handleDiscountType = (skuNumber) => {
    const updatedCartItems = transactionData?.inventory?.map((item) => {
      if (item?.sku === skuNumber) {
        return {
          ...item,
          isToggleDiscount: !item?.isToggleDiscount,
          priceAfterDiscount: item?.actualPrice,
          discountPercentage: 0,
        };
      } else {
        return item;
      }
    });

    updateTransactionData(
      { ...transactionData, inventory: updatedCartItems },
      SALE_TRANSACTION_ACTIVITY_TYPES.APPLY_SINGLE_ITEM_DISCOUNT
    );
  };

  useEffect(() => {
    if (searchProductType?.label && search) {
      updateGridState({
        ...saleProductState,
        filters: prepareFilterForPaginationCall(
          searchProductType,
          searchProductCategory,
          searchProductSubCategory
        ),
      });
    } // eslint-disable-next-line
  }, [searchProductType, searchProductCategory, searchProductSubCategory]);

  useEffect(() => {
    if (allCustomers && allCustomers.length > 0 && selectedCustomerFlag) {
      updateTransactionData(
        {
          ...transactionData,
          customer: {
            ...allCustomers[0],
            name: allCustomers[0].firstName + " " + allCustomers[0].lastName,
          },
        },
        SALE_TRANSACTION_ACTIVITY_TYPES.UPDATE_CUSTOMER
      );
    }
    setSelectedCustomerFlag(false);
    // eslint-disable-next-line
  }, [allCustomers]);

  useEffect(() => {
    const { initialTotalCogs, initialTotalStockPrice } =
      handleCalculateTotalCogsAndStockPrice(transactionData.inventory, false);
    let newSubTotal = transactionData.inventory?.reduce((totalPrice, item) => {
      return (
        totalPrice + parseToNumber(item.priceAfterDiscount) * item.quantity
      );
    }, 0);

    let discountAmount = transactionData.discountMethod
      ? newSubTotal * (transactionData.discountedValue / 100)
      : transactionData.discountedValue;

    let amountAfterDiscount = newSubTotal;

    if (transactionData.isDiscountField) {
      amountAfterDiscount = newSubTotal - discountAmount;
    }
    let taxAmount = roundNumber(
      amountAfterDiscount *
        (transactionData.isTaxApplied ? currentStore?.defaultTax / 100 : 0)
    );
    let amountAfterTaxApplied = taxAmount + amountAfterDiscount;

    updateTransactionData(
      {
        ...transactionData,
        tax: taxAmount,
        subTotal: newSubTotal,
        discount: discountAmount,
        totalAmountToPay: parseToDecimalNumber(amountAfterTaxApplied),
        currentAmountToPay: parseToDecimalNumber(
          transactionData.currentAmountToPay > 0
            ? amountAfterTaxApplied -
                getSumOfPaymentHistory(transactionData.paymentHistory)
            : amountAfterTaxApplied
        ),
        initialTotalCogs: initialTotalCogs,
        initialTotalStockPrice: initialTotalStockPrice,
      },
      SALE_TRANSACTION_ACTIVITY_TYPES.AMOUNT_CALCULATION
    );

    // eslint-disable-next-line
  }, [
    transactionData.inventory,
    transactionData.isTaxApplied,
    transactionData.isDiscountField,
    transactionData.discountedValue,
  ]);

  useEffect(() => {
    setAddCustomerModal(isAddCustomerModal);
    // eslint-disable-next-line
  }, [isAddCustomerModal]);

  useEffect(() => {
    if (transactionData?.TransactionType === transactionConstants.PROCESSED) {
      updateTransactionData(
        transactionDefaultData,
        SALE_TRANSACTION_ACTIVITY_TYPES.RESET_TRANSACTION
      );
    }
    // handle component unmounting
    cartItemsCount.current = transactionData?.inventory?.length ?? 0;
    // eslint-disable-next-line
  }, [transactionData]);

  const clearSearchText = () => {
    setSearch("");
    setProductsList([]);
  };

  //------ handle customer edit
  const handleCustomerEditClick = () => {
    const customer = allCustomers?.find(
      (customer) => customer.id === transactionData?.customer?.id
    );
    if (customer?.id) {
      setCustomerToEdit(customer);
      toggleAddCustomerModal(true);
    }
  };

  // // handle component unmounting
  // useEffect(() => {
  //   return () => {
  //     if (cartItemsCount.current > 0) {
  //       handleDraftModal(transactionConstants.TRANSACTION_DRAFT_MODAL);
  //     }
  //   }; // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    scrollToBottom();
  }, [transactionData?.inventory?.length]);

  useEffect(() => {
    const filteredtedInventory = allInventory.inventory?.map((option) => ({
      id: option.id,
      upc: option.upc,
      sku: option.sku,
      epid: option.epid,
      tags: option.tags,
      subcategory: option.subcategory,
      value: option.product_id,
      type: option.price.type,
      itemName: option.product_name,
      quantity: option.price.quantity,
      originalQuantity: option.price.quantity,
      productType: option.productType,
      tcgPlayerUrl: option.tcgPlayerUrl,
      category_name: option.category_name,
      price: option.price.unit_sell_price,
      cogs: option.price.unit_purchase_price,
      serialNumber: option?.serialNumber || EMPTY_STRING,
      total: option.price.unit_sell_price * option.price.quantity,
      label: `${option.product_id}/${option.category_name}/${option.product_name}/${option.price.type}`,
      trackQuantity:
        option?.trackQuantity === undefined || option?.trackQuantity === null
          ? true
          : option?.trackQuantity,
    }));
    if (search) {
      if (
        filteredtedInventory?.length === 1 &&
        (filteredtedInventory[0].sku.includes(search) ||
          filteredtedInventory[0]?.upc === search ||
          filteredtedInventory[0]?.serialNumber === search)
      ) {
        handleProductCardClick(filteredtedInventory[0]);
        setSearch(globalConstants.EMPTY_STRING);
        setProductsList([]);
      } else {
        if (filteredtedInventory?.length) {
          const inventory = transactionData?.inventory || [];
          handleUpdateProductListItemsQuantity(
            filteredtedInventory,
            inventory,
            setProductsList
          );
        } else {
          if (isScan) {
            setSearch("");
            setIsScan(false);
          }
          customToast(toastMessages.ITEM_NOT_FOUND, toastType.ERROR);
        }
      }
      setSaleProductState({
        ...saleProductState,
        pageIndex: 1,
        totalRecords: allInventory.totalRecords,
      });
      setIsSearchLoading(false);
    } // eslint-disable-next-line
  }, [allInventory]);

  useEffect(() => {
    return () => {
      emptyAllInventory();
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getSumOfPaymentHistory(transactionData?.paymentHistory) > 0) {
      navigate(
        createCompanyDynamicUrl(
          routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION_PAYMENT
        ),
        {
          state: {
            currentStore: currentStore,
          },
        }
      );
    }
    // eslint-disable-next-line
  }, []);

  //-------set default search product type
  useEffect(() => {
    setSearchProductType(
      getDefaultFilterType(customTypes, PRODUCT_TYPES_ENUMS.ALL)
    );
  }, [customTypes]);

  //------return new transaction component
  return (
    <>
      <div>
        <BarcodeReader onError={handleError} onScan={handleScan} />
      </div>
      <Row className="m-0">
        <Col md={7}>
          <Topbar
            isNewSale={true}
            title="New Transaction"
            sellTradeToggleButton={true}
          />
          <Row className="m-0">
            <Col
              md={12}
              className="d-flex justify-content-between align-items-center "
            >
              <span className="transaction-heading">Add Items</span>
              <div className="d-flex justify-content-center align-items-center gap-2">
                <Button
                  IconImage={AddIcon}
                  handleClick={handleGiftCardClick}
                  label={buttonNameConstants.GIFT_CARD}
                  buttonType={buttonTypeConstants.GHOST_BUTTON}
                />
                <Button
                  IconImage={AddIcon}
                  label={buttonNameConstants.CUSTOM_ITEM}
                  handleClick={handleCustomItemClick}
                />
              </div>
            </Col>
            <Col md={12} className="mt-2">
              <SearchField
                value={search}
                IconImage={SearchIcon}
                BlueBoxIcon={BlueBoxIcon}
                placeHolder="Search Item Name, UPC or SKU"
                onChange={(e) => handleSearchFieldChange(e.target.value, false)}
                CrossIcon={search ? CrossIcon : ""}
                clearSearchText={clearSearchText}
                LoadingIndicator={isSearchLoading && LoadingIndicator}
              />
            </Col>
            <Col md={10} className="mt-2 d-flex align-items-center gap-2">
              <FilterDropdowns
                customTypes={customTypes}
                customCategories={customCategories}
                customSubCategories={customSubCategories}
                setSearchProductType={setSearchProductType}
                setSearchProductCategory={setSearchProductCategory}
                setSearchProductSubCategory={setSearchProductSubCategory}
                defaultProductType={PRODUCT_TYPES_ENUMS.ALL}
              />
            </Col>
            {productsList?.length > 0 && (
              <Col md={12} className="mt-0">
                <div
                  id="scrollableDiv"
                  className=" searched-products-wrapper container-scrollbar"
                >
                  <InfiniteScroll
                    dataLength={productsList.length}
                    next={fetchMoreData}
                    hasMore={allInventory?.pageSize < allInventory.totalRecords}
                    loader={<ScrollLoadingConponent />}
                    scrollableTarget="scrollableDiv"
                  >
                    {productsList?.map((product, index) => (
                      <ProductDetailCard
                        key={index}
                        isSaleOutOfStock={
                          currentStore[
                            toggleSwitchButtonConstants.IS_SALE_OUT_OF_STOCK
                          ]
                        }
                        price={product.price}
                        productObject={product}
                        skuNumber={product.sku}
                        isNewTransaction={true}
                        productName={product.itemName}
                        productType={product.productType}
                        handleCardClick={handleProductCardClick}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col md={5} className="sale-cart-section px-3 py-3 overflow-hidden">
          <IsRegisterClosedOrNotConfigured>
            <Row className="m-0 px-0 h-100 d-flex flex-column align-items-center">
              <Row className="m-0 px-0">
                <Col
                  md={12}
                  className="d-flex justify-content-between align-items-center px-0"
                >
                  <span className="transaction-heading">Cart</span>
                  <div className=" d-flex justify-content-center align-items-center">
                    <Button
                      IconImage={ResetCartIcon}
                      label={buttonNameConstants.RESET}
                      buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                      handleClick={handleResetCart}
                    />
                    <Button
                      IconImage={ParkSaleIcon}
                      label={buttonNameConstants.PARK_SALE}
                      buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                      handleClick={handleParkSaleClick}
                    />
                    <Button
                      IconImage={RetriveSaleIcon}
                      label={buttonNameConstants.RETRIVE_SALE}
                      buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_GHOST}
                      handleClick={() =>
                        navigate(
                          createCompanyDynamicUrl(
                            routesPathConstants.SELL_AND_TRADE_DRAFTS
                          )
                        )
                      }
                      className="pe-1"
                    />
                  </div>
                </Col>
                {transactionData?.customer?.id ? (
                  <Col md={12} className="my-2 rounded-2 py-2 bg-white">
                    <SelectedCustomerCard
                      customer={transactionData?.customer}
                      isStoreCredit={true}
                      isDeleteEditOptions={
                        !parseToNumber(
                          transactionData?.paymentHistory?.totalPaidAmount[
                            transactionConstants.STORE_CREDIT
                          ]
                        ) > 0
                      }
                      handleCustomerDeleteClick={() => {
                        updateTransactionData(
                          {
                            ...transactionData,
                            customer: "",
                          },
                          SALE_TRANSACTION_ACTIVITY_TYPES.DELETE_CUSTOMER
                        );
                      }}
                      handleAddCustomerClick={handleAddCustomerClick}
                      handleCustomerEditClick={handleCustomerEditClick}
                    />
                  </Col>
                ) : (
                  <Col md={12} className="mt-1 px-0">
                    <CustomerDropdown
                      value={
                        transactionData?.customer?.id
                          ? {
                              value: stringifyObject(transactionData?.customer),
                              label: (
                                <div className="d-flex align-items-center gap-2 ">
                                  <span>
                                    <img
                                      src={CustomerImage}
                                      className="new-sale-customer-image"
                                      alt="customer"
                                    />
                                  </span>
                                  {`${transactionData?.customer.firstName} ${transactionData?.customer.lastName}`}
                                </div>
                              ),
                            }
                          : ""
                      }
                      isGuestCustomers={true}
                      dropdownIndicator={false}
                      handleAddCustomerClick={handleAddCustomerClick}
                      SelectIconImage={categoryDropdownConstants.SEARCH_ICON}
                      handleChange={(selectedOption) => {
                        updateTransactionData(
                          {
                            ...transactionData,
                            customer: selectedOption?.value
                              ? parseJsonObject(selectedOption?.value)
                              : globalConstants.EMPTY_STRING,
                          },
                          SALE_TRANSACTION_ACTIVITY_TYPES.ADD_CUSTOMER
                        );
                      }}
                      selectedCustomerFlag={selectedCustomerFlag}
                    />
                  </Col>
                )}
              </Row>
              <Col md={12} className="mt-1 px-0">
                <div
                  ref={cartRef}
                  className={`cart-cards-wrapper container-scrollbar ${
                    transactionData.isDiscountField &&
                    transactionData.isNoteField &&
                    transactionData?.customer?.id
                      ? "three-fields-open"
                      : (transactionData.isDiscountField &&
                          transactionData.isNoteField) ||
                        (transactionData.isDiscountField &&
                          transactionData?.customer?.id) ||
                        (transactionData.isNoteField &&
                          transactionData?.customer?.id)
                      ? "both-fields-open"
                      : transactionData.isDiscountField ||
                        transactionData.isNoteField ||
                        transactionData?.customer?.id
                      ? "one-field-open"
                      : ""
                  }`}
                >
                  {transactionData.inventory?.map((cartItem, index) => (
                    <ProductCartCard
                      key={index}
                      max={cartItem.max}
                      isNewTransaction={true}
                      productObject={cartItem}
                      skuNumber={cartItem.sku}
                      quantity={cartItem.quantity}
                      category={cartItem.category}
                      productName={cartItem.itemName}
                      actualPrice={cartItem.actualPrice}
                      handlePriceChange={handlePriceChange}
                      price={cartItem.priceAfterDiscount}
                      handleDeleteCartItem={handleDeleteCartItem}
                      handleQuantityChange={handleQuantityChange}
                      handleDiscountChange={handleDiscountChange}
                      isDiscountApplied={cartItem.isDiscountApplied}
                      discountPercentage={cartItem.discountPercentage}
                      handleSingleItemDiscountField={
                        handleSingleItemDiscountField
                      }
                      handleDiscountType={handleDiscountType}
                      handleDiscountValueChange={
                        handleCustomDiscountValueChange
                      }
                    />
                  ))}
                </div>
              </Col>
              <Row className="m-0 px-0 mt-auto">
                <Col
                  md={12}
                  className="d-flex justify-content-between align-items-center mt-2 px-0"
                >
                  <span className="opacity-50">ADD</span>
                  <div className="d-flex justify-content-center align-items-center gap-3">
                    <button
                      type="button"
                      className={`sale-extra-button ${
                        transactionData.isDiscountField && "active-link"
                      }`}
                      onClick={() => {
                        updateTransactionData(
                          {
                            ...transactionData,
                            isDiscountField: true,
                          },
                          SALE_TRANSACTION_ACTIVITY_TYPES.APPLY_DISCOUNT
                        );
                      }}
                    >
                      Discount
                    </button>
                    <button
                      type="button"
                      className={`sale-extra-button ${
                        transactionData.isNoteField && "active-link"
                      }`}
                      onClick={() => {
                        updateTransactionData(
                          {
                            ...transactionData,
                            isNoteField: true,
                          },
                          SALE_TRANSACTION_ACTIVITY_TYPES.TRIGGER_NOTE_FIELD
                        );
                      }}
                    >
                      Note
                    </button>
                  </div>
                </Col>
                {transactionData.isDiscountField && (
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-center mt-2 px-0"
                  >
                    <span>Discount</span>
                    <div className="d-flex justify-content-center align-items-center gap-2 discount-method">
                      $
                      <SwitchButton
                        id="applied-discount-method"
                        onSwitchChange={handleDiscountMethod}
                        isSwitchOn={transactionData.discountMethod}
                      />
                      %
                    </div>
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <SimpleNumberField
                        className="discount-number-field"
                        value={transactionData.discountedValue}
                        onChange={handleDiscountValueChange}
                        maxValue={
                          transactionData.discountMethod
                            ? 100
                            : transactionData?.subTotal
                        }
                      />
                      <button
                        type="button"
                        className="cart-card-delete-button"
                        onClick={() => {
                          updateTransactionData(
                            {
                              ...transactionData,
                              isDiscountField: false,
                              discountMethod: false,
                              discountedValue: 0,
                            },
                            SALE_TRANSACTION_ACTIVITY_TYPES.REMOVE_DISCOUNT
                          );
                        }}
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  </Col>
                )}
                {transactionData.isNoteField && (
                  <Col
                    md={12}
                    className="mt-2 d-flex justify-content-between px-0"
                  >
                    <textarea
                      value={transactionData.note}
                      onChange={(e) => {
                        updateTransactionData(
                          {
                            ...transactionData,
                            note: e.target.value,
                          },
                          SALE_TRANSACTION_ACTIVITY_TYPES.TRIGGER_NOTE_FIELD
                        );
                      }}
                      className="input-textfield text-area-field note-area-field"
                    />
                    <button
                      type="button"
                      className="cart-card-delete-button"
                      onClick={() => {
                        updateTransactionData(
                          {
                            ...transactionData,
                            isNoteField: false,
                            note: globalConstants.EMPTY_STRING,
                          },
                          SALE_TRANSACTION_ACTIVITY_TYPES.TRIGGER_NOTE_FIELD
                        );
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  </Col>
                )}
                <Col
                  md={12}
                  className="d-flex justify-content-between align-items-center cart-details-data mt-2 px-0"
                >
                  <span>
                    Subtotal ({transactionData.inventory?.length} items)
                  </span>
                  <span>
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      transactionData.subTotal || 0
                    )}
                  </span>
                </Col>
                {transactionData.isDiscountField && (
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-center cart-details-data mt-1 px-0"
                  >
                    <span>Discount</span>
                    <span>
                      $
                      {parseToThousandSeperatorDecimalNumber(
                        transactionData?.discount
                      )}
                    </span>
                  </Col>
                )}
                <Col
                  md={12}
                  className="d-flex justify-content-between align-items-center cart-details-data mt-1 px-0"
                >
                  <span className="d-flex align-items-center gap-2">
                    Tax ({currentStore?.defaultTax || 0}%)
                    <input
                      className="checkbox-field"
                      type="checkbox"
                      checked={transactionData.isTaxApplied}
                      onChange={() => {
                        updateTransactionData(
                          {
                            ...transactionData,
                            isTaxApplied: !transactionData.isTaxApplied,
                          },
                          SALE_TRANSACTION_ACTIVITY_TYPES.TRIGGER_TAX_BUTTON
                        );
                      }}
                    />
                  </span>
                  <span>
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      transactionData?.tax || 0
                    )}
                  </span>
                </Col>
                <Col md={12} className="px-0">
                  <button
                    type="button"
                    className="d-flex justify-content-between align-items-center w-100 mt-3 main-purple-button"
                    onClick={() => handlePayClick()}
                  >
                    <div className="d-flex align-items-center gap-1">
                      <CashIcon />
                      <span>Pay</span>
                      <span className="ms-2">
                        {getCountOfTotalItems(
                          transactionData?.inventory || 0,
                          "quantity"
                        )}{" "}
                        items
                      </span>
                    </div>
                    <span>
                      $
                      {parseToThousandSeperatorDecimalNumber(
                        transactionData.totalAmountToPay || 0
                      )}
                    </span>
                  </button>
                </Col>
              </Row>
            </Row>
          </IsRegisterClosedOrNotConfigured>
        </Col>
      </Row>
      <AddCustomItem
        customTypes={customTypes}
        addItemInCart={addItemInCart}
        customItemModal={customItemModal}
        customCategories={customCategories}
        customSubCategories={customSubCategories}
        toggleCustomItemModal={toggleCustomItemModal}
      />
      <AddGiftCard
        giftCardModal={giftCardModal}
        addItemInCart={addItemInCart}
        toggleGiftCardModal={toggleGiftCardModal}
      />
      {addCustomerModal && (
        <AddNewCustomerModal
          customerToEdit={customerToEdit}
          selectedCustomer={selectedCustomer}
          addCustomerModal={addCustomerModal}
        />
      )}
      <ConfirmationModal
        message={toastMessages.RESET_CART_WARNING}
        isConfirmationModal={isConfirmationModal}
        handleNoButtonClick={handleConfirmNoButtonClick}
        handleYesButtonClick={handleConfirmYesButtonClick}
        toggleConfirmationModal={toggleConfirmationModal}
      />
    </>
  );
}; //-------NewTransaction component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  outlet: state.outlet,
  customer: state.customer,
  inventory: state.inventory,
  user: state.authentication.user,
  tradeDiscount: state.tradeDiscount,
  company: state.authentication.company,
  currentStore: state.store.currentStore,
  itemOrganization: state.itemOrganization,
  transactionData: state.transaction.transactionData,
  isAddCustomerModal: state.transaction.isAddCustomerModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getPaginatedInventory: (storeId, gridState) =>
    dispatch(inventoryActions.getPaginatedInventory(storeId, gridState)),
  setTransactionData: (data, storeId) =>
    dispatch(transactionActions.setTransactionData(data, storeId)),
  toggleAddCustomerModal: (value) =>
    dispatch(transactionActions.toggleAddCustomerModal(value)),
  handleNewParkSale: (data, currentStore, outlet) =>
    dispatch(transactionActions.handleNewParkSale(data, currentStore, outlet)),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  handleDraftModal: (isToggle) =>
    dispatch({
      type: transactionConstants.SET_DRAFT_CONFIRMATION,
      data: isToggle,
    }),
  emptyAllInventory: () => dispatch(inventoryActions.emptyAllInventory()),
});

//-------Export transaction Component
export default connect(mapStateToProps, mapDispatchToProps)(NewTransaction);
