import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import React, { useEffect } from "react";

import OpenRegister from "./OpenRegister/OpenRegister";
import { outletActions } from "../../../redux/actions";
import { routesPathConstants } from "../../../constants";
import CloseRegister from "./CloseRegister/CloseRegister";
import Topbar from "../../../shared/components/topbar/Topbar";
import { createCompanyDynamicUrl } from "../../../shared/utility";
import SessionDetail from "../../../shared/components/sessionSummaryTable/SessionDetail";
import SessionSummaryTable from "../../../shared/components/sessionSummaryTable/SessionSummaryTable";

const OpenCloseRegister = (props) => {
  const navigate = useNavigate();

  //------- props
  const {
    user,
    outlet,
    currentStore,
    getSessionHistory,
    getLastSessionDetails,
    emptyCurrentAndLastSession,
  } = props;

  //-------reducer
  const {
    isTillOpen,
    lastSession,
    currentOutlet,
    lastSessionId,
    currentSessionId,
    singleSessionHistory,
  } = outlet;
  //------- useEffect
  useEffect(() => {
    if (currentStore && currentOutlet && !isTillOpen && lastSessionId) {
      getLastSessionDetails(currentStore?.id, currentOutlet?.id, lastSessionId);
    }
  }, [currentStore, currentOutlet, lastSessionId]);

  useEffect(() => {
    if (isTillOpen) {
      if (currentOutlet?.register?.openBy?.userId !== user?.id) {
        navigate(createCompanyDynamicUrl(routesPathConstants.CASH_MANAGEMENT));
      }
    }
  }, [currentOutlet]);

  useEffect(() => {
    if (currentStore?.id && lastSession?.id) {
      getSessionHistory(currentStore?.id, lastSession?.id);
    }
  }, [lastSession]);

  useEffect(() => {
    return () => {
      emptyCurrentAndLastSession();
    };
  }, []);

  return (
    <>
      <Topbar
        title={
          currentOutlet?.register?.currentSessionId
            ? "Close Register"
            : "Open Register"
        }
      ></Topbar>
      {isTillOpen ? (
        <Row className="m-0 user-info-wrapper register-screen-wrapper container-scrollbar">
          <Col md={12}></Col>
          <CloseRegister />
        </Row>
      ) : (
        <Row className="m-0 user-info-wrapper register-screen-wrapper container-scrollbar">
          <Col md={12} className="px-0">
            <Row className="m-0">
              <OpenRegister />
            </Row>
            {lastSessionId ? (
              <>
                <hr className="my-5" />
                <Row className="m-0">
                  <Col md={12}>
                    <span className="last-closure-heading">
                      Last Closure Details
                    </span>
                  </Col>
                  <Col md={12} className="py-2">
                    <SessionDetail
                      session={lastSession}
                      isCloseSessionDetails={true}
                    />
                  </Col>
                  <hr className="my-3" />
                  <Col md={12} className="my-4">
                    <SessionSummaryTable
                      session={lastSession}
                      tableHeading={"Last Session Summary"}
                      sessionDetailHistory={singleSessionHistory}
                    />
                  </Col>
                  <Col md={12} className=" mb-2">
                    <span className="last-closure-heading">Closure Notes:</span>
                  </Col>
                  <Col md={6}>
                    <p>{lastSession?.closeNote}</p>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  user: state.authentication.user,
  currentStore: state.store.currentStore,
  outlet: state.outlet,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getLastSessionDetails: (storeId, outletId, sessionId) =>
    dispatch(outletActions.getLastSessionDetails(storeId, outletId, sessionId)),
  emptyCurrentAndLastSession: () =>
    dispatch(outletActions.emptyCurrentAndLastSession()),
  getSessionHistory: (storeId, sessionId) =>
    dispatch(outletActions.getSessionHistory(storeId, sessionId)),
});

//-------Export Open CLose Register Component
export default connect(mapStateToProps, mapDispatchToProps)(OpenCloseRegister);
