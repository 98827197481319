import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { outletActions } from "../../../redux/actions";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import AddRemoveCashModal from "./AddRemoveCashModal/AddRemoveCashModal";
import TableCustomHeader from "../../../shared/components/table/TableCustomHeader";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import ServerPaginationTable from "../../../shared/components/agGrid/ServerPaginationTable";
import {
  outletConstants,
  defaultGridState,
  buttonNameConstants,
  buttonTypeConstants,
  EMPTY_STRING,
  routesPathConstants,
} from "../../../constants";
import {
  getRowId,
  getRowStyle,
  isSpinnerEnabled,
  getReceiptPrintDate,
  parseToThousandSeperatorDecimalNumber,
  createCompanyDynamicUrl,
  isSessionOpenedByCurrentUser,
  isCashOut,
} from "../../../shared/utility";

const CashManagement = (props) => {
  //-------Props
  const {
    user,
    outlet,
    addCash,
    spinnerArray,
    currentStore,
    getCashManagementDetails,
  } = props;
  //-------UseRef
  const gridRef = useRef();

  //------- Reducer

  const {
    isTillOpen,
    currentOutlet,
    currentSessionId,
    cashManangementDetails,
  } = outlet;

  //-------useStates
  const [isAddRemoveCashModal, setIsAddRemoveCashModal] = useState(false);
  const [gridState, setGridState] = useState(defaultGridState);

  //-------toggleIsAddRemoveCashModal
  const toggleIsAddRemoveCashModal = (value) => {
    setIsAddRemoveCashModal(value);
  };

  //-------update gridState
  const updateGridState = (gridState) => {
    getCashManagementDetails(
      currentStore?.id,
      currentOutlet?.id,
      currentSessionId,
      gridState
    );
    setGridState(gridState);
  };

  const navigate = useNavigate();

  //-------Coldefs
  const colDefs = useMemo(
    () => [
      {
        headerName: "Time",
        field: "createdOn",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.createdAt ? (
            <span className="inventory-cell-max-size ">
              <TableCustomHeader
                id={`created-on-in-array-${params?.data?.id}`}
                label={
                  <span className="inventory-cell-max-size">
                    {getReceiptPrintDate(params.data.createdAt)}
                  </span>
                }
                tooltopText={
                  <p className="text-start fw-normal m-0">
                    {getReceiptPrintDate(params.data.createdAt)}
                  </p>
                }
                className="sku-tooltip"
              />
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "User",
        field: "user.name",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.user?.name ? (
            <span className="inventory-cell-max-size ">
              {params?.data?.user?.name}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Action",
        field: "type",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.type ? (
            <span className="inventory-cell-max-size ">
              {params?.data?.type}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Notes",
        field: "note",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.note || params?.data?.note === EMPTY_STRING ? (
            <span className="inventory-cell-max-size ">
              <TableCustomHeader
                id={`created-on-in-array-${params?.data?.id}-note`}
                label={
                  <span className="inventory-cell-max-size">
                    {params.data.note}
                  </span>
                }
                tooltopText={
                  <p className="text-start fw-normal m-0">{params.data.note}</p>
                }
                className="sku-tooltip"
              />
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Amount",
        field: "amount",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.amount || params?.data?.amount === 0 ? (
            <span
              className={`inventory-cell-max-size ${
                isCashOut(params?.data?.type) ? "text-danger" : "text-primary"
              }`}
            >
              {isCashOut(params?.data?.type) ? "-" : ""}$
              {parseToThousandSeperatorDecimalNumber(params.data.amount)}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
    ],
    // eslint-disable-next-line
    [cashManangementDetails]
  );

  //-------Skeleton Array
  const skeletonArray = Array(10).fill({});

  //-------Grid Data
  const gridData = useMemo(() => {
    setGridState({
      ...gridState,
      pageIndex:
        cashManangementDetails?.pageNumber || defaultGridState?.pageIndex,
      totalRecords:
        cashManangementDetails?.totalRecords || defaultGridState?.totalRecords,
      pageEndRecordNumber:
        cashManangementDetails?.pageEndRecordNumber ||
        defaultGridState?.pageEndRecordNumber,
      pageStartRecordNumber:
        cashManangementDetails?.pageStartRecordNumber ||
        defaultGridState?.pageStartRecordNumber,
    });
    return cashManangementDetails?.cash || [];
  }, [cashManangementDetails]);

  //------- UseEffect
  useEffect(() => {
    if (currentStore && currentOutlet && isTillOpen) {
      getCashManagementDetails(
        currentStore?.id,
        currentOutlet?.id,
        currentSessionId,
        gridState
      );
    }
  }, [currentStore, currentOutlet]);

  //------- useEffect
  useEffect(() => {
    if (isTillOpen) {
      if (currentOutlet?.register?.openBy?.userId !== user?.id) {
        navigate(createCompanyDynamicUrl(routesPathConstants.CASH_MANAGEMENT));
      }
    } else {
      navigate(createCompanyDynamicUrl(routesPathConstants.CASH_MANAGEMENT));
    }
  }, [outlet, user]);

  return (
    <>
      <Topbar title="Cash Management"></Topbar>
      <Row className="m-0">
        <Col
          md={12}
          className="d-flex justify-content-end align-items-center gap-2"
        >
          <Button
            label={buttonNameConstants.REMOVE_CASH}
            className=""
            buttonType={buttonTypeConstants.RED_BUTTON_FILL}
            handleClick={() =>
              toggleIsAddRemoveCashModal(outletConstants.REMOVE_CASH_MODAL)
            }
          />
          <Button
            label={buttonNameConstants.ADD_CASH}
            className=""
            buttonType={buttonTypeConstants.BLUE_BUTTON}
            handleClick={() =>
              toggleIsAddRemoveCashModal(outletConstants.ADD_CASH_MODAL)
            }
          />
        </Col>
      </Row>
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3 p-0">
        <ServerPaginationTable
          colDefs={colDefs}
          gridRef={gridRef}
          gridData={
            isSpinnerEnabled(spinnerArray, [
              outletConstants.GET_CASH_MANAGEMENT_DETAILS,
            ])
              ? skeletonArray
              : gridData
          }
          gridState={gridState}
          getRowId={getRowId}
          getRowStyle={getRowStyle}
          updateGridState={updateGridState}
          paginationPageSize={gridState.pageSize}
          pageSizeOptions={gridState.pageSizeOptions}
          isDisablePaginationActions={isSpinnerEnabled(spinnerArray, [
            outletConstants.GET_CASH_MANAGEMENT_DETAILS,
          ])}
          tableHeight={`calc(100vh - 200px)`}
        />
      </Row>
      <AddRemoveCashModal
        user={user}
        addCash={addCash}
        currentStore={currentStore}
        currentOutlet={currentOutlet}
        currentSessionId={currentSessionId}
        isAddRemoveCashModal={isAddRemoveCashModal}
        toggleIsAddRemoveCashModal={toggleIsAddRemoveCashModal}
      />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  outlet: state.outlet,
  user: state.authentication.user,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getCashManagementDetails: (storeId, outletId, sessionId, gridState) =>
    dispatch(
      outletActions.getCashManagementDetails(
        storeId,
        outletId,
        sessionId,
        gridState
      )
    ),
  addCash: (data, message, gridState, handleHideModal) =>
    dispatch(outletActions.addCash(data, message, gridState, handleHideModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CashManagement);
