import React from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import { DollarIcon } from "../../../../assets/icons/iconsProvider";
import {
  buttonNameConstants,
  buttonTypeConstants,
  EMPTY_STRING,
  outletConstants,
} from "../../../../constants";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import { openRegisterValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import { outletActions } from "../../../../redux/actions";
import { isSpinnerEnabled } from "../../../../shared/utility";

const StartSelling = (props) => {
  //------- props
  const {
    openTill,
    formWidth = 5,
    closeRegister,
    currentStore,
    spinnerArray,
    currentOutlet,
    currentSessionId,
    setIsStartSellingFrom,
    isSessionOpen,
  } = props;
  //------- Form Initial Values
  const initialValues = {
    startingBalance: currentOutlet?.register?.defaultStartingBalance || 0,
    note: EMPTY_STRING,
  };

  //------- handle open till
  const handleOpeningTill = (values) => {
    const dataToSend = {
      id: currentSessionId,
      isOpen: isSessionOpen,
      isTillOpen: true,
      openNote: values.note,
      startingBalance: values.startingBalance,
      store: { id: currentStore?.id, name: currentStore?.storeName },
      outlet: { id: currentOutlet?.id, name: currentOutlet?.name },
    };
    openTill(dataToSend, currentOutlet?.macAddress);
  };

  //------- handle cancel Session
  const handleCancelSession = () => {
    const dataToSend = {
      id: currentSessionId,
      expectedBalance: 0,
      countedBalance: 0,
      differenceInBalance: 0,
      closeNote: "Session Cancelled",
      store: { id: currentStore?.id, name: currentStore?.storeName },
      outlet: { id: currentOutlet?.id, name: currentOutlet?.name },
    };
    closeRegister(dataToSend, currentOutlet?.macAddress, setIsStartSellingFrom);
  };

  return (
    <Col md={12} className="px-0">
      <Formik
        initialValues={initialValues}
        onSubmit={handleOpeningTill}
        validate={openRegisterValidationSchema}
      >
        {({ values }) => (
          <Form>
            <Row className="m-0">
              <Col md={formWidth} className="mx-auto">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <span className="open-register-heading mb-2">
                    Opening Till:
                  </span>
                  <p className="open-register-sub-heading mb-0">
                    Enter a Starting Balance to
                  </p>
                  <p className="open-register-sub-heading mb-0">
                    Start Selling
                  </p>
                </div>

                <Row className="m-0">
                  <Col md={12} className="mb-3 px-0">
                    <InputNumberField
                      label="Starting Balance"
                      name="startingBalance"
                      IconImage={DollarIcon}
                      isHandleKeyDown={true}
                    />
                  </Col>
                  <Col md={12} className="mb-3 px-0">
                    <InputTextField
                      label="Note (Optional)"
                      name="note"
                      placeHolder="Enter a note"
                      maxLength={500}
                    />
                  </Col>
                  <Col md={12} className="px-0 mt-2">
                    <Button
                      type="submit"
                      isDisabled={isSpinnerEnabled(spinnerArray, [
                        outletConstants.OPEN_REGISTER,
                        outletConstants.GET_OUTLET_BY_MAC_ADDRESS,
                      ])}
                      label={buttonNameConstants.CONTINUE}
                      className="w-100 open-register-button"
                    />
                  </Col>
                  <Col md={12} className="px-0 mt-2">
                    <Button
                      type="button"
                      isDisabled={isSpinnerEnabled(spinnerArray, [
                        outletConstants.OPEN_REGISTER,
                        outletConstants.GET_OUTLET_BY_MAC_ADDRESS,
                      ])}
                      label={buttonNameConstants.CANCEL}
                      className="w-100 open-register-button"
                      buttonType={buttonTypeConstants.GHOST_BUTTON}
                      handleClick={handleCancelSession}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Col>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  openTill: (data, macAddress) =>
    dispatch(outletActions.openTill(data, macAddress)),
  closeRegister: (data, macAddress, setIsStartSellingFrom) =>
    dispatch(
      outletActions.closeRegister(data, macAddress, setIsStartSellingFrom)
    ),
});

//-------Export Open Register Component
export default connect(mapStateToProps, mapDispatchToProps)(StartSelling);
