import moment from "moment";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce";
import React, { useEffect, useMemo, useRef, useState } from "react";

import AddInvoice from "./AddInvoice/AddInvoice";
import ViewInvoice from "./ViewInvoice/ViewInvoice";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import SearchField from "../../../shared/components/searchField/SearchField";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import ServerPaginationTable from "../../../shared/components/agGrid/ServerPaginationTable";
import ClickableTooltip from "../../../shared/components/toolTipComponent/ClickableTooltip";
import CategoriesDropdown from "../../../shared/components/selectDropdown/CategoriesDropdown";
import DashboardDatePicker from "../../../shared/components/datetimePickerDropdown/DashboardDatePicker";
import CustomCellRendererWithSkeleton from "../../../shared/components/agGrid/CustomCellRendererWithSkeleton";
import {
  systemActions,
  invoiceActions,
  inventoryActions,
} from "../../../redux/actions";
import {
  getRowId,
  formatDate,
  customToast,
  getRowStyle,
  isSpinnerEnabled,
  LoadingIndicator,
  getProductSubCategory,
  getTotalCogsOfBatchProducts,
  prepareFilterForInvoiceTable,
  handleSubmitAddBatchInventory,
  getTotalQuantityOfInvoiceBatch,
  getTotalStockPriceOfInvoiceBatch,
  parseToThousandSeperatorWholeNumber,
  parseToThousandSeperatorDecimalNumber,
  getInvoiceTotalCostOfGoods,
} from "../../../shared/utility";
import {
  AddIcon,
  EditIcon,
  ShowIcon,
  CrossIcon,
  DeleteIcon,
  SearchIcon,
  FulfillIcon,
  ActionDotsIcons,
} from "../../../assets/icons/iconsProvider";
import {
  toastType,
  EMPTY_STRING,
  skeletonArray,
  toastMessages,
  invoiceConstants,
  defaultGridState,
  buttonNameConstants,
  buttonTypeConstants,
  datepickerConstants,
  categoryDropdownConstants,
} from "../../../constants";

const Invoices = (props) => {
  //------- props
  const {
    invoices,
    spinnerArray,
    currentStore,
    deleteInvoice,
    getAllInvoices,
    fulfillInvoice,
    activateSpinner,
    deactivateSpinner,
    addBatchInventorySubmit,
    setInvoiceBatchInventory,
  } = props;

  //------useRef
  const gridRef = useRef();
  const tooltipRef = useRef();

  //------usestate
  const [searchText, setSearchText] = useState(EMPTY_STRING);
  const [gridState, setGridState] = useState(defaultGridState);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isAddInvoiceModal, setIsAddInvoiceModal] = useState(false);
  const [isViewInvoiceModal, setIsViewInvoiceModal] = useState(false);
  const [editInvoiceData, setEditInvoiceData] = useState(EMPTY_STRING);
  const [status, setStatus] = useState(invoiceConstants.STATUS_OPTIONS[0]);
  const [debouncedSearchText, setDebouncedSearchText] = useState(EMPTY_STRING);
  const [fulfilledInvoiceData, setFulfilledInvoiceData] =
    useState(EMPTY_STRING);
  const [timeFrame, setTimeFrame] = useState({
    start: "",
    end: "",
    duration: "daily",
  });

  //-------toggle Add Invoice Modal
  const toggleAddInvoiceModal = () => {
    setIsAddInvoiceModal(!isAddInvoiceModal);
  };

  //-------toggle View Invoice Modal
  const toggleViewInvoiceModal = (value) => {
    setIsViewInvoiceModal(value);
  };

  //-------handle View Invoice Modal
  const handleViewInvoiceModal = (params) => {
    if (params?.data) {
      setFulfilledInvoiceData(params?.data);
    }
    toggleViewInvoiceModal(true);
  };

  //-------Action Cell Renderer
  function actionCellRenderer(params) {
    if (params.data?.id) {
      return params?.data?.invoiceType === invoiceConstants.DRAFT ? (
        <div
          onMouseLeave={() => {
            tooltipRef.current?.close();
          }}
        >
          <>
            <ClickableTooltip
              anchorSelect={`#tool${params.data.id}`}
              openOnClick={true}
              tooltipRef={tooltipRef}
            >
              <Button
                label={buttonNameConstants.FULFILL_INVOICE}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={FulfillIcon}
                handleClick={() => handleFulfillInvoice(params, gridState)}
              />
              <Button
                className="w-100"
                IconImage={EditIcon}
                label={buttonNameConstants.EDIT}
                handleClick={() => handleEditInvoice(params)}
                buttonType={buttonTypeConstants.ACTION_BUTTON}
              />
              <Button
                className="w-100"
                IconImage={DeleteIcon}
                label={buttonNameConstants.DELETE}
                handleClick={() => handleDeleteInvoice(params)}
                buttonType={buttonTypeConstants.ACTION_BUTTON}
              />
            </ClickableTooltip>
            <button
              id={`tool${params.data.id}`}
              className="action-Button-Wrapper"
              onClick={() => {
                tooltipRef.current?.isOpen
                  ? tooltipRef.current?.close()
                  : tooltipRef.current?.open({
                      anchorSelect: `tool${params.data.id}`,
                    });
              }}
            >
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        </div>
      ) : params?.data?.invoiceType === invoiceConstants.FULFILLED ? (
        <div
          onMouseLeave={() => {
            tooltipRef.current?.close();
          }}
        >
          <>
            <ClickableTooltip
              anchorSelect={`#tool${params.data.id}`}
              openOnClick={true}
              tooltipRef={tooltipRef}
            >
              <Button
                className="w-100"
                IconImage={ShowIcon}
                label={buttonNameConstants.VIEW_INVOICE}
                handleClick={() => handleViewInvoiceModal(params)}
                buttonType={buttonTypeConstants.ACTION_BUTTON}
              />
            </ClickableTooltip>
            <button
              id={`tool${params.data.id}`}
              className="action-Button-Wrapper"
              onClick={() => {
                tooltipRef.current?.isOpen
                  ? tooltipRef.current?.close()
                  : tooltipRef.current?.open({
                      anchorSelect: `tool${params.data.id}`,
                    });
              }}
            >
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        </div>
      ) : (
        ""
      );
    } else {
      return <DropdownSkeleton width="5em" height="1.5em" />;
    }
  }

  //-------Define Columns for Invoices Table
  const invoiceColumns = useMemo(
    () => [
      {
        headerName: "Date",
        field: "date",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={`${formatDate(params.data.invoiceDate)}`}
          />
        ),
      },
      {
        headerName: "Vendor",
        field: "vendor",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={`${params.data?.vendor?.name}`}
          />
        ),
      },
      {
        headerName: "Invoice Id",
        field: "invoiceId",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={`${params.data.invoiceId}`}
          />
        ),
      },
      {
        headerName: "Status",
        field: "invoiceType",
        cellRenderer: (params) => {
          const data = (
            <span className="inventory-cell-max-size ">
              {params?.data?.invoiceType === invoiceConstants.DRAFT ? (
                <span className="draft-invoice-cell">Draft</span>
              ) : params?.data?.invoiceType === invoiceConstants.FULFILLED ? (
                <span className="fulfilled-invoice-cell">Fulfilled</span>
              ) : (
                ""
              )}
            </span>
          );
          return (
            <CustomCellRendererWithSkeleton
              params={params}
              cellComponent={data}
            />
          );
        },
      },
      {
        headerTooltip: "Total Quantity",
        headerName: "Total Q...",
        field: "totalQuantity",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={`${parseToThousandSeperatorWholeNumber(
              getTotalQuantityOfInvoiceBatch(
                params.data?.inventory || [],
                params.data?.invoiceType
              )
            )}`}
          />
        ),
      },
      {
        headerTooltip: "Total Cost",
        headerName: "Total C...",
        field: "totalCost",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={`$${parseToThousandSeperatorDecimalNumber(
              getInvoiceTotalCostOfGoods(
                params.data?.inventory || [],
                params.data?.invoiceType
              )
            )}`}
          />
        ),
      },
      {
        headerTooltip: "Total Stock Price",
        headerName: "Total S...",
        field: "totalStockPrice",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={`$${parseToThousandSeperatorDecimalNumber(
              getTotalStockPriceOfInvoiceBatch(
                params.data?.inventory || [],
                params.data?.invoiceType
              )
            )}`}
          />
        ),
      },
      {
        headerName: "Notes",
        field: "note",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={`${params.data.note}`}
          />
        ),
      },
      {
        maxWidth: 150,
        headerName: "",
        field: "id",
        cellStyle: {
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          overflow: "visible",
        },
        lockPosition: "right",
        cellRenderer: actionCellRenderer,
        sortable: false,
      },
    ],
    // eslint-disable-next-line
    [invoices]
  );

  //------handle Add Invoice
  const handleAddInvoice = () => {
    toggleAddInvoiceModal();
  };

  //-------getTimeFrame
  const getTimeFrame = (value) => {
    setTimeFrame({
      ...timeFrame,
      start: moment
        .utc(value.start)
        .format(datepickerConstants.DATE_TIME_PICKER_DEFAULT_FORMAT),
      end: moment
        .utc(value.end)
        .format(datepickerConstants.DATE_TIME_PICKER_DEFAULT_FORMAT),
      duration: value?.duration,
    });
  };

  //------handle status change
  const handleStatusChange = (option) => {
    setStatus(option);
  };

  const handleDebouncedTypeSearch = useDebouncedCallback((value) => {
    setSearchText(value);
    updateGridState({ ...gridState, searchQuery: value });
  }, 1000);

  //-------handle search value input change
  const handleSearchInputChange = (value) => {
    setIsSearchLoading(true);
    setDebouncedSearchText(value);
    handleDebouncedTypeSearch(value);
  };

  //-------handle clear search text
  const clearSearchText = () => {
    handleSearchInputChange(EMPTY_STRING);
  };

  //-------handleFulfillInvoice
  const handleFulfillInvoice = async (
    params,
    invoiceGridState,
    handleCloseModal = () => {}
  ) => {
    try {
      fulfillInvoice(params.data, handleCloseModal, invoiceGridState);
      // let addInventoryRes = await handleSubmitAddBatchInventory(
      //   currentStore,
      //   activateSpinner,
      //   deactivateSpinner,
      //   handleCloseModal, //handleCloseModal
      //   () => {}, //handleClearFilter
      //   params.data.inventory,
      //   (...props) => {
      //     addBatchInventorySubmit(...props, params.data);
      //   },
      //   invoiceConstants.GET_ALL_INVOICES
      // );
    } catch (e) {
      console.log(e);
      customToast(toastMessages.SOMETHING_WENT_WRONG, toastType.ERROR);
    }
  };

  //-------handleDeleteInvoice
  const handleDeleteInvoice = (params) => {
    if (currentStore?.id && params?.data?.id) {
      deleteInvoice(currentStore?.id, params?.data?.id, gridState);
    }
  };

  //-------handleEditInvoice
  const handleEditInvoice = (params) => {
    if (params?.data) {
      setEditInvoiceData(params.data);
      setInvoiceBatchInventory(params?.data?.inventory);
      toggleAddInvoiceModal();
    }
  };

  //-------update gridState
  const updateGridState = (gridState) => {
    if (
      currentStore?.id &&
      timeFrame?.start &&
      timeFrame?.end &&
      status?.value
    ) {
      getAllInvoices(currentStore?.id, gridState);
    }
    setGridState(gridState);
  };

  //-------Grid Data
  // helper functions
  const gridData = useMemo(() => {
    let filteredData = invoices?.invoice || [];
    setGridState({
      ...gridState,
      pageIndex: invoices?.pageNumber || defaultGridState?.pageIndex,
      totalRecords: invoices?.totalRecords || defaultGridState?.totalRecords,
      pageEndRecordNumber:
        invoices?.pageEndRecordNumber || defaultGridState?.pageEndRecordNumber,
      pageStartRecordNumber:
        invoices?.pageStartRecordNumber ||
        defaultGridState?.pageStartRecordNumber,
    });
    setIsSearchLoading(false);
    if (filteredData?.length) {
      setFulfilledInvoiceData(
        filteredData?.find(
          (invoice) => invoice?.id === fulfilledInvoiceData?.id
        )
      );
    }
    return filteredData;
  }, [invoices]);

  //-------useEffect
  useEffect(() => {
    if (currentStore && timeFrame.start && timeFrame.end) {
      updateGridState({
        ...gridState,
        filters: prepareFilterForInvoiceTable(
          status,
          timeFrame.start,
          timeFrame.end
        ),
      });
    }
  }, [currentStore, timeFrame, status]);

  return (
    <>
      <Topbar title="Invoices">
        <Button
          IconImage={AddIcon}
          handleClick={handleAddInvoice}
          label={buttonNameConstants.ADD_INVOICE}
        />
      </Topbar>
      <Row className="m-0">
        <Col md={12} className=" d-flex gap-2 align-items-center">
          <DashboardDatePicker label="Date" getTimeFrame={getTimeFrame} />
          <CategoriesDropdown
            label="Status"
            isClearable={false}
            placeHolder="Select Status"
            noOptionsMessage="No Status Found"
            options={invoiceConstants.STATUS_OPTIONS}
            className={"filter-dropdowns-activity-type "}
            SelectIconImage={categoryDropdownConstants.BEND_ICON}
            handleChange={handleStatusChange}
            defaultValue={status}
          />
          <SearchField
            onChange={(e) => handleSearchInputChange(e.target.value)}
            IconImage={SearchIcon}
            label="Search"
            placeHolder="Search Vendor, Invoice Number, or Item"
            value={debouncedSearchText}
            CrossIcon={debouncedSearchText ? CrossIcon : ""}
            clearSearchText={clearSearchText}
            LoadingIndicator={
              isSearchLoading ||
              (isSpinnerEnabled(
                spinnerArray,
                invoiceConstants.GET_ALL_INVOICES
              ) &&
                searchText)
                ? LoadingIndicator
                : false
            }
          />
        </Col>
      </Row>
      <Row className={`m-0 w-100 h-100 user-screen-wrapper mt-3`}>
        <ServerPaginationTable
          colDefs={invoiceColumns}
          gridRef={gridRef}
          gridData={
            isSpinnerEnabled(spinnerArray, invoiceConstants.GET_ALL_INVOICES)
              ? skeletonArray
              : gridData
          }
          gridState={gridState}
          getRowId={getRowId}
          getRowStyle={getRowStyle}
          updateGridState={updateGridState}
          paginationPageSize={gridState.pageSize}
          pageSizeOptions={gridState.pageSizeOptions}
          isDisablePaginationActions={isSpinnerEnabled(
            spinnerArray,
            invoiceConstants.GET_ALL_INVOICES
          )}
          tableHeight={`calc(100vh - 220px)`}
        />
      </Row>
      {isAddInvoiceModal && (
        <AddInvoice
          invoiceGridState={gridState}
          editInvoiceData={editInvoiceData}
          isAddInvoiceModal={isAddInvoiceModal}
          setEditInvoiceData={setEditInvoiceData}
          handleFulfillInvoice={handleFulfillInvoice}
          toggleAddInvoiceModal={toggleAddInvoiceModal}
        />
      )}
      {isViewInvoiceModal && (
        <ViewInvoice
          invoiceGridState={gridState}
          invoiceData={fulfilledInvoiceData}
          isViewInvoiceModal={isViewInvoiceModal}
          toggleViewInvoiceModal={toggleViewInvoiceModal}
        />
      )}
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  invoices: state.invoice.invoices,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  getAllInvoices: (storeId, gridState) =>
    dispatch(invoiceActions.getAllInvoices(storeId, gridState)),
  deleteInvoice: (storeId, id, gridState) =>
    dispatch(invoiceActions.deleteInvoice(storeId, id, gridState)),
  setInvoiceBatchInventory: (invoiceBatch) =>
    dispatch(invoiceActions.setInvoiceBatchInventory(invoiceBatch)),
  addBatchInventorySubmit: (
    data,
    handleCloseModal,
    handleClearFilter,
    invoice,
    gridState
  ) =>
    dispatch(
      inventoryActions.addBatchInventory(
        data,
        handleCloseModal,
        handleClearFilter,
        invoice,
        gridState
      )
    ),
  fulfillInvoice: (invoice, handleCloseModal, gridState) =>
    dispatch(
      invoiceActions.fulfillInvoice(invoice, handleCloseModal, gridState)
    ),
});

//-------Export AddInvoice Component
export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
