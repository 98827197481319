import React, { useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";

import Button from "../Buttons/Button";
import {
  DeleteIcon,
  EditIcon,
  PrintIcon,
} from "../../../assets/icons/iconsProvider";
import { buttonNameConstants, buttonTypeConstants } from "../../../constants";
import CustomGridHeader from "./CustomGridHeader";

const AGGridTable = ({
  colDefs,
  gridRef,
  gridData,
  searchText,
  getRowStyle,
  tableHeight,
  selectedRows,
  defaultColDef,
  onCellClicked,
  rowHeight = 60,
  components = null,
  rowClassRules = {},
  pagination = true,
  onSelectionChanged,
  animateRows = true,
  getRowId = () => {},
  editType = "fullRow",
  tooltipShowDelay = 0,
  handleDeleteAllClick,
  handlePrintLabelClick,
  singleClickEdit = true,
  tooltipHideDelay = 1000,
  paginationPageSize = 50,
  tooltipInteraction = true,
  suppressCellFocus = true,
  rowSelection = "multiple",
  alwaysShowVerticalScroll = false,
  suppressRowClickSelection = true,
  suppressRowHoverHighlight = true,
  alwaysShowHorizontalScroll = false,
  pageSizeOptions = [20, 50, 100, 200],
  suppressDragLeaveHidesColumns = true,
  defaultGridSizeStrategy = {
    type: "fitGridWidth",
    defaultMinWidth: 100,
  },
}) => {
  const onFirstDataRendered = useCallback((params) => {
    params.api.sizeColumnsToFit();
  }, []);

  const agGridComponents = useMemo(() => {
    return {
      agColumnHeader: CustomGridHeader,
    };
  }, []);

  const onGridSizeChanged = useCallback(
    (params) => {
      // get the current grids width
      var gridWidth = document.querySelector(".ag-body-viewport").clientWidth;
      // keep track of which columns to hide/show
      var columnsToShow = [];
      var columnsToHide = [];
      // iterate over all columns (visible or not) and work out
      // now many columns can fit (based on their minWidth)
      var totalColsWidth = 0;
      var allColumns = params.api.getColumns();
      if (allColumns && allColumns.length > 0) {
        for (var i = 0; i < allColumns.length; i++) {
          var column = allColumns[i];
          totalColsWidth += column.getMinWidth() || 0;
          if (totalColsWidth > gridWidth) {
            columnsToHide.push(column.getColId());
          } else {
            columnsToShow.push(column.getColId());
          }
        }
      }
      // show/hide columns based on current grid width
      params.api.setColumnsVisible(columnsToShow, true);
      params.api.setColumnsVisible(columnsToHide, false);
      // wait until columns stopped moving and fill out
      // any available space to ensure there are no gaps
      window.setTimeout(() => {
        params.api.sizeColumnsToFit();
      }, 10);
    },
    [window]
  );

  return (
    <div
      className="ag-theme-quartz" // applying the grid theme
      style={{
        height: tableHeight,
      }} // the grid will fill the size of the parent container
    >
      {selectedRows?.length > 0 && (
        <div className="d-flex justify-content-end gap-2 py-2">
          <Button
            label={buttonNameConstants.DELETE}
            buttonType={buttonTypeConstants.RED_BUTTON}
            IconImage={DeleteIcon}
            type="button"
            handleClick={() => handleDeleteAllClick()}
          />
          <Button
            label={buttonNameConstants.PRINT_LABEL}
            type="button"
            IconImage={PrintIcon}
            buttonType={buttonTypeConstants.LIGHT_PURPLE}
            handleClick={() => handlePrintLabelClick()}
          />
        </div>
      )}
      <AgGridReact
        ref={gridRef}
        rowData={gridData}
        getRowId={getRowId}
        editType={editType}
        columnDefs={colDefs}
        pagination={pagination}
        components={components || agGridComponents}
        animateRows={animateRows}
        getRowStyle={getRowStyle}
        rowSelection={rowSelection}
        quickFilterText={searchText}
        rowClassRules={rowClassRules}
        onCellClicked={onCellClicked}
        defaultColDef={defaultColDef}
        singleClickEdit={singleClickEdit}
        tooltipShowDelay={tooltipShowDelay}
        tooltipHideDelay={tooltipHideDelay}
        suppressCellFocus={suppressCellFocus}
        onGridSizeChanged={onGridSizeChanged}
        paginationPageSize={paginationPageSize}
        onSelectionChanged={onSelectionChanged}
        tooltipInteraction={tooltipInteraction}
        onFirstDataRendered={onFirstDataRendered}
        autoSizeStrategy={defaultGridSizeStrategy}
        paginationPageSizeSelector={pageSizeOptions}
        alwaysShowVerticalScroll={alwaysShowVerticalScroll}
        suppressRowClickSelection={suppressRowClickSelection}
        suppressRowHoverHighlight={suppressRowHoverHighlight}
        alwaysShowHorizontalScroll={alwaysShowHorizontalScroll}
        suppressDragLeaveHidesColumns={suppressDragLeaveHidesColumns}
        rowHeight={rowHeight}
      />
    </div>
  );
};

export default AGGridTable;
