import React, { useState } from "react";

import {
  handleEmptyOnFocus,
  handleKeyDown,
  parseToDecimalNumber,
  parseToNumber,
} from "../../utility";
import {
  StepUpNumberIcon,
  StepDownNumberIcon,
} from "../../../assets/icons/iconsProvider";

//-------Simple Number Field component start here
const SimpleNumberField = (props) => {
  const {
    value,
    label,
    onChange,
    IconImage,
    className,
    placeHolder,
    isHandleKeyDown = false,
    minimumValue = 0,
    disabled = false,
    maxValue = 999999999,
    isConvertToDecimal = true,
    step = "0.01",
  } = props;
  // Function to handle incrementing the value
  const handleIncrement = (e) => {
    e.preventDefault();
    if (value < maxValue) {
      onChange(parseToNumber(parseToNumber(value) + 1));
    }
  };

  // Function to handle decrementing the value
  const handleDecrement = (e) => {
    e.preventDefault();
    const updatedValue = parseToNumber(value);
    if (updatedValue > minimumValue) {
      onChange(parseToNumber(updatedValue - 1 > 0 ? updatedValue - 1 : 0));
    }
  };
  const handleInputValueChange = (value) => {
    const newValue = value;

    if (newValue <= maxValue) {
      onChange(newValue);
    }
  };

  //-------Return Simple Number Field Component
  return (
    <div
      className={`input-field-wrapper input-number-field ${className} ${
        disabled && "disabled-number-field"
      }`}
    >
      {IconImage && <IconImage className="icon-image" />}
      <input
        step={step}
        value={value}
        type="number"
        max={maxValue}
        autoComplete="off"
        min={minimumValue}
        disabled={disabled}
        placeholder={placeHolder}
        onChange={(e) => handleInputValueChange(e.target.value)}
        className={`input-textfield input-number-field ${
          IconImage || "input-textfield-without-icon"
        } ${disabled && "disabled-number-field"} ${className && className}`}
        onBlur={(e) => {
          if (isConvertToDecimal) {
            const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
            handleInputValueChange(inputValue);
          }
        }}
        onFocus={handleEmptyOnFocus}
        onKeyDown={isHandleKeyDown ? handleKeyDown : (e) => {}}
      />
      <div className="number-field-wrapper">
        <button type="button" disabled={disabled} onClick={handleIncrement}>
          <StepUpNumberIcon className="p-0 number-field-arrow-icon" />
        </button>
        <button type="button" disabled={disabled} onClick={handleDecrement}>
          <StepDownNumberIcon className="p-0 number-field-arrow-icon" />
        </button>
      </div>
    </div>
  ); //-------Simple Number Field component end here
};

//-------Export InputTextField Component
export default SimpleNumberField;
