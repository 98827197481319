import React from "react";

const PlainInputNumberField = ({ value, onChange, step = "0.01" }) => {
  const handleClick = (e) => {
    e.stopPropagation();
  };
  return (
    <input
      min={0}
      type="number"
      step={step}
      value={value}
      defaultValue={value}
      className="input-textfield input-textfield-without-icon  editable-table-row-fields register-input-field"
      onChange={onChange}
      onClick={handleClick}
    ></input>
  );
};

export default PlainInputNumberField;
