import qz from "qz-tray";
import { KEYUTIL, KJUR, hextorstr, stob64 } from "jsrsasign";

import {
  inventoryConstants,
  outletConstants,
  toastMessages,
  toastType,
} from "../../../constants";
import {
  customToast,
  formatedDateTime,
  formatOutletSessionId,
  parseToDecimalNumber,
} from "../../utility";
import { printZebraLabel } from "../ZebraPrinter/zebraPrinter";
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from "../../../system/storage";
import { prepareCashManagementReceiptForPrinter } from "./prepareReceiptForPrinter";

qz.security.setCertificatePromise(function (resolve, reject) {
  resolve(process.env.REACT_APP_QZ_DIGITAL_CERTIFICATE);
});

var privateKey = process.env.REACT_APP_QZ_PRIVATE_KEY;

qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
qz.security.setSignaturePromise(function (toSign) {
  return function (resolve, reject) {
    try {
      var pk = KEYUTIL.getKey(privateKey);
      var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
      sig.init(pk);
      sig.updateString(toSign);
      var hex = sig.sign();
      resolve(stob64(hextorstr(hex)));
    } catch (err) {
      console.error(err);
      reject(err);
    }
  };
});

// Function to connect to QZ Tray
export const connectToQZTray = () => {
  return qz.websocket
    .connect()
    .then(() => {})
    .catch((error) => {
      console.log("Connect To QZ Error : ", error);
    });
};

// Function to disconnect from QZ Tray
export const disconnectFromQZTray = () => {
  return qz.websocket
    .disconnect()
    .then(() => {})
    .catch((error) => {
      console.log("Disconnect To QZ Error : ", error);
    });
};

export const printTestReceiptData = (printer, receiptData) => {
  connectToQZTray().then(async () => {
    await qz.printers
      .find(printer)
      .then(function (printer) {
        // Print EPoS data to the default printer
        var configuration = qz.configs.create(printer);
        qz.print(configuration, receiptData)
          .then(() => {
            disconnectFromQZTray();
          })
          .catch(function (error) {
            // customToast(error.toString(), toastType.ERROR);
            console.error("Error printing:", error);
          });
      })
      .catch(function (error) {
        // customToast(error.toString(), toastType.ERROR);
        console.error("Error during find printer:", error);
      });
  });
};

//-------Get Mac Address of the computer device
export const getMacAddress = async () => {
  //------return if mac address found in local storage
  const localMac = getItemFromLocalStorage(outletConstants.MAC_ADDRESS);
  if (localMac) {
    return localMac;
  }

  //------if mac address not found in local storage then get from qz connection
  return connectToQZTray()
    .then(async () => {
      const macAddress = await qz.networking
        .device()
        .then((device) => device.mac);
      //-------set mac address to local storage
      setItemToLocalStorage(outletConstants.MAC_ADDRESS, macAddress);
      await disconnectFromQZTray();
      return macAddress;
    })
    .catch((error) => {
      console.error("Error getting MAC address:", error);
      disconnectFromQZTray();
    });
};

//Function to print receipt data
export const printReceiptData = async (
  receiptData,
  printer,
  isShowToast = true
) => {
  if (printer) {
    printTestReceiptData(printer.generalSettings.productName, receiptData);
  } else if (isShowToast) {
    customToast(toastMessages.NO_PRINTER_DEVICE_FOUND, toastType.ERROR);
  }
};

export const printZebraData = async (
  labelData,
  labelDesignType,
  storeLogo,
  printer,
  deactivateSpinner
) => {
  if (!printer) {
    return customToast(toastMessages.NO_PRINTER_DEVICE_FOUND, toastType.ERROR);
  }

  deactivateSpinner(inventoryConstants.LABELS_PRINTING);
  try {
    await connectToQZTray();
    const printerName = await qz.printers.find(printer?.printerName);
    // Print EPoS data to the default printer
    let configuration = qz.configs.create(printerName);
    for (let i = 0; i < labelData.length; i++) {
      const { printQuantity } = labelData[i];
      for (let j = 0; j < printQuantity; j++) {
        const label = printZebraLabel(labelDesignType, labelData[i], storeLogo);
        try {
          await qz.print(configuration, label);
        } catch (error) {
          customToast(error.toString(), toastType.ERROR);
          console.error("Error printing:", error);
        }
      }
    }
  } catch (error) {
    customToast(error.toString(), toastType.ERROR);
    console.error("Error during find printer:", error);
  } finally {
    disconnectFromQZTray();
  }
};

export const printCashManagementReceipt = (data, printer) => {
  const getReceiptBalanceValue = (amount) => {
    return data.type === outletConstants.CASH_OUT ||
      data.type === outletConstants.PETTY_CASH_OUT
      ? `-$${amount}`
      : `$${amount}`;
  };
  let printReceiptDataArr = [];
  let prepareReceiptData = {};
  prepareReceiptData = {
    header: {
      receiptTitle: data.type,
      storeName: data.store?.name,
      receiptBalance: getReceiptBalanceValue(data.amount),
    },
  };

  switch (data.type) {
    case outletConstants.CASH_IN:
    case outletConstants.CASH_OUT:
    case outletConstants.PETTY_CASH_IN:
    case outletConstants.PETTY_CASH_OUT:
      prepareReceiptData = {
        ...prepareReceiptData,
        body: [
          {
            leftValue: "Date",
            rightValue: formatedDateTime(new Date().toISOString()),
          },
          { leftValue: "User", rightValue: data.user.name },
          { leftValue: "Register", rightValue: data.outlet.name },
          { leftValue: "Note", rightValue: data.note },
        ],
      };
      printReceiptDataArr =
        prepareCashManagementReceiptForPrinter(prepareReceiptData);
      break;
    case outletConstants.OPEN_REGISTER:
      prepareReceiptData = {
        header: {
          ...prepareReceiptData.header,
          receiptTitle: "Starting Balance",
          receiptBalance: getReceiptBalanceValue(
            parseToDecimalNumber(data.startingBalance)
          ),
        },
        body: [
          { leftValue: "Outlet", rightValue: data.outlet.name },
          {
            leftValue: "Session ID",
            rightValue: formatOutletSessionId(data.sessionCount),
          },
          { leftValue: "User", rightValue: data.openBy.name },
          {
            leftValue: "Opening Time",
            rightValue: formatedDateTime(data.openAt),
          },
        ],
      };
      printReceiptDataArr = prepareCashManagementReceiptForPrinter(
        prepareReceiptData,
        true
      );
      break;
    case outletConstants.CLOSE_REGISTER:
      prepareReceiptData = {
        header: {
          ...prepareReceiptData.header,
          receiptTitle: "Ending Balance",
          receiptBalance: getReceiptBalanceValue(
            parseToDecimalNumber(data.countedBalance)
          ),
        },
        body: [
          { leftValue: "Outlet", rightValue: data.outlet.name },
          {
            leftValue: "Session ID",
            rightValue: formatOutletSessionId(data.sessionCount),
          },
          { leftValue: "Closure", rightValue: data.sessionCount },
          { leftValue: "User", rightValue: data.openBy.name },
          {
            leftValue: "Opening Time",
            rightValue: formatedDateTime(data.openAt),
          },
          {
            leftValue: "Closing Time",
            rightValue: formatedDateTime(data.closeAt),
          },
        ],
      };
      printReceiptDataArr = prepareCashManagementReceiptForPrinter(
        prepareReceiptData,
        true
      );
      break;
    default:
      printReceiptDataArr = null;
  }

  if (printReceiptDataArr) {
    printReceiptData(printReceiptDataArr, printer, true);
  }
};
