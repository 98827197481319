import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";

import { outletActions } from "../../../../redux/actions";
import { routesPathConstants } from "../../../../constants";
import Topbar from "../../../../shared/components/topbar/Topbar";
import { createCompanyDynamicUrl } from "../../../../shared/utility";
import SessionDetail from "../../../../shared/components/sessionSummaryTable/SessionDetail";
import SessionSummaryTable from "../../../../shared/components/sessionSummaryTable/SessionSummaryTable";

const SessionDetailHistory = (props) => {
  //------- props
  const { outlet, currentStore, getSessionHistory } = props;
  const { state } = useLocation();
  const navigate = useNavigate();

  //-------reducers
  const { singleSessionHistory } = outlet;
  //-------useEffect
  useEffect(() => {
    if (currentStore?.id) {
      getSessionHistory(currentStore?.id, state?.data?.id);
    }
  }, [currentStore]);

  useEffect(() => {
    //------if Session Id or data not found redirect to main page
    if (!state?.data) {
      navigate(
        createCompanyDynamicUrl(
          routesPathConstants.SELL_AND_TRADE_REGISTER_SESSIONS
        )
      );
    }
  }, []);

  return (
    <>
      <Topbar
        title="Session History"
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.SELL_AND_TRADE_REGISTER_SESSIONS,
        }}
      ></Topbar>
      <Row className="m-0 session-history-wrapper container-scrollbar">
        <Col md={12}>
          <span className="last-closure-heading">Session Details</span>
        </Col>
        <Col md={5}>
          <SessionDetail
            detailWidth="w-100"
            session={state?.data}
            isCloseSessionDetails={true}
          />
        </Col>
        <Col md={7} className="d-flex flex-column">
          <span className="last-closure-detail-heading px-0">
            Closure Notes:{" "}
          </span>
          <span>{state?.data?.closeNote || ""}</span>
        </Col>
        <hr className="my-5" />
        <Col md={12}>
          <SessionSummaryTable
            sessionDetailHistory={singleSessionHistory}
            session={state?.data}
          />
        </Col>
      </Row>
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  outlet: state.outlet,
  user: state.authentication.user,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getSessionHistory: (storeId, sessionId) =>
    dispatch(outletActions.getSessionHistory(storeId, sessionId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionDetailHistory);
