import { Col, Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import React, { useEffect, useRef, useState } from "react";

import TableCustomHeader from "../table/TableCustomHeader";
import TransactionHistoryModal from "./TransactionHistoryModal";
import {
  ShowIcon,
  DownloadIcon,
  PrintSaleSummaryIcon,
} from "../../../assets/icons/iconsProvider";
import {
  getTimeOfTransaction,
  combineSessionHistory,
  parseToThousandSeperatorDecimalNumber,
  downloadCSV,
  formatedDateTime,
  getSessionDurationTime,
  isCashOut,
} from "../../utility";
import moment from "moment";
import { EMPTY_STRING, outletConstants } from "../../../constants";
import PlainInputNumberField from "../inputNumberField/PlainInputNumberField";
import SessionDetail from "./SessionDetail";

const PaymentRow = ({
  onClick,
  counted,
  expected,
  difference,
  isExpanded,
  paymentType,
  isCloseRegisterTable,
}) => (
  <tr
    className={`session-summary-footer-total-row session-summary-data-row cursor-pointer ${
      isExpanded ? "expanded-row" : ""
    }`}
    onClick={onClick}
  >
    <th>
      <span>{paymentType}</span>
    </th>
    <th></th>
    <th>
      <span>{expected}</span>
    </th>
    <th>
      <span className={""}>
        {isCloseRegisterTable ? (
          <>
            <PlainInputNumberField
              value={isCloseRegisterTable.value}
              onChange={(e) =>
                isCloseRegisterTable.handleChnage(e.target.value)
              }
            />
          </>
        ) : (
          counted
        )}
      </span>
    </th>
    <th>
      <span
        className={`${Number(difference) < 0 ? "text-danger" : "text-primary"}`}
      >
        {difference}
      </span>
    </th>
  </tr>
);

const TotalPaymentRow = ({ paymentType, expected, counted, difference }) => (
  <tr className={`session-summary-footer-total-row`}>
    <th>
      <span>{paymentType}</span>
    </th>
    <th></th>
    <th>
      <span>{expected}</span>
    </th>
    <th>
      <span>{counted}</span>
    </th>
    <th>
      <span
        className={`${Number(difference) < 0 ? "text-danger" : "text-primary"}`}
      >
        {difference}
      </span>
    </th>
  </tr>
);

const SubPaymentRow = ({ paymentType, expected, counted, difference }) => (
  <tr
    className={`session-summary-footer-total-row session-summary-sub-data-row`}
  >
    <th>
      <span className="ps-5 text-nowrap">{paymentType}</span>
    </th>
    <th></th>
    <th>
      <span>{expected}</span>
    </th>
    <th>
      <span>{counted}</span>
    </th>
    <th>
      <span>{difference}</span>
    </th>
  </tr>
);

const CashMovements = ({ movements, session }) => {
  return (
    <Row className="w-100 m-0 session-summary-detail-row">
      <Col xs={3} className="expanded-row-second-heading">
        Time
      </Col>
      <Col xs={4} className="expanded-row-second-heading">
        User
      </Col>
      <Col xs={5} className="justify-content-end expanded-row-second-heading">
        Amount ($)
      </Col>
      {movements.map((movement, index) => (
        <>
          <Col key={`time-${index}`} xs={3} className="expanded-row-detail">
            {getTimeOfTransaction(movement.time)}
          </Col>
          <Col key={`user-${index}`} xs={4} className="expanded-row-detail">
            {session?.openBy?.name}
          </Col>
          <Col
            key={`amount-${index}`}
            xs={5}
            className={`d-flex justify-content-end align-items-center expanded-row-detail ${
              isCashOut(movement.activityType) ? "text-danger" : "text-primary"
            }`}
          >
            {`${
              isCashOut(movement.activityType) ? "-" : ""
            } ${parseToThousandSeperatorDecimalNumber(movement.amount)}`}
          </Col>
        </>
      ))}
    </Row>
  );
};

const ActivityLog = ({ activities, handleViewTransactionDetail }) => (
  <Row className="w-100 m-0 session-summary-detail-row">
    <Col xs={9} className="expanded-row-second-heading ps-5">
      Activity
    </Col>
    <Col
      xs={3}
      className="d-flex justify-content-end align-items-center expanded-row-second-heading"
    >
      View
    </Col>
    {activities?.map((activity, index) => (
      <>
        <Col
          key={`activity-${index}`}
          xs={9}
          className="expanded-row-detail ps-5"
        >
          <div className="d-flex flex-column justify-content-center ">
            <span>{activity.activityType}</span>
            <span>
              <TableCustomHeader
                id={`created-on-in-array-${index}-open`}
                label={
                  <span className="inventory-cell-max-size activity-detail">
                    {activity.note}
                  </span>
                }
                tooltopText={
                  <p className="text-start fw-normal m-0">{activity.note}</p>
                }
                className="sku-tooltip"
              />
            </span>
          </div>
        </Col>
        <Col
          key={`view-${index}`}
          xs={3}
          className="d-flex justify-content-end align-items-center expanded-row-detail"
        >
          {activity?.hasObject ? (
            <span
              className="cursor-pointer"
              onClick={() => handleViewTransactionDetail(activity?.entryObject)}
            >
              <ShowIcon className="transaction-view-icon" />
            </span>
          ) : (
            ""
          )}
        </Col>
      </>
    ))}
  </Row>
);

const ExpandedRow = ({
  isExpanded,
  data,
  session,
  handleViewTransactionDetail,
}) => (
  <tr
    className={`session-summary-transactions-detail-wrapper ${
      isExpanded ? "" : "d-none"
    }`}
  >
    <td className="d-flex">
      <span className="expanded-row-heading ps-5 w-100 h-100">
        Cash Movements
      </span>
    </td>
    <td colSpan={2} className="px-0">
      <CashMovements movements={data} session={session} />
    </td>
    <td colSpan={2} className="px-0">
      <ActivityLog
        activities={data}
        session={session}
        handleViewTransactionDetail={handleViewTransactionDetail}
      />
    </td>
  </tr>
);

const SessionSummaryTable = ({
  session,
  isCloseRegisterTable = false,
  isDownloadButton = true,
  sessionDetailHistory,
  tableHeading = "Session Payments Summary",
}) => {
  const [data, setData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [transactionData, setTransactionData] = useState({});
  const [isTransactionHistoryModal, setIsTransactionHistory] = useState(false);

  //-------toggle transaction history modal
  const toggleTransactionHistoryModal = () => {
    setIsTransactionHistory(!isTransactionHistoryModal);
  };

  const contentRef = useRef();
  //------ react to print
  const printFunction = useReactToPrint({
    contentRef,
    documentTitle: "Sales Summary",
  });

  const toggleExpandableRow = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    setData(combineSessionHistory(sessionDetailHistory?.sessionHistory || {}));
  }, [sessionDetailHistory]);

  //------- handle download csv
  const handleDownloadCsv = () => {
    //------ use "-","--", "---" as keys to define header name of columns
    //------ use "--","--","--" as values to seperate section and put in place of empty cells
    downloadCSV(
      [
        {
          "-": "Outlet",
          "--": session?.outlet?.name,
          "---": ``,
          "----": "",
          "-----": "",
          "------": "",
          "-------": "",
        },
        {
          "-": "Closure #",
          "--": session?.sessionCount,
          "---": ``,
          "----": "",
          "-----": "",
          "------": "",
          "-------": "",
        },
        {
          "-": `Session ID`,
          "--": `Session-${session?.sessionCount}`,
          "---": ``,
          "----": "",
          "-----": "",
          "------": "",
          "-------": "",
        },
        {
          "-": "User",
          "--": session?.openBy?.name,
          "---": ``,
          "----": "",
          "-----": "",
          "------": "",
          "-------": "",
        },
        {
          "-": "Opening Time",
          "--": formatedDateTime(session?.openAt).replaceAll(",", "-"),
          "---": ``,
          "----": "",
          "-----": "",
          "------": "",
          "-------": "",
        },
        {
          "-": "Closing Time",
          "--": session?.closeAt
            ? formatedDateTime(session?.closeAt).replaceAll(",", "-")
            : "-",
          "---": ``,
          "----": "",
          "-----": "",
          "------": "",
          "-------": "",
        },
        {
          "-": "Session Length",
          "--": session?.closeAt ? getSessionDurationTime(session) : "-",
          "---": ``,
          "----": "",
          "-----": "",
          "------": "",
          "-------": "",
        },
        {
          "-": "--",
          "--": "--",
          "---": `--`,
          "----": "--",
          "-----": "--",
          "------": "--",
          "-------": "--",
        },
        {
          "-": "Payment Type",
          "--": "Expected ($)",
          "---": `Counted ($)`,
          "----": "Difference ($)",
          "-----": "",
          "------": "",
          "-------": "",
        },
        {
          "-": "Cash",
          "--": parseToThousandSeperatorDecimalNumber(
            sessionDetailHistory?.expectedBalance || 0
          ),
          "---": parseToThousandSeperatorDecimalNumber(
            session?.countedBalance || 0
          ),
          "----": isCloseRegisterTable
            ? parseToThousandSeperatorDecimalNumber(
                Number(isCloseRegisterTable?.value || 0) -
                  Number(sessionDetailHistory?.expectedBalance || 0)
              )
            : parseToThousandSeperatorDecimalNumber(
                Number(session?.countedBalance || 0) -
                  Number(sessionDetailHistory?.expectedBalance || 0)
              ),
          "-----": "",
          "------": "",
          "-------": "",
        },
        {
          "-": "Cash Movements",
          "--": "Time",
          "---": "User",
          "----": "Amount ($)",
          "-----": "Activity",
          "------": "",
          "-------": "",
        },
        ...data.map((item) => ({
          "-": "",
          "--": getTimeOfTransaction(item.time),
          "---": session?.openBy?.name,
          "----": parseToThousandSeperatorDecimalNumber(item.amount),
          "-----": `${item.activityType} ${
            item?.note ? `(${item?.note})` : ""
          }`,
          "------": "",
          "-------": "",
        })),
        {
          "-": "Cash Payments Received",
          "--": parseToThousandSeperatorDecimalNumber(
            sessionDetailHistory?.grossCashIn || 0
          ),
          "---": "",
          "----": "",
          "-----": "",
          "------": "",
          "-------": "",
        },
        {
          "-": "Trade Cash Given",
          "--": parseToThousandSeperatorDecimalNumber(
            sessionDetailHistory?.totalCashTrade || 0
          ),
          "---": "",
          "----": "",
          "-----": "",
          "------": "",
          "-------": "",
        },
        {
          "-": "Total",
          "--": parseToThousandSeperatorDecimalNumber(
            sessionDetailHistory?.expectedBalance || 0
          ),
          "---": parseToThousandSeperatorDecimalNumber(
            session?.countedBalance || 0
          ),
          "----": isCloseRegisterTable
            ? parseToThousandSeperatorDecimalNumber(
                Number(isCloseRegisterTable?.value || 0) -
                  Number(sessionDetailHistory?.expectedBalance || 0)
              )
            : parseToThousandSeperatorDecimalNumber(
                Number(session?.countedBalance || 0) -
                  Number(sessionDetailHistory?.expectedBalance || 0)
              ),
          "-----": "",
          "------": "",
          "-------": "",
        },
        {
          "-": "--",
          "--": "--",
          "---": `--`,
          "----": "--",
          "-----": "--",
          "------": "--",
          "-------": "--",
        },
        {
          "-": "Closure Notes:",
          "--": "",
          "---": ``,
          "----": "",
          "-----": "",
          "------": "",
          "-------": "",
        },
        {
          "-": session?.closeNote || EMPTY_STRING,
          "--": "",
          "---": ``,
          "----": "",
          "-----": "",
          "------": "",
          "-------": "",
        },
      ],
      "session-payment-summary",
      `${moment(session?.openAt).format("YYYY-MM-DD")}`
    );
  };

  const handleViewTransactionDetail = (data) => {
    setTransactionData(data);
    toggleTransactionHistoryModal();
  };

  useEffect(() => {
    if (data.length > 0) {
      const transaction = data.find((item) => item.id === transactionData.id);
      if (transaction) {
        setTransactionData(transaction.entryObject);
      }
    }
  }, [data]);
  return (
    <>
      <Row className="m-0 mb-3">
        <Col md={6}>
          <span className="last-closure-heading ps-md-0">{tableHeading}</span>
        </Col>
        <Col md={6} className="d-flex justify-content-end align-items-center">
          {isDownloadButton && (
            <>
              <button
                onClick={printFunction}
                className="download-summary-button"
              >
                <PrintSaleSummaryIcon />
              </button>
              <button
                onClick={handleDownloadCsv}
                className="download-summary-button"
              >
                <DownloadIcon />
              </button>
            </>
          )}
        </Col>
      </Row>
      <div className="session-summary-wrapper" ref={contentRef}>
        <span className="session-summary-detail-wrapper">
          <SessionDetail
            detailWidth="w-50"
            session={session}
            isCloseSessionDetails={true}
            className="px-4 pt-3 mb-5"
          />
        </span>

        <table className="session-summary-table">
          <thead>
            <tr>
              <th>
                <span>Payment Type</span>
              </th>
              <th></th>
              <th>
                <span>Expected ($)</span>
              </th>
              <th>
                <span>Counted ($)</span>
              </th>
              <th>
                <span>Differences ($)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <PaymentRow
              paymentType="Cash"
              expected={parseToThousandSeperatorDecimalNumber(
                sessionDetailHistory?.expectedBalance || 0
              )}
              counted={parseToThousandSeperatorDecimalNumber(
                session?.countedBalance || 0
              )}
              difference={
                isCloseRegisterTable
                  ? parseToThousandSeperatorDecimalNumber(
                      Number(isCloseRegisterTable?.value || 0) -
                        Number(sessionDetailHistory?.expectedBalance || 0)
                    )
                  : parseToThousandSeperatorDecimalNumber(
                      Number(session?.countedBalance || 0) -
                        Number(sessionDetailHistory?.expectedBalance || 0)
                    )
              }
              onClick={toggleExpandableRow}
              isExpanded={isExpanded}
              isCloseRegisterTable={isCloseRegisterTable}
            />
            <ExpandedRow
              isExpanded={isExpanded}
              data={data}
              session={session}
              handleViewTransactionDetail={handleViewTransactionDetail}
            />
            <SubPaymentRow
              paymentType="Cash Payments Received"
              expected={parseToThousandSeperatorDecimalNumber(
                sessionDetailHistory?.grossCashIn || 0
              )}
              counted=""
              difference=""
            />
            <SubPaymentRow
              paymentType="Trade Cash Given"
              expected={parseToThousandSeperatorDecimalNumber(
                sessionDetailHistory?.totalCashTrade || 0
              )}
              counted=""
              difference=""
            />
            <TotalPaymentRow
              paymentType="Total"
              expected={parseToThousandSeperatorDecimalNumber(
                sessionDetailHistory?.expectedBalance || 0
              )}
              counted={parseToThousandSeperatorDecimalNumber(
                isCloseRegisterTable
                  ? isCloseRegisterTable?.value
                  : session?.countedBalance || 0
              )}
              difference={
                isCloseRegisterTable
                  ? parseToThousandSeperatorDecimalNumber(
                      Number(isCloseRegisterTable?.value || 0) -
                        Number(sessionDetailHistory?.expectedBalance || 0)
                    )
                  : parseToThousandSeperatorDecimalNumber(
                      Number(session?.countedBalance || 0) -
                        Number(sessionDetailHistory?.expectedBalance || 0)
                    )
              }
            />
          </tbody>
        </table>

        <span className="session-summary-detail-wrapper">
          <Row className="m-0">
            <Col md={7} className="d-flex flex-column">
              <span className="last-closure-detail-heading px-0">
                Closure Notes:
              </span>
              <span>{session?.closeNote || ""}</span>
            </Col>
          </Row>
        </span>
      </div>
      {isTransactionHistoryModal && (
        <TransactionHistoryModal
          session={session}
          modalData={transactionData}
          isTransactionHistoryModal={isTransactionHistoryModal}
          toggleTransactionHistoryModal={toggleTransactionHistoryModal}
        />
      )}
    </>
  );
};

export default SessionSummaryTable;
